<template>
  <div class="select-none calendar">
    <header class="flex items-center justify-between border-b border-gray-200 py-4 px-6 lg:flex-none">
      <h1 class="text-base font-semibold leading-6 text-gray-900">
        <time :datetime="datetime">{{ date }}</time>
      </h1>
      <div class="flex items-center">
        <div class="relative flex items-center rounded-md bg-white shadow-sm md:items-stretch">
          <div class="pointer-events-none absolute inset-0 rounded-md ring-1 ring-inset ring-gray-300" aria-hidden="true" />
          <button @click="prevMonth" type="button" class="flex items-center justify-center rounded-l-md py-2 pl-3 pr-4 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:px-2 md:hover:bg-gray-50">
            <span class="sr-only">Previous month</span>
            <ChevronLeftIcon class="h-5 w-5" aria-hidden="true" />
          </button>
          <button @click="today" type="button" class="hidden px-3.5 text-sm font-semibold text-gray-900 hover:bg-gray-50 focus:relative md:block">Today</button>
          <span class="relative -mx-px h-5 w-px bg-gray-300 md:hidden" />
          <button @click="nextMonth" type="button" class="flex items-center justify-center rounded-r-md py-2 pl-4 pr-3 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:px-2 md:hover:bg-gray-50">
            <span class="sr-only">Next month</span>
            <ChevronRightIcon class="h-5 w-5" aria-hidden="true" />
          </button>
        </div>
      </div>
    </header>
    <div class="shadow ring-1 ring-black ring-opacity-5 lg:flex lg:flex-auto lg:flex-col">
      <div class="grid grid-cols-7 gap-px border-b border-gray-300 bg-gray-200 text-center text-xs font-semibold leading-6 text-gray-700 lg:flex-none">
        <div class="bg-white py-2">S<span class="sr-only sm:not-sr-only">un</span></div>
        <div class="bg-white py-2">M<span class="sr-only sm:not-sr-only">on</span></div>
        <div class="bg-white py-2">T<span class="sr-only sm:not-sr-only">ue</span></div>
        <div class="bg-white py-2">W<span class="sr-only sm:not-sr-only">ed</span></div>
        <div class="bg-white py-2">T<span class="sr-only sm:not-sr-only">hu</span></div>
        <div class="bg-white py-2">F<span class="sr-only sm:not-sr-only">ri</span></div>
        <div class="bg-white py-2">S<span class="sr-only sm:not-sr-only">at</span></div>
      </div>
      <div class="flex bg-gray-200 text-xs leading-6 text-gray-700 lg:flex-auto">
        <div class="hidden w-full lg:grid lg:grid-cols-7 lg:grid-rows-6 lg:gap-px">
          <div v-for="(day, idxD) in days" :key="`cal-${day.date}`" :class="[day.isCurrentMonth ? 'bg-white' : 'bg-gray-50 text-gray-500', 'relative py-2 px-3 min-h-[100px]']" @click="selectDay(idxD)" @dblclick="addEvent(idxD)">
            <time :datetime="day.date" :class="day.isToday ? 'flex h-6 w-6 items-center justify-center rounded-full bg-indigo-600 font-semibold text-white' : undefined">{{ day.date.split('-').pop().replace(/^0/, '') }}</time>
            <ol v-if="day.events.length > 0" class="mt-2 space-y-2">
              <li v-for="(event, idxE) in day.events.slice(0, 5)" :key="`cal-${day.date}-${idxE}`" class="text-gray-900">
                <div class="group flex items-center px-2 text-opacity-70 text-black bg-opacity-30 hover:bg-opacity-40 hover:text-opacity-80" :class="{
                  'bg-[#d43327]': event.scheduleable_type == '\\App\\Series',
                  'bg-[#93c8c5]': event.scheduleable_type == '\\App\\SeriesPost',
                  'bg-[#eb5525]': event.scheduleable_type == '\\App\\RealHopeMinute',
                }">
                  <base-popover :ref="`calendar-popover-${day.date}-${idxE}`" v-on:close="saveEvent(idxD, idxE)">
                    <template v-slot:icon>
                      <p class="flex-auto truncate font-medium" :title="`${event.scheduleable?.title} ${event.media ? '(' + mediaOptions.find(e => e.value == event.media)?.label + ')' : ''}`">
                        <video-camera-icon v-if="event.media?.indexOf('watch') > -1" class="w-3 h-3 inline-block align-middle" />
                        <volume-up-icon v-else-if="event.media?.indexOf('listen') > -1" class="w-3 h-3 inline-block align-middle" />
                        <span class="align-middle">{{ event.scheduleable?.title }} {{ event.media ? `(${mediaOptions.find(e => e.value == event.media)?.label})` : '' }}</span>
                      </p>
                    </template>
                    <div class="space-y-4 min-h-[400px] text-black">
                      <base-input label="Type">
                        <el-select v-model="days[idxD].events[idxE].scheduleable_type" :options="scheduleables" />
                      </base-input>
                      <base-input label="Item" v-if="days[idxD].events[idxE].scheduleable_type">
                        <el-select :ref="`cal-${idxD}-${idxE}-sched-id`" v-model="days[idxD].events[idxE].scheduleable_id" :recordset="scheduleables.find(sch => sch.value == event.scheduleable_type).recordset_id" @input="scheduleableUpdated(idxD, idxE)" />
                      </base-input>
                      <base-input label="Media" v-if="days[idxD].events[idxE].scheduleable_type == '\\App\\SeriesPost'">
                        <el-select :ref="`cal-${idxD}-${idxE}-media`" v-model="days[idxD].events[idxE].media" :options="mediaOptions" />
                      </base-input>
                      <base-input label="Date/Time" v-model="days[idxD].events[idxE].datetime" type="datetime-local" />
                    </div>
                    <template v-slot:btns>
                      <base-button type="danger" class="ml-4" @click="deleteEvent(idxD, idxE)">Delete</base-button>
                    </template>
                  </base-popover>
                  <base-time :datetime="event.datetime" format="LT" class="ml-3 hidden flex-none  opacity-80 xl:block" />
                </div>
              </li>
              <li v-if="day.events.length > 5" class=" opacity-80">+ {{ day.events.length - 2 }} more</li>
            </ol>
          </div>
        </div>
        <div class="isolate grid w-full grid-cols-7 grid-rows-6 gap-px lg:hidden">
          <button v-for="day in days" :key="day.date" type="button" :class="[day.isCurrentMonth ? 'bg-white' : 'bg-gray-50', (day.isSelected || day.isToday) && 'font-semibold', day.isSelected && 'text-white', !day.isSelected && day.isToday && 'text-indigo-600', !day.isSelected && day.isCurrentMonth && !day.isToday && 'text-gray-900', !day.isSelected && !day.isCurrentMonth && !day.isToday && 'text-gray-500', 'flex h-14 flex-col py-2 px-3 hover:bg-gray-100 focus:z-10']">
            <time :datetime="day.date" :class="[day.isSelected && 'flex h-6 w-6 items-center justify-center rounded-full', day.isSelected && day.isToday && 'bg-indigo-600', day.isSelected && !day.isToday && 'bg-gray-900', 'ml-auto']">{{ day.date.split('-').pop().replace(/^0/, '') }}</time>
            <span class="sr-only">{{ day.events.length }} events</span>
            <span v-if="day.events.length > 0" class="-mx-0.5 mt-auto flex flex-wrap-reverse">
              <span v-for="event in day.events" :key="event.id" class="mx-0.5 mb-1 h-1.5 w-1.5 rounded-full bg-gray-400" />
            </span>
          </button>
        </div>
      </div>
    </div>
    <ul class="flex py-4 gap-3 text-opacity-70 text-black text-xs">
      <li class="bg-[#d43327] bg-opacity-30 py-1 px-2 ">Series</li>
      <li class="bg-[#93c8c5] bg-opacity-30 py-1 px-2 ">Series Post</li>
      <li class="bg-[#eb5525] bg-opacity-30 py-1 px-2 ">Real Hope Minute</li>
    </ul>
    <div class="py-10 px-4 sm:px-6 space-y-2">
      <base-input label="Notes" type="textarea" v-model="cal.notes" />
      <base-button @click="updateCal">Update Notes</base-button>
    </div>
    <event-list v-bind="{mediaOptions, days}" @edit="editButton" />
  </div>
</template>
<style>
  .calendar .base-popover-container {
    max-width: calc(100% - 67px);
  }
  .calendar .base-popover-container > button {
    max-width: 100%;
  }
</style>
<script>
import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ClockIcon,
  DotsHorizontalIcon,
  VideoCameraIcon,
  VolumeUpIcon
} from '@vue-hero-icons/solid'
import moment from 'moment'
import Form from '../../../components/Form'
import EventList from './EventList'


const days = [
  { date: '2021-12-27', events: [] },
  { date: '2021-12-28', events: [] },
  { date: '2021-12-29', events: [] },
  { date: '2021-12-30', events: [] },
  { date: '2021-12-31', events: [] },
  { date: '2022-01-01', isCurrentMonth: true, events: [] },
  { date: '2022-01-02', isCurrentMonth: true, events: [] },
  {
    date: '2022-01-03',
    isCurrentMonth: true,
    events: [
      { id: 1, name: 'Design review', time: '10AM', datetime: '2022-01-03T10:00', href: '#' },
      { id: 2, name: 'Sales meeting', time: '2PM', datetime: '2022-01-03T14:00', href: '#' },
    ],
  },
  { date: '2022-01-04', isCurrentMonth: true, events: [] },
  { date: '2022-01-05', isCurrentMonth: true, events: [] },
  { date: '2022-01-06', isCurrentMonth: true, events: [] },
  {
    date: '2022-01-07',
    isCurrentMonth: true,
    events: [{ id: 3, name: 'Date night', time: '6PM', datetime: '2022-01-08T18:00', href: '#' }],
  },
  { date: '2022-01-08', isCurrentMonth: true, events: [] },
  { date: '2022-01-09', isCurrentMonth: true, events: [] },
  { date: '2022-01-10', isCurrentMonth: true, events: [] },
  { date: '2022-01-11', isCurrentMonth: true, events: [] },
  {
    date: '2022-01-12',
    isCurrentMonth: true,
    isToday: true,
    events: [{ id: 6, name: "Sam's birthday party", time: '2PM', datetime: '2022-01-25T14:00', href: '#' }],
  },
  { date: '2022-01-13', isCurrentMonth: true, events: [] },
  { date: '2022-01-14', isCurrentMonth: true, events: [] },
  { date: '2022-01-15', isCurrentMonth: true, events: [] },
  { date: '2022-01-16', isCurrentMonth: true, events: [] },
  { date: '2022-01-17', isCurrentMonth: true, events: [] },
  { date: '2022-01-18', isCurrentMonth: true, events: [] },
  { date: '2022-01-19', isCurrentMonth: true, events: [] },
  { date: '2022-01-20', isCurrentMonth: true, events: [] },
  { date: '2022-01-21', isCurrentMonth: true, events: [] },
  {
    date: '2022-01-22',
    isCurrentMonth: true,
    isSelected: true,
    events: [
      { id: 4, name: 'Maple syrup museum', time: '3PM', datetime: '2022-01-22T15:00', href: '#' },
      { id: 5, name: 'Hockey game', time: '7PM', datetime: '2022-01-22T19:00', href: '#' },
    ],
  },
  { date: '2022-01-23', isCurrentMonth: true, events: [] },
  { date: '2022-01-24', isCurrentMonth: true, events: [] },
  { date: '2022-01-25', isCurrentMonth: true, events: [] },
  { date: '2022-01-26', isCurrentMonth: true, events: [] },
  { date: '2022-01-27', isCurrentMonth: true, events: [] },
  { date: '2022-01-28', isCurrentMonth: true, events: [] },
  { date: '2022-01-29', isCurrentMonth: true, events: [] },
  { date: '2022-01-30', isCurrentMonth: true, events: [] },
  { date: '2022-01-31', isCurrentMonth: true, events: [] },
  { date: '2022-02-01', events: [] },
  { date: '2022-02-02', events: [] },
  {
    date: '2022-02-03',
    events: [{ id: 7, name: 'Cinema with friends', time: '9PM', datetime: '2022-02-04T21:00', href: '#' }],
  },
  { date: '2022-02-04', events: [] },
  { date: '2022-02-05', events: [] },
  { date: '2022-02-06', events: [] },
]
const selectedDay = days.find((day) => day.isSelected)
const eventBase = { scheduleable_type: '', scheduleable_id: '', scheduleable: {title: 'New Item'}, datetime: '2022-01-22T15:00' }
export default {
  name: 'Calendar',
  components: {
    EventList,
    ChevronDownIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
    ClockIcon,
    DotsHorizontalIcon,
    VideoCameraIcon,
    VolumeUpIcon,
    ...Form
  },
  props: {
    cal: {
      type: Object,
      default: () => {
        return {
          year: 0,
          month: 0,
          notes: ''
        }
      }
    },
    days: {
      type: Array,
      default: () => []
    },
    year: Number,
    month: Number,
    day: Number
  },

  computed: {
    date() {
      return `${this.months[this.month-1]} ${this.year}`;
    },
    datetime() {
      return `${this.year}-${new String(this.month).padStart(2, '0')}`;
    },
    selectedDay() {
      return this.days.find((day) => day.isSelected)
    }
  },

  methods: {
    togglePopover(date, idxE) {
      const popoverRef = this.$refs[`calendar-popover-${date}-${idxE}`];
      if (popoverRef && popoverRef.length) {
        popoverRef[0].toggle();
      } else {
        console.warn(this.$refs);
      }
    },
    nextMonth() {
      this.$inertia.visit(route('cms.broadcast-calendar.index', {'year-month': moment(`${this.datetime}-${new String(this.day).padStart(2, '0')}`).add(1, 'month').format(`YYYY-MM`) }))
    },
    prevMonth() {
      this.$inertia.visit(route('cms.broadcast-calendar.index', {'year-month': moment(`${this.datetime}-${new String(this.month).padStart(2, '0')}`).subtract(1, 'month').format(`YYYY-MM`) }))
    },
    today() {
      this.$inertia.visit(route('cms.broadcast-calendar.index'))
    },
    addEvent(idx) {
      if (this.days[idx].isCurrentMonth) {
        if (!Array.isArray(this.days[idx].events)) {
          this.days[idx].events = [];
        }
        const evt = JSON.parse(JSON.stringify(eventBase));
        evt.datetime = moment(this.days[idx].date).format(moment.HTML5_FMT.DATETIME_LOCAL);
        evt.isEditing = true;
        this.days[idx].events.push(evt);
        this.$nextTick(() => {
          this.togglePopover(this.days[idx].date, this.days[idx].events.length-1);
        });
      } else {
        this.$inertia.visit(route('cms.broadcast-calendar.index', {'year-month': moment(this.days[idx].date).format(`YYYY-MM`) }));
      }
    },
    deleteEvent(idxD, idxE) {
      const confirmed = window.confirm('Are you sure?');
      const evt = this.days[idxD].events[idxE];
      if (confirmed) {
        if (evt.id) {
          this.$CMS_DELETE('broadcast-calendar', {item: evt.id}).then(() => {
            this.$inertia.reload();
          });
        } else {
          this.$inertia.reload();
        }
      }
    },
    saveEvent(idxD, idxE) {
      const evt = this.days[idxD].events[idxE];
      if (evt) {
        if (evt.scheduleable_id && evt.scheduleable_type && evt.datetime) {
          if (evt.id) {
            this.$CMS_UPDATE('broadcast-calendar', {item: evt.id}, evt, true).then((data) => {
              this.$inertia.reload();
            });
          } else {
            this.$CMS_CREATE('broadcast-calendar', {}, evt, true).then((data) => {
              this.$inertia.reload();
            });
          }
        }
      }
    },
    scheduleableUpdated(idxD, idxE) {
      const ref = this.$refs[`cal-${idxD}-${idxE}-sched-id`];
      if (ref.length && ref[0].$children.length) {
        setTimeout(() => {
          this.days[idxD].events[idxE].scheduleable.title = ref[0].$children[0].selectedValue
        }, 500)
      } else {
        console.warn('error with select');
      }
    },
    selectDay(idx) {
      this.days.forEach((day, idxD) => {
        if (idxD == idx) {
          this.days[idxD].isSelected = true;
        } else {
          this.days[idxD].isSelected = false;
        }
      });
    },
    updateCal() {
      this.$CMS_POST('broadcast-calendar.cal-update', {}, {
        '_method': 'PUT',
        ...this.cal
      })
    },
    editButton(payload) {
      this.togglePopover(payload.date, payload.idxE);
    }
  },

  data() {
    return {
      months: [
        'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'
      ],
      scheduleables: [
        { label: 'Series', value: "\\App\\Series", recordset_id: 9 },
        { label: 'Series Post', value: "\\App\\SeriesPost", recordset_id: 14 },
        { label: 'Real Hope Minute', value: "\\App\\RealHopeMinute", recordset_id: 17 },
      ],
      mediaOptions: [
        { value: 'listen-part-1', label: 'Listen: Part 1' },
        { value: 'listen-part-2', label: 'Listen: Part 2' },
        { value: 'listen', label: 'Listen: Single Message' },
        { value: 'watch', label: 'Watch' },
      ]
    }
  }
}
</script>
