<template>
  <div class="space-y-3">
    <base-input label="Name" v-model="server.name" />
    <base-input label="Protocol">
      <el-select v-model="server.protocol" :options="['FTP', 'SFTP']" />
    </base-input>
    <base-input label="Host" v-model="server.host" />
    <base-input label="Port" v-model="server.port" />
    <base-input label="Username" v-model="server.username" />
    <base-input label="Password" type="password" v-model="server.password" />
    <base-input label="Directory" v-model="server.directory" />
  </div>
</template>
<script>

import Form from '../../../components/Form'
export default {
  components: {
    ...Form,
  },
  props: {
    server: {
      type: Object,
      default: () => {
        return {
          name: '',
          protocol: 'FTP',
          host: '',
          port: 21,
          username: '',
          password: '',
          directory: '/',
        }
      }
    }
  }
}
</script>
