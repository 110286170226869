import axios from 'axios';
import swal from 'sweetalert2';

axios.defaults.withCredentials = true;

function handleCmsError(error) {
  console.error(error);
  const data = error.response.data || 'An error occured...';
  if (typeof data === 'string') {
    swal.fire('Uh-Oh!', 'An error occured...', 'error');
  } else {
    let message = [];
    if (data.message) {
      message.push(data.message);
    }
    if (data.errors) {
      Object.keys(data.errors).forEach(key => {
        message.push(`${key}: ${data.errors[key].join('. ')}`);
      });
    }
    swal.fire('Uh-Oh!', message.join('<br>'), 'error');
  }
}

export default {
  methods: {
    async $CMS_CLONE(subject, routeParams) {
      return await axios({
        url: route(`cms.${subject}.clone`, routeParams),
        method: "GET"
      }).then(async (resp) => {
        return resp.data;
      }).catch(error => {
        switch (error.response.status) {
          case 401:

            break;
          case 500:
            handleCmsError(error)
            break;
          default:
            handleCmsError(error)
        }
      });
    },
    async $CMS_RESTORE(subject, routeParams) {
      return await axios({
        url: route(`cms.${subject}.restore`, routeParams),
        method: "GET"
      }).then(async (resp) => {
        return resp.data;
      }).catch(error => {
        switch (error.response.status) {
          case 401:
            router.push('/unauthorized');
            break;
          case 500:
            handleCmsError(error)
            break;
          default:
            handleCmsError(error)
        }
      });
    },
    async $CMS_GET(subject, routeParams, silent) {
      return await axios({
        url: route(`cms.${subject}`, routeParams),
        method: "GET"
      }).then(async (resp) => {
        return resp.data;
      }).catch(error => {
        if (!silent) {
          switch (error.response.status) {
            case 401:
              router.push('/unauthorized');
              break;
            case 500:
              handleCmsError(error)
              break;
            default:
              handleCmsError(error)
          }
        }
      });
    },
    async $CMS_POST(subject, routeParams, data, options) {
      if (!options) {
        options = {}
      }
      return await axios({
        url: route(`cms.${subject}`, routeParams),
        method: "POST",
        data,
        options
      }).then(async (resp) => {
        return resp.data;
      }).catch(error => {
        switch (error.response.status) {
          case 401:
            router.push('/unauthorized');
            break;
          case 500:
            handleCmsError(error)
            break;
          default:
            handleCmsError(error)
        }
      });
    },
    async $CMS_UPDATE(subject, routeParams, data, silent) {
      return await axios({
        url: route(`cms.${subject}.update`, routeParams),
        method: "PUT",
        data
      }).then(async (resp) => {
        if (resp.data.status === 'error') {
          swal.fire('Uh-Oh!', resp.data.message || 'An error occured...', 'error');
          return false;
        } else {
          if (!silent) {
            this.$noty.success(resp.data.message || 'Updated!');
          }
          return resp.data;
        }
      }).catch(error => {
        if (error.response) {
          switch (error.response.status) {
            case 401:

              break;
            case 500:
              handleCmsError(error)
              break;
            default:
              handleCmsError(error)
          }
        } else {
          handleCmsError(error)
        }
      });
    },
    async $CMS_CREATE(subject, routeParams, data, silent) {
      return await axios({
        url: route(`cms.${subject}.store`, routeParams),
        method: "POST",
        data
      }).then(async (resp) => {
        if (resp.data.status === 'error') {
          swal.fire('Uh-Oh!', resp.data.message || 'An error occured...', 'error');
        } else {
          if (!silent) {
            this.$noty.success('Created!');
          }
          return resp.data;
        }
      }).catch(error => {
        if (error.response) {
          switch (error.response.status) {
            case 401:

              break;
            case 500:
              handleCmsError(error)
              break;
            default:
              handleCmsError(error)
          }
        } else {
          handleCmsError(error)
        }
      });
    },
    async $CMS_DELETE(subject, routeParams) {
      return await axios({
        url: route(`cms.${subject}.destroy`, routeParams),
        method: "DELETE"
      }).then(async (resp) => {
        if (resp.data.status === 'error') {
          swal.fire('Uh-Oh!', resp.data.message || 'An error occured...', 'error');
        } else {
          return resp.data;
        }
      }).catch(error => {
        if (error.response) {
          switch (error.response.status) {
            case 401:

              break;
            case 500:

              handleCmsError(error)
              break;
            default:
              handleCmsError(error)
          }
        } else {
          handleCmsError(error)
        }
      });
    },
    async $CMS_FORCEDELETE(subject, routeParams) {
      return await axios({
        url: route(`cms.${subject}.forcedelete`, routeParams),
        method: "GET"
      }).then(async (resp) => {
        if (resp.data.status === 'error') {
          swal.fire('Uh-Oh!', resp.data.message || 'An error occured...', 'error');
        } else {
          return resp.data;
        }
      }).catch(error => {
        if (error.response) {
          switch (error.response.status) {
            case 401:

              break;
            case 500:

              handleCmsError(error)
              break;
            default:
              handleCmsError(error)
          }
        } else {
          handleCmsError(error)
        }
      });
    },
    async $CMS_FILE_UPLOAD(data) {
      let loader = this.$loading.show({
        color: '#009ae3',
        backgroundColor: '#000'
      });
      return await axios({
        url: route('cms.storage.store'),
        method: "POST",
        data
      }).then(async (resp) => {
        loader.hide();
        if (resp.data.status === 'error') {
          swal.fire('Uh-Oh!', resp.data.message || 'An error occured...', 'error');
        } else {
          this.$noty.success('File uploaded!');
          return resp.data;
        }
      }).catch(error => {
        loader.hide();
        switch (error.response.status) {
          case 401:
            router.push('/unauthorized');
            break;
          case 500:
            // router.push('/error');
            handleCmsError(error)
            break;
          default:
            handleCmsError(error)
        }
      });
    },
    async $CMS_FILE_DELETE(data) {
      return await axios({
        url: route('cms.storage.delete'),
        method: "DELETE",
        data
      }).then(async (resp) => {
        if (resp.data.status === 'error') {
          swal.fire('Uh-Oh!', resp.data.message || 'An error occured...', 'error');
        } else {
          return resp.data;
        }
      }).catch(error => {
        switch (error.response.status) {
          case 401:
            router.push('/unauthorized');
            break;
          case 500:
            // router.push('/error');
            handleCmsError(error)
            break;
          default:
            handleCmsError(error)
        }
      });
    },
    $CMS_ALERT(opts) {
      return swal.fire(opts)
    },
    $CMS_ERROR(error) {
      handleCmsError(error);
    }
  }
}
