<template>
  <app-layout>
    <base-card class="space-y-4 mb-10 relative">
      <base-input
        label="IP Address"
        v-model="banned_ip.ip_address"
        required
      />
      <base-input
        label="Email"
        v-model="banned_ip.email"
      />
      <base-input
        label="Strikes"
        v-model="banned_ip.strikes"
      />
      <base-input label="Is Banned">
        <base-switch v-model="banned_ip.is_banned" />
      </base-input>

      <base-input label="Reason" v-model="banned_ip.reason" />

      <base-input label="Banned Until">
        <el-date-picker v-model="banned_ip.banned_until" />
      </base-input>

      <base-button @click="saveBannedIp"> Save </base-button>
    </base-card>
  </app-layout>
</template>
<script>
import Form from '../components/Form';
export default {
  name: 'BannedIpEdit',
  components: {
    ...Form,
  },
  props: {
    banned_ip: {
      type: Object,
      default: () => {
        return {
          ip_address: '',
          reason: '',
          banned_until: '',
        };
      },
    },
  },
  data() {
    return {
    };
  },
  methods: {
    saveBannedIp() {
      if (this.banned_ip.id) {
        this.$root
          .$CMS_UPDATE('bannedips', this.banned_ip.id, this.banned_ip)
          .then(() => {
            this.$root.protected = false;
          });
      } else {
        this.$root.$CMS_CREATE('bannedips', {}, this.banned_ip).then((data) => {
          this.$root.protected = false;
          if (data && data.form && data.banned_ip.id) {
            window.location.href = route(
              'cms.bannedips.show',
              data.banned_ip.id
            );
          }
        });
      }
    },
    mounted() {
      document.addEventListener('keyup', this.$root.protectEvent);
    },
    beforeDestroy() {
      document.removeEventListener('keyup', this.$root.protectEvent);
    },
  },
};
</script>
