<template>
  <div
    class="relative"
    @mouseleave="highlight = null"
    v-click-outside="closeDropdown"
    :ref="`combobox-${id}-container`"
  >
    <input
      ref="comboBoxQuery"
      @focus="openDropdown"
      @input="queryOptions"
      :id="id"
      v-model="query"
      :placeholder="placeholderValue"
      type="text"
      class="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-12 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm"
      role="combobox"
      :aria-controls="`${id}-options`"
      aria-expanded="false"
    />
    <button
      type="button"
      @click="toggleDropdown"
      class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none"
      :ref="`combobox-${id}-button`"
    >
      <selector-icon class="h-5 w-5 text-gray-400" />
    </button>
    <transition
      enter-active-class="transition-opacity ease-out duration-100"
      enter-from-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="transition-opacity ease-in duration-75"
      leave-from-class="opacity-100 "
      leave-to-class="opacity-0"
    >
      <ul
        :id="`combobox-${id}-options`"
        v-show="open"
        class="fixed duration-75 will-change-transform z-50 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
        :aria-controls="`${id}-options`"
        role="listbox"
        :ref="`combobox-${id}`"
      >
        <!--
          Combobox option, manage highlight styles based on mouseenter/mouseleave and keyboard navigation.

          Active: "text-white bg-indigo-600", Not Active: "text-gray-900"
        -->
        <li
          :key="`${id}-option--1`"
          :class="[
            'relative cursor-default select-none py-2 pl-8 pr-4',
            highlight === -1 ? 'text-white bg-indigo-600' : 'text-gray-900',
          ]"
          role="option"
          tabindex="-1"
          @mouseenter="highlight = -1"
          @click="setValue(-1)"
        >
          <span
            :class="[
              'block truncate',
              selectedIndex === -1 ? 'font-semibold' : '',
            ]"
            >{{ placeholder || 'Please Select...' }}</span
          >
        </li>
        <template v-if="options.length">
          <li
            v-for="(opt, idxO) in options"
            :key="`${id}-option-${idxO}`"
            :class="[
              'relative cursor-default select-none py-2 pl-8 pr-4',
              highlight === idxO ? 'text-white bg-indigo-600' : 'text-gray-900',
            ]"
            role="option"
            tabindex="-1"
            @mouseenter="highlight = idxO"
            @click="setValue(idxO)"
          >
            <!-- Selected: "font-semibold" -->
            <span
              :class="[
                'block truncate',
                selectedIndex === idxO ? 'font-semibold' : '',
              ]"
            >
              {{ typeof opt === 'object' && opt.label ? opt.label : opt }}
            </span>

            <!--
              Checkmark, only display for selected option.

              Active: "text-white", Not Active: "text-indigo-600"
            -->
            <span
              v-if="selectedIndex === idxO"
              :class="[
                'absolute inset-y-0 left-0 flex items-center pl-1.5 ',
                highlight === idxO ? 'text-white' : 'text-indigo-600',
              ]"
            >
              <check-icon class="h-5 w-5" />
            </span>
          </li>
        </template>
        <li
          v-else
          class="relative cursor-default select-none py-2 pl-8 pr-4 text-gray-900"
        >
          No Options Found.
        </li>
      </ul>
    </transition>
  </div>
</template>
<script>
import { v4 as uuidv4 } from 'uuid';
import { SelectorIcon, CheckIcon } from '@vue-hero-icons/solid';
import { createPopper } from '@popperjs/core';
export default {
  name: 'ComboBoxMod',
  components: {
    SelectorIcon,
    CheckIcon,
  },
  props: {
    id: {
      type: String,
      default: () => uuidv4(),
    },
    value: {
      type: [String, Number, Boolean, Object],
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    recordset: {
      type: Number,
      default: 0,
    },
  },
  watch: {
    value(val) {
      this.valueUpdated(val);
    },
  },
  computed: {
    selected: {
      get() {
        return this.value && typeof this.value.id !== 'undefined'
          ? this.value.id
          : this.value || '';
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    placeholderValue() {
      return typeof this.selectedValue === 'string'
        ? this.selectedValue
        : this.placeholder;
    },
  },
  methods: {
    valueUpdated(val) {
      if (val) {
        // update: silently fail
        this.$root
          .$CMS_GET(`mod.show`, { module: this.recordset, id: val }, true)
          .then((resp) => {
            if (resp && resp.item && resp.mod) {
              this.selectedValue = this.getLabel(resp.item, resp.mod.columns);
            } else {
              this.selectedValue = null;
            }
          });
      } else {
        this.selectedValue = null;
      }

      let selected = null;
      this.options.forEach((opt, idx) => {
        if (typeof opt === 'object' && opt.value == val) {
          // console.log([1, opt.value, val]);
          selected = idx;
        } else if (opt == val) {
          // console.log([2, opt, val]);
          selected = idx;
        }
      });
      this.selectedIndex = selected;
    },

    queryOptions() {
      if (this.queryDebounce) {
        clearTimeout(this.queryDebounce);
      }
      this.queryDebounce = setTimeout(() => {
        this.currentPage = 0;
        this.loadItems(true);
        this.queryDebounce = null;
      }, 2000);
    },

    scrollLoad({ target: { scrollTop, clientHeight, scrollHeight } }) {
      if (scrollTop + clientHeight >= scrollHeight) {
        // TODO: debounce
        this.loadItems();
      }
    },

    loadItems(overwrite) {
      const params = { module: this.recordset, page: this.currentPage + 1 };
      if (this.query.length) {
        params.search = this.query;
      }
      this.$root.$CMS_GET(`mod.index`, params).then((resp) => {
        this.currentPage = resp.data.current_page || 0;
        if (overwrite) {
          this.options = JSON.parse(
            JSON.stringify(resp.data.data || resp.data)
          ).map((opt) => {
            return {
              value: opt.id,
              label: this.getLabel(opt, resp.mod.columns),
            };
          });
        } else {
          resp.data.data
            .map((opt) => {
              return {
                value: opt.id,
                label: this.getLabel(opt, resp.mod.columns),
              };
            })
            .forEach((element) => {
              this.options.push(element);
            });
        }
        this.$nextTick(() => {
          this.valueUpdated(this.value);
        });
      });
    },

    getLabel(opt, columns) {
      if (opt.name || opt.title) {
        return opt.name || opt.title;
      } else if (Array.isArray(columns) && columns.length) {
        let label = [];
        columns.slice(0, 3).forEach((col) => {
          label.push(opt[col]);
        });
        return label.join(' ');
      } else {
        return opt.id;
      }
    },

    setValue(idx) {
      const opt = idx >= 0 ? this.options[idx] : '';
      if (typeof opt === 'object') {
        this.$emit('input', opt.value);
      } else {
        this.$emit('input', opt);
      }
      this.closeDropdown();
    },
    closeDropdown() {
      this.query = '';
      this.open = false;
      this.dropdown.removeAttribute('data-show');
    },
    openDropdown() {
      this.query = '';
      this.open = true;
      this.dropdown.setAttribute('data-show', '');
      this.instance.update();
      window.dispatchEvent(
        new CustomEvent('comboboxOpen', { detail: { uid: this.uuid } })
      );
    },
    toggleDropdown() {
      if (this.open) {
        this.closeDropdown();
      } else {
        this.openDropdown();
      }
    },
  },
  mounted() {
    this.query = '';
    this.container = this.$refs[`combobox-${this.id}-container`];
    this.dropdown = this.$refs[`combobox-${this.id}`];
    this.instance = createPopper(this.container, this.dropdown, {
      placement: 'bottom-start',
      strategy: 'fixed',
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 10],
          },
        },
        {
          name: 'sameWidth',
          enabled: true,
          fn: ({ state }) => {
            state.styles.popper.width = `${state.rects.reference.width}px`;
          },
          phase: 'beforeWrite',
          requires: ['computeStyles'],
        },
      ],
    });

    window.addEventListener('comboboxOpen', (event) => {
      if (this.open && this.uuid !== event.detail.uid) {
        this.toggleDropdown();
      }
    });
    setTimeout(() => {
      if (document.getElementById(`combobox-${this.id}-options`)) {
        document
          .getElementById(`combobox-${this.id}-options`)
          .addEventListener('scroll', this.scrollLoad);
      }
      this.loadItems(true);
    }, 500);
  },
  beforeDestroy() {
    if (document.getElementById(`combobox-${this.id}-options`)) {
      document
        .getElementById(`combobox-${this.id}-options`)
        .removeEventListener('scroll', this.scrollLoad);
    }
  },
  data() {
    return {
      instance: null,
      container: null,
      dropdown: null,
      query: null,
      highlight: -1,
      selectedIndex: -1,
      selectedValue: null,
      open: false,
      currentPage: 0,
      options: [],
      queryDebounce: null,
    };
  },
};
</script>
