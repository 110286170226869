<template>
  <fieldset>
    <input type="hidden" :id="id" :name="id" :value="data">
    <!-- Enabled: "bg-indigo-600", Not Enabled: "bg-gray-200" -->
    <button type="button" @click="toggleSwitch()" :class="!!data ? 'bg-indigo-600' : 'bg-gray-200'" class="relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" aria-pressed="false">
      <span class="sr-only">Use setting</span>
      <!-- Enabled: "translate-x-5", Not Enabled: "translate-x-0" -->
      <span aria-hidden="true" :class="!!data ? 'translate-x-5' : 'translate-x-0'" class=" pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"></span>
    </button>
  </fieldset>
</template>
<script>
import { v4 as uuidv4 } from 'uuid';
export default {
  name: 'BaseSwitch',
  props: {
    id: {
      type: String,
      default: () => uuidv4()
    },
    value: {
      type: [Boolean, Number],
      default: false
    },
    onText: String,
    offText: String
  },
  computed: {
    data: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    }
  },
  methods: {
    toggleSwitch() {
      this.data = !this.data;
    }
  }
}
</script>
