<template>
  <div>

    <nav v-if="slug === 'mod' && sisters.length > 1" class="relative z-0 rounded-lg shadow flex divide-x divide-gray-200 mb-6" aria-label="Tabs">
      <inertia-link v-for="(sister, idx) in sisters" :key="`sister${idx}`" :href="route(`cms.mod.index`, { module: sister.id })" :class="[`${route().current(`cms.mod.index`, { module: sister.id }) ? 'text-gray-900' : 'text-gray-500 hover:text-gray-700'}`, `${idx === 0 ? 'rounded-l-lg' : ''}`, `${idx === sisters.length-1 ? 'rounded-r-lg' : ''}`, 'capitalize group relative min-w-0 flex-1 flex flex-col justify-center overflow-hidden bg-white py-4 px-4 text-xs font-medium text-center lg:text-sm hover:bg-gray-50 focus:z-10']" >
        {{ sister.name }}
        <span aria-hidden="true" :class="[ `${route().current(`cms.mod.index`, { module: sister.id }) ? 'bg-indigo-500' : 'bg-transparent'}`, 'absolute inset-x-0 bottom-0 h-0.5']"></span>
      </inertia-link>
    </nav>
    <!-- TO BE CONTINUED -->
    <nav v-else-if="sisters.length > 1" class="relative z-0 rounded-lg shadow flex divide-x divide-gray-200 mb-6" aria-label="Tabs">
      <inertia-link v-for="(sister, idx) in sisters" :key="`sister${idx}`" :href="route(`${sister.route}`)" :class="[`${route().current(`${sister.route}`) ? 'text-gray-900' : 'text-gray-500 hover:text-gray-700'}`, `${idx === 0 ? 'rounded-l-lg' : ''}`, `${idx === sisters.length-1 ? 'rounded-r-lg' : ''}`, 'capitalize group relative min-w-0 flex-1 flex flex-col justify-center overflow-hidden bg-white py-4 px-4 text-xs font-medium text-center lg:text-sm hover:bg-gray-50 focus:z-10']" >
        {{ sister.name }}
        <span aria-hidden="true" :class="[ `${route().current(`${sister.route}`) ? 'bg-indigo-500' : 'bg-transparent'}`, 'absolute inset-x-0 bottom-0 h-0.5']"></span>
      </inertia-link>
    </nav>

    <div class="pb-5 sm:flex sm:items-center sm:justify-between">

      <!-- SEARCH -->
      <div class="mt-3 sm:mt-0 flex gap-7">
        <div class="flex rounded-md shadow-sm">
          <div class="relative flex-grow focus-within:z-10">
            <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <SearchIcon class="h-5 w-5 text-gray-400" />
            </div>
            <base-input id="search_listing" v-model="searchQuery" placeholder="Search..." input-class="pl-10 pr-2" @input="searchItems" />
            <div v-if="searchDebounce" class="absolute inset-y-0 right-4 flex items-center pointer-events-none text-primary">
              <base-loader size="3px" />
            </div>
          </div>
        </div>

        <span v-if="columnSortable" class="isolate inline-flex rounded-md shadow-sm">
          <inertia-link :href="route(`cms.${slug}.index`, {...routeParams })" :class="['relative inline-flex items-center rounded-l-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 focus:z-10', showingPublished === 'all' ? 'bg-gray-200' : 'bg-white hover:bg-gray-50']">Display All</inertia-link>
          <inertia-link :href="route(`cms.${slug}.index`, {...routeParams, 'published': 'only' })" :class="['relative -ml-px inline-flex items-center px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 focus:z-10', showingPublished === 'only' ? 'bg-gray-200' : 'bg-white hover:bg-gray-50']">Published</inertia-link>
          <inertia-link :href="route(`cms.${slug}.index`, {...routeParams, 'published': 'none' })" :class="['relative -ml-px inline-flex items-center rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 focus:z-10', showingPublished === 'none' ? 'bg-gray-200' : 'bg-white hover:bg-gray-50']">Unpublished</inertia-link>
        </span>

        <div v-if="trashable">
          <base-button v-if="trashed" tag="inertia-link" :href="route(`cms.${slug}.index`, {...routeParams })">
            Non-deleted Items
          </base-button>
          <base-button v-else tag="inertia-link" :href="route(`cms.${slug}.index`, {...routeParams, 'trashed': 'only' })">
            Deleted Items
          </base-button>
        </div>
      </div>



      <div :class="['mt-3 sm:mt-0 sm:ml-4 flex items-center justify-end gap-6', buttonsReverse ? 'flex-row-reverse' : 'flex-row']">
        <div v-if="sortable" class="hidden items-center space-x-3 lg:flex">
          <base-label>Sort</base-label>
          <base-switch v-model="sortEnabled" onText="On" offText="Off" />
        </div>
        <div v-else-if="columnSort">
          <base-button type="secondary" tag="inertia-link" :href="route(`cms.${slug}.index`, {...routeParams })">Return To Default Sorting</base-button>
        </div>
        <base-button v-if="route().current('cms.reports.index') && route().has('cms.search')" tag="a" :href="route(`cms.search`)">Create</base-button>
        <base-button v-else-if="route().has(`cms.${slug}.create`)" tag="a" :href="route(`cms.${slug}.create`, {...routeParams})">Create</base-button>
        <base-button v-if="items.length && route().has(`cms.${slug}.indexexport`)" tag="a" :href="route(`cms.${slug}.indexexport`, {...routeParams})">Export All</base-button>
        <base-button v-if="route().has(`cms.${slug}.import`)" @click="importItem">Import</base-button>
        <slot name="buttons"></slot>
      </div>
    </div>

    <listing-inner :items="refinedItems" :columns="columns" :slug="slug" :routeParams="routeParams || {}" :searched="(!searchDebounce && searchQuery.length > 0)" :sortable="sortEnabled" :columnSortable="columnSortable" />
    <base-label :class="['text-center w-full my-4 transition-opacity', loadingItems ? 'opacity-80' : 'opacity-0']">Loading Items...</base-label>
  </div>
</template>
<script>
import { SearchIcon, FilterIcon } from '@vue-hero-icons/solid'
import { BaseInput, BaseLabel, BaseSwitch } from '../Form';
import ListingInner from './ListingInner';
import Fuse from 'fuse.js';

export default {
  name: 'BaseListing',
  components: {
    ListingInner,
    BaseInput,
    BaseLabel,
    BaseSwitch,
    SearchIcon,
    FilterIcon
  },
  props: {
    slug: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      default: () => {
        return [];
      },
    },
    columns: {
      type: Array,
      default: () => {
        return [];
      },
    },
    routeParams: {
      type: Object,
      default: () => {},
    },
    sisters: {
      type: Array,
      default: () => [],
    },
    id: {
      type: Number,
      default: 0,
    },
    mod: {
      type: Object,
      default: () => {
        return {};
      },
    },
    parent: {
      type: Object,
      default: () => {
        return {
          mod: null,
          item: null,
        };
      },
    },
    trashable: {
      type: Boolean,
      default: false,
    },
    trashed: {
      type: Boolean,
      default: false,
    },
    sortable: {
      type: Boolean,
      default: false,
    },
    buttonsReverse: {
      type: Boolean,
      default: false,
    },
    asyncItems: {
      type: Boolean,
      default: false
    },
    columnSortable: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      searchQuery: '',
      searchData: [],
      searchResults: [],
      searchDebounce: null,
      refinedItems: [],
      fuseSearch: null,
      sortEnabled: false,
      currentPage: 0,
      loadingItems: false,
    };
  },
  methods: {
    searchItems() {
      clearTimeout(this.searchDebounce);
      this.searchDebounce = setTimeout(() => {
        if (this.asyncItems) {
          // TODO: unrelated but I'm here: update notification for admin users
          this.currentPage = 0;
          this.loadItems(true);
        } else {
          this.refinedItems = JSON.parse(JSON.stringify(this.items));
          if (this.searchQuery.trim().length > 0) {
            const result = this.fuseSearch.search(this.searchQuery);
            this.searchResults = result.map((res) => {
              return res.item;
            });
            this.$nextTick(() => {
              const _this = this;
              this.refinedItems = this.refinedItems.filter(function fil(item) {
                return (
                  (item.children &&
                    (item.children = item.children.filter(fil)).length) ||
                  _this.searchResults.filter((obj) => obj.id == item.id).length >
                    0
                );
              });
            });
          }
        }
        this.$nextTick(() => {
          this.searchDebounce = null;
        });
      }, 750);
    },
    reloadData() {
      this.searchQuery = '';
      this.refinedItems = [];
      this.$nextTick(() => {
        this.refinedItems = JSON.parse(JSON.stringify(this.items));
        this.buildSearchData(this.items);
        this.$nextTick(() => {
          document.body.classList.remove('is-waiting');
        });
      });
    },
    buildSearchData(items) {
      items.forEach((item) => {
        if (item.children && item.children.length) {
          this.buildSearchData(item.children);
        }
        this.searchData.push({
          id: item.id,
          name: item.name || '',
          email: item.email || '',
          title: item.title || '',
        });
      });
    },
    importItem() {
      this.$swal
        .fire({
          title: `Import Item`,
          html: `<form id="item-import-form" enctype="multipart/form-data"><input type="file" id="item-import-file" class="swal2-input" /></form>`,
          confirmButtonText: 'Upload',
          focusConfirm: false,
          preConfirm: () => {
            const file = this.$swal
              .getPopup()
              .querySelector('#item-import-file');
            if (!file.files.length) {
              this.$swal.showValidationMessage(`Please include a file`);
            }
            return { file: file };
          },
        })
        .then((result) => {
          // upload
          if (result.value && result.value.file) {
            let loader = this.$loading.show({
              color: '#009ae3',
              backgroundColor: '#000',
            });
            const formData = new FormData();
            formData.append('file', result.value.file.files[0]);
            this.$root
              .$CMS_POST(`${this.slug}.import`, {}, formData, {
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
              })
              .then((resp) => {
                loader.hide();
                if (resp && resp.status == 'success') {
                  window.location.reload();
                }
              });
          }
        });
    },

    scrollLoad({ target: { scrollTop, clientHeight, scrollHeight }}) {
      if (scrollTop + clientHeight >= scrollHeight) {
        // TODO: debounce
        this.loadItems();
      }
    },

    loadItems(overwrite) {
      this.loadingItems = true;
      const params = {...this.routeParams, page: this.currentPage+1};
      if (this.searchQuery.length) {
        params.search = this.searchQuery;
      }
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      if (urlParams.has('trashed')) {
        params.trashed = true;
      }
      if (urlParams.has('sortBy')) {
        params.sortBy = urlParams.get('sortBy');
      }
      if (urlParams.has('sortDirection')) {
        params.sortDirection = urlParams.get('sortDirection');
      }
      if (urlParams.has('published')) {
        params.published = urlParams.get('published');
      }
      this.$root.$CMS_GET(`${this.slug}.index`, params).then((resp) => {
        this.currentPage = resp.data.current_page || 0;
        if (overwrite) {
          this.refinedItems = JSON.parse(JSON.stringify(resp.data.data || resp.data));
        } else {
          resp.data.data.forEach(element => {
            this.refinedItems.push(element)
          });
        }
        document.body.classList.remove('is-waiting');
        setTimeout(() => {
          this.loadingItems = false;
        });
      });
    }
  },
  computed: {
    columnSort() {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      let val = false;
      if (urlParams.has('sortBy')) {
        val = {};
        val.by = urlParams.get('sortBy');
        val.direction = urlParams.get('sortDirection') || 'asc';
      }
      return val;
    },
    showingPublished() {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      let val = 'all';
      if (urlParams.has('published')) {
        val = urlParams.get('published');
      }
      return val;
    }
  },
  beforeDestroy() {
    document.getElementById('appMain').removeEventListener('scroll', this.scrollLoad);
  },
  mounted() {
    if (this.asyncItems) {
      document.getElementById('appMain').addEventListener('scroll', this.scrollLoad);
      this.loadItems(true);
    } else {
      this.refinedItems = JSON.parse(JSON.stringify(this.items));
      this.$nextTick(() => {
        this.buildSearchData(this.items);
        this.fuseSearch = new Fuse(this.searchData, {
          findAllMatches: true,
          keys: ['id', 'name', 'email', 'title'],
          threshold: 0.3,
        });
      });
    }


    window.addEventListener('listingReload', () => {
      if (this.asyncItems) {
        this.currentPage = 0;
        this.loadItems(true);
      } else {
        this.reloadData();
      }
    });
  },
};
</script>
