<template>
  <div class="flex flex-col">
    <div class="py-2 align-middle inline-block min-w-full ">
      <base-table v-if="items.length" :nested="nested" :level="level" :scroll="true" :sortable="sortable">
        <template v-slot:head>
          <!-- Child expand heading -->
          <table-heading class="pl-6 pr-0"></table-heading>

          <!-- Sort Table Heading -->
          <table-heading
            v-if="sortable"
            class="hidden lg:table-cell"
          >
            <span>Sort</span>
          </table-heading>

          <!-- Pinned -->
          <table-heading
            v-if="(typeof items[0] !== 'undefined' && typeof items[0].is_pinned !== 'undefined')"
            class="hidden lg:table-cell"
          >
            <span>Pin</span>
          </table-heading>

          <!-- ID Table Heading -->
          <table-heading class="hidden lg:table-cell">
            ID
          </table-heading>

          <!-- Dynamic Table Headings -->
          <table-heading v-for="(col, idxC) in columns"
            :key="`th${idxC}`"
            :class="[idxC > 0 ? 'hidden lg:table-cell' : '', 'relative', columnSortable ? 'cursor-pointer' : '', sortBy == col.attribute ? 'underline' : '']"
            @click="sortByColumn(idxC)"
          >
            {{ col.label }}
            <ArrowNarrowDownIcon v-if="sortBy == col.attribute && sortDirection == 'desc'" class="w-4 h-4 absolute left-0 inset-y-0 my-auto" />
            <ArrowNarrowUpIcon v-else-if="sortBy == col.attribute && sortDirection == 'asc'" class="w-4 h-4 absolute left-0 inset-y-0 my-auto" />
          </table-heading>
          <table-heading v-if="route().has(`cms.${slug}.update`) && (slug === 'mod')"></table-heading>
          <table-heading class="w-32">Active</table-heading>
          <!-- Actions Table Heading -->
          <table-heading></table-heading>
        </template>
            <!-- TODO: Pagination? -->
            <!-- TODO: Implement sortable functionality -->
        <template v-slot:default>
          <!-- Sortable rows -->
          <template v-for="(item, idxI) in items">
            <listing-inner-row
              :key="`tableRow${item.id || idxI}`"
              :item="item"
              :columns="columns"
              :slug="slug"
              :sortable="sortable"
              :routeParams="routeParams"
              :searched="searched"
              :listingSize="items.length"
              :showChildren="showChildren"
              @childrenToggle="childrenToggle($event)"
            ></listing-inner-row>
            <tr :key="`tableRow${item.id || idxI}-children`" v-if="showChildren && Array.isArray(item.children) && item.children.length" class="border-0">
              <td :colspan="columnCount" class="p-0">
                <div v-if="visibleChildren.includes(item.id) || searched">
                  <listing-inner :items="item.children" :columns="columns" :slug="slug" :nested="true" :routeParams="routeParams" :searched="searched" :listingSize="items.length" :sortable="sortable" :showChildren="showChildren" />
                </div>
              </td>
            </tr>
          </template>
        </template>
      </base-table>
      <p v-else class="p-4 bg-white">No items found.</p>
    </div>
  </div>
</template>
<script>
import Table from '../Table';
import { v4 as uuidv4 } from 'uuid';
import ListingInnerRow from './ListingInnerRow';
import { ArrowNarrowUpIcon, ArrowNarrowDownIcon } from '@vue-hero-icons/solid'
export default {
  name: 'ListingInner',
  components: {
    ...Table,
    ArrowNarrowDownIcon,
    ArrowNarrowUpIcon,
    [ListingInnerRow.name]: ListingInnerRow,
  },
  props: {
    slug: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      default: () => {
        return [];
      },
    },
    columns: {
      type: Array,
      default: () => {
        return [];
      },
    },
    sortable: {
      type: Boolean,
      default: false,
    },
    nested: {
      type: Boolean,
      default: false,
    },
    level: {
      type: Number,
      default: 1,
    },
    searched: {
      type: Boolean,
      default: false,
    },
    showChildren: {
      type: Boolean,
      default: true,
    },
    routeParams: {
      type: Object,
      default: () => {},
    },
    columnSortable: {
      type: Boolean,
      default: false
    }
  },
  remember: {
    data: ['visibleChildren'],
    key: () => `Component/ListingInner:${uuidv4()}`,
  },
  data() {
    return {
      visibleChildren: [],
      sortBy: '',
      sortDirection: '',
    };
  },
  computed: {
    columnCount() {
      const addSort = this.sortable ? 1 : 0;
      const addPinned =
        typeof this.items[0] !== 'undefined' &&
        typeof this.items[0].is_pinned !== 'undefined'
          ? 1
          : 0;
      return this.columns.length + addSort + addPinned + 3;
    },
  },
  methods: {
    childrenToggle($event) {
      if ($event.visible) {
        this.visibleChildren.push($event.id);
      } else {
        this.visibleChildren = this.visibleChildren.filter(function ($c) {
          return $c !== $event.id;
        });
      }
    },
    sortByColumn(idx) {
      if (this.columnSortable) {
        const sortBy = this.columns[idx].attribute;
        let sortDirection;
        // Only change direction if resorting by same column
        if (sortBy == this.sortBy) {
          switch (this.sortDirection) {
            case 'asc':
              sortDirection = 'desc';
              break;
            case 'desc':
              sortDirection = '';
              break;
            default:
              sortDirection = 'asc';
              break;
          }
        } else {
          sortDirection = 'asc';
        }
        if (sortDirection.length) {
          this.$inertia.visit(route(`cms.${this.slug}.index`, { ...this.routeParams, sortBy, sortDirection }))
        } else {
          this.$inertia.visit(route(`cms.${this.slug}.index`, { ...this.routeParams }))
        }
      }
    }
  },
  mounted() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    if (urlParams.has('sortBy')) {
      this.sortBy = urlParams.get('sortBy');
      this.sortDirection = urlParams.get('sortDirection');
    }
  }
};
</script>
