<template>
  <div class="space-y-3">
    <base-input label="Name" v-model="file.name" />
    <base-input label="Type">
      <el-select v-model="file.type" :options="['jpg', 'png', 'mp4', 'mov', 'mp3', 'wav']" />
    </base-input>
    <base-input label="Config" v-model="file.config" />
    <div>
      <base-label>
        <span>Configuration Examples</span>
        <base-tooltip class="inline-block ml-2 w-4 h-4 text-center align-middle" :offsetY="5">
          <template v-slot:content>
            <div class="text-left normal-case">
              <div class="flex gap-2 underline">
                <div class="w-20 flex-none">Config</div>
                <div>Description</div>
              </div>
              <div class="flex gap-2">
                <div class="w-20 flex-none">800w</div>
                <div>Image/Video 800px width, auto height</div>
              </div>
              <div class="flex gap-2">
                <div class="w-20 flex-none">600h</div>
                <div>Image/Video 600px height, auto width</div>
              </div>
              <div class="flex gap-2">
                <div class="w-20 flex-none">800x600</div>
                <div>Image/Video crop to 800px by 600px</div>
              </div>
              <div class="flex gap-2">
                <div class="w-20 flex-none">128kbps</div>
                <div>Audio bit-rate</div>
              </div>
              <div class="mt-2">(Dimensions for videos must be even numbers)</div>
            </div>
          </template>
          <information-circle-icon class="align-top w-full h-full inline-block" />
        </base-tooltip>
      </base-label>
    </div>
  </div>
</template>
<script>
import {InformationCircleIcon} from '@vue-hero-icons/solid'
import BaseTooltip from '../../../components/Base/BaseTooltip.vue'
import Form from '../../../components/Form'
export default {
  components: {
    InformationCircleIcon,
    BaseTooltip,
    ...Form,
  },
  props: {
    file: {
      type: Object,
      default: () => {
        return {
          name: '',
          type: '',
          config: '',
        }
      }
    }
  }
}
</script>
