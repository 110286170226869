<template>
  <app-layout>
    <nav class="relative z-0 rounded-lg shadow flex divide-x divide-gray-200 mb-6" aria-label="Tabs">
      <inertia-link
        :href="route('cms.products.index')"
        :class="[`${route().current('cms.products.index') ? 'text-gray-900' : 'text-gray-500 hover:text-gray-700'}`, 'rounded-l-lg', 'capitalize group relative min-w-0 flex-1 flex flex-col justify-center overflow-hidden bg-white py-4 px-4 text-xs font-medium text-center lg:text-sm hover:bg-gray-50 focus:z-10']" >
        Items
        <span aria-hidden="true" :class="[ `${route().current('cms.products.index') ? 'bg-indigo-500' : 'bg-transparent'}`, 'absolute inset-x-0 bottom-0 h-0.5']"></span>
      </inertia-link>
      <inertia-link
        :href="route('cms.pages.index', {
          filter: {
            shopify_page_type: 'collection',
          },
        })"
        :class="['text-gray-500 hover:text-gray-700', 'rounded-r-lg', 'capitalize group relative min-w-0 flex-1 flex flex-col justify-center overflow-hidden bg-white py-4 px-4 text-xs font-medium text-center lg:text-sm hover:bg-gray-50 focus:z-10']"
      >
        Collection Pages
      </inertia-link>

    </nav>
    <base-listing v-bind="$props" :buttons-reverse="true">
    <!-- TODO: Syncing -->
      <template v-slot:buttons>
        <base-button class="justify-self-start" tag="inertia-link" :href="route('cms.products.sync-down')">Pull From Shopify</base-button>
        <base-button class="justify-self-start" tag="inertia-link" :href="route('cms.products.sync-up')">Push To Shopify</base-button>
      </template>
    </base-listing>
  </app-layout>
</template>
<script>
import BaseListing from '../../components/Listing/BaseListing';
export default {
  name: 'ProductListing',
  components: {
    BaseListing,
  },
  props: {
    slug: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      default: () => {
        return [];
      },
    },
    columns: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
};
</script>
