<template>
  <div v-if="value" class="fixed bg-gray-800/60 backdrop-blur-sm top-0 left-0 w-full h-full z-50 flex items-center justify-center py-16 px-24">
    <button class="absolute top-16 right-24 -translate-y-1/2 translate-x-1/2 rounded-full p-1 border-2 border-solid border-white shadow-lg bg-black" title="Cancel" type="button" @click="cancelModal">
      <XIcon class=" w-6 h-6 text-white" />
    </button>

    <div class="w-full min-h-full max-h-[90vh] bg-white shadow-xl p-5 space-y-4 rounded-sm overflow-hidden flex flex-col">
      <notes-form v-bind="{noteableId,noteableType,parentId}" @input="cancelModal()" />
    </div>
  </div>
</template>
<script>
import { XIcon } from '@vue-hero-icons/solid'
import NotesForm from './NotesForm.vue';
export default {
  name: 'NotesCreate',
  components: {
    XIcon,
    NotesForm,
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    noteableType: {
      type: String,
      default: ''
    },
    noteableId: {
      type: Number,
      default: 0
    },
    parentId: {
      type: Number,
      default: 0
    },
  },
  methods: {
    escape(event) {
      if (event.keyCode === 27) {
        this.cancelModal();
      }
    },
    cancelModal() {
      this.$emit('input', false);
    },
  },
  created: function() {
    document.addEventListener('keyup', this.escape);
  },
  destroyed: function() {
    document.removeEventListener('keyup', this.escape);
  },
}
</script>
