<template>
  <div>
    <textarea v-model="data" :id="`tinymce-${uuid}`" />
  </div>
</template>
<script>
import tinymce from 'tinymce/tinymce';

import 'tinymce/icons/default';

// A theme is also required
import 'tinymce/themes/silver';

// Any plugins you want to use has to be imported
import 'tinymce/plugins/code';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/anchor';
import 'tinymce/plugins/link';
import 'tinymce/plugins/image';
import 'tinymce/plugins/media';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/table';
import 'tinymce/plugins/hr';

export default {
  name: 'Wysiwyg',
  props: {
    value: {
      type: [String],
      default: '',
    },
  },
  data() {
    return {
      uuid: this.uuid,
      editor: null,
      defaultConfig: {
        selector: `#tinymce-${this.uuid}`,
        menubar: false,
        // file_picker_callback : mceElf.browser,
        // images_upload_handler: mceElf.uploadHandler,
        toolbar:
          'undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist | hr | outdent indent | link anchor | elfinder media | code | table',
        plugins: [
          'paste',
          'link',
          'media',
          'code',
          'paste',
          'anchor',
          'image',
          'lists',
          'table',
          'hr',
        ],
        contextmenu: ['copy', 'cut', 'paste', 'link', 'anchor', 'image'],
        paste_as_text: true,
        smart_paste: true,
        content_style: 'body { padding: 15px; }',
        relative_urls: false,
        remove_script_host: false,
        convert_urls: false,
        setup: (editor) => {
          const _this = this;
          this.editor = editor;
          editor.on('input Change', function (e) {
            _this.$emit('input', editor.getContent());
          });
          editor.ui.registry.addButton('elfinder', {
            icon: 'gallery',
            title: 'Open Media Manager',
            onAction: () => {
              _this.createElFinder();
            },
          });
        },
      },
      customConfig: {},
    };
  },
  computed: {
    data: {
      get() {
        return this.value;
      },
      set(data) {
        this.$emit('input', data);
      },
    },
    config() {
      // Merge default and custom objs
      return {
        ...this.defaultConfig,
        ...this.customConfig,
      };
    },
  },
  watch: {
    value() {
      this.$emit('input', this.value);
    },
  },
  mounted() {
    const cacheKey = 'cms_wysiwyg_config';
    const cachedConfig = window.sessionStorage.getItem(cacheKey);
    if (cachedConfig && cachedConfig.length) {
      this.customConfig = JSON.parse(cachedConfig);
      this.$nextTick(() => {
        this.initTinyMCE();
      });
    } else {
      fetch('/storage/_cms/wysiwyg.json?date=' + Date.now().toString()).then(
        (resp) => {
          if (resp.status === 200) {
            try {
              resp.json().then((data) => {
                this.customConfig = data;
                window.sessionStorage.setItem(cacheKey, JSON.stringify(data));
                this.$nextTick(() => {
                  this.initTinyMCE();
                });
              });
            } catch (err) {
              this.$nextTick(() => {
                this.initTinyMCE();
              });
            }
          } else {
            this.$nextTick(() => {
              this.initTinyMCE();
            });
          }
        }
      );
    }
  },
  methods: {
    initTinyMCE() {
      tinymce.init(this.config);
    },
    onInput() {},
    escape(event) {
      if (event.keyCode === 27) {
        this.destroyElFinder();
      }
    },
    createElFinder() {
      const _this = this;
      let container = document.getElementById('el-finder-container');
      if (container) {
        container.remove();
      }
      container = document.createElement('div');
      container.setAttribute('id', 'el-finder-container');
      container.classList.add('c-elfinder-container');
      container.addEventListener('click', function (ev) {
        if (ev.target.classList.contains('c-elfinder-container')) {
          _this.destroyElFinder();
        }
      });
      let iframe = document.createElement('iframe');
      iframe.addEventListener('load', function () {
        iframe.width = iframe.contentWindow.document.body.scrollWidth;
        iframe.height = iframe.contentWindow.document.body.scrollHeight;
        iframe.classList.add('is-visible');
      });
      iframe.setAttribute('src', `/admin/elfinder?input_id=${this.uuid}`);
      iframe.classList.add('c-elfinder-box');
      container.appendChild(iframe);
      document.body.appendChild(container);
      setTimeout(function () {
        container.classList.add('is-visible');
      });
      window.addEventListener('keyup', this.escape);
      window.addEventListener('cms-wysiwyg-elfinder-file', this.elfinderFile);
    },
    destroyElFinder() {
      let container = document.getElementById('el-finder-container');
      let iframe = container.querySelector('iframe');
      iframe.classList.remove('is-visible');
      setTimeout(function () {
        container.classList.remove('is-visible');
        setTimeout(function () {
          container.remove();
        }, 500);
      }, 300);
      window.removeEventListener('keyup', this.escape);
      window.removeEventListener(
        'cms-wysiwyg-elfinder-file',
        this.elfinderFile
      );
    },
    elfinderFile(ev) {
      let file = ev.detail.file;
      let id = ev.detail.input_id;
      if (toString(id) === toString(this.uuid)) {
        if (file.url && file.url.indexOf('/admin/elfinder') < 0) {
          if (file.mime.match(/^image\//)) {
            // Image
            // this.$emit('input', this.data.concat(`<img src="${file.url}" alt="${file.name}">`));
            this.editor.insertContent(
              `<img src="${file.url}" alt="${file.name}">`
            );
          } else {
            // Link
            // this.$emit('input', this.data.concat(`<a href="${file.url}">${file.name}</a>`));
            this.editor.insertContent(`<a href="${file.url}">${file.name}</a>`);
          }
          this.destroyElFinder();
        } else {
          console.error('No valid file url!', file);
        }
      } else {
        console.error(`${id} does not match ${this.uuid}`);
      }
    },
  },
};
</script>
<style>
.c-elfinder-container {
  background: rgba(0, 0, 0, 0.333);
  height: 100vh;
  left: 0;
  opacity: 0;
  position: fixed;
  top: 0;
  transition: all 0.5s ease-in-out;
  width: 100vw;
  z-index: 9999999;
}

.c-elfinder-container.is-visible {
  opacity: 1;
}

.c-elfinder-box {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.5);
  left: 50%;
  max-height: 80vh;
  max-width: 80vw;
  opacity: 0;
  position: absolute;
  top: 50%;
  transform: translate3d(-50%, -30%, 0) scale(0.25);
  transform-origin: 50% 50%;
  transition: all 0.5s ease-in-out;
  width: 1000px;
  min-height: 400px;
}

.c-elfinder-box.is-visible {
  opacity: 1;
  transform: translate3d(-50%, -50%, 0) scale(1);
}

.c-elfinder {
  max-width: 100%;
  max-height: calc(80vh - 210px);
}
</style>
