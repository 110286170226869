
import { createInertiaApp, Link } from '@inertiajs/vue2'
import Vue from 'vue'
import {
  BaseAlert,
  BaseButton,
  BaseCard,
  BaseCode,
  BaseDropdown,
  BaseHeading,
  BaseLoader,
  BaseLogs,
  BaseModal,
  BaseNotification,
  BasePopover,
  BaseTooltip,
  BaseTime
} from './components/Base'
import { EchoOnline } from './components/Echo'
import { Ziggy } from 'ziggy-js'
import cms from './mixins/cms'
import uuid from './mixins/uuid';
import keyboardShortcuts from './mixins/keyboard-shortcuts';
import pusher from './plugins/pusher'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import VueSweetalert2 from 'vue-sweetalert2';
import VueNoty from 'vuejs-noty'
import Noty from 'noty'
import moment from 'moment'
import AppLayout from './layouts/AppLayout'
import axios from 'axios'
import * as Sentry from "@sentry/vue";


window.axios = axios;
window.axios.defaults.withCredentials = true;

window.$ = window.jQuery = require('jquery');

if (typeof findElParent === 'undefined') {
  window.findElParent = (el, className) => {
    if (Array.isArray(className)) {
      var hasMatch = className.filter(name => {
        return el.classList.contains(name) || el.id === name;
      });
      return hasMatch.length ? el : findElParent(el.parentNode, className);
    } else {
      return el.classList.contains(className) ? el : findElParent(el.parentNode, className);
    }
  }
}

Sentry.init({
  Vue,
  dsn: "https://0230e7d45a59f35bbaa7e9c3b442b54f@o324323.ingest.sentry.io/4506111219859456",
});

createInertiaApp({
  id: 'inertia-app',
  progress: {
    color: '#4f46e5',
  },
  resolve: name => require(`./views/${name}`),
  setup({ el, App, props, plugin }) {
    Vue.prototype.moment = moment;

    // Mixin to pull in routes from Laravel using Ziggy
    Vue.mixin({
      methods: {
        route: (name, params, absolute, config = Ziggy) => route(name, params, absolute, config)
      }
    })

    // Mixin for CMS functions
    Vue.mixin(cms)

    // Mixin for UUID
    Vue.mixin(uuid)

    // Mixin for keyboard shortcuts
    Vue.mixin(keyboardShortcuts)

    // Plugin for Pusher
    Vue.use(pusher)

    // Globally accessible components
    Vue.component('base-alert', BaseAlert)
    Vue.component('base-button', BaseButton)
    Vue.component('base-card', BaseCard)
    Vue.component('base-code', BaseCode)
    Vue.component('base-dropdown', BaseDropdown)
    Vue.component('base-heading', BaseHeading)
    Vue.component('base-loader', BaseLoader)
    Vue.component('base-logs', BaseLogs)
    Vue.component('base-modal', BaseModal)
    Vue.component('base-notification', BaseNotification)
    Vue.component('base-popover', BasePopover)
    Vue.component('base-tooltip', BaseTooltip)
    Vue.component('base-time', BaseTime)
    Vue.component('app-layout', AppLayout)
    Vue.component('inertia-link', Link)

    // Notification banners
    Vue.use(VueNoty)

    // Alerts
    Vue.use(VueSweetalert2);

    // Loading Overlay
    Vue.use(Loading)

    // Inertia plugin
    Vue.use(plugin)

    Vue.directive('click-outside', {
      bind: function (el, binding, vnode) {
        el.clickOutsideEvent = function (event) {
          // here I check that click was outside the el and his children
          if (!(el == event.target || el.contains(event.target))) {
            // and if it did, call method provided in attribute value
            vnode.context[binding.expression](event);
          }
        };
        document.body.addEventListener('click', el.clickOutsideEvent)
      },
      unbind: function (el) {
        document.body.removeEventListener('click', el.clickOutsideEvent)
      },
    });


    const echoOnlineClass = Vue.extend(EchoOnline);

    new Vue({
      data: {
        CMS_ROOT: window.CMS_ROOT,
        protected: false,
        usersLocations: [],
        userLocationInterval: null,
      },
      methods: {
        $SLUGIFY(str, dash) {
          dash = dash || '-';
          str = str.replace(/^\s+|\s+$/g, ''); // trim
          str = str.toLowerCase();

          // remove accents, swap ñ for n, etc
          var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
          var to = "aaaaeeeeiiiioooouuuunc------";
          for (var i = 0, l = from.length; i < l; i++) {
            str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
          }

          str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
          str = str.replace(/\s+/g, dash) // collapse whitespace and replace by -
          str = str.replace(/-+/g, dash); // collapse dashes
          return str;
        },
        beforeUnload(e) {
          e.returnValue = "Are you sure you wish to leave before saving?";
        },
        protectEvent() {
          this.protected = true;
        }
      },
      watch: {
        protected(newVal, oldVal) {
          if (newVal && newVal !== oldVal) {
            window.addEventListener("beforeunload", this.beforeUnload);
          } else if (!newVal) {
            window.removeEventListener("beforeunload", this.beforeUnload);
          }
        }
      },
      beforeCreate() {
        if (this.$echo) {
          const echoOnlineInstance = new echoOnlineClass();
          echoOnlineInstance.$mount(document.getElementById('echo-online'));
        }
      },
      mounted() {
        this.$inertia.on('before', (evt) => {
          if (this.protected) {
            const conf = confirm("Are you sure you wish to leave before saving?");
            if (conf) {
              this.protected = false;
            }
            return conf;
          } else {
            return true;
          }
        })

        this.$inertia.on('navigate', (event) => {
          clearInterval(this.userLocationInterval);
          document.body.dispatchEvent(new CustomEvent('cms_page_loaded'));
          axios.post(route('cms.users-locations.store'), { location: event.detail.page.url }).then(resp => this.usersLocations = resp.data.locations || []);
          this.userLocationInterval = setInterval(() => {
            axios.post(route('cms.users-locations.store'), { location: event.detail.page.url }).then(resp => this.usersLocations = resp.data.locations || []);
          }, 20000);
        })

        window.addEventListener('CmsNotification', function (e) {
          if (e.detail && e.detail.type) {
            new Noty({
              type: e.detail.type,
              text: e.detail.message || e.detail.type
            }).show();
          } else {
            console.warn(['Unknown notification type', e]);
          }
        });
      },
      render: h => h(App, props),
    }).$mount(el)
  }
});

window.cmsPluginDependenciesLoaded = [];

window.cmsPluginDependencies = (deps, cb) => {
  deps.forEach((dep) => {
    console.log(dep.name);
    if (window.cmsPluginDependenciesLoaded.indexOf(dep.name) === -1) {
      if (eval('typeof ' + dep.obj) === 'undefined') {
        let element = document.createElement(dep.element);
        for (var key in dep.attrs) {
          element.setAttribute(key, dep.attrs[key]);
        }
        if (Array.isArray(dep.children)) {
          element.onload = window.cmsPluginDependencies(dep.children, false);
        }
        window.cmsPluginDependenciesLoaded.push(dep.name);
        document[dep.location || 'body'].appendChild(element);
      } else if (Array.isArray(dep.children)) {
        window.cmsPluginDependencies(dep.children, false);
      }
    }
  });
  if (cb) {
    cb();
  }
};

window.addEventListener('load', () => {
  console.log('CMS Loaded...');
  document.body.dispatchEvent(new CustomEvent('cms_page_loaded'));
})
