<template>
  <div v-if="ready">
    <div v-if="(field.is === 'draggable' && typeof element.pivot !== 'undefined')">
      <bldr-repeater
        v-model="data"
        :element="element"
        :disabled="disabled"
      />
    </div>
    <component
      :is="field.is"
      v-else-if="field.is === 'file-upload' || field.is === 'base-file'"
      v-model="data"
      :config-i-d="element.pivot.upload_config_id || element.pivot.upload_config"
      :disabled="disabled"
      :files="field.fileList || []"
      @fileupdated="updateFiles($event, field)"
      ref="fileupload"
    />
    <component
      :is="field.is"
      v-else-if="field.is === 'base-checkboxes'"
      v-model="data"
      :disabled="disabled"
      :options="dataset"
      :recordset="recordset_id"
    />
    <!-- eslint-disable-next-line -->
    <component
      v-else-if="typeof field === 'object'"
      :is="field.is"
      v-bind="field"
      :isDynamic="true"
      :name="`e${element.pivot.id}el${element.id}_${idx}`"
      :placeholder="element.pivot.placeholder || field.placeholder || ''"
      :options="dataset || null"
      :recordset="recordset_id"
      :charlimit="element.pivot.charlimit || ''"
      :disabled="disabled"
      v-model="data"
    >
      <component
        :is="field.child.is"
        v-if="typeof field.child === 'object'"
        v-model="data"
        v-bind="field.child"
        :placeholder="element.pivot.placeholder || field.child.placeholder || ''"
        :name="element.pivot.variable"
        :options="dataset || null"
        :recordset="recordset_id"
        :disabled="disabled"
      ></component>

      <div
        v-else-if="typeof field.child === 'string'"
        v-html="field.child"
      />
    </component>
    <div v-if="element.pivot.column === 'ip_address' || element.pivot.column === 'email'" class="pt-2">
      <BanHammer v-if="(typeof mod_item === 'object')" :item="mod_item" :icon="false" />
      <BanHammer v-else :item="{[element.pivot.column]: data}" :icon="false" />
    </div>
  </div>
</template>
<script>
import BanHammer from '../Misc/BanHammer'
import Form from '../Form'
import BldrRepeater from './BldrRepeater'
export default {
  name: 'BldrField',
  components: {
    ...Form,
    BldrRepeater,
    BanHammer
  },
  props: {
    field: Object,
    element: Object,
    idx: Number,
    value: {
      type: [String, Number, Array, Object, Boolean],
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dataset: [],
      ready: false
    }
  },
  computed: {
      data: {
          get() { return this.value },
          set(data) {this.$emit('input', data)}
      },
      mod_item() {
        return this.$page?.props?.item || null;
      },
      recordset_id() {
        return typeof this.element.pivot === 'object' ? (this.element.pivot.recordset_id || this.element.pivot.module || null) : null;
      }
  },
  methods: {
    updateFiles($event, field) {
      if ($event.length) {
        this.data = $event.map((file) => {
          delete file.dataURL;
          return file;
        });
      } else {
        this.data = [];
      }
    },

  },
  mounted() {
    if (this.field.is === 'el-select' ||
      (typeof this.field.child === 'object' && this.field.child.is === 'el-select')
    ) {
      if (this.element.pivot.recordset_id || this.element.pivot.module) {
        const recordset_id = this.element.pivot.recordset_id || this.element.pivot.module;
        // Dynamic Select
        // TODO: Update to pull in label
        // this.$root.$CMS_GET('mod.index', {module: recordset_id, }).then(resp => {
        //   this.dataset = resp.data.map(opt => {
        //     return { value: opt.id, label: opt.name || opt.title || opt[resp.mod.columns[0]] }
        //   });
        // });
        if (!!this.value && typeof this.value === 'object') {
          this.data = this.value.id ? this.value.id : this.data;
        }
      } else if(this.element.pivot.options) {
        // Standard Select
        this.dataset = this.element.pivot.options;
      }
      this.$nextTick(() => {
        this.ready = true;
      });
    } else if(this.field.is === 'file-upload' || this.field.is === 'base-file') {
      console.log(this.value);
      if (!this.value) {
        this.data = [];
      } else if (Array.isArray(this.value) && this.value[0] && Array.isArray(this.value[0])) {
          this.data = JSON.parse(JSON.stringify(this.value[0]));
      } else if(typeof this.value === 'object' && !Array.isArray(this.value) && this.value.url) {
        // this.data = [this.value];
      }

      this.$nextTick(() => {
        this.ready = true;
      });
    } else if(this.field.is === 'base-checkboxes') {
      const recordset_id = this.element.pivot.recordset_id || this.element.pivot.module;
      if (recordset_id) {
        const recordset_params = {module: recordset_id, };
        // Special treatment for using checkboxes from sibling / child modules
        if (this.$inertia.page?.component === 'ModDetail' && this.$inertia.page.props?.mod) {
          // If currently in a child module and recordset is a sibling
          if (this.$inertia.page.props.parent?.id && this.$inertia.page.props.mod.parent.children?.length) {
            const mod_parent_children = this.$inertia.page.props.mod.parent.children.filter(child => child.id === recordset_id);
            if (mod_parent_children.length) {
              recordset_params.module = this.$inertia.page.props.mod.parent.id;
              recordset_params.child = recordset_id;
              recordset_params.parent = this.$inertia.page.props.parent.id;
            }
          }
          // If currently in a module and recordset is one of this modules children
          else if (this.$inertia.page.props.mod.children?.length) {
            const mod_parent_children = this.$inertia.page.props.mod.children.filter(child => child.id === recordset_id);
            if (mod_parent_children.length && this.$inertia.page.props.item?.id) {
              recordset_params.module = this.$inertia.page.props.mod.id;
              recordset_params.parent = this.$inertia.page.props.item.id;
              recordset_params.child = recordset_id;
            }
          }
        }
        const recordset_route = recordset_params.parent ? 'mod.child.index' : 'mod.index';
        this.$root.$CMS_GET(recordset_route, recordset_params).then(resp => {
          this.dataset = resp.data;
          this.$nextTick(() => {
            this.ready = true;
          });
        });
      } else {
        this.dataset = this.element.pivot.options;
        this.$nextTick(() => {
          this.ready = true;
        });
      }
    } else {
      this.$nextTick(() => {
        this.ready = true;
      });
    }
  }
}
</script>
