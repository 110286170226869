<template>
  <div class="shadow bg-white border border-solid border-gray-300 border-opacity-80">
    <div class="bg-gray-200 min-h-[75px] flex justify-start items-center px-9 py-4">
      <template v-if="$slots.heading">
        <slot name="heading" />
      </template>
      <span v-else class="font-bold text-xl text-gray-800">{{ heading }}</span>
    </div>
    <div class="p-10">
      <slot />
    </div>
  </div>
</template>
<script>
export default {
  name: 'DamPanel',
  props: {
    heading: {
      type: String,
      default: ''
    }
  }
}
</script>
