<template>
  <base-card :nospace="true" :class="maxH ? 'max-h-screen' : ''">
    <ul :class="['divide-y divide-gray-200', `${maxH ? 'max-h-screen flex flex-col overflow-hidden' : ''}`]">
      <slot />
    </ul>
  </base-card>
</template>
<script>
export default {
  name: 'StackList',
  props: {
    maxH: {
      type: Boolean,
      default: false
    }
  }
}
</script>
