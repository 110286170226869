<template>
  <div>
    <base-heading no-margin size="lg" class="capitalize">{{ title }}</base-heading>
    <div v-if="isEditing" class="flex gap-2 items-center justify-start">
      <base-input class="flex-1" v-model="metaValue" />
      <dam-button-simple bg-color="bg-indigo-500 hover:bg-indigo-600" text-color="text-white" border-color="" @click="save">
        <CheckIcon class="w-4 h-4" />
      </dam-button-simple>
      <dam-button-simple bg-color="bg-red-500 hover:bg-red-600" text-color="text-white" border-color="" @click="isEditing = false">
        <XIcon class="w-4 h-4" />
      </dam-button-simple>
    </div>
    <p v-else class="flex items-center justify-start gap-2">
      <span v-show="value">{{ value }}</span>
      <dam-button-simple title="Edit" @click="isEditing = true"><PencilIcon class="w-4 h-4" /></dam-button-simple>
    </p>
  </div>
</template>
<script>
import DamButtonSimple from './ButtonSimple';
import { PencilIcon, CheckIcon, XIcon } from '@vue-hero-icons/solid';
import BaseInput from '../../../components/Form/BaseInput.vue';
export default {
  name: 'DamMetaItem',
  components: {
    PencilIcon,
    CheckIcon,
    XIcon,
    DamButtonSimple,
    BaseInput,
  },
  props: ['value', 'title'],
  computed: {
    metaValue: {
      get() { return this.value },
      set(val) { this.$emit('input', val) }
    }
  },
  methods: {
    save() {
      this.$emit('save', this.metaValue);
      this.isEditing = false;
    }
  },
  data() {
    return {
      isEditing: false
    }
  },
  mounted() {
    this.$on('edit_all', () => {
      this.isEditing = true;
    });
    this.$on('save_all', () => {
      this.isEditing = false;
    });
  }
}
</script>
