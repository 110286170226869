<template>
  <app-layout>
    <div v-if="page !== false" class="relative">
      <div class="absolute -top-10 right-0 transform -translate-y-1/2 z-50">
        <base-button
          :disabled="$root.protected"
          :href="
            draftId && pageId
              ? route('cms.pages.draft', { page: pageId, draft: draftId })
              : route('cms.pages.show', page.id)
          "
          tag="a"
          type="link"
        >
          Switch to Edit Mode
        </base-button>
      </div>

      <div class="block lg:flex">
        <!-- CONTENT -->
        <div class="w-full lg:mr-6 lg:w-3/5 lg:flex-1">
          <base-heading>
            Design Mode
            <template v-if="page.type === 'component'" v-slot:tooltip
              >Double click an added component<br />to give it a friendly
              name</template
            >
          </base-heading>
          <div v-if="page.type === 'component'" class="relative">
            <draggable

              v-model="page.components"
              :class="[
                'w-full rounded-md bg-gray-200 p-8',
                page.components.length === 0
                  ? 'pb-60 border-dashed border-4 border-gray-300'
                  : '',
              ]"
              tag="div"
              v-bind="dragOptions"
              group="components"
              handle=".component-handle"
              ghost-class="list-none"
              @start="drag = true"
              @end="drag = false"
              @add="addComponent"
            >
              <base-card
                v-for="(component, index) in page.components"
                :key="`designComponent${index}-${component.id}`"
                class="
                  bg-white
                  relative
                  rounded-md
                  shadow-md
                  w-full
                  mb-2
                "
              >
                <div
                  class="component flex items-center"
                  @dblclick="nameComponent"
                  ref="pageDesignComponent"
                >
                  <div class="component-handle flex-grow-0 w-5 mr-4">
                    <switch-vertical-icon
                      class="h-5 w-5 text-gray-900 cursor-move"
                    />
                  </div>
                  <img
                    v-if="component.image.files.length"
                    class="
                      component-inner
                      hidden
                      flex-grow-0 flex-shrink-0
                      w-10
                      mr-4
                      md:inline-block
                    "
                    :src="
                      typeof component.image.files[0] === 'string'
                        ? component.image.files[0]
                        : component.image.files[0].url
                    "
                  />
                  <div class="component-inner flex-grow-1 w-5/6 xl:w-11/12">
                    <form
                      v-if="page.components[index].pivot"
                      class="component-name-update"
                      @submit.prevent="updateComponentName"
                    >
                      <base-input
                        v-model="page.components[index].pivot.name"
                        class="component-name-update__input"
                        placeholder="Name"
                      />
                    </form>
                    <label class="component-name w-full">{{
                      component.pivot && component.pivot.name
                        ? `${component.pivot.name} (${component.name})`
                        : component.name
                    }}</label>
                  </div>
                  <div v-if="page.components[index].pivot && Array.isArray(component.variants) && component.variants.length" class="flex-none flex gap-2 ml-4 w-56 items-center">
                    <base-label class="mt-2">Variant: </base-label>
                    <el-select :options="component.variants" v-model="page.components[index].pivot.variant" />
                  </div>
                  <!-- POPOVER FOR CONFIGS -->
                  <bldr-component-configs v-if="(typeof page.components[index]?.pivot?.configs !== 'undefined')" class=" ml-4 align-middle" v-bind="{componentConfigs, componentConfigTypes}" v-model="page.components[index].pivot.configs" />
                  <div class="element-actions flex-none w-5 ml-4">
                    <base-button
                      icon
                      class="align-middle"
                      @click="removeComponent(index)"
                    >
                      <x-icon class="w-5 h-5 text-red-500" />
                    </base-button>
                  </div>
                </div>
              </base-card>
            </draggable>
            <p
              v-if="page.components.length === 0"
              class="
                text-center text-2xl
                font-bold
                text-gray-300
                pointer-events-none
                absolute
                top-1/2
                left-1/2
                transform
                -translate-x-1/2 -translate-y-1/2
              "
            >
              Drag &amp; Drop Components
            </p>
          </div>
          <div v-else class="relative">
            <draggable
              v-model="page.elements"
              :class="[
                'w-full rounded-md bg-gray-200 p-8',
                page.elements.length === 0
                  ? 'pb-60 border-dashed border-4 border-gray-300'
                  : '',
              ]"
              tag="div"
              v-bind="dragOptions"
              ghost-class="list-none"
              group="elements"
              handle=".element-handle"
              @start="drag = true"
              @end="drag = false"
            >
              <bldr-component-element
                v-for="(element, index) in page.elements"
                v-model="page.elements[index]"
                :key="`moduleElement${index}`"
                :index="index"
                :upload-configs="uploadConfigs"
                :mods="mods"
                @removeElement="removeElement(index)"
              />
            </draggable>
            <p
              v-if="page.elements.length === 0"
              class="
                text-center text-2xl
                font-bold
                text-gray-300
                pointer-events-none
                absolute
                top-1/2
                left-1/2
                transform
                -translate-x-1/2 -translate-y-1/2
              "
            >
              Drag &amp; Drop Elements
            </p>
          </div>
        </div>

        <!-- SIDEBAR -->
        <div
          v-if="page.type === 'component'"
          class="w-full relative mt-4 lg:w-2/5 lg:mt-0 flex-none"
        >
          <div
            class="
              lg:flex
              lg:flex-col
              lg:absolute
              lg:top-0
              lg:left-0
              lg:w-full
              lg:h-full
            "
          >
            <base-heading class="lg:flex-none">
              <span class="capitalize">{{ item }}s</span>
              <template v-slot:tooltip
                >Search by {{ item }} name or select by category.<br />Drag and
                drop {{ item }}s from right to left.</template
              >
            </base-heading>
            <base-card
              class="mb-4 lg:sticky top-0 z-20 max-h-screen min-h-[300px] flex flex-col"
            >
              <div class="flex-grow-0 flex items-center">
                <div class="w-full md:w-5/12">
                  <base-input
                    v-model="componentQuery"
                    type="text"
                    label="Search"
                  />
                </div>
                <div class="w-full md:w-2/12">
                  <p class="text-center">
                    <small
                      ><strong><br />- OR -</strong></small
                    >
                  </p>
                </div>
                <div class="w-full md:w-5/12">
                  <base-input label="Category">
                    <el-select v-model="componentCategorySelected">
                      <option value="">Select...</option>
                      <option
                        v-for="cat in componentCategories"
                        :key="`componentCategory${cat.name}`"
                        :value="cat.id"
                      >
                        {{ cat.name }}
                      </option>
                    </el-select>
                  </base-input>
                </div>
              </div>
              <base-input
                v-show="componentsQueried && componentsQueried.length > 0"
                label="Results"
                class="flex-grow flex-shrink overflow-auto mt-5"
              >
                <div class="componentsList">
                  <draggable
                    v-if="componentsQueried && componentsQueried.length > 0"
                    v-model="componentsQueried"
                    class="w-full"
                    tag="ul"
                    :clone="clone"
                    :group="{ name: 'components', pull: 'clone', put: false }"
                    :sort="false"
                    data-listname="components"
                    @start="drag = true"
                    @end="drag = false"
                  >
                    <base-card
                      v-for="(component, index) in componentsQueried"
                      :key="`designComponent${index}-${component.id}`"
                      class="
                        bg-white
                        relative
                        rounded-md
                        shadow-md
                        overflow-hidden
                        w-full
                        mb-2
                        cursor-move
                      "
                    >
                      <div class="component flex items-center">
                        <img
                          v-if="component.image.files.length"
                          class="
                            component-inner
                            hidden
                            flex-grow-0 flex-shrink-0
                            w-10
                            mr-4
                            md:inline-block
                          "
                          :src="
                            typeof component.image.files[0] === 'string'
                              ? component.image.files[0]
                              : component.image.files[0].url
                          "
                        />
                        <div class="component-inner flex-grow-1 w-5/6 xl:w-11/12">
                          <label class="w-full">{{ component.name }}</label>
                        </div>
                      </div>
                    </base-card>
                  </draggable>
                </div>
              </base-input>
            </base-card>
          </div>
        </div>
        <!-- SIDEBAR -->
        <div v-else class="w-full relative mt-4 lg:w-2/5 lg:mt-0 flex-none">
          <div
            class="
              lg:flex
              lg:flex-col
              lg:absolute
              lg:top-0
              lg:left-0
              lg:w-full
              lg:h-full
            "
          >
            <base-heading class="lg:flex-none">
              <span>Elements &amp; Components</span>
              <template v-slot:tooltip
                >Drag elements from right to left to<br />add them to the
                module. Components can only<br />be added to the "components"
                element</template
              >
            </base-heading>
            <stack-list class="mb-4 lg:sticky top-0 z-20 max-h-screen min-h-[300px] " max-h>
              <stack-list-item :start-open="true" max-h>
                <span>Elements</span>
                <template v-slot:expand>
                  <tabs-container :tabs="elementCategories" class="max-h-full">
                    <template v-slot:default="slotProps">
                      <tabs-content
                        v-for="(cat, idx) in elementCategories"
                        :key="`element-tab-${idx}`"
                        :tab="cat"
                        v-bind="slotProps"
                        class="bg-gray-200 p-6"
                      >
                        <draggable
                          tag="ul"
                          :clone="clone"
                          :group="{
                            name: 'elements',
                            pull: 'clone',
                            put: false,
                          }"
                          :sort="false"
                          :value="elements.filter((el) => el.category === cat)"
                          @start="drag = true"
                          @end="drag = false"
                        >
                          <li
                            v-for="(el, idxE) in elements.filter(
                              (el) => el.category === cat
                            )"
                            :key="`element-tab-${cat}-el-${idxE}`"
                          >
                            <base-card class="cursor-move mb-6" @dblclick="dblclickElement(el)">
                              <base-input :label="el.name">
                                <div
                                  class="pointer-events-none"
                                  v-if="Array.isArray(el.markup)"
                                >
                                  <div
                                    v-for="(mk, idxM) in el.markup"
                                    :key="`elmk.${idxM}`"
                                  >
                                    <div
                                      v-if="
                                        typeof mk === 'object' &&
                                        mk.is === 'draggable'
                                      "
                                    ></div>
                                    <component
                                      :is="mk.is"
                                      v-else-if="typeof mk === 'object'"
                                      v-bind="mk"
                                    >
                                      <component
                                        :is="mk.child.is"
                                        v-if="typeof mk.child === 'object'"
                                        v-bind="mk.child"
                                      ></component>
                                      <div
                                        v-else-if="typeof mk.child === 'string'"
                                        v-html="mk.child"
                                      ></div>
                                    </component>
                                    <div v-else v-html="mk"></div>
                                  </div>
                                </div>
                                <div v-else v-html="el.markup"></div>
                              </base-input>
                            </base-card>
                          </li>
                        </draggable>
                      </tabs-content>
                    </template>
                  </tabs-container>
                </template>
              </stack-list-item>
              <stack-list-item max-h>
                <span>Components</span>
                <template v-slot:expand>
                  <div class="flex-grow-0 flex items-center">
                    <div class="w-full md:w-5/12">
                      <base-input
                        v-model="componentQuery"
                        type="text"
                        label="Search"
                      />
                    </div>
                    <div class="w-full md:w-2/12">
                      <p class="text-center">
                        <small
                          ><strong><br />- OR -</strong></small
                        >
                      </p>
                    </div>
                    <div class="w-full md:w-5/12">
                      <base-input label="Category">
                        <el-select v-model="componentCategorySelected">
                          <option value="">Select...</option>
                          <option
                            v-for="cat in componentCategories"
                            :key="`componentCategory${cat.name}`"
                            :value="cat.id"
                          >
                            {{ cat.name }}
                          </option>
                        </el-select>
                      </base-input>
                    </div>
                  </div>
                  <base-input
                    v-show="componentsQueried && componentsQueried.length"
                    label="Results"
                    class="flex-grow-1 mt-5"
                  >
                    <div class="componentsList">
                      <draggable
                        v-if="componentsQueried && componentsQueried.length > 0"
                        v-model="componentsQueried"
                        class="w-full"
                        tag="ul"
                        :clone="clone"
                        :group="{
                          name: 'components',
                          pull: 'clone',
                          put: false,
                        }"
                        :sort="false"
                        data-listname="components"
                        @start="drag = true"
                        @end="drag = false"
                      >
                        <base-card
                          v-for="(component, index) in componentsQueried"
                          :key="`designComponent${index}-${component.id}`"
                          class="
                            bg-white
                            relative
                            rounded-md
                            shadow-md
                            overflow-hidden
                            w-full
                            mb-2
                            cursor-move
                          "
                          @dblclick="dblclickComponent(component)"
                        >
                          <div class="component flex items-center">
                            <img
                              v-if="
                                component.image &&
                                component.image.files &&
                                component.image.files.length
                              "
                              class="
                                component-inner
                                hidden
                                flex-grow-0 flex-shrink-0
                                w-10
                                mr-4
                                md:inline-block
                              "
                              :src="
                                typeof component.image.files[0] === 'string'
                                  ? component.image.files[0]
                                  : component.image.files[0].url
                              "
                            />
                            <div class="component-inner flex-grow-1 w-5/6 xl:w-11/12">
                              <base-label class="w-full">{{
                                component.name
                              }}</base-label>
                            </div>
                          </div>
                        </base-card>
                      </draggable>
                    </div>
                  </base-input>
                </template>
              </stack-list-item>
            </stack-list>
          </div>
        </div>
      </div>

      <div class="mt-6">
        <base-button class="mr-4" @click="save(false)">Save</base-button>
        <base-button class="mr-4" @click="save(true)"
          >Save &amp; Edit</base-button
        >
      </div>
    </div>
  </app-layout>
</template>
<script>
import Form from '../components/Form';
import Stack from '../components/Stack';
import Tabs from '../components/Tabs';
import draggable from 'vuedraggable';
import {
  InformationCircleIcon,
  SwitchVerticalIcon,
  XIcon,
  CogIcon,
} from '@vue-hero-icons/solid';
import BldrComponentElement from '../components/Builder/BldrComponentElement';
import BldrComponentConfigs from '../components/Builder/BldrComponentConfigs';
export default {
  components: {
    ...Stack,
    ...Form,
    ...Tabs,
    BldrComponentElement,
    BldrComponentConfigs,
    draggable,
    InformationCircleIcon,
    SwitchVerticalIcon,
    XIcon,
    CogIcon,
  },
  props: {
    page: {
      type: [Object, Boolean],
      default: () => false,
    },
    componentCategories: {
      type: [Array],
      default: () => [],
    },
    componentConfigs: {
      type: [Array],
      default: () => [],
    },
    componentConfigTypes: {
      type: [Array],
      default: () => [],
    },
    components: {
      type: [Array],
      default: () => [],
    },
    mods: {
      type: Array,
      default: () => [],
    },
    elements: {
      type: [Array],
      default: () => [],
    },
    uploadConfigs: {
      type: [Array],
      default: () => [],
    },
    pageId: {
      type: Number,
      default: 0,
    },
    draftId: {
      type: [Number],
      default: 0,
    },
  },
  data() {
    return {
      drag: false,
      componentQuery: '',
      componentCategorySelected: null,
      elementCategorySelected: null,
      elementQuery: '',
    };
  },
  computed: {
    elementCategories() {
      return this.elements
        .map(function (el) {
          return el.category;
        })
        .filter(function (value, index, self) {
          return self.indexOf(value) === index;
        });
    },
    cms() {
      return this.$page.props.cms;
    },
    dragOptions() {
      return {
        animation: 200,
        group: 'components',
        disabled: false,
        ghostClass: 'ghost',
      };
    },
    componentsQueried() {
      if (this.componentQuery.length) {
        return this.components.filter((component) => {
          return (
            component.name
              .toLowerCase()
              .indexOf(this.componentQuery.toLowerCase()) >= 0
          );
        });
      } else if (this.componentCategorySelected !== null) {
        return this.components.filter((component) => {
          return (
            parseInt(component.category_id) ===
            parseInt(this.componentCategorySelected)
          );
        });
      } else {
        return [];
      }
    },
    elementsQueried() {
      if (this.elementQuery.length) {
        return this.elements.filter((element) => {
          return (
            element.name
              .toLowerCase()
              .indexOf(this.elementQuery.toLowerCase()) >= 0
          );
        });
      } else if (this.elementCategorySelected !== null) {
        return this.elements.filter((element) => {
          return (
            element.category.toUpperCase() ===
            this.elementCategorySelected.toUpperCase()
          );
        });
      } else {
        return [];
      }
    },
    item() {
      return this.page.type === 'component' ? 'component' : 'element';
    },
  },
  methods: {
    nameComponent($event) {
      const component = $event.target;
      component.classList.toggle('is-naming');
      this.$nextTick(() => {
        component.querySelectorAll('.component-name-update__input')[0].focus();
      });
    },
    updateComponentName() {
      this.$refs.pageDesignComponent.forEach((el) => {
        el.classList.remove('is-naming');
      });
    },
    clone: function (original) {
      var element = {};
      for (var key in original) {
        if (original.hasOwnProperty(key)) {
          element[key] = JSON.parse(JSON.stringify(original[key]));
        }
      }
      this.$root.protected = true;
      return element;
    },
    addComponentRepeater(element, data) {
      if (!element.children) {
        element.children = JSON.parse(element.pivot.children);
      }
      element.children.map((el) => {
        if (typeof el.pivot === 'undefined') {
          if (Array.isArray(el.properties)) {
            el.pivot = {};
            el.properties.forEach((prop) => {
              el.pivot[prop.property] = prop.value;
            });
          } else {
            el.pivot = el.properties;
          }
        }
      });
      element.debug = data;
      element.basis = this.addComponentElements(
        JSON.parse(JSON.stringify(element.children))
      );
      // debugger;
      if (Array.isArray(data)) {
        let temp = [];
        data.forEach((d, idxD) => {
          temp.push(
            this.addComponentElements(
              JSON.parse(JSON.stringify(element.children)),
              d
            )
          );
        });
        console.log(temp);
        element.children = temp;
      } else {
        element.children = [this.addComponentElements(element.children, data)];
      }
      this.$root.protected = true;
      return element;
    },
    addComponentElement(el, data) {
      let elData;
      if (data) {
        try {
          elData = data[el.pivot.variable];
          if (!elData) {
            elData = data;
          }
        } catch (err) {
          elData = data;
          console.error('elData: ', err);
        }
      }
      if (
        (el.children && el.children.length) ||
        (typeof el.pivot !== 'undefined' &&
          el.pivot.children &&
          el.pivot.children.length)
      ) {
        el = this.addComponentRepeater(el, elData);
      } else {
        el.markup =
          typeof el.markup === 'string' ? JSON.parse(el.markup) : el.markup;
        if (Array.isArray(el.markup)) {
          el.markup.forEach((mk, idxM) => {
            if (mk.is === 'file-upload' || mk.is === 'base-file') {
              if (!Array.isArray(mk.fileList)) {
                mk.fileList = [];
              }
              if (elData && typeof elData[idxM] !== 'undefined') {
                // mk.fileList.push(elData[idxM]);
                mk.fileList = elData[idxM];
              }
              mk.data = mk.fileList;
            } else {
              mk.data =
                elData && typeof elData[idxM] !== 'undefined'
                  ? elData[idxM]
                  : '';
            }
            if (typeof mk.data === 'undefined') {
              mk.data =
                elData && typeof elData[idxM] !== 'undefined'
                  ? elData[idxM]
                  : '';
            }
          });
        } else {
          if (typeof el.data === 'undefined') {
            el.data =
              elData && typeof elData[idxM] !== 'undefined' ? elData[idxM] : '';
          }
        }
      }
      this.$root.protected = true;
      return el;
    },
    addComponentElements(elements, data) {
      if (data) {
        // debugger;
      }
      elements.forEach((el, idxE) => {
        if (Array.isArray(el)) {
          el.forEach((elel, idxEE) => {
            elel = this.addComponentElement(
              elel,
              !!data && typeof data[idxEE] !== 'undefined' ? data[idxEE] : data
            );
          });
        } else {
          if (Array.isArray(data)) {
            el = this.addComponentElement(
              el,
              !!data && typeof data[idxE] !== 'undefined' ? data[idxE] : data
            );
          } else {
            el = this.addComponentElement(el, data);
          }
        }
      });
      this.$root.protected = true;
      return elements;
    },
    addComponentData(elements, data) {
      if (!data) {
        data = {};
      }
      let els =
        Array.isArray(elements) &&
        elements.length === 1 &&
        typeof elements[0] === 'object' &&
        elements[0].name !== 'Repeater'
          ? elements[0]
          : elements;
      if (!Array.isArray(els)) {
        els = [els];
      }
      els.forEach((el, idxE) => {
        if (el.children && el.children.length) {
          data[el.pivot.variable] = [];
          data[el.pivot.variable].push(this.addComponentData(el.children));
        } else {
          data[el.pivot.variable] = [];
          if (!Array.isArray(el.markup) && typeof el.markup === 'string') {
            el.markup = JSON.parse(el.markup);
          }
          el.markup.forEach((mk, idxM) => {
            if (mk.is === 'file-upload' || mk.is === 'base-file') {
              data[el.pivot.variable][idxM] = [];
            } else {
              data[el.pivot.variable][idxM] = '';
            }
          });
        }
      });
      this.$root.protected = true;
      return data;
    },
    addComponent(evt) {
      try {
        setTimeout(() => {
          let component = this.page.components[evt.newIndex];
          component.elements = this.addComponentElements(component.elements);
          if (!component.pivot) {
            component.pivot = {};
          }
          this.$nextTick(() => {
            if (!component.pivot.name) {
              component.pivot.name = '';
            }
            if (!component.pivot.variant) {
              component.pivot.variant = '';
            }
            component.pivot.data = this.addComponentData(component.elements);
            this.$nextTick(() => {
              console.log(component);
              console.log(this.page.components[evt.newIndex]);
              const pageLoaded = new Event('cms_page_loaded');
              document.body.dispatchEvent(pageLoaded);
            });
          });
        }, 1000);
        this.$root.protected = true;
      } catch (err) {
        console.error(err);
      }
    },
    orderComponent() {
      this.page.components.forEach((el, idx) => {
        el.sort_order = idx;
      });
      this.$root.protected = true;
    },
    removeComponent(idx) {
      this.page.components.splice(idx, 1);
      this.$root.protected = true;
    },

    orderElement() {
      this.page.elements.forEach((el, idx) => {
        el.sort_order = idx;
      });
      this.$root.protected = true;
    },
    removeElement(idx) {
      this.page.elements.splice(idx, 1);
      this.$root.protected = true;
    },
    save(goToEdit) {
      this.$root
        .$CMS_UPDATE('pages', this.pageId || this.page.id, {
          page: this.page,
          status: this.draftId ? 'draft' : 'published',
          draftId: this.draftId,
        })
        .then(() => {
          // afterwards remove protected
          this.$root.protected = false;
          if (goToEdit) {
            this.$nextTick(() => {
              window.location.href =
                this.draftId && this.pageId
                  ? route('cms.pages.draft', {
                      page: this.pageId,
                      draft: this.draftId,
                    })
                  : route('cms.pages.show', { page: this.page.id });
            });
          }
        });
    },
  },
  mounted() {
    document.addEventListener('keyup', this.$root.protectEvent);
    this.selectedDraft = this.draftId;
  },
  beforeDestroy() {
    document.removeEventListener('keyup', this.$root.protectEvent);
  },
};
</script>

<style scoped>
.component:not(.is-naming) .component-inner,
.component:not(.is-naming) .component-inner * {
  pointer-events: none !important;
}
.component:not(.is-naming) .component-name-update,
.component.is-naming .component-name {
  display: none;
}
</style>
