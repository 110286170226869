<template>
  <dam-layout :content-container="false">
    <create-assets :collections="collections" :categories="categories" />
   </dam-layout>
</template>
<script>
import DamLayout from '../../layouts/DamLayout';
import CreateAssets from './components/CreateAssets';
export default {
  name: 'AssetsCreate',
  components: {
    CreateAssets,
    DamLayout,
  },
  props: {
    collections: {
      type: Array,
      default: () => []
    },
    categories: {
      type: Array,
      default: () => []
    },
  },
};
</script>
