<template>
  <app-layout>
    <form @submit.prevent="submit">
      <base-card class="space-y-4 mb-10">
        <base-input
          v-model="productType.name"
          type="text"
          label="Name"
          placeholder=""
          :error="$page.props.errors.name || null"
          required
        />
        <base-button type="button" @click="submit">Submit</base-button>
      </base-card>
    </form>
  </app-layout>
</template>
<script>
import Form from '../../components/Form';
export default {
  name: 'ProductType',
  components: {
    ...Form,
  },
  props: {
    productType: {
      type: Object,
      default: () => {
        return {
          name: '',
        };
      },
    },
  },
  methods: {
    submit() {
      if (this.productType.id) {
        this.$inertia.put(route('cms.product-types.update'), this.productType);
      } else {
        this.$inertia.post(route('cms.product-types.store'), this.productType);
      }
    },
  },
};
</script>
