<template>
  <div :class="[...sizeCss, css, shadowCss, 'bg-white rounded-md']">
    <slot />
  </div>
</template>
<script>
export default {
  name: 'Card',
  props: {
    nospace: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 'md'
    },
    css: {
      type: String,
      default: ''
    }
  },
  computed: {
    sizeCss() {
      let padding = '';
      let text = '';
      switch(this.size) {
        case 'sm':
          padding = this.nospace ? '' : 'px-2 py-3 sm:p-4'
          text = 'text-sm'
        break;
        case 'lg':
          padding = this.nospace ? '' : 'px-5 py-6 sm:p-7'
          text = 'text-lg'
        break;
        default:
          padding = this.nospace ? '' : 'px-4 py-5 sm:p-6'
          text = 'text-base'
      }
      return [padding, text]
    },
    shadowCss() {
      if (this.css.length && this.css.indexOf('shadow') >= 0) {
        return '';
      } else {
        return 'shadow-xl';
      }
    }
  }
}
</script>
