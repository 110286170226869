<template>
  <div v-if="open" class="fixed bg-gray-800/60 backdrop-blur-sm top-0 left-0 w-full h-full z-50 flex items-center justify-center py-16 px-24">
    <button class="absolute top-16 right-24 -translate-y-1/2 translate-x-1/2 rounded-full p-1 border-2 border-solid border-white shadow-lg bg-black" title="Cancel" type="button" @click="cancelModal">
      <XIcon class=" w-6 h-6 text-white" />
    </button>

    <div class="w-full min-h-full max-h-[90vh] bg-white shadow-xl flex">
      <div class="flex-none w-20 bg-[#424C85] space-y-9 pt-32">
        <button v-for="tab in tabs" :key="`tab-${tab}`" type="button" class="block mx-auto" @click="activeTab = tab" :title="tab">
          <component :is="`${tab}-icon`" :class="['block w-9 h-9', activeTab === tab ? 'text-white' : 'text-gray-400']" />
        </button>

      </div>
      <div class="flex-grow py-9 px-14 overflow-y-scroll overflow-x-visible show-scrollbar">
        <div v-if="activeTab === 'search'" class="flex flex-col gap-12 h-full">
          <div class="flex-none flex items-start justify-between">
            <p class="text-3xl">Digital Asset Manager &mdash; <strong class="text-indigo-600">Search</strong></p>
            <button type="button" @click="activeTab = 'upload'" title="Upload" class="relative inline-flex items-center px-4 py-2 space-x-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-500 hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-indigo-500">
              <span>Upload Assets</span>
              <upload-icon class="h-6 w-6" />
            </button>
          </div>
          <div class="relative flex-none ">
            <div class="shadow h-14 flex items-center justify-start rounded border border-solid border-gray-100 overflow-hidden">
              <SearchIcon class="w-8 h-8 mx-4 text-gray-500 flex-none" />
              <input type="text" v-model="searchQuery" @keyup.enter="doSearch" class="text-xl border-none focus:outline-none flex-grow mr-1" placeholder="Search By Name " />
              <button type="button" @click="searchFiltersOpen = !searchFiltersOpen" class="bg-gray-50 w-14 h-full flex-none flex items-center justify-center">
                <AdjustmentsIcon class="w-8 h-8 rotate-90" />
              </button>
            </div>
            <div v-show="searchFiltersOpen" class="flex flex-col gap-11 items-stretch justify-between absolute top-full left-0 shadow-lg bg-white rounded p-12 w-full z-50 h-[600px] max-h-screen">
              <div class="space-y-11">
                <p class="text-xl font-bold pb-5">Advanced Search</p>
                <div class="flex items-center justify-start gap-5">
                  <label class="flex-none w-32 text-right">File Type</label>
                  <el-select :class="['flex-grow', defaultType !== null ? 'opacity-70 pointer-events-none' : '']" :options="filters.types" v-model="selectedFilter.type" :disabled="defaultType !== null" />
                </div>
                <div class="flex items-center justify-start gap-5">
                  <label class="flex-none w-32 text-right">Category</label>
                  <el-select class="flex-grow" :options="filters.categories" v-model="selectedFilter.category" />
                </div>
                <div class="flex items-center justify-start gap-5">
                  <label class="flex-none w-32 text-right">Collection</label>
                  <el-select class="flex-grow" :options="filters.collections" v-model="selectedFilter.collection" />
                </div>
                <div class="flex items-center justify-start gap-5">
                  <label class="flex-none w-32 text-right">Meta</label>
                  <div class="flex-grow flex gap-3">
                    <el-select class="w-1/2 flex-grow" :options="filters.meta_groups" v-model="selectedFilter.meta_group" />
                    <el-select v-if="selectedFilter.meta_group" class="w-1/2" :options="filters.meta_values.filter(v => v.parent == selectedFilter.meta_group)" v-model="selectedFilter.meta_value" />
                  </div>
                </div>
                <div class="flex items-center justify-start gap-5">
                  <label class="flex-none w-32 text-right">Date Modified</label>
                  <el-select class="flex-grow" :options="filters.dates" v-model="selectedFilter.date_modified" />
                </div>
              </div>
              <div class="self-end flex gap-4">
                <dam-button tag="button" type="secondary" @click="cancelFilters">Cancel</dam-button>
                <dam-button tag="button" type="primary" @click="searchFilters">Search</dam-button>
              </div>
            </div>
          </div>
          <div class="flex-auto pb-10">
            <p class="text-lg font-bold mb-3" v-text="searched ? 'Results' : 'Recent Uploads'"></p>
            <div class="flex flex-wrap items-start gap-6">
              <asset-card v-for="item in assets" :key="`asset-${item.uuid}`" :item="item" :linkToAsset="false">
                <template v-slot:actions="{ isFocused }">
                  <div class="flex items-center justify-center w-full">
                    <popover-download :item="item" @selected="itemSelected" action="Select" v-on:popoveropen="isFocused = true" v-on:popoverclose="isFocused = false" />
                  </div>
                </template>
              </asset-card>
            </div>
          </div>
        </div>
        <div v-else class="flex flex-col gap-12 h-full">
          <p class="text-3xl">Digital Asset Manager &mdash; <strong class="text-indigo-600">File Upload</strong></p>
          <create-assets :collections="filters.collections" :single="true" :modal="true" @uploaded="uploaded" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {SearchIcon, UploadIcon, XIcon, AdjustmentsIcon } from '@vue-hero-icons/solid'
import AssetCard from './AssetCard.vue';
import PopoverDownload from './PopoverDownload.vue';
import ElSelect from '../../../components/Form/ElSelect.vue';
import DamButton from './Button.vue'
import moment from 'moment';
import CreateAssets from './CreateAssets.vue';
export default {
  components: {
    AdjustmentsIcon,
    SearchIcon,
    UploadIcon,
    XIcon,
    AssetCard,
    PopoverDownload,
    ElSelect,
    DamButton,
    CreateAssets,
  },
  data() {
    return {
      open: false,
      tabs: ['search', 'upload'],
      activeTab: 'search',
      searchQuery: '',
      defaultType: null,
      selectedFilter: {
        type: null,
        category: null,
        collection: null,
        meta_group: null,
        meta_value: null,
        date_modified: null,
      },
      searchFiltersOpen: false,
      assets: [],
      searched: false,
      filters: {
        types: [],
        categories: [],
        collections: [],
        meta_groups: [],
        meta_values: [],
        dates: [...Array(30).keys()].map(num => { return { label: `Last ${num+1} Day${num === 0 ? '' : 's'}`, value: num+1 } })
      },
    }
  },
  mounted() {
    window.addEventListener('asset-manager-modal', (evt) => {
      if (this.open) {
        this.open = false;
        this.defaultType = null;
        this.selectedFilter = {
          type: null,
          category: null,
          collection: null,
          meta_group: null,
          meta_value: null,
          date_modified: null,
        };
        this.searchQuery = '',
        this.searched = false
      } else {
        // Get most recent on first load
        this.defaultType = evt.detail?.defaultType || null;
        this.selectedFilter.type = evt.detail?.defaultType || null;
        this.$nextTick().then(() => {
          this.getRecent();
          this.open = true;
        })
      }
    });

    if (this.$inertia.page.props.suffixes) {
      window.cmsDamAllSuffixes = this.$inertia.page.props.suffixes;
    } else {
      this.$CMS_GET('dam.assets.suffixes', {}, true).then(data => {
        window.cmsDamAllSuffixes = data.suffixes;
      });
    }

    this.getFilters();

  },
  created: function() {
    document.addEventListener('keyup', this.escape);
  },
  destroyed: function() {
    document.removeEventListener('keyup', this.escape);
  },
  methods: {
    escape(event) {
      if (event.keyCode === 27) {
        this.cancelModal();
      }
    },
    cancelModal() {
      window.dispatchEvent(new CustomEvent('asset-manager-file-selected', {
        detail: {
          files: []
        }
      }))
      this.open = false;
    },
    selectFile(name, url) {
      window.dispatchEvent(new CustomEvent('asset-manager-file-selected', {
        detail: {
          files: [{name, url}]
        }
      }))
      this.open = false;
    },
    itemSelected(evt) {
      this.selectFile(evt.name, evt.url)
    },
    getFilters() {
      this.$CMS_GET('dam.filter-options').then(resp => {
        this.filters.categories = resp.categories
        this.filters.collections = resp.collections
        this.filters.types = resp.types
        this.filters.meta_groups = resp.meta_groups
        this.filters.meta_values = resp.meta_values
      })
    },
    getRecent() {
      this.$CMS_GET('dam.assets.index', {order_by: 'updated_at,desc', type: this.defaultType}).then(resp => {
        this.assets = resp.data;
        this.searched = false;
      });
    },
    cancelFilters() {
      this.selectedFilter = {
        type: this.defaultType,
        category: null,
        collection: null,
        meta_group: null,
        meta_value: null,
        date_modified: null,
      };
      this.searchFiltersOpen = false;
    },
    searchFilters() {
      this.$CMS_GET('dam.assets.index', {
        query: this.searchQuery,
        type: this.selectedFilter.type,
        meta: this.selectedFilter.meta_group && this.selectedFilter.meta_value ? `${this.selectedFilter.meta_group}|${this.selectedFilter.meta_value}` : null,
        collection: this.selectedFilter.collection,
        category: this.selectedFilter.category,
        updated_at: this.selectedFilter.date_modified ? moment().subtract(this.selectedFilter.date_modified, 'days').format('YYYY-MM-DD HH:mm:ss') : null
      }).then(resp => {
        this.assets = resp.data;
        this.searched = true;
        this.searchFiltersOpen = false;
      });
    },
    doSearch() {
      if (this.searchQuery.length) {
        this.$CMS_GET('dam.assets.index', {query: this.searchQuery, type: this.defaultType}).then(resp => {
          this.assets = resp.data;
          this.searched = true;
        });
      } else {
        this.getRecent();
      }
    },
    uploaded(evt) {
      console.log(evt);
      const file = evt.files[0].asset;
      this.selectFile(file.name, route('cms.dam.asset', {uuid: file.uuid}));
    }
  }
}
</script>
