<template>
  <div class="relative flex justify-end items-center z-50" :ref="`base-actions-${uuid}-container`">
    <button @click="toggle()" :id="`base-actions-${uuid}`" :ref="`base-actions-${uuid}-button`" aria-haspopup="true" type="button" class="w-8 h-8 bg-white inline-flex items-center justify-center text-gray-400 rounded-full hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">
      <span class="sr-only">Open options</span>
      <dots-vertical-icon class="w-5 h-5" />
    </button>
    <transition
      enter-active-class="transition-opacity ease-out duration-100"
      enter-from-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="transition-opacity ease-in duration-75"
      leave-from-class="opacity-100 "
      leave-to-class="opacity-0"
    >
      <div
        v-show="open"
        class="origin-top-right absolute right-7 top-0 w-48 rounded-md shadow-lg z-10 bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200"
        role="menu"
        aria-orientation="vertical"
        :aria-labelledby="`base-actions-${uuid}`"
        :ref="`base-actions-${uuid}`"
      >
        <slot />
      </div>
    </transition>
  </div>
</template>
<script>
import { createPopper } from '@popperjs/core'
import { DotsVerticalIcon } from '@vue-hero-icons/solid'
export default {
  name: 'ActionsPopover',
  components: {
    DotsVerticalIcon
  },
  props: {

  },
  data() {
    return {
      open: false,
      instance: null,
      container: null,
      actions: null,
    }
  },
  methods: {
    close($event) {
      this.open = false
    },
    toggle() {
      if (this.open) {
        this.open = false;
        this.actions.removeAttribute('data-show');
      } else {
        this.open = true;
        this.actions.setAttribute('data-show', '');
        this.instance.update();
        window.dispatchEvent(new CustomEvent("actionsPopoverOpen", {"detail": {"uid": this.uuid}}));
      }
    },
  },
  mounted() {
    this.container = this.$refs[`base-actions-${this.uuid}-container`];
    this.actions = this.$refs[`base-actions-${this.uuid}`];
    this.instance = createPopper(this.container, this.actions, {
      placement: 'left',
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, this.offsetY],
          },
        },
      ],
    });
    this.$on('toggle', () => {
      this.toggle();
    });
    window.addEventListener('actionsPopoverOpen', (event) => {
      if (this.open && this.uuid !== event.detail.uid) {
        this.toggle()
      }
    });
  }
}
</script>
