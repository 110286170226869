<template>
  <dam-layout>
    <div class="flex flex-col lg:flex-row justify-start items-start gap-10">
      <div class="lg:w-2/3">
        <base-heading>Spotlight Collections</base-heading>
        <div v-if="collections && collections.length" class="flex flex-wrap gap-4">
          <inertia-link v-for="coll in collections" :key="`collection-${coll.id}`" :href="route('cms.dam.assets.index', { ...route().params, collection: coll.id})" >
            <image-card  v-if="show_preview" class="relative w-52 2xl:w-72 transition-all hover:shadow-2xl hover:scale-105 hover:z-20">
              <template v-slot:image>
                <div class="flex flex-wrap absolute top-0 left-0 w-full h-full">
                  <template v-for="item in coll.first_four">
                    <img
                      v-if="item.thumbnail"
                      :key="`collection-${coll.id}-thumb-${item.id}`"
                      :src="item.thumbnail"
                      class="object-cover w-1/2 h-1/2 flex-grow flex-shrink-0"
                    />
                    <div v-else :key="`collection-${coll.id}-thumb-${item.id}`" class="w-1/2 h-1/2 flex-grow flex-shrink-0 relative">
                      <svg class="absolute inset-0 m-auto w-1/2 h-1/2 opacity-60">
                        <use :href="`#filetype-${fileIcon(item.type)}`" />
                      </svg>
                    </div>
                  </template>
                </div>
              </template>
              <p class="font-bold text-left px-2">{{ coll.name }}</p>
            </image-card>
            <base-card v-else class="relative w-52 2xl:w-72 transition-all hover:shadow-2xl hover:scale-105 hover:z-20">
              <p class="font-bold text-left">{{ coll.name }}</p>
            </base-card>
          </inertia-link>
        </div>
        <p v-else>No collections added to the dashboard</p>
      </div>
      <div class="lg:w-1/3">
        <activity :activity="activity" class="-mt-1" />
      </div>
    </div>
  </dam-layout>
</template>
<script>
import mime from 'mime'
import DamLayout from '../../layouts/DamLayout';
import ImageCard from './components/ImageCard';
import Activity from './components/Activity'
export default {
  name: 'Dashboard',
  props: ['activity', 'collections', 'show_preview'],
  components: {
    DamLayout,
    ImageCard,
    Activity,
  },
  methods: {
    fileIcon(type) {
      return mime.getExtension(type)
    }
  }
};
</script>
