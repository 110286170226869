<template>
  <label :class="['block', noMargin ? '' : 'mb-2']">
    <span :class="cssClass"><slot /></span>
    <template v-if="tooltip">
      <base-tooltip class="inline-block ml-2 w-4 h-4 text-center align-middle" :offsetY="5">
        <template v-slot:content>
          <span v-html="tooltip"></span>
        </template>
        <information-circle-icon class="align-top w-full h-full inline-block" />
      </base-tooltip>
    </template>
  </label>
</template>
<script>
import { InformationCircleIcon } from '@vue-hero-icons/solid'
export default {
  name: 'BaseLabel',
  props: {
    tooltip: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: 'text-sm'
    },
    color: {
      type: String,
      default: 'text-gray-700'
    },
    spanClass: {
      type: String,
      default: ''
    },
    noMargin: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    cssClass() {
      return [this.size, this.color, this.spanClass, 'uppercase font-bold tracking-wide align-middle']
    }
  },
  components: {
    InformationCircleIcon
  }
}
</script>
