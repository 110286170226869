<template>
  <div v-if="element.name.toUpperCase() === 'SECTION'">
    <base-heading size="lg">{{ element.pivot.label }}</base-heading>
    <small
      v-if="element.pivot.helptext"
      slot="helperText"
      class="form-text text-muted"
    >{{ element.pivot.helptext }}</small>
  </div>
  <div v-else-if="ready">
    <base-label>{{ labelPrefix }}{{ element.pivot.label }}</base-label>
    <bldr-components
      v-if="element.name.toUpperCase() === 'COMPONENTS'"
      v-model="data"
      :element="element"
      :disabled="disabled"
    />
    <div v-else>
      <div
        v-for="(mk,idxM) in element.markup"
        :key="`elmk.${idxM}`"
      >
        <bldr-field
          v-if="element.markup.length === 1"
          v-model="data"
          :idx="idxM"
          :element="element"
          :field="mk"
          :disabled="disabled"
          ref="field"
        />
        <bldr-field
          v-else
          v-model="data[idxM]"
          :idx="idxM"
          :element="element"
          :field="mk"
          :disabled="disabled"
          ref="field"
        />
      </div>
    </div>
    <small
      v-if="element.pivot.helptext"
      slot="helperText"
      class="form-text text-muted"
    >{{ element.pivot.helptext }}</small>
  </div>
</template>
<script>
import BaseLabel from '../Form/BaseLabel'
import BldrField from './BldrField'
export default {
  name: 'BldrElement',
  components: {
    BldrComponents: () => import('./BldrComponents'),
    BldrField,
    BaseLabel
  },
  props: {
    element: Object,
    value: {
      type: [String, Number, Array, Object, Boolean],
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    labelPrefix: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      ready: false,
      markup: []
    }
  },
  mounted() {
    this.element.markup = typeof this.element.markup === 'string' ?
      JSON.parse(this.element.markup) : this.element.markup;
    if (typeof this.element.pivot.options === 'string') {
      this.element.pivot.options = JSON.parse(this.element.pivot.options);
    }
    if (this.element.markup.length > 1 && !this.data) {
      this.data = this.element.markup.map(()=>{ return '' });
    }

    if (Array.isArray(this.value) &&
      this.value.length === 1 &&
      !(this.element.name === 'Repeater' ||
        this.element.name === 'File Upload' ||
        this.element.name === 'Components' ||
        this.element.name === 'Checkboxes' ||
        this.element.name === 'Dynamic Checkboxes'
      )
    ) {
      this.data = JSON.parse(JSON.stringify(this.value[0]));
    }

    this.$nextTick(() => {
      this.ready = true;
    })
  },
  computed: {
      data: {
          get() { return this.value },
          set(data) {this.$emit('input', data)}
      }
  }
}
</script>
