<template>
  <time :datetime="datetime">{{ label }}</time>
</template>
<script>
import moment from 'moment'
export default {
  props: {
    datetime: String,
    format: String
  },
  computed: {
    label() {
      return moment(this.datetime).format(this.format);
    }
  }
}
</script>
