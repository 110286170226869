<template>
  <app-layout>
    <base-card>
      <search-bar />
      <base-heading>Results for '<span>{{ query}}</span>'</base-heading>
      <event-list v-if="results.length" v-bind="{mediaOptions}" :days="results" @edit="editButton" />
      <p v-else>No results</p>
    </base-card>
  </app-layout>
</template>
<script>
  import SearchBar from './components/SearchBar'
  import EventList from './components/EventList'
  import moment from 'moment'
  export default {
    name: 'BroadcastCalendarSearch',
    components: {
      SearchBar,
      EventList
    },
    props: {
      query: {
        type: String,
        default: ''
      },
      results: {
        type: Array,
        default: () => []
      }
    },
    methods: {
      editButton(payload) {
        this.$inertia.visit(route('cms.broadcast-calendar.index', {'year-month': moment(payload.date).format(`YYYY-MM`)}))
      }
    },
    data() {
      return {
        months: [
          'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'
        ],
        scheduleables: [
          { label: 'Series', value: "\\App\\Series", recordset_id: 9 },
          { label: 'Series Post', value: "\\App\\SeriesPost", recordset_id: 14 },
          { label: 'Real Hope Minute', value: "\\App\\RealHopeMinute", recordset_id: 17 },
        ],
        mediaOptions: [
          { value: 'listen-part-1', label: 'Listen: Part 1' },
          { value: 'listen-part-2', label: 'Listen: Part 2' },
          { value: 'listen', label: 'Listen: Single Message' },
          { value: 'watch', label: 'Watch' },
        ]
      }
    }
  }
</script>
