<template>
  <div v-if="(item && typeof idx === 'number')">
    <div v-if="(typeof item.children !== 'undefined' && Array.isArray(item.children))" class="space-y-2">
      <button
        :class="cssClass"
        @click="isOpen = !isOpen"
      >
        <component :is="`${item.icon}-icon`" :class="iconClass" />
        <span class="inline-block ml-2 whitespace-nowrap overflow-hidden overflow-ellipsis">{{ item.name }}</span>
        <!-- Expanded: "text-gray-200 rotate-90", Collapsed: "text-gray-100" -->
        <svg
          :class="isOpen ? 'text-gray-200 rotate-90' : 'text-gray-100'"
          class="ml-auto h-5 w-5 transform group-hover:text-gray-200 transition-colors ease-in-out duration-150"
          viewBox="0 0 20 20"
          aria-hidden="true"
        >
          <path d="M6 6L14 10L6 14V6Z" fill="currentColor" />
        </svg>
      </button>
      <!-- Expandable link section, show/hide based on state. -->
      <div class="space-y-1 bg-gray-900 bg-opacity-30 p-3 rounded-md" v-show="isOpen">
        <inertia-link :href="route(item.route)" :class="[
        'nav-child-item',
        (route().current(item.route) ? 'bg-gray-900 text-white' : 'text-gray-100 hover:bg-gray-700 hover:text-white'),
        'group flex items-center px-2 py-2 text-sm font-medium rounded-md bg-opacity-50 hover:bg-opacity-50'
      ]">
          <span class="2xl:hidden">MM</span><span>Manage {{ item.name }}</span>
        </inertia-link>
        <inertia-link v-for="(child, idxC) in item.children"
          :key="`navItem-${idx}-child-${idxC}`"
          :href="route(child.route, child.id)"
          :class="[
            'nav-child-item',
            (route().current(child.route, child.id) ? 'bg-gray-900 text-white' : 'text-gray-100 hover:bg-gray-700 hover:text-white'),
            'group flex items-center px-2 py-2 text-sm font-medium rounded-md bg-opacity-50 hover:bg-opacity-50'
          ]"
        >
          <span class="2xl:hidden">{{ child.name.includes(' ') ? child.name.split(' ').map(n => n.substring(0,1)).join('').substring(0,2) : child.name.substring(0,2) }}</span>
          <span>{{ child.name }}</span>
        </inertia-link>
      </div>
    </div>
    <inertia-link v-else :href="route(item.route)" :class="cssClass">
      <!-- Current: "text-gray-100", Default: "text-gray-200 group-hover:text-gray-100" -->
      <!-- Heroicon name: outline/home -->
      <component :is="`${item.icon}-icon`" :class="iconClass" />
      <span class="inline-block ml-2 whitespace-nowrap overflow-hidden overflow-ellipsis">{{ item.name }}</span>
    </inertia-link>
  </div>
</template>
<script>
import * as Icons from '@vue-hero-icons/outline';
export default {
  name: 'AppNavItem',
  components: {
    ...Icons,
  },
  props: ['item', 'idx'],
  data() {
    return {
      isOpen: false,
    };
  },
  computed: {
    cssClass() {
      return [
        route().current(this.item.routeFam) || this.isOpen
          ? 'bg-gray-900 text-white'
          : 'text-gray-100 hover:bg-gray-700 hover:text-white',
        'group w-full flex-none flex items-center px-2 py-2 text-sm font-medium rounded-md bg-opacity-50 hover:bg-opacity-50',
      ];
    },
    iconClass() {
      return [
        route().current(this.item.routeFam) || this.isOpen
          ? 'text-gray-100'
          : 'text-gray-200 group-hover:text-gray-100',
        'mr-3 h-6 w-6',
      ];
    },
  },
  mounted() {
    if (route().current(this.item.routeFam)) {
      this.isOpen = true;
    }
  },
};
</script>
