<template>
  <button type="button" :class="[
    'border border-solid rounded-md p-1',
    disabled ? 'opacity-50 pointer-events-none' : '',
    bgColor,
    textColor,
    borderColor,
  ]" @click="$emit('click')">
    <slot />
  </button>
</template>
<script>
export default {
  name: 'DamButtonSimple',
  props: {
    disabled: {
      type: [Boolean, Number],
      default: false
    },
    bgColor: {
      type: String,
      default: 'bg-white hover:bg-gray-100'
    },
    textColor: {
      type: String,
      default: 'text-gray-900'
    },
    borderColor: {
      type: String,
      default: 'border-gray-300'
    }
  }
}
</script>
