<template>
  <div class="relative inline-block text-left z-10" @mouseleave="mouseLeave" @mouseenter="mouseEnter">
    <div>
      <button type="button" @click="toggleDropdown" :class="unstyledButton ? '' : 'inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'" aria-expanded="true" aria-haspopup="true">
        <slot name="button" />
      </button>
    </div>

    <!--
      Dropdown menu, show/hide based on menu state.

      Entering: "transition ease-out duration-100"
        From: "transform opacity-0 scale-95"
        To: "transform opacity-100 scale-100"
      Leaving: "transition ease-in duration-75"
        From: "transform opacity-100 scale-100"
        To: "transform opacity-0 scale-95"
    -->
    <transition
      enter-active-class="ease-out duration-100"
      enter-class="opacity-0 scale-95"
      enter-to-class="opacity-100 scale-100"
      leave-active-class="ease-in duration-75"
      leave-class="opacity-100 scale-100"
      leave-to-class="opacity-0 scale-95"
    >
      <div v-show="open" :class="[
        'transition absolute p-2',
        position === 'right' ? '-right-2' : '',
        position === 'left' ? '-left-2' : '',
        position === 'center' ? 'left-1/2 -translate-x-1/2' : '',
      ]">
        <div :class="[
          'transition transform origin-top-right rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-20',
          width === 'normal' ? 'w-56' : '',
          width === 'wide' ? 'w-64' : '',
          width === 'narrow' ? 'w-32' : '',
        ]" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
          <div class="py-1" role="none">
            <slot />
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
export default {
  name: 'DropdownButton',
  data() {
    return {
      open: false,
      mouseLeaveTimeout: null
    };
  },
  props: {
    unstyledButton: {
      type: Boolean,
      default: false,
    },
    position: {
      type: String,
      default: 'right',
      validator(value) {
        return ['right', 'left', 'center'].includes(value)
      }
    },
    width: {
      type: String,
      default: 'normal',
      validator(value) {
        return ['normal', 'wide', 'narrow'].includes(value)
      }

    }
  },
  methods: {
    toggleDropdown() {
      this.open = !this.open;
    },
    mouseEnter() {
      if (this.mouseLeaveTimeout) {
        clearTimeout(this.mouseLeaveTimeout)
      }
    },
    mouseLeave() {
      // prevents instant close if the mouse leaves for less than a second
      this.mouseLeaveTimeout = setTimeout(() => {
        this.open = false;
      }, 1000)
    },
  },
};
</script>
