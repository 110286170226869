<template>
  <app-layout>
    <form @submit.prevent="save('published')" class="relative">
      <mod-detail-tabs
        :mod="mod"
        :item-id="
          parent && parent.id ? parent.id : item && item.id ? item.id : 0
        "
      />
      <div class="block">
        <div class=" flex flex-col gap-8 w-full 2xl:flex-row">
          <base-card class="space-y-4 w-full flex-auto 2xl:w-3/4">
            <template
              v-if="mod.items && mod.items.length && Object.keys(item).length"
            >
              <div :class="`module-item-${modItem.pivot.column}`" v-for="(modItem, idx) in mod.items" :key="`modItem${idx}`" v-show="(
                (modItem.pivot.show_if_column &&
                modItem.pivot.show_if_operator &&
                modItem.pivot.show_if_value) ? (
                  (
                    modItem.pivot.show_if_column.includes('component_variant') && (
                      (modItem.pivot.show_if_operator === 'equals') ? (
                        modItem.pivot.variant == modItem.pivot.show_if_value
                      ) : (
                        modItem.pivot.variant != modItem.pivot.show_if_value
                      )
                    )
                  ) ||
                  (
                    typeof item[modItem.pivot.show_if_column] !== 'undefined' &&
                    (
                      (modItem.pivot.show_if_operator === 'equals') ? (
                        item[modItem.pivot.show_if_column] == modItem.pivot.show_if_value
                      ) : (
                        item[modItem.pivot.show_if_column] != modItem.pivot.show_if_value
                      )
                    )
                  ) || (
                    modItem.pivot.show_if_column.indexOf('parent.') === 0 &&
                    parent[modItem.pivot.show_if_column.replace('parent.', '')] &&
                    (
                      (modItem.pivot.show_if_operator === 'equals') ? (
                        parent[modItem.pivot.show_if_column.replace('parent.', '')] == modItem.pivot.show_if_value
                      ) : (
                        parent[modItem.pivot.show_if_column.replace('parent.', '')] != modItem.pivot.show_if_value
                      )
                    )
                  )
                ) : true
              )">
                <template
                  v-if="
                    !(
                      mod.publishable &&
                      (modItem.pivot.column === 'published' ||
                        modItem.pivot.column === 'published_at')
                    )
                  "
                >
                  <stack-list v-if="modItem.elements">
                    <stack-list-item :start-open="modItem.elements.length > 0">
                      <template v-slot:default>
                        <img
                          v-if="modItem.image && modItem.image.files.length"
                          class="inline-block mr-3 w-14 align-middle"
                          :src="
                            typeof modItem.image.files[0] === 'string'
                              ? modItem.image.files[0]
                              : modItem.image.files[0].url
                          "
                        />
                        <label class="inline-block align-middle">{{
                          i.pivot.label
                        }}</label>
                      </template>
                      <template v-slot:expand>
                        <div
                          v-for="(element, idxE) in modItem.elements"
                          :key="`modItem${idx}Element${idxE}`"
                        >
                          <i-element
                            v-model="
                              item[modItem.pivot.column][element.pivot.variable]
                            "
                            :element="element"
                          />
                        </div>
                      </template>
                    </stack-list-item>
                  </stack-list>
                  <div v-else :class="item.extension_id && item.extension ? 'flex gap-4 justify-stretch' : ''">
                    <bldr-element
                      v-model="item[modItem.pivot.column]"
                      :element="modItem"
                      :class="['w-full',modItem.name.toUpperCase() === 'SECTION' && idx > 0 ? 'pt-8' : '']"
                    />
                    <div v-if="item.extension_id && item.extension && (typeof item.extension[modItem.pivot.column] !== 'undefined')" class="flex-none w-5/12">
                      <bldr-element v-model="item.extension[modItem.pivot.column]" :element="modItem" label-prefix="Inherited " disabled  />
                    </div>
                  </div>
                </template>
              </div>
            </template>
            <div v-if="mod.publishable">
              <base-input
                v-if="(typeof item.published !== 'undefined')"
                label="Published"
              >
                <base-switch v-model="item.published" />
              </base-input>

              <base-input
                v-if="(typeof item.published_at !== 'undefined')"
                label="Publish Date"
                tooltip="Only set this if you need to schedule it to go live on a specific<br> date. Be sure to leave it marked unpublished above."
              >
                <el-date-picker v-model="item.published_at" />
              </base-input>
            </div>
          </base-card>
          <div class="w-full 2xl:w-1/4 3xl:w-1/5 flex-none space-y-6 relative">
            <base-card class="2xl:sticky 2xl:top-5 z-10">
              <div id="cms-mod-detail-action-buttons" :data-model="mod.model || null" :data-module-id="mod.id || null" :data-item-id="item.id || null" class="flex flex-wrap items-start gap-4 w-full 2xl:justify-center">
                <base-button
                  id="cms-detail-action-publish"
                  class=""
                  @click="save('published')"
                  >Save</base-button
                >
                <base-button class="" @click="save('published', true)"
                  >Save &amp; Exit</base-button
                >
                <base-button
                  v-if="item && item.id && mod.preview_enabled"
                  small
                  tag="a"
                  :href="
                    route('cms.preview.module', {
                      module: mod.id,
                      item_id: item.id,
                    })
                  "
                  target="_blank"
                  type="secondary"
                  :disabled="$root.protected"
                  class=""
                  >Preview</base-button
                >
              </div>
            </base-card>
            <base-card class="mb-4">
              <base-label>Active Users</base-label>
              <active-users :url="$inertia.page.url" />
            </base-card>
            <base-card v-if="$inertia.page.props.cms.notes_enabled && item.id" class="mb-4">
              <base-label>Notes</base-label>
              <div class="w-full max-h-60 overflow-auto mb-4">
                <notes-listing :detail="true" :notes="item.notes.filter(n => !n.parent_id)" />
              </div>
              <base-button small @click="creatingNote = true">Create</base-button>
            </base-card>
            <base-card css="overflow-hidden">
              <base-label class="block">Logs</base-label>
              <base-logs slug="mod" type="detail" :logs="logs" />
          </base-card>
          </div>
        </div>
      </div>
    </form>
    <notes-create v-if="$inertia.page.props.cms.notes_enabled" :noteable-id="item.id" :noteable-type="mod.model" v-model="creatingNote" />
  </app-layout>
</template>
<script>
import { BldrElement } from '../components/Builder';
import Form from '../components/Form';
import Stack from '../components/Stack';
import ModDetailTabs from '../components/Misc/ModDetailTabs';
import ActiveUsers from '../components/Misc/ActiveUsers';
import NotesListing from '../components/Notes/NotesListing';
import NotesCreate from '../components/Notes/NotesCreate';
import { BaseLogs } from '../components/Base';
export default {
  name: 'ModDetail',
  components: {
    ...Form,
    ...Stack,
    BldrElement,
    ModDetailTabs,
    BaseLogs,
    ActiveUsers,
    NotesListing,
    NotesCreate,
  },
  props: {
    mod: {
      type: Object,
      default: () => {
        return {
          name: '',
          model: '',
          parent: null,
          sister: null,
          template: 'New',
          publishable: false,
          elements: [],
          items: [],
        };
      },
    },
    item: {
      type: Object,
      default: () => {},
    },
    parent: {
      type: Object,
      default: () => {},
    },
    logs: {
      type: [Array],
      default: () => [],
    },
  },
  methods: {
    save(status, exit) {
      this.$root.protected = false;
      const subject = this.parent && this.parent.id ? 'mod.child' : 'mod';
      const baseParms =
        this.parent && this.parent.id
          ? {
              module: this.mod.parent.id,
              parent: this.parent.id,
              child: this.mod.id,
            }
          : { module: this.mod.id };
      if (this.item.id) {
        this.$root
          .$CMS_UPDATE(
            subject,
            { ...baseParms, id: this.item.id },
            { item: this.item, status: status }
          )
          .then((data) => {
            this.$root.protected = false;
            if (exit) {
              window.location.href = route(`cms.${subject}.index`, baseParms);
            }
          });
      } else {
        this.$root
          .$CMS_CREATE(subject, baseParms, {
            item: this.item,
            status: 'published',
          })
          .then((data) => {
            this.$root.protected = false;
            if (data.item && data.item.id) {
              if (exit) {
                window.location.href = route(`cms.${subject}.index`, baseParms);
              } else {
                window.location.href = route(`cms.${subject}.show`, {
                  ...baseParms,
                  id: data.item.id,
                });
              }
            }
          });
      }
    },
  },
  data() {
    return {
      creatingNote: false
    }
  },
  mounted() {
    document.addEventListener('keyup', this.$root.protectEvent);
  },
  beforeDestroy() {
    document.removeEventListener('keyup', this.$root.protectEvent);
  },
};
</script>
