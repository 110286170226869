export default {
  methods: {
    keyboardShortcuts(e) {
      if ((e.ctrlKey || e.metaKey) && e.key === 's') {
        // Prevent the Save dialog to open
        e.preventDefault();
        // Place your code here
        if (typeof this.save === 'function') {
          console.log('saving...');
          this.save();
        } else if (typeof this.update === 'function') {
          console.log('updating...');
          this.update();
        } else if (typeof this.updateComponent === 'function') {
          console.log('updating component...');
          this.updateComponent();
        } else {
          console.log('no save function in component');
        }
      }
    }
  },
  mounted() {
    document.addEventListener('keydown', this.keyboardShortcuts);
  },
  destroyed() {
    document.removeEventListener('keydown', this.keyboardShortcuts);
  }
}
