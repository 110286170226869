<template>
  <combo-box-mod v-if="recordset > 0" v-model="selected" v-bind="$props" />
  <combo-box v-else-if="Array.isArray(options) && options.length" v-bind="$props" v-model="selected" />
  <el-select-classic v-else v-bind="$props" v-model="selected">
    <slot />
  </el-select-classic>
</template>
<script>
import {v4 as uuidv4} from 'uuid';
import ComboBox from './ComboBox';
import ComboBoxMod from './ComboBoxMod';
import ElSelectClassic from './ElSelectClassic';
export default {
  name: 'ElSelect',
  components: {
    ComboBox,
    ComboBoxMod,
    ElSelectClassic
  },
  props: {
    id: {
      type: String,
      default: () => uuidv4()
    },
    value: {
      type: [String, Number, Boolean, Object],
      default: ''
    },
    options: {
      type: Array,
      default: () => []
    },
    placeholder: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    recordset: {
      type: Number,
      default: 0
    }
  },
  computed: {
    selected: {
      get() {
        return (this.value && typeof this.value.id !== 'undefined') ? this.value.id : (this.value || '')
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  }
}
</script>
