<template>
  <app-layout>
    <base-card class="space-y-10 mb-10">

      <!-- <div class="space-y-6">
        <base-input label="Import Spreadsheet">
          <input type="file" @change="setImportFile" name="file" />
        </base-input>
        <base-button @click="runImport">Import</base-button>
      </div> -->

      <base-table table-css="table-fixed xl:table-auto">
        <template v-slot:head>
          <table-heading class="w-5/12 xl:w-auto">
            From Column
          </table-heading>
          <table-heading class="w-5/12 xl:w-auto">
            To Column
          </table-heading>
          <table-heading class="w-2/12 max-w-[100px]">
            Action
          </table-heading>
        </template>
        <template v-slot:default>
          <template v-for="(item, idx) in items">
            <tr class="bg-white" :key="idx">
              <table-cell>
                <base-input v-if="editingMap == idx" v-model="items[idx].from_column" />
                <span v-else>{{ item.from_column }}</span>
              </table-cell>
              <table-cell>
                <base-input v-if="editingMap == idx" v-model="items[idx].to_column" />
                <span v-else>{{ item.to_column }}</span>
              </table-cell>
              <table-cell v-if="editingMap == idx">
                <base-button icon @click="editingMap = null">
                  <check-icon class="w-5 h-5 text-green-500" />
                </base-button>
              </table-cell>
              <table-cell v-else>
                <base-button icon @click="editingMap = idx">
                  <pencil-icon class="w-5 h-5" />
                </base-button>
                <base-button icon @click="removeItem(idx)">
                  <x-icon class="w-5 h-5 text-red-500" />
                </base-button>
              </table-cell>
            </tr>
          </template>
          <tr v-if="!items.length">
            <table-cell colspan="3">
              No Items
            </table-cell>
          </tr>
          <tr v-if="addingMap">
            <table-cell>
              <base-input v-model="addedMap.from_column" />
            </table-cell>
            <table-cell>
              <el-select :options="toOptions" v-model="addedMap.to_column" />
            </table-cell>
            <table-cell>
              <base-button icon v-if="addedMap.from_column && addedMap.to_column" title="Save" @click="addMap">
                <check-icon class="w-5 h-5 text-green-500" />
              </base-button>
              <check-icon v-else class="w-5 h-5 text-gray-400 cursor-not-allowed" />
            </table-cell>
          </tr>
        </template>
        <template v-slot:foot>
          <table-cell colspan="3">
            <div class="flex gap-4">
              <base-button v-if="!addingMap" small @click="addingMap = true">Add Map</base-button>
              <base-button v-else small @click="addingMap = false">Cancel</base-button>
              <base-button v-if="!addingMap" @click="save">Save</base-button>
            </div>
          </table-cell>
        </template>
      </base-table>

    </base-card>
  </app-layout>
</template>
<script>
import Form from '../components/Form';
import { XIcon, CheckIcon, PencilIcon } from '@vue-hero-icons/solid';
import Table from '../components/Table';
export default {
  name: 'ContactEdit',
  components: {
    ...Form,
    ...Table,
    XIcon,
    PencilIcon,
    CheckIcon,
  },
  props: {
    rootSlug: {
      type: String,
      default: ''
    },
    toOptions: {
      type: Array,
      default: () => []
    },
    items: {
      type: Array,
      default: () => []
    },
  },
  methods: {
    addMap() {
      this.items.push(JSON.parse(JSON.stringify(this.addedMap)));
      this.$nextTick().then(() => {
        this.addedMap = { from_column: '', to_column: null };
        this.addingMap = false;
      })
    },
    save() {
      this.$CMS_POST(this.rootSlug + 'import-map.update', {}, {items: this.items}).then(() => {
        this.$noty.success('Import maps updated');
      });
    },
    runImport() {
      window.alert('TODO');
    },
    setImportFile(evt) {
      console.log(evt);
    }
  },
  data() {
    return {
      importFile: null,
      editingMap: null,
      addingMap: false,
      addedMap: { from_column: '', to_column: null },
    }
  }
};
</script>
