<template>
  <div class="w-full flex items-center mb-6">
    <div class="element-handle flex-none w-5 mr-4">
      <switch-vertical-icon class="h-5 w-5 text-gray-900 cursor-move" />
    </div>
    <div v-if="element.name.toUpperCase() === 'SECTION'" class="w-full mr-4">
      <base-heading size="lg">{{ getProp(element,'label') || element.name }}</base-heading>
      <small
        v-if="getProp(element,'helptext')"
        slot="helperText"
        class="form-text text-muted"
      >{{ getProp(element,'helptext') }}</small>
    </div>
    <base-card v-else class="w-full mr-4">
      <base-label>{{ getProp(element,'label') || element.name }}</base-label>
      <draggable
        v-if="element.name === 'Repeater'"
        v-model="element.children"
        :class="['w-full rounded-md bg-gray-200 p-8', element.children.length === 0 ? 'pb-60 border-dashed border-4 border-gray-300' : '']"
        tag="div"
        :group="{name: 'repeater', pull: 'repeater', put: 'elements' }"
        handle=".element-handle"
        @start="element.drag = true"
        @end="element.drag = false"
        @sort="orderElement"
        @add="addElement"
        ghost-class="list-none"
      >
        <bldr-component-element
          v-for="(el,idx) in element.children"
          :key="`componentElementElement${idx}`"
          :index="idx"
          :upload-configs="uploadConfigs"
          :mods="mods"
          v-model="element.children[idx]"
          @removeElement="removeElementElement(idx)"
        />
      </draggable>
      <div v-else-if="element.name === 'Components'" class="w-full flex-none p-8 bg-gray-200 rounded-md">
        <base-heading size="xs">
          <span class="uppercase">Components</span>
          <template v-slot:tooltip>Drag &amp; drop components you want<br>available in this section of the module.</template>
        </base-heading>
        <draggable
          v-if="element.pivot && Array.isArray(element.pivot.options)"
          v-model="element.pivot.options"
          class="list-group"
          :class="element.pivot.options && element.pivot.options.length === 0 ? 'is-empty' : ''"
          tag="div"
          :group="{ name: 'options', pull: false, put: 'components' }"
          :sort="false"
        >
          <base-card
            v-for="(opt,idxO) in element.pivot.options"
            :key="`elementOption${idxO}`"
            class="bg-white relative rounded-md shadow-md w-full mb-2 "
          >
            <div class="component flex items-center">
              <div class="component-handle flex-grow-0 w-5 mr-4">
                <switch-vertical-icon class="h-5 w-5 text-gray-900 cursor-move" />
              </div>
              <div class="component-inner flex-grow-1 w-5/6 xl:w-11/12">
                <base-label>{{ opt.name }}</base-label>
              </div>
              <div v-if="Array.isArray(opt.variants) && opt.variants.length" class="flex-none flex gap-2 ml-4 w-56 items-center">
                <base-label class="mt-2">Variant: </base-label>
                <el-select :options="opt.variants" v-model="opt._variant" />
              </div>
              <!-- POPOVER FOR CONFIGS -->
              <bldr-component-configs v-if="(typeof opt._configs !== 'undefined')" class="mt-1 mr-3" v-bind="{componentConfigs, componentConfigTypes}" v-model="opt._configs" />
              <div class="flex-none">
                <base-button
                  icon
                  @click="removeElementOption(idxO)"
                  class="mt-3"
                >
                  <x-icon class="w-5 h-5 text-red-500" />
                </base-button>
              </div>
            </div>
          </base-card>
        </draggable>
        <small
          v-if="getProp(element,'helptext')"
          slot="helperText"
          class="form-text text-muted"
        >{{ getProp(element,'helptext') }}</small>
      </div>
      <div v-else-if="element.name === 'Checkboxes'" class="pointer-events-none">
        <base-checkboxes :options="getProp(element,'options')"></base-checkboxes>
        <small
          v-if="getProp(element,'helptext')"
          slot="helperText"
          class="form-text text-muted"
        >{{ getProp(element,'helptext') }}</small>
      </div>
      <div v-else-if="Array.isArray(element.markup)">
        <div
          v-for="(mk,idxM) in element.markup"
          :key="`elmk.${idxM}`"
        >
          <div v-if="(typeof mk === 'object')" class="pointer-events-none">
            <component
              :is="mk.is"
              v-bind="mk"
              :placeholder="getProp(element,'placeholder') || mk.placeholder || ''"
            >
              <component
                :is="mk.child.is"
                v-if="typeof mk.child === 'object'"
                v-bind="mk.child"
                :placeholder="getProp(element,'placeholder') || mk.child.placeholder || ''"
              />
              <div
                v-else-if="typeof mk.child === 'string'"
                v-html="mk.child"
              />
            </component>
          </div>
          <div
            class="pointer-events-none"
            v-else
            v-html="mk"
          />
        </div>
        <small
          v-if="getProp(element,'helptext')"
          slot="helperText"
          class="form-text text-muted"
        >{{ getProp(element,'helptext') }}</small>
      </div>
      <div
        v-else
        class="element-inner"
        v-html="element.markup"
      />
    </base-card>
    <div class="flex-none">
      <edit-popover
        v-model="element"
        :mods="mods"
        :upload-configs="uploadConfigs"
      />
      <base-button
        icon
        @click="removeElement()"
        class="mt-3"
      >
        <x-icon class="w-5 h-5 text-red-500" />
      </base-button>
    </div>
  </div>
</template>
<script>
import Form from '../Form'
import draggable from 'vuedraggable'
import EditPopover from './EditPopover'
import { SwitchVerticalIcon, XIcon } from '@vue-hero-icons/solid'
import BldrComponentConfigs from './BldrComponentConfigs'
export default {
  name: 'BldrComponentElement',
  components: {
    ...Form,
    draggable,
    EditPopover,
    SwitchVerticalIcon,
    XIcon,
    BldrComponentConfigs,
  },
  props: {
    index: Number,
    value: Object,
    uploadConfigs: {
      type: Array,
      default: () => []
    },
    mods: {
      type: Array,
      default: () => []
    },
  },
  computed: {
    element: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
    componentConfigs() {
      return this.$page.props.componentConfigs || [];
    },
    componentConfigTypes() {
      return this.$page.props.componentConfigTypes || [];
    },
  },
  data() {
    return {
      uuid: Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15),
    }
  },
  methods: {
    getProp(element, property) {
      try {
        if (element.pivot && typeof element.pivot[property] !== 'undefined') {
          return element.pivot[property];
        } else {
          const filter = element.properties.filter((prop) => { return prop.property === property });
          console.log(filter);
          return filter.length ? filter[0].value : '';
        }
      } catch(err) {
        console.error(err);
      }
    },
    addElement(evt) {
      if (evt.item.innerText === 'Repeater') {
        // TODO: replace .path with .composedPath()
        var parentRepeaters = evt.path.filter((el) => {
          if (typeof el.classList !== 'undefined') {
            return el.classList.contains('element-inner--repeater');
          }
        });
        if (parentRepeaters.length >= 2) {
          this.removeElementElement(evt.newIndex);
        }
      }
    },
    removeElement() {
      this.$emit('removeElement');
    },
    removeElementElement(idx) {
      this.element.children.splice(idx, 1);
    },
    removeElementOption(idx) {
      this.element.pivot.options.splice(idx, 1);
    },
    orderElement() {
      this.element.children.forEach((el, idx) => {
        el.sort_order = idx;
      });
    }
  },
  mounted() {
    // if (this.element.name === 'Components' && typeof this.element.pivot === 'undefined') {
    //   const pivot = {};
    //   this.element.properties.forEach(prop => {
    //     switch(prop.property) {
    //       case 'options':
    //         pivot[prop.property] = prop.value ? prop.value : [];
    //       break;
    //       default:
    //         pivot[prop.property] = prop.value;
    //     }
    //   })
    //   this.element.pivot = JSON.parse(JSON.stringify(pivot));
    //   this.$nextTick(() => {
    //     this.$emit('input', this.element);
    //     this.$nextTick(() => {
    //       this.ready = true
    //     })
    //   });
    // } else {
    //   this.ready = true;
    // }
  }
}
</script>
