import Echo from "laravel-echo"
import axios from 'axios';

export default {
  install: function (Vue, options) {
    window.Pusher = require('pusher-js');
    if (window.pusher_key) {
      Vue.prototype.$echo = new Echo({
        broadcaster: 'pusher',
        key: window.pusher_key,
        cluster: window.pusher_cluster,
        namespace: 'Atypiccraft.Cms.Events'
        // wsHost: window.location.hostname,
        // wsPort: 6001,
        // forceTLS: resp.data.pusher_https || false,
        // disableStats: true,
      });
    }
  }
}
