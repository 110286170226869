<template>
  <th class="px-6 py-3 bg-gray-50 text-xs font-medium text-gray-500 uppercase tracking-wider" @click="$emit('click')">
    <slot />
  </th>
</template>
<script>
export default {
  name: 'TableHeading'
}
</script>
