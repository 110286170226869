<template>
  <dam-layout>
    <div class="space-y-4">
      <base-input label="Name" v-model="item.name" />
      <base-input label="Parent">
        <el-select :options="categoryOptions" v-model="item.parent_id" />
      </base-input>
      <div class="inline-flex gap-4 items-start justify-start">
        <base-button @click="save()">Save</base-button>
        <base-button @click="save(true)">Save &amp; Exit</base-button>
      </div>
    </div>
  </dam-layout>
</template>
<script>
import Form from '../../components/Form';
import DamLayout from '../../layouts/DamLayout';
export default {
  name: 'CategoriesEdit',
  components: {
    DamLayout,
    ...Form,
  },
  props: {
    item: {
      type: Object,
      default: () => {
        return {
          id: 0,
          parent_id: null,
          name: ''
        }
      }
    }
  },
  methods: {
    save(exit) {
      if (this.item.id) {
        this.$root
          .$CMS_UPDATE('dam.categories', this.item.id, this.item)
          .then(() => {
            this.$root.protected = false;
            if (exit) {
              this.$inertia.visit(route('cms.dam.categories.index'));
            } else {
              this.$inertia.reload();
            }
          });
      } else {
        this.$root.$CMS_CREATE('dam.categories', {}, this.item).then((data) => {
          this.$root.protected = false;
          if (data && data.item && data.item.id) {
            if (exit) {
              this.$inertia.visit(route('cms.dam.categories.index'));
            } else {
              window.location.href = route('cms.dam.categories.show', data.item.id);
            }
          }
        });
      }
    }
  },
  data() {
    return {
      categoryOptions: [],
    }
  },
  mounted() {
    this.$CMS_GET('dam.categories.index').then(data => {
      this.categoryOptions = data.items.filter(cat => cat.id !== this.item.id).map(cat => {
        return {
          value: cat.id,
          label: cat.full_name
        }
      })
    });
  }
};
</script>
