<template>
  <div>
    <div class="hidden h-screen md:flex overflow-hidden bg-gray-100">
      <!-- Off-canvas menu for mobile, show/hide based on off-canvas menu state. -->
      <div v-show="mobileNavOpen" class="md:hidden">
        <div class="fixed inset-0 flex z-40">
          <!--
            Off-canvas menu overlay, show/hide based on off-canvas menu state.

            Entering: "transition-opacity ease-linear duration-300"
              From: "opacity-0"
              To: "opacity-100"
            Leaving: "transition-opacity ease-linear duration-300"
              From: "opacity-100"
              To: "opacity-0"
          -->
          <transition
            v-show="mobileNavOpen"
            enter-class="transition-opacity ease-linear duration-300"
            enter-active-class="opacity-0"
            enter-to-class="opacity-100"
            leave-class="transition-opacity ease-linear duration-300"
            leave-active-class="scale-100"
            leave-to-class="scale-95"
            class="fixed inset-0"
          >
            <div class="absolute inset-0 bg-gray-600 opacity-75"></div>
          </transition>
          <!--
            Off-canvas menu, show/hide based on off-canvas menu state.

            Entering: "transition ease-in-out duration-300 transform"
              From: "-translate-x-full"
              To: "translate-x-0"
            Leaving: "transition ease-in-out duration-300 transform"
              From: "translate-x-0"
              To: "-translate-x-full"
          -->
          <transition
            v-show="mobileNavOpen"
            enter-class="transition ease-in-out duration-300 transform"
            enter-active-class="-translate-x-full"
            enter-to-class="translate-x-0"
            leave-class="transition ease-in-out duration-300 transform"
            leave-active-class="translate-x-0"
            leave-to-class="-translate-x-full"
            class="relative flex-1 flex flex-col max-w-xs w-full cms-sidebar-gradient shadow-xl"
          >
            <div>
              <div class="absolute top-0 right-0 -mr-12 pt-2">
                <button @click="mobileNavOpen = !mobileNavOpen" class="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span class="sr-only">Close sidebar</span>
                  <!-- Heroicon name: outline/x -->
                  <x-icon class="h-6 w-6 text-white" />
                </button>
              </div>
              <div class="flex-1 h-0 pt-5 pb-4 overflow-y-auto bg-gray-900 bg-opacity-50">
                <div class="flex-shrink-0 flex items-center px-4">
                  <app-logo class="w-10 h-10 flex-none" />
                  <span class="inline-block ml-4 font-semibold text-xl text-white">{{ appName }} CMS</span>
                </div>
                <app-nav :nav="nav" />
              </div>
              <div v-if="me" class="flex-shrink-0 flex bg-gray-900 bg-opacity-80 p-4 justify-center items-center">
                <inertia-link :href="route('cms.profile')" class="flex-1 w-full group block">
                  <div class="flex items-center">
                    <div class="flex-shrink-0">
                      <img v-if="me.avatar" class="inline-block h-10 w-10 rounded-full" :src="me.avatar" alt="">
                      <span v-else class="inline-block h-10 w-10 rounded-full bg-gray-800 text-white leading-10 text-center">{{ meInitials }}</span>
                    </div>
                    <div class="ml-3">
                      <p class="text-base font-medium text-white whitespace-nowrap overflow-hidden overflow-ellipsis">
                        {{ me.first_name }} {{ me.last_name }}
                      </p>
                      <p class="text-sm font-medium text-gray-300 group-hover:text-gray-200 whitespace-nowrap overflow-hidden overflow-ellipsis">
                        View profile
                      </p>
                    </div>
                  </div>
                </inertia-link>
                <a class="inline-block text-white p-3 flex-none" :href="route('cms.logout')">
                  <svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
                  </svg>
                </a>
              </div>
            </div>
          </transition>
          <div class="flex-shrink-0 w-14">
            <!-- Force sidebar to shrink to fit close icon -->
          </div>
        </div>
      </div>

      <!-- Static sidebar for desktop -->
      <div class="relative z-50 hidden md:flex w-16 2xl:w-64">
        <div class="nav-container flex md:flex-shrink-0 shadow relative transition-all transform-gpu w-16 overflow-hidden hover:w-64 2xl:w-64">
          <div class="flex flex-col transition w-64">
            <!-- Sidebar component, swap this element with another sidebar if you like -->
            <div class="flex flex-col h-0 flex-1 cms-sidebar-gradient">
              <div class="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto bg-gray-900 bg-opacity-50">
                <div class="flex items-center flex-shrink-0 px-3 2xl:px-4">
                  <app-logo class="w-10 h-10 flex-none" />
                  <span class="inline-block ml-4 font-semibold text-xl text-white whitespace-nowrap overflow-hidden overflow-ellipsis">{{ appName }}</span>
                </div>
                <app-nav :nav="nav" />
              </div>

              <div v-if="me" class="flex-shrink-0 flex justify-center items-center p-3 bg-gray-900 bg-opacity-80">
                <inertia-link :href="route('cms.profile')" class="flex-1 w-full group block">
                  <div class="flex items-center">
                    <div class="flex-shrink-0">
                      <img v-if="me.avatar" class="inline-block h-9 w-9 rounded-full" :src="me.avatar" alt="">
                      <span v-else class="inline-block h-9 w-9 rounded-full bg-gray-800 text-white leading-9 text-center">{{ meInitials }}</span>
                    </div>
                    <div class="ml-4">
                      <p class="text-sm font-medium text-white whitespace-nowrap overflow-hidden overflow-ellipsis">
                        {{ me.first_name }} {{ me.last_name }}
                      </p>
                      <p class="text-xs font-medium text-gray-300 group-hover:text-gray-200 whitespace-nowrap overflow-hidden overflow-ellipsis">
                        View profile
                      </p>
                    </div>
                  </div>
                </inertia-link>
                <a class="inline-block text-white p-3 flex-none" :href="route('cms.logout')">
                  <svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
                  </svg>
                </a>
              </div>

            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-col w-0 flex-1 overflow-hidden">
        <div class="md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3">
          <button @click="mobileNavOpen = !mobileNavOpen" class="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
            <span class="sr-only">Open sidebar</span>
            <!-- Heroicon name: outline/menu -->
            <menu-icon class="h-6 w-6" />
          </button>
        </div>
        <main id="appMain" class="flex-1 relative overflow-y-auto focus:outline-none" tabindex="0">
          <div class="py-6">
            <div v-if="pageName && !noPageName" class=" mx-auto mb-6 px-4 sm:px-6 lg:px-8">
              <h2 class="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">{{ pageName }}</h2>
            </div>
            <div class="mx-auto px-4 sm:px-6 lg:px-8">

              <slot />

            </div>
          </div>
          <div class="flex py-4 px-4 sm:px-6 lg:px-8 justify-between items-center">
            <div class="text-left">
              <a href="/admin/logs" target="_blank" class="font-bold text-sm text-gray-300 underline">Error Logs</a>
            </div>
            <div v-if="cmsVersion" class="font-bold text-sm text-gray-300 text-right ">
              {{ cmsVersion }}
            </div>
          </div>
        </main>
      </div>

      <div v-if="cmsUpdating"  class="fixed z-50 inset-0 overflow-y-auto" aria-labelledby="cms-updating-title" role="dialog" aria-modal="true">
        <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <!--
            Background overlay, show/hide based on modal state.

            Entering: "ease-out duration-300"
              From: "opacity-0"
              To: "opacity-100"
            Leaving: "ease-in duration-200"
              From: "opacity-100"
              To: "opacity-0"
          -->
          <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

          <!-- This element is to trick the browser into centering the modal contents. -->
          <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

          <!--
            Modal panel, show/hide based on modal state.

            Entering: "ease-out duration-300"
              From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              To: "opacity-100 translate-y-0 sm:scale-100"
            Leaving: "ease-in duration-200"
              From: "opacity-100 translate-y-0 sm:scale-100"
              To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          -->
          <div class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
            <div>
              <div class="mx-auto flex items-center justify-center h-12 w-12">
                <refresh-icon class="w-8 h-8 text-indigo-700 animate-spin" />
              </div>
              <div class="mt-3 text-center sm:mt-5">
                <h3 class="text-lg leading-6 font-medium text-gray-900" id="cms-updating-title">
                  CMS Updating
                </h3>
                <div class="mt-2">
                  <p class="text-sm text-gray-500">
                    The CMS is currently being updated. Once complete, please save your work and refresh the browser to get the latest updates.
                  </p>
                </div>
              </div>
            </div>
            <!-- <div class="mt-5 sm:mt-6">
              <button type="button" class="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm">
                Go back to dashboard
              </button>
            </div> -->
          </div>
        </div>
      </div>


    </div>
    <DamModal v-if="damInstalled" />
    <DamBatches v-if="damInstalled" />
    <h1 class="md:hidden">This app is not mobile optimized.</h1>
  </div>
</template>
<style>
@media screen and (max-width: 1535px) {
  .nav-container:hover .nav-child-item span:first-of-type {
    display: none;
  }
  .nav-container:not(:hover) .nav-child-item span+span {
    display: none;
  }
}
</style>
<script>
import { XIcon, MenuIcon, RefreshIcon } from '@vue-hero-icons/outline';
import AppLogo from '../components/Misc/AppLogo';
import AppNav from '../components/Misc/AppNav';
import DamModal from '../views/DAM/components/Modal';
import DamBatches from '../views/DAM/components/Batches'
export default {
  name: 'AppLayout',
  components: {
    AppLogo,
    AppNav,
    DamBatches,
    DamModal,
    MenuIcon,
    XIcon,
    RefreshIcon,
  },
  data() {
    return {
      mobileNavOpen: false,
    };
  },
  props: {
    noPageName: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    appName() {
      return this.$page.props.appName;
    },
    nav() {
      return this.$page.props.nav;
    },
    me() {
      return this.$page.props.me;
    },
    pageName() {
      return this.$page.props.pageName;
    },
    meInitials() {
      const me = this.$page.props.me;
      return (
        me.first_name.charAt(0).toUpperCase() +
        me.last_name.charAt(0).toUpperCase()
      );
    },
    cmsUpdating() {
      return this.$page.props.cms.updating;
    },
    cmsVersion() {
      return this.$page.props.cms.version;
    },
    damInstalled() {
      return this.$page.props.cms.dam_installed;
    }
  },
};
</script>
