<template>
  <dam-layout>
    <div class="space-y-4">
      <base-input label="Name" v-model="action.name" />
      <base-input label="Type">
        <el-select v-model="action.type" :options="types" @input="updateConfig" />
      </base-input>
      <base-input v-if="action.type && action.type !== 'email'" label="Server">
        <el-select v-model="action.settings_id" :options="settingsOptions" />
      </base-input>
      <base-label>Configuration</base-label>
      <div class="p-4 border rounded border-gray-200">
        <div v-if="action.type === 'email'" class="space-y-4">
          <base-input label="Recipient" v-model="action.config.recipient" tooltip="Multiple emails comma separated. example:<br>test1@example.com, test2@example.com" />
          <base-input label="Message" type="textarea" v-model="action.config.message" />
        </div>
        <div v-else-if="action.type === 'upload' || action.type === 'dropbox'"  class="space-y-4">
          <base-input label="Directory" v-model="action.config.directory" />
          <base-input label="Email" v-model="action.config.email" tooltip="Optional. Notifies recipient with directory and filename after uploaded. Multiple emails comma separated." />
        </div>
        <div v-else>
          <p class="font-bold text-sm text-gray-700">No configuration for selected type</p>
        </div>
      </div>
      <base-button @click="save">Save</base-button>
    </div>
  </dam-layout>
</template>
<script>
import DamLayout from '../../layouts/DamLayout';
import Form from '../../components/Form';
export default {
  name: 'ActionsEdit',
  components: {
    DamLayout,
    ...Form,
  },
  props: {
    settings: {
      type: Array,
      default: () => []
    },
    action: {
      type: Object,
      default: () => {
        return {
          name: '',
          type: '', // email, upload, dropbox
          config: {},
          settings_id: null
        }
      }
    }
  },
  computed: {
    actionSettingType() {
      switch(this.action.type) {
        case 'email':
          return null;
        case 'upload':
          return 'server';
        case 'dropbox':
          return 'dropbox';
        default:
          return null;
      }
    },
    settingsOptions() {
      return this.settings.filter(s => s.type == this.actionSettingType).map(s => {
        return {label: s.name, value: s.id};
      });
    },
  },
  methods: {
    save() {
      if (this.action.id) {
        this.$CMS_UPDATE('dam.actions', {action: this.action.id}, this.action).then(data => {
          if (data.action?.id) {
            this.$inertia.reload();
          }
        })
      } else {
        this.$CMS_CREATE('dam.actions', {}, this.action).then(data => {
          if (data.action?.id) {
            this.$inertia.visit(route('cms.dam.actions.show', data.action.id));
          }
        })
      }
    },
    updateConfig(type) {
      let baseConfig;
      switch(type) {
        case 'email':
          baseConfig = {
            recipient: '',
            message: ''
          };
          break;
        case 'upload':
        case 'dropbox':
          baseConfig = {
            directory: '',
            email: ''
          };
          break;
        default:
          baseConfig = {};
      }
      Object.keys(baseConfig).forEach(key => {
        if (this.action.config[key]) {
          baseConfig[key] = this.action.config[key];
        }
      });
      this.action.config = baseConfig;
    },
  },
  data() {
    return {
      types: [
        'email',
        'upload',
        // 'dropbox',
      ],
    }
  },
};
</script>
