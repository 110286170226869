var render = function render(){var _vm=this,_c=_vm._self._c;return _c('fieldset',[_c('div',{staticClass:"bg-white rounded-md -space-y-px"},_vm._l((_vm.options),function(opt,idx){return _c('div',{key:`radioGroup-${_vm.uuid}-option-${idx}`,class:[
        `${idx === 0 ? 'rounded-tl-md rounded-tr-md' : ''}`,
        `${idx === _vm.options.length-1 ? 'rounded-bl-md rounded-br-md' : ''}`,
        `${opt.value === _vm.value ? 'bg-indigo-50 border-indigo-200 z-10' : 'border-gray-200'}`,
        _vm.bordered ? 'border p-4' : 'py-3',
        'relative flex'
      ]},[_c('div',{staticClass:"flex items-center h-5"},[_c('input',{staticClass:"focus:ring-indigo-500 h-4 w-4 text-indigo-600 cursor-pointer border-gray-300",attrs:{"type":"radio","id":`radioGroup-${_vm.uuid}-option-${idx}`,"name":`radioGroup-${_vm.uuid}`},domProps:{"checked":opt.value === _vm.value,"value":opt.value},on:{"input":function($event){return _vm.updateValue(opt.value)}}})]),_vm._v(" "),_c('label',{staticClass:"ml-3 flex flex-col cursor-pointer",attrs:{"for":`radioGroup-${_vm.uuid}-option-${idx}`}},[_c('span',{class:[
          `${opt.value === _vm.value ? 'text-indigo-900' : 'text-inherit'}`,
          'block text-sm font-medium'
        ]},[_vm._v("\n          "+_vm._s(opt.label)+"\n        ")])])])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }