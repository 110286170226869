<template>
  <div class="c-cropper-container">
    <div :class="'c-cropper-box'">
      <base-heading class="text-white">{{ config.name }}</base-heading>
      <base-button
        class="c-cropper-cancel btn btn-link"
        type="danger"
        size="sm"
        icon
        @click="cancel"
      >
        <i class="tim-icons icon-simple-remove" />
      </base-button>
      <div class="c-cropper">
        <img
          :id="cropperID"
          :src="image.data"
        >
      </div>
      <div class="c-cropper-bottom">
        <div class="c-cropper-preview" />
        <div class="c-cropper-zoom">
          <base-input
            v-model="zoom"
            type="number"
            :min="minZoom"
            label="Zoom"
            @change="doZoom"
          />
        </div>
        <base-button
          type="primary"
          @click="doCrop()"
        >
          Crop
        </base-button>
      </div>
    </div>
  </div>
</template>
<script>
// import { Modal } from 'src/components';
// import {InputNumber} from 'element-ui'
import BaseInput from './BaseInput'
import Cropper from "cropperjs";
export default {
  components: {
    // Modal
    // [InputNumber.name]: InputNumber
    BaseInput
  },
  props: {
    image: File,
    config: Object
  },
  data() {
    return {
      cropperID: `croppper${Math.floor(Math.random() * 1000000)}`,
      croppper: null,
      show: true,
      filetype: '',
      zoom: 100,
      minZoom: 0
    }
  },
  mounted() {
    this.filetype = this.image.type.trim();
    this.$nextTick(() => {
      const image = document.getElementById(this.cropperID);
      image.onload = () => {
        console.log(this);
        image.addEventListener('zoom', (event) => {
          // console.log(event);
          this.zoom = Math.round(event.detail.ratio * 100)
        });
        image.addEventListener('ready', () => {
          this.cropper.zoom(0);
          this.$nextTick(() => {
            // this.minZoom = parseInt(this.zoom)
          })
        });
        this.cropper = new Cropper(image, {
          viewMode: this.filetype === 'image/png' || this.filetype === 'image/tiff' ? 0 : 1,
          aspectRatio: this.config.dimensions.width / this.config.dimensions.height,
          preview: '.c-cropper-preview'
        });
      };
    });
  },
  created: function() {
    document.addEventListener('keyup', this.escape);
  },
  destroyed: function() {
    document.removeEventListener('keyup', this.escape);
  },
  methods: {
    doZoom(value) {
      this.cropper.zoomTo(value/100);
    },
    doCrop() {
      let multiplier = this.config.retina ? 2 : 1;
      this.$emit('crop', {
        type: this.filetype,
        data: this.cropper.getCroppedCanvas({
          width: this.config.dimensions.width * multiplier,
          height: this.config.dimensions.height * multiplier
        }).toDataURL(this.filetype),
        name: this.image.name
      });
    },
    escape() {
      if (event.keyCode === 27) {
        this.$emit('cancel');
      }
    },
    cancel() {
      this.$emit('cancel');
    }
  }
}
</script>
<style>
.c-cropper-container {
  position: fixed;
  top:0;
  left:0;
  width: 100vw;
  height: 100vh;
  background: rgba(0,0,0,0.75);
  z-index: 9999999;
}
.c-cropper-box {
  position: absolute;
  top: 50%;
  left:50%;
  max-height: 80vh;
  max-width: 80vw;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
}

.c-cropper-box img {
  display: block;
  max-width: 100%;
  max-height: calc(80vh - 210px);
}
.c-cropper-box .card-body {
  max-height: 100%;
}
.c-cropper-bottom {
  position: relative;
}
.c-cropper-preview {
  width: 100%;
  height: 100px;
  overflow: hidden;
  margin: 20px 0;
}
.c-cropper {
  max-width: 100%;
  max-height: calc(80vh - 210px);
  width: 100%;
  height:100%;
}
.c-cropper .cropper-canvas,
.c-cropper .cropper-crop-box,
.c-cropper .cropper-drag-box,
.c-cropper .cropper-modal,
.c-cropper .cropper-wrap-box {
  position: absolute !important;
}
.c-cropper-cancel {
  position: absolute !important;
  top: -10px;
  right: -10px;
  display: block !important;
}

.c-cropper-zoom {
  position: absolute;
  top:0;
  right:0;
}
</style>
