<template>
  <dam-layout>
    <base-listing v-bind="$props" />
  </dam-layout>
</template>
<script>
import DamLayout from '../../layouts/DamLayout';
import BaseListing from '../../components/Listing/BaseListing';
export default {
  name: 'CategoriesIndex',
  components: {
    DamLayout,
    BaseListing,
  },
  props: {
    slug: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      default: () => {
        return [];
      },
    },
    columns: {
      type: Array,
      default: () => {
        return [];
      },
    },
    sortable: {
      type: Boolean,
      default: true,
    }
  },
};
</script>
