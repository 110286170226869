<template>
  <app-layout>
    <base-card class="space-y-4 mb-10 relative">
      <base-input
        label="From"
        v-model="redirect.from"
        @input="inputFrom"
        required
      />
      <base-input label="To" v-model="redirect.to" required />
      <base-input label="Type">
        <el-select v-model="redirect.type">
          <option value="301">Permanent (301)</option>
          <option value="302">Temporary (302)</option>
        </el-select>
      </base-input>

      <base-button @click="saveRedirect"> Save </base-button>
    </base-card>
  </app-layout>
</template>
<script>
import Form from '../components/Form';
export default {
  name: 'RedirectEdit',
  components: {
    ...Form,
  },
  props: {
    redirect: {
      type: Object,
      default: () => {
        return {
          from: '',
          to: '',
          type: 301,
        };
      },
    },
  },
  data() {
    return {
      inputFromTimeout: null,
    };
  },
  methods: {
    inputFrom(val) {
      if (this.inputFromTimeout) {
        clearTimeout(this.inputFromTimeout);
      }
      this.inputFromTimeout = setTimeout(() => {
        if (!val.length || val.indexOf('/') !== 0) {
          this.redirect.from = '/' + val;
        }
      }, 500);
    },
    saveRedirect() {
      if (this.redirect.id) {
        this.$root
          .$CMS_UPDATE('redirects', this.redirect.id, this.redirect)
          .then(() => {
            this.$root.protected = false;
          });
      } else {
        this.$root.$CMS_CREATE('redirects', {}, this.redirect).then((data) => {
          this.$root.protected = false;
          if (data && data.form && data.redirect.id) {
            window.location.href = route(
              'cms.redirects.show',
              data.redirect.id
            );
          }
        });
      }
    },
    mounted() {
      document.addEventListener('keyup', this.$root.protectEvent);
    },
    beforeDestroy() {
      document.removeEventListener('keyup', this.$root.protectEvent);
    },
  },
};
</script>
