<template>
<div class="flow-root">
  <ul class="-mb-8">

    <li v-for="(log, idx) in logs.filter(l => l.subject)" :key="`log-${idx}`">
      <div class="relative pb-8">
        <span class="absolute top-5 left-5 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true"></span>
        <div class="relative flex items-start space-x-3">
          <div>
            <div class="relative px-1">
              <div class="h-8 w-8 bg-gray-100 rounded-full ring-8 ring-white flex items-center justify-center">
                <template v-if="log.causer">
                  <img v-if="log.causer.avatar" :src="log.causer.avatar" class="rounded-full w-full h-full object-cover" />
                  <user-circle-icon v-else class="h-5 w-5 text-gray-500" />
                </template>
                <server-icon v-else class="h-5 w-5 text-gray-500" />
              </div>
            </div>
          </div>
          <div class="min-w-0 flex-1 py-1.5">
            <div class="text-sm text-gray-500">
              <span class="font-medium text-gray-900">{{ log.causer ? `${log.causer.first_name} ${log.causer.last_name} ` : 'System ' }}</span>
              {{ log.description }}
              <a v-if="type === 'listing' && log.subject"
                :href="itemRoute(log.subject.id)"
                class="font-medium text-gray-900"
              >{{ log.subject[namecol] }}</a>
              <span v-else-if="type === 'detail'">this</span>
              <span v-else>an item</span>
              <span class="whitespace-nowrap">{{ ago(log.date) }}</span>
            </div>
          </div>
        </div>
      </div>
    </li>

  </ul>
</div>


</template>
<script>
import { UserCircleIcon, ServerIcon } from '@vue-hero-icons/solid'
export default {
  name: 'BaseLogs',
  components: {
    UserCircleIcon,
    ServerIcon
  },
  props: {
    logs: {
      type: Array,
      default: () => []
    },
    namecol: {
      type: String,
      default: 'name'
    },
    type: {
      type: String, // Used to determine linking and wording
      default: 'listing' // 'listing' or 'detail'
    },
    slug: {
      type: String,
      required: true
    },
    routeParams: {
      type: Object,
      default: () => {}
    },
    routeParamIdName: {
      type: String,
      default: 'id'
    },
  },
  methods: {
    ago (date) {
      return this.moment(date).fromNow()
    },
    itemRoute(id) {
      if (Object.keys(this.routeParams).length) {
        return route(`cms.${this.slug}.show`, {[this.routeParamIdName]: id, ...this.routeParams });
      } else {
        return route(`cms.${this.slug}.show`, id);
      }
    }
  }
}
</script>
