<template>
  <div :class="['relative', showDatepicker ? 'z-30' : 'z-20']" ref="datepicker">
    <div ref="datepicker-anchor">
      <base-input
        type="text"
        v-model="datepickerValue"
        @focus="showDatepicker = true"
        @keydown.escape="showDatepicker = false"
        @keydown="inputDate"
        placeholder="Select date"
      />
    </div>

    <button type="button"
      @click="showDatepicker = true"
      @keydown.escape="showDatepicker = false"
      class="absolute top-0 right-0 px-3 py-2"
    >
      <svg
        class="h-6 w-6 text-gray-400"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
        />
      </svg>
    </button>

    <div
      class="bg-white mt-12 rounded-lg shadow p-4 datepicker-popover transition-opacity opacity-0"
      style="width: 17rem"
      ref="datepicker-popover"
      :data-show="showDatepicker"
    >
      <div class="flex justify-between items-center mb-2">
        <div>
          <span
            v-text="months[month]"
            class="text-lg font-bold text-gray-800"
          ></span>
          <span
            v-text="year"
            class="ml-1 text-lg text-gray-600 font-normal"
          ></span>
        </div>
        <div>
          <button
            type="button"
            class="transition ease-in-out duration-100 inline-flex cursor-pointer hover:bg-gray-200 p-1 rounded-full"
            @click="
              month--;
              getNoOfDays();
            "
          >
            <svg
              class="h-6 w-6 text-gray-500 inline-flex"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M15 19l-7-7 7-7"
              />
            </svg>
          </button>
          <button
            type="button"
            class="transition ease-in-out duration-100 inline-flex cursor-pointer hover:bg-gray-200 p-1 rounded-full"
            @click="
              month++;
              getNoOfDays();
            "
          >
            <svg
              class="h-6 w-6 text-gray-500 inline-flex"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M9 5l7 7-7 7"
              />
            </svg>
          </button>
        </div>
      </div>

      <div class="flex flex-wrap mb-3 -mx-1">
        <template v-for="(day, index) in days">
          <div style="width: 14.26%" class="px-1" :key="index">
            <div
              v-text="day"
              class="text-gray-800 font-medium text-center text-xs"
            ></div>
          </div>
        </template>
      </div>

      <div class="flex flex-wrap -mx-1">
        <template v-for="(blankday, bdIdx) in blankdays">
          <div
            :key="`blankDay${bdIdx}`"
            style="width: 14.28%"
            class="text-center border p-1 border-transparent text-sm"
          ></div>
        </template>
        <template v-for="(date, dateIndex) in no_of_days">
          <div style="width: 14.28%" class="px-1 mb-1" :key="dateIndex">
            <div
              @click="getDateValue(date)"
              v-text="date"
              class="cursor-pointer text-center text-sm rounded-full leading-loose transition ease-in-out duration-100"
              :class="{
                'bg-blue-500 text-white': isToday(date) == true,
                'text-gray-700 hover:bg-blue-200': isToday(date) == false,
              }"
            ></div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import BaseInput from './BaseInput';
import { createPopper } from '@popperjs/core';
export default {
  name: 'ElDatePicker',
  props: ['value', 'type'],
  components: {
    BaseInput,
  },
  computed: {
    date: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit(val);
      },
    },
  },
  data() {
    return {
      anchor: null,
      instance: null,
      inputDebounce: null,
      showDatepicker: false,
      datepickerValue: '',
      month: '',
      year: '',
      no_of_days: [],
      blankdays: [],
      days: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      months: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ],
    };
  },
  methods: {
    initDate() {
      if (this.date) {
        const date = this.moment(this.date).local();
        console.log(date);
        this.month = date.month();
        this.year = date.year();
        this.datepickerValue = date.toDate().toDateString();
      } else {
        let today = new Date();
        this.month = today.getMonth();
        this.year = today.getFullYear();
        // this.datepickerValue = new Date(this.year, this.month, today.getDate()).toDateString();
      }
    },

    isToday(date) {
      const today = new Date();
      const d = new Date(this.year, this.month, date);

      return today.toDateString() === d.toDateString() ? true : false;
    },

    getDateValue(date) {
      let selectedDate = new Date(this.year, this.month, date);
      this.datepickerValue = selectedDate.toDateString();

      this.$emit(
        'input',
        this.moment(selectedDate.getFullYear() +
          '-' +
          ('0' + (selectedDate.getMonth() + 1)).slice(-2) +
          '-' +
          ('0' + selectedDate.getDate()).slice(-2)).local(true).utc().format("YYYY-MM-DD HH:mm:ss")
      );

      // console.log(this.$refs.date.value);

      this.showDatepicker = false;
    },

    getNoOfDays() {
      let daysInMonth = new Date(this.year, this.month + 1, 0).getDate();

      // find where to start calendar day of week
      let dayOfWeek = new Date(this.year, this.month).getDay();
      let blankdaysArray = [];
      for (var i = 1; i <= dayOfWeek; i++) {
        blankdaysArray.push(i);
      }

      let daysArray = [];
      for (var i = 1; i <= daysInMonth; i++) {
        daysArray.push(i);
      }

      this.blankdays = blankdaysArray;
      this.no_of_days = daysArray;
    },
    closePicker($event) {
      if ($event.composedPath().indexOf(this.$refs.datepicker) === -1) {
        this.showDatepicker = false;
      }
    },
    inputDate() {
      clearTimeout(this.inputDebounce);
      this.inputDebounce = setTimeout(() => {
        const dpVal = this.moment(this.datepickerValue);
        if (dpVal.isValid()) {
          this.$emit(
            'input',
            dpVal.local(true).utc().format("YYYY-MM-DD HH:mm:ss")
          );
          setTimeout(() => {
            this.initDate();
            this.getNoOfDays();
          }, 250);
        }
      }, 1500);
    }
  },
  watch: {
    showDatepicker(val) {
      this.$nextTick().then(() => {
        this.instance.forceUpdate();
      })
      if (val) {
        window.document.addEventListener('click', this.closePicker);
      } else {
        window.document.removeEventListener('click', this.closePicker);
      }
    },
    month(val) {
      if (val > 11) {
        // next year
        ++this.year;
        this.month = 0;
      } else if (val < 0) {
        // prev year
        --this.year;
        this.month = 11;
      }
    },
  },
  mounted() {
    this.initDate();
    this.getNoOfDays();

    this.anchor = this.$refs[`datepicker-anchor`];
    this.popover = this.$refs[`datepicker-popover`];
    this.instance = createPopper(this.anchor, this.popover, {
      placement: 'bottom-start',
      strategy: 'fixed',
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 20],
          },
        }
      ],
    });
  },
};
</script>
<style>

.datepicker-popover {
  z-index: 999999999;
}
.datepicker-popover:not([data-show]) {
  pointer-events: none;
}
.datepicker-popover[data-show] {
  opacity: 1 !important;
}

</style>
