<template>
  <app-layout>
    <base-card class="space-y-6 mb-10">
      <div class="space-y-4">
        <base-input
          v-model="contact.email"
          type="text"
          label="Email"
          placeholder=""
          required
        />
        <base-input
          v-model="contact.first_name"
          type="text"
          label="First Name"
          placeholder=""
        />
        <base-input
          v-model="contact.last_name"
          type="text"
          label="Last Name"
          placeholder=""
        />
        <base-input
          v-model="contact.job_title"
          type="text"
          label="Job Title"
          placeholder=""
        />
        <base-input
          v-model="contact.company"
          type="text"
          label="Company"
          placeholder=""
        />
        <base-input
          v-model="contact.phone"
          type="text"
          label="Phone Number"
          placeholder=""
        />
      </div>
      <base-button @click="save">
        Save
      </base-button>
    </base-card>
    <div class="flex flex-col gap-6 lg:flex-row">
      <base-card class="w-full">
        <base-heading size="sm">Submissions</base-heading>
        <base-table v-if="contact.submissions.length" table-css="table-fixed xl:table-auto">
          <template v-slot:head>
            <table-heading class="w-2/12 xl:w-auto">
              ID
            </table-heading>
            <table-heading class="w-5/12 max-w-[20rem] xl:w-auto">
              URL
            </table-heading>
            <table-heading class="w-5/12  max-w-[8rem] xl:w-auto">
              Date
            </table-heading>
          </template>
          <template v-slot:default>
            <template v-for="(submission, idxS) in contact.submissions">
              <tr class="bg-white" :key="`submission${idxS}`">
                <table-cell>
                  <inertia-link :href="route('cms.forms.submissions.show', {form: submission.form_id, id: submission.id})">
                    {{ submission.id }}
                  </inertia-link>
                </table-cell>
                <table-cell>
                  <inertia-link :href="route('cms.forms.submissions.show', {form: submission.form_id, id: submission.id})">
                    {{ submission.url }}
                  </inertia-link>
                </table-cell>
                <table-cell>
                  <inertia-link :href="route('cms.forms.submissions.show', {form: submission.form_id, id: submission.id})">
                    {{ submission.updated_at }}
                  </inertia-link>
                </table-cell>
              </tr>
            </template>
          </template>
        </base-table>
        <p v-else>
          No Submissions
        </p>
      </base-card>
      <base-card class="w-full">
        <base-heading size="sm">Contact Lists</base-heading>
        <base-table table-css="table-fixed xl:table-auto">
          <template v-slot:head>
            <table-heading class="w-1/12 max-w-[100px]">
              ID
            </table-heading>
            <table-heading class="w-10/12 xl:w-auto">
              Name
            </table-heading>
            <table-heading class="w-1/12 max-w-[100px]">
              Action
            </table-heading>
          </template>
          <template v-slot:default>
            <template v-for="(list, idxL) in contact.lists">
              <tr class="bg-white" :key="`list${idxL}`">
                <table-cell>
                  <inertia-link :href="route('cms.contact-lists.show', { id: list.id})">
                    {{ list.id }}
                  </inertia-link>
                </table-cell>
                <table-cell>
                  <inertia-link :href="route('cms.contact-lists.show', { id: list.id})">
                    {{ list.name }}
                  </inertia-link>
                </table-cell>
                <table-cell>
                  <inertia-link method="delete" as="button" :href="route('cms.contact-lists.remove-contact', { contactList: list.id, contact: contact.id})" title="Remove">
                    <x-icon class="w-5 h-5 text-red-500" />
                  </inertia-link>
                </table-cell>
              </tr>
            </template>
            <tr v-if="!contact.lists.length">
              <table-cell colspan="3">
                No Lists
              </table-cell>
            </tr>
            <tr v-if="addingList">
              <table-cell>List</table-cell>
              <table-cell>
                <el-select :options="listOptions" v-model="addedList" />
              </table-cell>
              <table-cell>
                <inertia-link v-if="addedList" method="put" as="button" :href="route('cms.contact-lists.add-contact', { contactList: addedList, contact: contact.id})" v-on:finish="addingList = false" title="Save">
                  <check-icon class="w-5 h-5 text-green-500" />
                </inertia-link>
                <check-icon v-else class="w-5 h-5 text-gray-400 cursor-not-allowed" />
              </table-cell>
            </tr>
          </template>
          <template v-slot:foot>
            <table-cell colspan="3">
              <base-button v-if="!addingList" small @click="addingList = true">Add List</base-button>
            <base-button v-else small @click="addingList = false">Cancel</base-button>
            </table-cell>
          </template>
        </base-table>
      </base-card>
    </div>
  </app-layout>
</template>
<script>
import Form from '../components/Form';
import { XIcon, CheckIcon } from '@vue-hero-icons/solid';
import Table from '../components/Table';
export default {
  name: 'ContactEdit',
  components: {
    ...Form,
    ...Table,
    XIcon,
    CheckIcon,
  },
  props: {
    contact: {
      type: Object,
      default: () => {
        return {
          email: '',
          first_name: '',
          last_name: '',
          phone: '',
          job_title: '',
          company: '',
          submissions: [],
          lists: [],
          alt_names: [],
          other_data: {},
        };
      },
    },
    listOptions: {
      type: Array,
      default: () => []
    },
  },
  methods: {
    save() {
      if (this.contact.id) {
        this.$root.$CMS_UPDATE(
          'contacts',
          this.contact.id,
          this.contact
        );
      } else {
        this.$root
          .$CMS_CREATE('contacts', {}, this.contact)
          .then((data) => {
            if (data.contact.id) {
              window.location.href = route(
                'cms.contacts.show',
                data.contact.id
              );
            }
          });
      }
    },
  },
  data() {
    return {
      addingList: false,
      addedList: null,
    }
  }
};
</script>
