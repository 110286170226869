<template>
  <component :is="tag" :class="[maxH ? 'max-h-screen min-h-0 flex-1 flex flex-col ring-1 ring-gray-100' : '', 'relative']">
    <component
      :is="expand ? 'a' : 'div'"
      :href="href"
      :class="['block sticky top-0 bg-white',`${expand ? 'hover:bg-gray-50' : ''}` , `${expanded ? 'border-b-2 border-gray-100' : ''}`]"
      :style="`z-index: ${50 - parentDepth};`"
      v-on="expand ? { click: toggleExpand } : {}"
      :ref="`${id}-button`"
    >
      <div class="flex items-center px-4 py-4 sm:px-6 w-100">
        <p class="text-sm font-medium text-gray-900 truncate flex items-center w-100 flex-auto">
          <slot />
        </p>
        <chevron-right-icon v-if="expand" class="h-5 w-5 text-gray-400 transform flex-none" :class="expanded ? 'rotate-90' : ''" />
      </div>
    </component>
    <transition
      name="stack-list-item-accordion"
      v-on:before-enter="beforeEnter"
      v-on:enter="enter"
      v-on:before-leave="beforeLeave"
      v-on:leave="leave"
    >
      <div
        :ref="id"
        v-show="expanded"
        :class="['transition-height duration-500 ease-in-out', `${maxH ? 'max-h-full overflow-auto' : 'overflow-hidden'}`]"
      >
        <div class="bg-gray-50 border-gray-100 border-b px-4 py-5 sm:p-6 relative">
          <slot name="expand" />
        </div>
      </div>
    </transition>
  </component>
</template>
<script>
import { v4 as uuidv4 } from 'uuid';
import { ChevronRightIcon } from '@vue-hero-icons/solid';
export default {
  name: 'StackListItem',
  components: {
    ChevronRightIcon,
  },
  props: {
    href: {
      type: String,
      default: 'javascript:;',
    },
    startOpen: {
      type: Boolean,
      default: false,
    },
    maxH: {
      type: Boolean,
      default: false,
    },
    tag: {
      type: String,
      default: 'li'
    }
  },
  data() {
    return {
      id: `stack-list-item-${uuidv4()}`,
      expanded: false,
      collapsePx: 0,
      expandPx: 0,
    };
  },
  computed: {
    expand() {
      return this.$slots.expand && this.$slots.expand.length;
    },
    maxHeight() {
      return (
        this.$parent.$el.offsetHeight -
        this.$parent.$children.length *
          this.$refs[`${this.id}-button`].offsetHeight
      );
    },
    parentDepth() {
      let parent = this.$parent;
      let count = 0;
      while(parent && typeof parent.$parent !== undefined) {
        parent = parent.$parent;
        ++count;
      }
      return count;
    }
  },
  methods: {
    getScrollHeight() {
      return this.$refs[this.id] ? this.$refs[this.id].scrollHeight : 0;
    },
    toggleExpand() {
      this.expandPx = this.getScrollHeight();
      this.$nextTick(() => {
        if (!this.expanded) {
          window.dispatchEvent(
            new CustomEvent('cmsStackItemExpand', {
              detail: { uid: this.uuid, parent: this.$parent.uuid },
            })
          );
        }
        this.expanded = !this.expanded;
      });
    },
    beforeEnter: (el) => {
      el.style.height = '0';
    },
    // afterEnter: (el) => {
    //   el.style.height = 'auto';
    // },
    enter: (el) => {
      // el.style.height = el.scrollHeight + 'px';
      el.style.height = 'auto';
    },
    beforeLeave: (el) => {
      // el.style.height = el.scrollHeight + 'px';
      el.style.height = 'auto';
    },
    leave: (el) => {
      el.style.height = '0';
    },
  },
  mounted() {
    if (this.startOpen) {
      this.expanded = true;
    }
    this.$nextTick(() => {
      this.expandPx = this.getScrollHeight();
    });
    window.addEventListener('cmsStackItemExpand', (evt) => {
      if (
        evt.detail.parent === this.$parent.uuid &&
        evt.detail.uid !== this.uuid &&
        this.expanded
      ) {
        this.expanded = false;
      }
    });
  },
};
</script>
