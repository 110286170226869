<template>
  <div class="fixed bottom-0 right-2 rounded-t-sm shadow-sm bg-gray-800 text-white py-3 px-5 z-50">Online ({{users.length}})</div>
</template>
<script>
export default {
  name: 'EchoOnline',
  data() {
    return {
      users: []
    }
  },
  methods: {
    init() {
      if (this.$echo) {
        console.log('joining');
        this.$echo.join('cms.online')
          .here((users) => {
            this.users = users
          })
          .joining((user) => {
            // fetch user list via api
            console.log(user);
          })
          .leaving((user) => {
            // fetch user list via api
            console.log(user)
          })
          .listen('.cms.updating', (e) => {
            console.warn('Updating!');
            this.$inertia.reload({
              only: ['cms']
            })
          })
          .listen('.cms.updated', (e) => {
            console.warn('Updated!');
            this.$inertia.reload({
              only: ['cms']
            })
          })
      } else {
        console.warn('Cannot find echo', this.$echo)
      }
    }
  },
  mounted() {
    this.$nextTick(this.init());
  }
}
</script>
