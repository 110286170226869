<!-- DEPRECATED -->
<template>
  <form :id="`dam-dz-${uuid}`" class="dropzone min-h-[600px] bg-gray-200 relative cursor-pointer border-none">
    <div :class="[files.length ? 'bottom-0 translate-y-0 scale-75 opacity-75' : 'bottom-1/2 translate-y-1/2 scale-100 opacity-100', 'absolute inset-x-0 mx-auto text-center flex flex-col items-center justify-center gap-2 w-80 h-40 pointer-events-none transition-all']">
      <svg class="w-20 h-20 text-gray-400" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
        <path d="M0 0h48v48h-48z" fill="none"/>
        <path fill="currentColor" d="M38.71 20.07c-1.36-6.88-7.43-12.07-14.71-12.07-5.78 0-10.79 3.28-13.3 8.07-6.01.65-10.7 5.74-10.7 11.93 0 6.63 5.37 12 12 12h26c5.52 0 10-4.48 10-10 0-5.28-4.11-9.56-9.29-9.93zm-10.71 5.93v8h-8v-8h-6l10-10 10 10h-6z"/>
      </svg>
      <div class="dz-message">Drop file{{ single ? '' : 's' }} here or <span class="text-blue-500">click to browse</span></div>
    </div>
  </form>
</template>
<script>
import Dropzone from 'dropzone'
Dropzone.autoDiscover = false;

export default {
  name: 'DamDropzone',
  props: ['value', 'single'],
  computed: {
    files: {
      get() { return this.value },
      set(val) { this.$emit('input', val) }
    },
  },
  data() {
    return {
      dz: null
    }
  },
  mounted() {
    this.dz = new Dropzone(`form#dam-dz-${this.uuid}`, {
      addRemoveLinks: true,
      autoProcessQueue: false,
      uploadMultiple: !this.single,
      url: 'null'
    });
    this.dz.on('addedfile', file => {
      console.log(file);
      this.files.push({name: file.name, file: file});
    });
    this.dz.on('removedfile', removed => {
      this.files = this.files.filter(f => f.file.upload.uuid !== removed.upload.uuid);
    });
  }
}
</script>


<style>
  .dropzone .dz-progress {
    display: none;
  }
  .dropzone .dz-preview.dz-image-preview {
    background: transparent;
  }
  .dropzone .dz-message {
    margin: 0;
  }
  .dropzone.dz-started .dz-message {
    display: block;
  }
  .dropzone .dz-remove {
    background-color: rgb(239 68 68);
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9Im5vbmUiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3Ryb2tlLXdpZHRoPSIxLjUiIHN0cm9rZT0id2hpdGUiIGNsYXNzPSJ3LTYgaC02Ij4KICA8cGF0aCBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIGQ9Ik05Ljc1IDkuNzVsNC41IDQuNW0wLTQuNWwtNC41IDQuNU0yMSAxMmE5IDkgMCAxMS0xOCAwIDkgOSAwIDAxMTggMHoiIC8+Cjwvc3ZnPg==");
    background-position: center center;
    background-repeat: no-repeat;
    border-radius: 100%;
    color: rgba(0,0,0,0);
    height: 30px;
    overflow: hidden;
    position: absolute;
    right:-10px;
    top:-10px;
    width: 30px;
    z-index: 25;
  }
  .dropzone .dz-remove:hover {
    background-color: rgb(220 38 38);
  }
</style>
