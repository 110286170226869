<template>
  <div class="mb-4 flex items-end gap-2">
    <base-input label="Search Calendar" v-model="query" class="flex-grow" />
    <base-button class="flex-none" @click="search">Search</base-button>
  </div>
</template>
<script>

import Form from '../../../components/Form'
export default {
  components: {
    ...Form
  },
  data() {
    return {
      query: ''
    }
  },
  methods: {
    search() {
      this.$inertia.visit(route('cms.broadcast-calendar.search', {query: this.query}));
    }
  }
}
</script>
