<template>
  <dam-layout>
    <div class="lg:flex gap-10">
      <div class="lg:w-1/2 space-y-10">
        <dam-panel>
          <template v-slot:heading>
            <p class="text-lg font-bold flex gap-5 items-center justify-start w-full">
              <span>Name</span>
              <span class="font-light inline-block truncate flex-1">{{ asset.name }}</span>
            </p>
          </template>

          <template v-if="embeddableTag">
            <component
              :is="embeddableTag"
              :class="['w-full', ['embed', 'SourceViewer'].includes(embeddableTag) ? 'h-[600px]' : '']"
              :src="currentOriginal.url"
              controls />
          </template>
          <p v-else>No preview available</p>

          <div class="mt-10 flex items-stretch justify-start gap-5">
            <popover-download :item="asset" />
            <popover-share :item="asset" />
            <label for="replace-asset" class="relative">
              <dam-button type="secondary">Replace</dam-button>
              <input type="file" id="replace-asset" :accept="asset.type" class="absolute opacity-0 w-1 h-1" @change="replaceAsset" ref="asset-replacement" />
            </label>
          </div>

        </dam-panel>

        <dam-panel heading="Collections">
          <asset-taggable slug="collections" :assetId="asset.id" v-model="asset.collections" />
        </dam-panel>

        <dam-panel heading="Categories">
          <asset-taggable slug="categories" :assetId="asset.id" v-model="asset.categories" />
        </dam-panel>

      </div>
      <div class="lg:w-1/2 space-y-10">

        <dam-panel heading="File Info">
          <ul class="space-y-5">
            <li><strong>Created By: </strong> {{ asset.user.first_name }} {{ asset.user.last_name }}</li>
            <li><strong>Created At: </strong> {{ moment(asset.created_at).toString() }}</li>
            <li><strong>Updated At: </strong> {{ moment(asset.updated_at).toString() }}</li>
            <li><strong>File Type: </strong> {{ asset.type }} </li>
          </ul>
        </dam-panel>

        <dam-panel>
          <template v-slot:heading>
            <p class="text-lg font-bold flex gap-5 items-center justify-between w-full">
              <span>Meta Information</span>
              <dam-button-simple v-if="editingAll" @click="saveAllMeta">
                <span class="text-base font-normal inline-flex items-center justify-center px-3 gap-1">
                  <CheckIcon class="w-4 h-4 inline-block" />
                  Save All
                </span>
              </dam-button-simple>
              <dam-button-simple v-else @click="editAllMeta">
                <span class="text-base font-normal inline-flex items-center justify-center px-3 gap-1">
                  <PencilIcon class="w-4 h-4 inline-block" />
                  Edit All
                </span>
              </dam-button-simple>
            </p>
          </template>
          <ul class="space-y-5 mb-4">
            <li v-for="(field,idxF) in defaultMetaFields" :key="`defaultMetaField-${idxF}`" class="space-y-2">
              <dam-meta-item :title="field" v-model="asset[field]" v-on:save="updateAssetAttribute(field)" ref="meta_item" />
            </li>
            <li v-for="(meta,idxM) in asset.meta" :key="`assetMetaField-${idxM}`">
              <dam-meta-item :title="meta.name" v-model="meta.pivot.value" v-on:save="updateAssetMeta(meta)" ref="meta_item" />
            </li>
          </ul>
          <div v-if="addingMeta" class="space-y-4">
            <el-select :options="metaOptions" v-model="newMeta.id" @input="setNewMetaName" placeholder="Please select..." />
            <div v-if="newMeta.id >= 0" class="flex gap-4">
              <base-input label="Name" v-model="newMeta.name" :disabled="newMeta.id > 0" />
              <base-input label="Value" v-model="newMeta.value" />
            </div>
            <div class="flex gap-4 justify-start items-start">
              <dam-button tag="button" @click="addMeta">Save</dam-button>
              <dam-button tag="button" type="secondary" @click="addingMeta = false">Cancel</dam-button>
            </div>
          </div>
          <dam-button v-else-if="!editingAll" tag="button" @click="addingMeta = true">Add Custom Meta</dam-button>
        </dam-panel>



        <!-- <dam-panel heading="Tags">

        </dam-panel> -->

      </div>

    </div>

    <div class="pt-20 flex justify-end">
      <base-button type="danger" @click="deleteAsset">Delete Asset</base-button>
    </div>
  </dam-layout>
</template>
<script>
import moment from 'moment';
import DamLayout from '../../layouts/DamLayout';
import DamPanel from './components/Panel';
import DamButton from './components/Button';
import DamButtonSimple from './components/ButtonSimple';
import DamMetaItem from './components/MetaItem'
import SourceViewer from './components/SourceViewer';
import PopoverDownload from './components/PopoverDownload'
import PopoverShare from './components/PopoverShare'
import Form from '../../components/Form';
import { PencilIcon, CheckIcon, XIcon } from '@vue-hero-icons/solid';
import AssetTaggable from './components/AssetTaggable';
export default {
  name: 'AssetsEdit',
  components: {
    AssetTaggable,
    CheckIcon,
    DamButton,
    DamButtonSimple,
    DamLayout,
    DamMetaItem,
    DamPanel,
    PencilIcon,
    PopoverDownload,
    PopoverShare,
    SourceViewer,
    XIcon,
    ...Form,
  },
  props: {
    asset: {
      type: Object,
      default: () => {
        return {
          name: '',
          type: '',
          description: '',
          keywords: '',
        }
      }
    },
    meta_options: {
      type: Array,
      default: () => []
    },
  },
  computed: {
    metaOptions() {
      return [
        {value: 0, label: 'New Meta Option'},
        ...this.meta_options.filter(mo => {
          return this.asset.meta.filter(am => am.name == mo.name).length == 0
        }).map(mo => {
          return { value: mo.id, label: mo.name }
        })
      ]
    },
    currentOriginal() {
      return this.asset.current_files.find(f => f.original == true);
    },
    embeddableTag() {
      // application/pdf
      if (this.currentOriginal) {
        if (this.currentOriginal.type.includes('pdf')) {
          return 'embed';
        } if (this.currentOriginal.type.includes('application/xml')) {
          return 'SourceViewer';
        } else {
          // image/*, video/*, audio/*, text/*
          const type = this.currentOriginal.type.split('/')[0] || null;
          if (type && this.previewableTypes.includes(type)) {
            switch(type) {
              case 'image':
                return 'img';
              case 'text':
                return 'SourceViewer';
              case 'video':
              case 'audio':
                return type;
              default:
                return null;
            }
          } else {
            return null;
          }
        }
      } else {
        return null
      }
    }
  },
  methods: {
    moment(param) {
      return moment(param)
    },
    setNewMetaName(id) {
      if (id > 0) {
        this.newMeta.name = this.meta_options.find(mo => mo.id == id).name
      }
    },
    addMeta() {
      if (this.newMeta.id == 0) {
        this.$CMS_CREATE('dam.metas', {}, {...this.newMeta, asset_id: this.asset.id}).then(data => {
          this.$inertia.reload();
          this.newMeta = {
            id: -1,
            name: '',
            value: ''
          }
        });
      } else if (this.newMeta.id > 0) {
        this.$CMS_UPDATE('dam.metas', this.newMeta.id, {...this.newMeta, asset_id: this.asset.id}).then(data => {
          this.$inertia.reload();
          this.newMeta = {
            id: -1,
            name: '',
            value: ''
          }
        });
      }
    },
    editAllMeta() {
      this.editingAll = true;
      this.$refs.meta_item.forEach(mi => mi.$emit('edit_all'));
    },
    saveAllMeta() {
      this.editingAll = false;
      this.$refs.meta_item.forEach(mi => mi.$emit('save_all'));
      this.$CMS_UPDATE('dam.assets', this.asset.id, this.asset, true).then(() => {
        this.asset.meta.forEach(meta => {
          this.$CMS_UPDATE('dam.metas', meta.id, {value: meta.pivot.value, asset_id: this.asset.id}, true);
        });
        this.$noty.success('Updated!');
      });
    },
    updateAssetAttribute(attr) {
      this.$CMS_UPDATE('dam.assets', this.asset.id, this.asset);
    },
    updateAssetMeta(meta) {
      this.$CMS_UPDATE('dam.metas', meta.id, {value: meta.pivot.value, asset_id: this.asset.id});
    },
    replaceAsset(evt) {
      const files = evt.target.files;
      if (files.length) {
        const file = files[0];
        if (file.type === this.asset.type) {
          const formdata = new FormData();
          formdata.append('file', file);
          this.$CMS_POST('dam.assets.replace', {asset: this.asset.id}, formdata, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }).then(data => {
            if (data.status === 'success') {
              this.$inertia.reload();
            } else {
              console.log(data);
              console.error('DAM ERROR: Asset replace failed.');
            }
          });
        } else {
          console.error('DAM Error: Cannot replace assset. File type does not match.');
        }
      }
    },
    deleteAsset() {
      this.$swal.fire({
        title: 'Are you sure you want to delete this?',
        text: 'This will cause all shared links for this asset to no longer function and this cannot be undone',
        showDenyButton: true,
        showConfirmButton: false,
        showCancelButton: true,
        denyButtonText: 'Delete'
      })
      .then((result) => {
        if (result.isDenied) {
          this.$inertia.delete(
            route(`cms.dam.assets.destroy`, {
              id: this.asset.id,
            }),
            {
              onFinish: () =>
                this.$inertia.visit(route('cms.dam.assets.index'))
            }
          );
        }
      });
    }
  },
  data() {
    return {
      previewableTypes: ['image', 'video', 'audio', 'pdf', 'text'],
      defaultMetaFields: ['name', 'description', 'keywords'],
      editingAll: false,
      addingMeta: false,
      newMeta: {
        id: -1,
        name: '',
        value: ''
      },
    }
  },

};
</script>
