<template>
  <app-layout>
    <FormNav />
    <base-card class="space-y-4 mb-10 relative">

      <base-listing v-bind="$props" />

    </base-card>
  </app-layout>
</template>
<script>
import BaseListing from '../components/Listing/BaseListing';
import FormNav from './Partials/FormNav';
export default {
  name: 'FormSubmissions',
  components: {
    BaseListing,
    FormNav,
  },
  props: {
    form: {
      type: Object,
      default: () => {
        return {
          id: 0,
          name: '',
        };
      },
    },
    slug: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      default: () => {
        return [];
      },
    },
    columns: {
      type: Array,
      default: () => {
        return [];
      },
    },
    routeParams: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
