<template>
  <base-button v-if="icon" small icon :class="[
    'align-middle',
    status > 0 ? (
      status == 9999 ? 'text-red-500 hover:text-red-600' : 'text-yellow-500 hover:text-yellow-600'
    ) : 'text-gray-400 hover:text-gray-500'
  ]" v-on:click="trigger" title="Ban / Warn User">
    <BanIcon class="w-5 h-5" />
  </base-button>
  <base-button v-else small type="danger" @click="trigger" title="Ban / Warn User"><BanIcon class="w-5 h-5 align-middle" /> &nbsp; Ban User</base-button>
</template>
<script>
const strikeMessages = [
  "Your submission does not follow the site's posted rules. This is your first strike of 3. If you continue, you will be banned from submitting forms to this site.",
  "Your submission does not follow the site's posted rules. This is your second strike of 3. If you continue, you will be banned from submitting forms to this site.",
  "Your submission does not follow the site's posted rules. This is your final warning. If you continue, you will be banned from submitting forms to this site.",
];
import moment from 'moment'
import { BanIcon } from '@vue-hero-icons/solid';
export default {
  props: {
    item: {
      type: Object,
      default: () => {
        return {
          ip_address: '',
          email: '',
          is_banned: false,
          strikes: 0,
          reason: '',
          banned_until: null,
          strike_message: ''
        }
      }
    },
    icon: {
      type: Boolean,
      default: true
    }
  },
  components: {
    BanIcon
  },
  computed: {
    // 9999 = banned, -1 = no ban or strike, # = strikes
    status() {
      const ips = this.$page?.props?.bannedIps;
      if (ips.length) {
        const existing = ips.filter(ip => {
          return ip.ip_address == this.item.ip_address || ip.email == this.item.email;
        });
        if (existing.length) {
          return existing[0].is_banned ? 9999 : parseInt(existing[0].strikes)
        } else {
          return -1;
        }
      } else {
        return -1;
      }
    }
  },
  methods: {
    trigger () {
      this.$CMS_GET('bannedips.lookup', {ip_address: this.item.ip_address, email: this.item.email || ''})
        .then((data) => {
          if (data.results.length) {
            // existing ban/strikes
            this.bannable = data.results[0];
            if (!this.bannable.email && this.item.email) {
              this.bannable.email = this.item.email;
            }
          } else {
            // new ban/strikes
            this.bannable = {
              ip_address: this.item.ip_address,
              email: this.item.email || '',
              is_banned: false,
              strikes: 0,
              reason: '',
              banned_until: null,
              strike_message: ''
            }
          }
          // Ask if you want to ban or issue strike
          this.$swal.fire({
            title: `${this.bannable.id ? 'Existing User:' : 'New User'} ${this.bannable.is_banned ? 'Already Banned' : (this.bannable.strikes+' strikes')}`,
            text: 'Next steps?',
            showCancelButton: true,
            showDenyButton: this.bannable.is_banned ? false : true, // ban
            showConfirmButton: true, // strike
            confirmButtonText: this.bannable.is_banned ? 'Unban' : 'Issue Strike',
            denyButtonText: 'Ban User'
          }).then((result) => {
            if (result.isConfirmed) {
              if (this.bannable.is_banned) {
                this.unban(this.bannable);
              } else {
                this.strike();
              }
            } else if (result.isDenied) {
              this.ban();
            }
          });
        });
    },
    ban() {
      this.$swal
        .fire({
          title: `Ban ${this.item.ip_address}${this.item.email ? '/'+this.item.email : ''}`,
          html:
            '<label for="swal-banip-reason" class="swal2-label">Reason:</label>' +
            `<input id="swal-banip-reason" class="swal2-input" placeholder="(optional)" value="${this.bannable.reason || ''}" />` +
            '<label for="swal-banip-until" class="swal2-label">Until:</label>' +
            `<input id="swal-banip-until" class="swal2-input" type="date" value="${moment().add(30, 'days').format('YYYY-MM-DD')}" />`,
          showCancelButton: true,
          confirmButtonText: 'BAN',
          focusConfirm: false,
            preConfirm: () => {
              return {
                reason: document.getElementById('swal-banip-reason').value,
                until: document.getElementById('swal-banip-until').value
              }
            }
        })
        .then((result) => {
          // upload
          if (typeof result.value === 'object') {
            const bannable = this.bannable;
            bannable.is_banned = true;
            bannable.reason = result.value.reason;
            bannable.banned_until = result.value.until;
            this.$nextTick().then(() => {
              this.save(bannable);
            })
          }
        });
    },
    strike() {
      // TODO: Strike popup
      this.$swal
        .fire({
          title: `Issue Strike for ${this.item.ip_address}${this.item.email ? '/'+this.item.email : ''}`,
          html:
            '<label for="swal-strike-message" class="swal2-label block text-left">Message:</label>' +
            `<textarea id="swal-strike-message" class="block w-full" rows="4">${strikeMessages[this.bannable.strikes]}</textarea>`,
          showCancelButton: true,
          confirmButtonText: 'Send',
          focusConfirm: false,
            preConfirm: () => {
              return {
                msg: document.getElementById('swal-strike-message').value,
              }
            }
        })
        .then((result) => {
          // upload
          if (typeof result.value === 'object') {
            const bannable = this.bannable;
            bannable.strikes = parseInt(this.bannable.strikes) + 1;
            bannable.strike_message = result.value.msg;
            this.$nextTick().then(() => {
              this.save(bannable);
            })
          }
        });
    },
    unban(bannable) {
      let loader = this.$loading.show({
        color: '#009ae3',
        backgroundColor: '#000',
      });
      if (!bannable) {
        bannable = this.bannable;
      }
      if (bannable.id) {
        this.$root
          .$CMS_POST('bannedips.destroy', {bannedip: bannable.id}, {_method: 'DELETE'}, {method: 'DELETE'})
          .then((resp) => {
            console.log(resp);
            loader.hide();
          }).finally(() => {
            setTimeout(() => {
              this.$inertia.reload();
            }, 500);
          });
      }
    },
    save(bannable) {
      let loader = this.$loading.show({
        color: '#009ae3',
        backgroundColor: '#000',
      });
      if (!bannable) {
        bannable = this.bannable;
      }
      if (bannable.id) {
        this.$root
          .$CMS_UPDATE(`bannedips`, {bannedip: bannable.id}, bannable)
          .then((resp) => {
            console.log(resp);
            loader.hide();
          }).finally(() => {
            setTimeout(() => {
              this.$inertia.reload();
            }, 500);
          });
      } else {
        this.$root
          .$CMS_CREATE(`bannedips`, {}, bannable)
          .then((resp) => {
            console.log(resp);
            loader.hide();
          }).finally(() => {
            setTimeout(() => {
              this.$inertia.reload();
            }, 500);
          });
      }
    }
  },
  data() {
    return {
      bannable: {}
    }
  }
}
</script>
