<template>
  <app-layout>
    <base-card class="space-y-4 mb-10 relative">
      <base-input label="Name" v-model="digest.name" required />


      <!-- FORMS AND THEIR QUERIES -->
      <div v-for="(form, idxF) in digest.forms" :key="`digestForm-${idxF}`">
        <p>{{ form.name }}</p>
        <div v-for="(dfq, idxDFQ) in form.pivot.query" :key="`digestFormQuery-${idxDFQ}`">
          <p v-show="idxDFQ > 0" class="p-4 text-center"> - AND - </p>
          <form-query v-model="form.pivot.query[idxDFQ]"
            :fields="form.fields.map(f => { return { label: f.label, value: f.id } })"
            :class="[`${idxF === form.pivot.query.length - 1 ? 'mr-4' : ''}`, 'w-full']"
            :canDelete="idxF > 0"
            @addQuery="addQuery(idxF, idxDFQ)"
            @removeQuery="removeQuery(idxF, idxDFQ)"
            />
          <base-button v-show="idxDFQ > 0" @click="removeQuery(idxF, idxDFQ)">Remove Query</base-button>
        </div>

        <base-button @click="removeForm(idxF)">Remove Form</base-button>
      </div>

      <el-select v-model="newForm">
        <option value="">Select...</option>
        <option v-for="(formOpt, idxFO) in forms" :key="`formOpt${idxFO}`" :value="formOpt.id">{{ formOpt.name }}</option>
      </el-select>

      <base-button @click="addForm">Add</base-button>

      <!-- SUBSCRIBBLES -->
      <div>
        <base-label tooltip="Automated delivery of this report">Subscriptions</base-label>
        <base-table>

          <template v-slot:head>
            <table-heading>
              Email
            </table-heading>
            <table-heading>
              Frequency
              <base-tooltip class="inline-block ml-2 w-4 h-4 text-center align-middle normal-case" :offsetY="5">
                <template v-slot:content>
                  See <a href="https://crontab.guru" target="_blank">https://crontab.guru</a> for reference
                </template>
                <information-circle-icon class="align-top w-full h-full inline-block" />
              </base-tooltip>
            </table-heading>
            <table-heading>
              Delta
              <base-tooltip class="inline-block ml-2 w-4 h-4 text-center align-middle normal-case" :offsetY="5">
                <template v-slot:content>
                  Only get the items created since last run.
                </template>
                <information-circle-icon class="align-top w-full h-full inline-block" />
              </base-tooltip>
            </table-heading>
            <table-heading>
              Last Run
            </table-heading>
            <table-heading class="w-16">
              <span class="sr-only">Delete</span>
            </table-heading>
          </template>

          <template v-if="digest.subscriptions.length" v-slot:default>
            <tr v-for="(sub, idxS) in digest.subscriptions" :key="`sub-${idxS}`">
              <table-cell>
                {{ sub.email }}
              </table-cell>
              <table-cell>
                <el-select v-model="digest.subscriptions[idxS].frequency">
                  <option value="">Select...</option>
                  <option v-for="(freq, idxFQ) in frequencies" :key="`frequency-${idxFQ}`" :value="freq">
                    {{ idxFQ }}
                  </option>
                </el-select>
                <!-- <base-input class="font-mono" v-model="digest.subscriptions[idxS].frequency" /> -->
              </table-cell>
              <table-cell>
                <base-switch v-model="digest.subscriptions[idxS].delta" />
              </table-cell>
              <table-cell>
                {{ sub.last_run }}
              </table-cell>
              <table-cell class="w-16">
                <base-button icon @click="removeSub(idxS)">
                  <x-icon class="w-4 h-4" />
                </base-button>
              </table-cell>
            </tr>
          </template>
          <template v-else v-slot:default>
            <tr>
              <table-cell colspan="4">No subscriptions</table-cell>
            </tr>
          </template>
          <template v-slot:foot>
            <table-cell colspan="4">
              <base-input v-model="newEmail" label="Add a Subscription" />
              <base-button @click="addSub">Add</base-button>
            </table-cell>
          </template>
        </base-table>
      </div>

      <div class="flex">
        <base-button @click="updateDigest" class="mr-4">
          Save
        </base-button>
        <base-button v-if="digest.id" tag="a" :href="route('cms.form-digest.download', digest.id)" target="_blank">Download (.xlsx)</base-button>
      </div>

    </base-card>
  </app-layout>
</template>
<script>
import FormNav from './Partials/FormNav';
import Form from '../components/Form';
import Stack from '../components/Stack';
import FormQuery from '../components/Misc/FormQuery'
import {
  XIcon,
  PencilAltIcon,
  SwitchVerticalIcon,
  InformationCircleIcon
} from '@vue-hero-icons/solid';
import Table from '../components/Table';
import draggable from 'vuedraggable';
export default {
  name: 'FormDigestEdit',
  components: {
    ...Form,
    ...Table,
    ...Stack,
    FormNav,
    XIcon,
    PencilAltIcon,
    SwitchVerticalIcon,
    InformationCircleIcon,
    draggable,
    FormQuery,
  },
  props: {
    // every Form in the CMS
    forms: {
      type: Array,
      default: () => []
    },

    // current Digest
    digest: {
      type: Object,
      default: () => {
        return {
          name: '',
          forms: [],
          subscriptions: []
        };
      },
    },
  },
  data() {
    return {
      newEmail: '',
      newForm: 0,
      frequencies: {
        Daily: 'daily',
        Weekly: 'weekly',
        Monthly: 'monthly',
      },
      formQueryBasis: {
        field: '',
        query: '',
        operator: '',
        or: [],
        and: []
      },
    };
  },
  mounted() {
    document.addEventListener('keyup', this.$root.protectEvent);
  },
  beforeDestroy() {
    document.removeEventListener('keyup', this.$root.protectEvent);
  },
  computed: {

  },
  methods: {

    // creates new empty Subscription item to populate
    addSub() {
      this.digest.subscriptions.push({
          email: this.newEmail,
          frequency: 'daily',
          delta: false,
          last_run: ''
      });
      this.$nextTick(() => {
        this.newEmail = '';
      });
    },

    // removes Subscription item
    removeSub(idx) {
      this.digest.subscriptions.splice(idx, 1);
    },

    // creates new Query item for the selected Form
    addForm() {
      const newForm = this.forms.filter((f) => f.id === this.newForm);
      if (newForm.length) {
        this.digest.forms.push({
          ...newForm[0],
          pivot: {
            query: [JSON.parse(JSON.stringify(this.formQueryBasis))]
          }
        });
      } else {
        console.log(['idk that form', this.newForm]);
      }
    },

    removeForm(idx) {
      this.digest.forms.splice(idx, 1);
    },

    // creates new Query item (using AND / OR)
    addQuery(fIdx, qIdx) {
      const basis = JSON.parse(JSON.stringify(this.formQueryBasis));
      this.digest.forms[fIdx].pivot.query.splice(qIdx+1, 0, {
        ...basis
      });
    },

    // removes Query item (or the entire group if there are subqueries)
    removeQuery(fIdx, qIdx) {
      this.digest.forms[fIdx].pivot.query.splice(qIdx, 1);
    },

    // saves Digest to database
    updateDigest() {
      if (this.digest.id) {
        this.$root.$CMS_UPDATE('form-digest', this.digest.id, this.digest);
      } else {
        this.$root.$CMS_CREATE('form-digest', {}, this.digest).then((data) => {

          this.$root.protected = false;

          if (data.digest.id) {
            window.location.href = route('cms.form-digest.show', data.digest.id);
          }
        });
      }
    },
  },
};
</script>
