<template>
  <dam-layout>
    <base-heading>DAM: Workflow page to be built</base-heading>
  </dam-layout>
</template>
<script>
import DamLayout from '../../layouts/DamLayout';
export default {
  name: 'WorkflowsEdit',
  components: {
    DamLayout,
  },
};
</script>
