<template>
  <div class="space-y-3">
    <base-input label="Name" v-model="general.name" />
    <base-input label="Key" v-model="general.key" />
    <base-input label="Value" v-model="general.value" />
  </div>
</template>
<script>

import Form from '../../../components/Form'
export default {
  components: {
    ...Form,
  },
  props: {
    general: {
      type: Object,
      default: () => {
        return {
          name: '',
          key: '',
          value: '',
        }
      }
    }
  }
}
</script>
