<template>
  <nav v-if="form.id" class="relative z-0 rounded-lg shadow flex divide-x divide-gray-200 mb-6" aria-label="Tabs">
    <inertia-link :href="route(`cms.forms.show`, { form: form.id })" :class="[`${route().current(`cms.forms.show`, { form: form.id}) ? 'text-gray-900' : 'text-gray-500 hover:text-gray-700'}`, 'rounded-l-lg capitalize group relative min-w-0 flex-1 flex flex-col justify-center overflow-hidden bg-white py-4 px-4 text-xs font-medium text-center lg:text-sm hover:bg-gray-50 focus:z-10']" >
      {{ form.name }}
      <span aria-hidden="true" :class="[ `${route().current(`cms.forms.show`, { form: form.id}) ? 'bg-indigo-500' : 'bg-transparent'}`, 'absolute inset-x-0 bottom-0 h-0.5']"></span>
    </inertia-link>
    <inertia-link :href="route(`cms.forms.submissions.index`, { form: form.id })" :class="[`${route().current(`cms.forms.submissions.*`, { form: form.id}) ? 'text-gray-900' : 'text-gray-500 hover:text-gray-700'}`, 'rounded-r-lg capitalize group relative min-w-0 flex-1 flex flex-col justify-center overflow-hidden bg-white py-4 px-4 text-xs font-medium text-center lg:text-sm hover:bg-gray-50 focus:z-10']" >
      Submissions
      <span aria-hidden="true" :class="[ `${route().current(`cms.forms.submissions.*`, { form: form.id}) ? 'bg-indigo-500' : 'bg-transparent'}`, 'absolute inset-x-0 bottom-0 h-0.5']"></span>
    </inertia-link>
  </nav>
</template>
<script>
export default {
  computed: {
    form() {
      return this.$page.props.form;
    },
  },
};
</script>
