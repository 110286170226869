<template>
  <div :class="(Array.isArray(value.and) && value.and.length) || (Array.isArray(value.or) && value.or.length) ? 'p-8 border border-gray-400 rounded' : ''">
    <div class="lg:flex items-end">
      <base-input label="Subject" class="flex-none w-full lg:w-1/5 xl:w-56 mb-4 lg:mb-0 lg:mr-4">
        <combo-box
          :options="subjects"
          :value="value.subject"
          :disabled="subjectDisabled"
          @input="updateValue('subject', $event)"
          placeholder="Please Select..."
        />
      </base-input>
      <base-input label="Column" class="flex-none w-full lg:w-1/5 xl:w-56 mb-4 lg:mb-0 lg:mr-4">
        <!-- :options="columns.length ? ['Any Column', ...columns] : ['Any Column']" -->
        <combo-box
          :disabled="!value.subject || !columns.length"
          :options="columns"
          :value="value.column"
          @input="updateValue('column', $event)"
          placeholder="Please Select..."
        />
      </base-input>
      <base-input label="Operator" class="flex-none w-full lg:w-1/5 xl:w-56 mb-4 lg:mb-0 lg:mr-4">
        <combo-box
          :disabled="!value.subject || !value.column"
          :options="operators"
          :value="value.operator"
          @input="updateValue('operator', $event)"
          placeholder="Please Select..."
        />
      </base-input>
      <base-input
        :disabled="!value.subject || !value.column || !value.operator"
        :value="value.query"
        @input="updateValue('query', $event)"
        class="w-full mb-4 lg:mb-0 lg:mr-4"
        label="Query"
      />
      <div class="flex-none flex items-center">
        <base-button
          :disabled="!value.subject || !value.column || !value.operator || !value.query"
          @click="type !== 'or' ? $emit('addQuery') : updateAndOr('and')"
          class="mr-4"
        >
          And
        </base-button>
        <base-button
          :disabled="!value.subject || !value.column || !value.operator || !value.query"
          @click="type !== 'and' ? $emit('addQuery') : updateAndOr('or')"
          class="mr-4"
        >
          Or
        </base-button>
        <base-button
          icon
          v-show="canDelete && !(Array.isArray(value.and) && value.and.length) && !(Array.isArray(value.or) && value.or.length)"
          class="text-red-600 w-5"
          @click="$emit('removeQuery')"
        >
            <x-icon class="w-full h-auto" />
        </base-button>
      </div>
    </div>
    <template v-if="value.or && Array.isArray(value.or) && value.or.length">
      <div v-for="(qry, idxO) in value.or" :key="`searchQueryOr${idxO}`">
        <div class="my-4 w-full font-bold tracking-widest uppercase text-center">- OR -</div>
        <search-query
          type="or"
          :value="qry"
          @input="updateAndOr('or', idxO, $event)"
          @addQuery="updateAndOr('or', idxO)"
          @removeQuery="removeAndOr('or', idxO)"
        />
      </div>
    </template>
    <template v-if="value.and && Array.isArray(value.and) && value.and.length">
      <div v-for="(qry, idxA) in value.and" :key="`searchQueryAnd${idxA}`">
        <div class="my-4 w-full font-bold tracking-widest uppercase text-center">- AND -</div>
        <search-query
          type="and"
          :value="qry"
          @input="updateAndOr('and', idxA, $event)"
          @addQuery="updateAndOr('and', idxA)"
          @removeQuery="removeAndOr('and', idxA)"
        />
      </div>
    </template>
  </div>
</template>
<script>
import {XIcon} from '@vue-hero-icons/solid'
import Form from '../Form'
export default {
  name: 'SearchQuery',
  components: {
    ...Form,
    XIcon
  },
  props: {
    modules: {
      type: Array,
      default: () => []
    },
    value: {
      type: Object,
      default: () => {
        return JSON.parse(JSON.stringify(this.searchQueryBasis))
      }
    },
    type: {
      type: String,
      default: 'and'
    },
    canDelete: {
      type: Boolean,
      default: true
    },
    subjectDisabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      columns: [],
      operators: ['Is', 'Contains', 'Is Not', 'Does Not Contain'],
      searchQueryBasis: {
        subject: '',
        column: '',
        query: '',
        operator: '',
        or: [],
        and: []
      },
    }
  },
  computed: {
    subjects() {
      const mods = this.modules.map(mod => {
        return {
          label: mod.name,
          value: mod.model
        }
      });
      return [{
        label: 'Pages',
        value: 'Atypiccraft\\Cms\\Page'
      }, ...mods];
    }
  },
  methods: {
    fetchColumns(subject) {
      this.columns = [];
      this.$nextTick(() => {
        this.$root.$CMS_GET('search.columns', {subject: subject}).then((data) => {
          this.columns = data.columns;
        });
      })
    },
    updateValue(key, value) {
      // Fetch columns when changing the subject
      if (key === 'subject') {
        this.fetchColumns(value)
      }

      this.$emit('input', { ...this.value, [key]: value });

      console.log(key,value);
    },
    updateAndOr(type, index, value) {
      const q = this.value[type];
      if (value) {
        // update existing
        q[index] = value;
      } else {
        // add new
        const basis = JSON.parse(JSON.stringify(this.searchQueryBasis));
          q.splice(index+1, 0, {
          ...basis,
          subject: this.value.subject,
          column: this.value.column
        });
      }
      this.$emit('input', { ...this.value, [type]: q });
    },
    removeAndOr(type, index) {
      const q = this.value[type];
      q.splice(index, 1);
      this.$emit('input', { ...this.value, [type]: q });
    },
  },
  mounted() {
    if (this.value.subject && this.value.subject.length) {
      this.fetchColumns(this.value.subject);
    }
  }
}
</script>
