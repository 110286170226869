<template>
  <app-layout>
    <div class="flex flex-col items-center justify-center text-center min-h-screen">
      <h1 class="font-bold text-4xl mb-10">404 Not Found</h1>
      <img
        src="https://media3.giphy.com/media/Pxa2uH0kA3wqvxpuDv/giphy.gif"
        alt="Not Found"
      >
    </div>
  </app-layout>
</template>
<script>
export default {
  name: 'ErrorMissing'
}
</script>
