<template>
  <base-popover>
    <template v-slot:icon>
      <cog-icon class="w-5 h-5 text-gray-500" />
    </template>
    <div class="space-y-4">
      <base-input v-for="(type, idx) in componentConfigTypes" :key="`type-${idx}`" :label="type">
        <el-select :options="componentConfigOptions.filter(c => c.type == type)" :value="getTypeValue(type)" @input="setTypeValue($event, type)" />
      </base-input>
    </div>
  </base-popover>
</template>
<script>
import {
  CogIcon,
} from '@vue-hero-icons/solid';
import Form from '../Form'
import { BasePopover } from '../Base';
export default {
  components: {
    CogIcon,
    BasePopover,
    ...Form,
  },
  props: {
    value: null,
    componentConfigs: {
      type: [Array],
      default: () => [],
    },
    componentConfigTypes: {
      type: [Array],
      default: () => [],
    },
  },
  computed: {
    componentConfig() {
      if (typeof this.value === 'string') {
        return JSON.parse(this.value);
      } else if (this.value === null) {
        return [];
      } else {
        return this.value;
      }
    },
    componentConfigOptions() {
      return this.componentConfigs.map(c => {return {label: `${c.name} (${c.css_classes})`, value: c.css_classes, type: c.type}})
    },
  },
  methods: {
    getTypeValue(type) {
      if (Array.isArray(this.componentConfig)) {
        return this.componentConfigs.filter(c => c.type == type).find(c => this.componentConfig.filter(v => v == c.css_classes).length > 0)?.css_classes || '';
      } else {
        return '';
      }
    },
    setTypeValue(evt, type) {
      if (Array.isArray(this.componentConfig) && this.componentConfig.length) {
        let newVal = this.componentConfig.filter(v => v != this.getTypeValue(type));
        newVal.push(evt);
        this.$emit('input', newVal.filter(v => v.length));
      } else {
        this.$emit('input', [evt].filter(v => v.length));
      }
    }
  }
}
</script>
