<template>
  <td :class="['px-6 py-4 whitespace-nowrap text-sm text-gray-700', overflow ? '' : 'overflow-hidden overflow-ellipsis']">
    <slot />
  </td>
</template>
<script>
export default {
  name: 'TableCell',
  props: {
    overflow: {
      type: Boolean,
      default: false
    }
  }
}
</script>
