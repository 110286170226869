var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',{class:[
  'border border-solid rounded-md p-1',
  _vm.disabled ? 'opacity-50 pointer-events-none' : '',
  _vm.bgColor,
  _vm.textColor,
  _vm.borderColor,
],attrs:{"type":"button"},on:{"click":function($event){return _vm.$emit('click')}}},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }