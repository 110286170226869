<template>
  <div @mouseover="show" @mouseout="hide" :ref="`popper-${uuid}`">
    <div class="tooltip absolute bg-gray-800 font-semibold text-sm tracking-wide shadow-xl rounded-md text-white px-2 py-1 transition-opacity opacity-0" role="tooltip" :ref="`popper-${uuid}-tooltip`">
      <slot name="content" />
      <div class="tooltip-arrow" data-popper-arrow></div>
    </div>
    <slot />
  </div>
</template>
<script>
import { createPopper } from '@popperjs/core'
export default {
  name: 'BaseTooltip',
  props: {
    offsetY: {
      type: Number,
      default: 15
    },
    delay: {
      type: Number,
      default: 0 // seconds
    }
  },
  data() {
    return {
      instance: null,
      container: null,
      tooltip: null,
      timeout: null
    }
  },
  methods: {
    show() {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.tooltip.setAttribute('data-show', '');
        this.instance.update();
      }, (this.delay * 1000))
    },
    hide() {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.tooltip.removeAttribute('data-show');
    }
  },
  mounted() {
    this.container = this.$refs[`popper-${this.uuid}`];
    this.tooltip = this.$refs[`popper-${this.uuid}-tooltip`];
    this.instance = createPopper(this.container, this.tooltip, {
      placement: 'top',
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, this.offsetY],
          },
        },
      ],
    });
  }
}
</script>
<style>
.tooltip {
  z-index: 999999999;
}
.tooltip:not([data-show]) {
  pointer-events: none;
}
.tooltip[data-show] {
  opacity: 1 !important;
}
.tooltip-arrow,
.tooltip-arrow::before {
  position: absolute;
  width: 8px;
  height: 8px;
  background: inherit;
}

.tooltip-arrow {
  visibility: hidden;
}

.tooltip-arrow::before {
  visibility: visible;
  content: '';
  transform: rotate(45deg);
}
.tooltip[data-popper-placement^='top'] > .tooltip-arrow {
  bottom: -4px;
}

.tooltip[data-popper-placement^='bottom'] > .tooltip-arrow {
  top: -4px;
}

.tooltip[data-popper-placement^='left'] > .tooltip-arrow {
  right: -4px;
}

.tooltip[data-popper-placement^='right'] > .tooltip-arrow {
  left: -4px;
}
</style>
