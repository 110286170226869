<template>
  <div>
    <textarea v-model="data" :id="id" :name="id" :rows="rows" :readonly="readonly" :disabled="disabled" :class="['max-w-full shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md', `${disabled ? 'bg-gray-100 text-gray-700 opacity-80 cursor-not-allowed' : ''}`, textareaClass]" :maxlength="maxlength || charlimit || null"></textarea>
    <char-limit v-if="charlimit" :count="data.length" :limit="charlimit" />
  </div>
</template>
<script>
import { v4 as uuidv4 } from 'uuid';
import CharLimit from './CharLimit.vue';
export default {
  name: 'BaseTextArea',
  components: {
    CharLimit,
  },
  props: {
    value: {
      type: [String, Array, null],
      default: '',
    },
    id: {
      type: String,
      default: () => {
        return uuidv4();
      },
    },
    rows: {
      type: String,
      default: '4',
    },
    delimiter: {
      type: String,
      default: '',
    },
    charlimit: {
      type: [Number, String],
      default: '',
    },
    maxlength: {
      type: [Number, String],
      default: '',
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    textareaClass: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    data: {
      get() {
        if (this.delimiter.length > 0) {
          let val = this.value;
          if (!Array.isArray(val)) {
            val = JSON.parse(val);
          }
          return val.join(this.delimiter);
        } else {
          return this.value;
        }
      },
      set(val) {
        if (this.delimiter.length > 0) {
          this.$emit('input', val.split(this.delimiter));
        } else {
          this.$emit('input', val);
        }
      },
    },
  },
  mounted() {
    if (this.value == null) {
      this.$emit('input', '');
    }
  },
};
</script>
