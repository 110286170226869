<template>
  <app-layout>
    <base-card>
      <search-bar />
      <calendar v-bind="$props" class="lg:min-h-[768px]" />
    </base-card>
  </app-layout>
</template>
<script>
import Calendar from './components/Calendar'
import SearchBar from './components/SearchBar'
export default {
  name: 'BroadcastCalendarIndex',
  components: {
    Calendar,
    SearchBar
  },
  props: {
    cal: {
      type: Object,
      default: () => {
        return {
          year: 0,
          month: 0,
          notes: ''
        }
      }
    },
    days: {
      type: Array,
      default: () => []
    },
    year: Number,
    month: Number,
    day: Number
  },
}
</script>
