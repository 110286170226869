<template>
  <app-layout>
    <div class="block lg:flex">
      <!-- CONTENT -->
      <div class="w-full lg:mr-6 lg:w-3/5 lg:flex-1">
        <base-card class="space-y-4 mb-10">
          <base-input
            v-model="mod.name"
            type="text"
            label="Name"
            placeholder=""
            required
            @change="setModel()"
          />

          <div id="cms-mod-model">
            <base-input
              v-model="mod.model"
              type="text"
              label="Eloquent Model"
              tooltip="This is used for integration. Once it is established<br>on module creation, it cannot be changed."
              placeholder=""
              :disabled="!!mod.id"
            />
          </div>

          <base-input
            label="Publishable"
            tooltip="Toggle this on if you want to be able to<br>publish/unpublish/schedule items in this module"
          >
            <base-switch v-model="mod.publishable" />
          </base-input>

          <base-input label="Sort By">
            <el-select v-model="mod.sort_by" :options="sortByOptions" />
          </base-input>

          <base-input label="Sort Direction">
            <el-select
              v-model="mod.sort_direction"
              :options="sortDirectionOptions"
            />
          </base-input>

          <base-input label="Parent">
            <el-select
              v-model="mod.parent"
              class="select-primary"
              placeholder="Select..."
              :options="mods.map(md => { return { label: md.name, value: md.id } })"
            />
          </base-input>

          <base-input label="Sister">
            <el-select
              v-model="mod.sister"
              class="select-primary"
              placeholder="Select..."
              :options="mods.map(md => { return { label: md.name, value: md.id } })"
            />
          </base-input>

          <base-input label="Extension Of" tooltip="Adds Extensionable trait and EXTENSION_MODEL constant to model file. <br>You (the developer) will need to add extension to $with variable in model file. <br>You will also need to add extension_id dynamic dropdown to elements. <br>This can be labeled whatever you want. <br>The column <u>needs</u> to be extension_id.">
            <el-select
              v-model="mod.extension_of"
              class="select-primary"
              placeholder="Select..."
              :options="mods.map(md => { return { label: md.name, value: md.id } })"
            />
          </base-input>

          <base-input label="Template">
            <el-cascade v-model="mod.template" :options="templates" />
          </base-input>

          <base-input
            v-model="mod.preview_controller_method"
            type="text"
            label="Preview Controller Method"
            placeholder="(optional)"
            class="mb-4"
          />

          <base-input
            label="Preview Enabled"
          >
            <base-switch v-model="mod.preview_enabled" />
          </base-input>

        </base-card>

        <base-heading>
          Elements
          <!-- <template v-slot:tooltip>Drag &amp; drop elements </template> -->
        </base-heading>
        <div class="relative">
          <draggable
            v-model="mod.items"
            :class="[
              'w-full rounded-md bg-gray-200 p-8',
              mod.items.length === 0
                ? 'pb-60 border-dashed border-4 border-gray-300'
                : '',
            ]"
            tag="div"
            :group="{ name: 'items', pull: 'items', put: 'elements' }"
            handle=".element-handle"
            ghost-class="list-none"
            @start="drag = true"
            @end="drag = false"
            @add="addItem"
            @sort="orderItem"
          >
            <bldr-component-element
              v-for="(item, index) in mod.items"
              v-model="mod.items[index]"
              :key="`moduleElement${index}`"
              :index="index"
              :upload-configs="uploadConfigs"
              :mods="mods"
              @removeElement="removeItem(index)"
              @input="checkElement(index)"
            />
          </draggable>
          <p
            v-if="mod.items && mod.items.length === 0"
            class="
              text-center text-2xl
              font-bold
              text-gray-300
              pointer-events-none
              absolute
              top-1/2
              left-1/2
              transform
              -translate-x-1/2 -translate-y-1/2
            "
          >
            Drag &amp; Drop Components
          </p>
        </div>
      </div>

      <!-- SIDEBAR -->
      <div class="w-full relative mt-4 lg:w-2/5 lg:mt-0 flex-none">
        <div
          class="
            lg:flex
            lg:flex-col
            lg:absolute
            lg:top-0
            lg:left-0
            lg:w-full
            lg:h-full
          "
        >
          <base-heading class="lg:flex-none">
            <span>Elements &amp; Components</span>
            <template v-slot:tooltip
              >Drag elements from right to left to<br />add them to the module.
              Components can only<br />be added to the "components"
              element</template
            >
          </base-heading>
          <stack-list class="mb-4 lg:sticky top-0 z-20 max-h-screen" max-h>
            <stack-list-item :start-open="true" max-h>
              <span>Elements</span>
              <template v-slot:expand>
                <tabs-container :tabs="elementCategories" class="max-h-full">
                  <template v-slot:default="slotProps">
                    <tabs-content
                      v-for="(cat, idx) in elementCategories"
                      :key="`element-tab-${cat}`"
                      :tab="cat"
                      v-bind="slotProps"
                      class="bg-gray-200 p-6"
                    >
                      <draggable
                        tag="ul"
                        :clone="clone"
                        :group="{ name: 'elements', pull: 'clone', put: false }"
                        :sort="false"
                        :value="elements.filter((el) => el.category === cat)"
                        @start="drag = true"
                        @end="drag = false"
                      >
                        <li
                          v-for="(el, idxE) in elements.filter(
                            (el) => el.category === cat
                          )"
                          :key="`element-tab-${cat}-el-${idxE}`"
                        >
                          <base-card class="cursor-move mb-6">
                            <base-input :label="el.name">
                              <div
                                class="pointer-events-none"
                                v-if="Array.isArray(el.markup)"
                              >
                                <div
                                  v-for="(mk, idxM) in el.markup"
                                  :key="`elmk.${idxM}`"
                                >
                                  <div
                                    v-if="
                                      typeof mk === 'object' &&
                                      mk.is === 'draggable'
                                    "
                                  ></div>
                                  <component
                                    :is="mk.is"
                                    v-else-if="typeof mk === 'object'"
                                    v-bind="mk"
                                  >
                                    <component
                                      :is="mk.child.is"
                                      v-if="typeof mk.child === 'object'"
                                      v-bind="mk.child"
                                    ></component>
                                    <div
                                      v-else-if="typeof mk.child === 'string'"
                                      v-html="mk.child"
                                    ></div>
                                  </component>
                                  <div v-else v-html="mk"></div>
                                </div>
                              </div>
                              <div v-else v-html="el.markup"></div>
                            </base-input>
                          </base-card>
                        </li>
                      </draggable>
                    </tabs-content>
                  </template>
                </tabs-container>
              </template>
            </stack-list-item>
            <stack-list-item max-h>
              <span>Components</span>
              <template v-slot:expand>
                <div class="flex-grow-0 flex items-center">
                  <div class="w-full md:w-5/12">
                    <base-input
                      v-model="componentQuery"
                      type="text"
                      label="Search"
                    />
                  </div>
                  <div class="w-full md:w-2/12">
                    <p class="text-center">
                      <small
                        ><strong><br />- OR -</strong></small
                      >
                    </p>
                  </div>
                  <div class="w-full md:w-5/12">
                    <base-input label="Category">
                      <el-select v-model="componentCategorySelected">
                        <option value="">Select...</option>
                        <option
                          v-for="cat in componentCategories"
                          :key="`componentCategory${cat.name}`"
                          :value="cat.id"
                        >
                          {{ cat.name }}
                        </option>
                      </el-select>
                    </base-input>
                  </div>
                </div>
                <base-input
                  v-if="componentsQueried && componentsQueried.length"
                  label="Results"
                  class="flex-grow-1 mt-5"
                >
                  <div class="componentsList">
                    <draggable
                      v-model="componentsQueried"
                      class="w-full"
                      tag="ul"
                      :clone="clone"
                      :group="{ name: 'components', pull: 'clone', put: false }"
                      :sort="false"
                      data-listname="components"
                      @start="drag = true"
                      @end="drag = false"
                    >
                      <base-card
                        v-if="mod.items && mod.items.length > 0"
                        v-for="(component, index) in componentsQueried"
                        :key="`designComponent${index}-${component.id}`"
                        class="
                          bg-white
                          relative
                          rounded-md
                          shadow-md
                          overflow-hidden
                          w-full
                          mb-2
                          cursor-move
                        "
                      >
                        <div class="component flex items-center">
                          <img
                            v-if="
                              component.image &&
                              component.image.files &&
                              component.image.files.length
                            "
                            class="
                              component-inner
                              hidden
                              flex-grow-0 flex-shrink-0
                              w-10
                              mr-4
                              md:inline-block
                            "
                            :src="
                              typeof component.image.files[0] === 'string'
                                ? component.image.files[0]
                                : component.image.files[0].url
                            "
                          />
                          <div class="component-inner flex-grow-1 w-5/6 xl:w-11/12">
                            <base-label class="w-full">{{
                              component.name
                            }}</base-label>
                          </div>
                        </div>
                      </base-card>
                    </draggable>
                  </div>
                </base-input>
              </template>
            </stack-list-item>
          </stack-list>
        </div>
      </div>
    </div>

    <div class="mt-6">
      <base-button class="mr-4" @click="updateModule()">Save</base-button>
      <base-button
        v-if="mod.id && route().has('cms.modules.export')"
        tag="a"
        :href="route(`cms.modules.export`, { id: mod.id })"
        target="_blank"
        >Export</base-button
      >
    </div>
  </app-layout>
</template>
<script>
import Form from '../components/Form';
import Stack from '../components/Stack';
import Tabs from '../components/Tabs';
import draggable from 'vuedraggable';
import { EditPopover, BldrComponentElement } from '../components/Builder';
import {
  InformationCircleIcon,
  SwitchVerticalIcon,
  XIcon,
} from '@vue-hero-icons/solid';
export default {
  components: {
    ...Stack,
    ...Form,
    ...Tabs,
    draggable,
    BldrComponentElement,
    EditPopover,
    InformationCircleIcon,
    SwitchVerticalIcon,
    XIcon,
  },
  props: {
    mod: {
      type: Object,
      default: () => {
        return {
          name: '',
          model: '',
          parent: null,
          sister: null,
          template: 'New',
          elements: [],
          items: [],
        };
      },
    },
    componentCategories: {
      type: [Array],
      default: () => [],
    },
    components: {
      type: [Array],
      default: () => [],
    },
    mods: {
      type: [Array],
      default: () => [],
    },
    elements: {
      type: [Array],
      default: () => [],
    },
    uploadConfigs: {
      type: [Array],
      default: () => [],
    },
  },
  data() {
    return {
      componentCategorySelected: null,
      componentQuery: '',
      elementCategorySelected: null,
      elementQuery: '',
      checkElementTimeout: null,
      drag: false,
      templates: [
        {
          label: 'None',
          value: 'None',
        },
        {
          label: 'Create New Template File',
          value: 'NEW',
        },
      ],
      sortByOptions: [
        {
          label: 'Manual Sorting',
          value: 'sort_order',
        },
        {
          label: 'ID',
          value: 'id',
        },
        {
          label: 'Created Date/Time',
          value: 'created_at',
        },
        {
          label: 'Updated Date/Time',
          value: 'updated_at',
        },
      ],
      sortDirectionOptions: [
        {
          label: 'Ascending (A - Z)',
          value: 'asc',
        },
        {
          label: 'Descending (Z - A)',
          value: 'desc',
        },
      ],
    };
  },
  computed: {
    elementCategories() {
      return this.elements
        .map(function (el) {
          return el.category;
        })
        .filter(function (value, index, self) {
          return self.indexOf(value) === index;
        });
    },
    cms() {
      return this.$page.props.cms;
    },
    dragOptions() {
      return {
        animation: 200,
        group: 'components',
        disabled: false,
        ghostClass: 'ghost',
      };
    },
    componentsQueried() {
      if (this.componentQuery.length) {
        return this.components.filter((component) => {
          return (
            component.name
              .toLowerCase()
              .indexOf(this.componentQuery.toLowerCase()) >= 0
          );
        });
      } else if (this.componentCategorySelected !== null) {
        return this.components.filter((component) => {
          return component.category_id === this.componentCategorySelected;
        });
      } else {
        return [];
      }
    },
    elementsQueried() {
      if (this.elementQuery.length) {
        return this.elements.filter((element) => {
          return (
            element.name
              .toLowerCase()
              .indexOf(this.elementQuery.toLowerCase()) >= 0
          );
        });
      } else if (this.elementCategorySelected !== null) {
        return this.elements.filter((element) => {
          return (
            element.category.toUpperCase() ===
            this.elementCategorySelected.toUpperCase()
          );
        });
      } else {
        return [];
      }
    },
  },

  methods: {
    clone: function (original) {
      var element = {};
      for (var key in original) {
        if (original.hasOwnProperty(key)) {
          element[key] = JSON.parse(JSON.stringify(original[key]));
        }
      }
      return element;
    },
    getProp(element, property) {
      const filter = element.properties.filter((prop) => {
        return prop.property === property;
      });
      return filter.length ? filter[0].value : '';
    },
    setModel() {
      if (!this.mod.model) {
        this.mod.model = 'App\\' + this.cleanModel(this.mod.name);
      }
    },
    cleanModel(val) {
      return val
        .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
          return word.toUpperCase();
        })
        .replace(/[^(A-Z)(a-z)(\\)]+/g, '');
    },
    addItem(evt) {
      // if (evt.from.dataset.listname === 'components') {
      //   console.log(evt);
      //   return false;
      // }
    },
    addOption(evt) {
      if (evt.from.dataset.listname === 'elements') {
        console.log(evt);
        return false;
      }
    },
    orderItem() {
      this.mod.items.forEach((el, idx) => {
        if (el.pivot && el.pivot.sort_order) {
          el.pivot.sort_order = idx;
        }
      });
    },
    removeItem(idx) {
      this.mod.items.splice(idx, 1);
    },
    removeItemOption(idx, idxO) {
      this.mod.items[idx].pivot.options.splice(idxO, 1);
    },
    updateModule() {
      if (this.mod.id) {
        this.$root.$CMS_UPDATE('modules', this.mod.id, { mod: this.mod });
      } else {
        this.$root
          .$CMS_CREATE('modules', {}, { mod: this.mod })
          .then((data) => {
            if (data.module.id) {
              window.location.href = route('cms.modules.show', data.module.id);
            }
          });
      }
    },
    checkElement(index) {
      const el = this.mod.items[index];
      console.log(el);
      clearInterval(this.checkElementTimeout);
      this.checkElementTimeout = setTimeout(() => {
        if (this.mod.publishable) {
          if (
            el.pivot &&
            (el.pivot.column === 'publish' ||
              el.pivot.column === 'published_at')
          ) {
            this.$root.$CMS_ALERT({
              title: 'Column Name Not Allowed',
              text: `The column name '${el.pivot.column}' is system reserved`,
              type: 'warning',
            });
          }
        }
      }, 1500);
    },
  },
  mounted() {
    this.$root.$CMS_GET('templates', { dir: 'modules' }).then((data) => {
      this.templates = this.templates.concat(data);
    });
    if (this.mod.publishable) {
      this.sortByOptions.push({
        label: 'Published Date/Time',
        value: 'published_at',
      });
    }
    this.mod.items.forEach((item) => {
      if (item.datatype !== 'json') {
        this.sortByOptions.push({
          label: item.pivot.label,
          value: item.pivot.column,
        });
      }
    });
  },
};
</script>
