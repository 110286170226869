<template>
  <app-layout>
    <div class="flex flex-col items-center justify-center text-center min-h-screen">
      <h1 class="font-bold text-4xl mb-10">Access Denied</h1>

      <img
        src="https://media2.giphy.com/media/uIGfoVAK9iU1y/giphy.gif"
        alt="Forbidden"
      >

      <audio src="/vendor/cms/images/ahahah.mp3" autoplay loop></audio>

    </div>
  </app-layout>
</template>
<script>
export default {
  name: 'ErrorUnauthorized'
}
</script>
