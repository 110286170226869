<template>
  <div>
    <div class="flex flex-col gap-4 px-8 xl:flex-row xl:gap-0 xl:justify-between xl:items-center">
      <base-heading no-margin>Activity</base-heading>
      <nav class="flex space-x-4" aria-label="Tabs">
        <!-- Current: "bg-gray-100 text-gray-700", Default: "text-gray-500 hover:text-gray-700" -->
        <button
          type="button"
          v-for="tab in tabs" :key="`tab-${tab.label}`"
          @click="selectedTab = tab.action"
          :class="['rounded-md px-3 py-2 text-sm font-medium', selectedTab == tab.action ? 'bg-gray-900 text-gray-100' : 'text-gray-500 hover:text-gray-700']"
        >{{ tab.label }}</button>
      </nav>
    </div>
    <section v-for="tab in tabs" :key="`tab-content-${tab.label}`" v-show="selectedTab == tab.action" class="my-3 pt-2 pb-10 px-8 space-y-6 max-h-[calc(100vh-300px)] overflow-auto">
      <template v-if="activeActivity.length">
        <base-card size="sm" v-for="(act, idx) in activeActivity.filter(a => a.subject)" :key="`log-${idx}`">
          <inertia-link :href="route('cms.dam.assets.show', act.subject.id)" class="flex flex-col gap-4 items-start justify-start lg:flex-row">
            <img v-if="act.subject.thumbnail" :src="act.subject.thumbnail" class="w-20 h-20 object-cover flex-none" />
            <div v-else class="w-20 h-20 bg-gray-200 relative">
              <svg class="absolute inset-0 m-auto w-1/2 h-1/2 opacity-60"><use :href="`#filetype-${fileIcon(act.subject.type)}`" /></svg>
            </div>
            <div class="space-y-1">
              <p class="font-medium text-base">{{ act.subject.name }}</p>
              <p class="text-gray-500"><span class="capitalize">{{ act.event }}</span> {{ ago(act.created_at) }}</p>
              <p class="text-gray-500" v-if="act.causer">By {{ act.causer.first_name }} {{ act.causer.last_name }}</p>
            </div>
          </inertia-link>
        </base-card>
      </template>
      <p v-else>Nothing logged.</p>
    </section>
  </div>
</template>
<script>
import mime from 'mime'
export default {
  props: {
    activity: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      selectedTab: 'created',
      tabs: [
        {
          label: 'New',
          action: 'created'
        },
        {
          label: 'Updated',
          action: 'updated'
        },
        {
          label: 'Shared',
          action: 'shared'
        }
      ],
    }
  },
  computed: {
    activeActivity() {
      return this.activity.filter(act => act.event == this.selectedTab);
    }
  },
  methods: {
    ago (date) {
      return this.moment(date).fromNow()
    },
    fileIcon(type) {
      return mime.getExtension(type)
    }
  }
}
</script>
