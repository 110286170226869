<template>
  <fieldset class="flex items-end align-middle" v-if="link && typeof link === 'object' && typeof link.url === 'string'">
    <base-input label="Text" label-size="text-xs" label-color="text-gray-600" v-model="link.text" class="mr-2" />
    <base-input label="URL"  label-size="text-xs" label-color="text-gray-600" v-model="link.url" />
    <base-popover>
      <template v-slot:icon>
        <dots-vertical-icon class="w-5 h-5 ml-3 mb-2" />
      </template>
      <div>
        <base-label>Target</base-label>
        <el-select v-model="link.target" :options="targetOptions" />
      </div>
      <base-input type="textarea" label="Click Event" v-model="link.onclick" />
      <base-input type="textarea" label="ID" v-model="link.id" />
      <base-input type="textarea" label="Class" v-model="link.class" />
      <div class="space-y-3">
        <base-label class="mb-0">Other Attributes</base-label>
        <div v-if="Array.isArray(link.attrs) && link.attrs.length" class="space-y-2">
          <div v-for="(attr, idx) in link.attrs" :key="`otherAttr-${idx}`" class="flex items-end space-x-2">
            <base-input label="Attribute" label-size="text-xs" label-color="text-gray-600" v-model="link.attrs[idx].attribute" />
            <base-input label="Value" label-size="text-xs" label-color="text-gray-600" v-model="link.attrs[idx].value" />
            <base-button small type="danger" @click="removeAttr(idx)">
              <x-icon class="w-4 h-4 text-white" />
            </base-button>
          </div>
        </div>
        <base-button small @click="addAttr">Add Attribute</base-button>
      </div>
    </base-popover>
  </fieldset>
</template>
<script>

import { DotsVerticalIcon, XIcon } from '@vue-hero-icons/solid'
import BaseInput from './BaseInput'
import BaseLabel from './BaseLabel'
import ElSelect from './ElSelect'
export default {
  name: 'BaseLink',
  components: {
    DotsVerticalIcon,
    BaseInput,
    BaseLabel,
    ElSelect,
    XIcon
  },
  props: {
    value: {
      type: [Object, String, Array],
      default: () => []
    }
  },
  computed: {
    link: {
      get() {return this.value},
      set(val) { this.$emit('input', val)}
    }
  },
  methods: {
    addAttr() {
      const link = this.link;
      link.attrs.push({
        attribute: '',
        value: ''
      })
      this.$emit('input', link)
    },
    removeAttr(idx) {
      const link = this.link;
      link.attrs.splice(idx, 1);
      this.$emit('input', link)
    }
  },
  data() {
    return {
      ready: false,
      targetOptions: [
        {
          label: 'New Window/Tab',
          value: '_blank'
        },
        {
          label: 'Self',
          value: '_self'
        },
        {
          label: 'Parent',
          value: '_parent'
        }
      ]
    }
  },
  mounted() {
    if (!this.value || Array.isArray(this.value) || (this.value && typeof this.value.url !== 'string')) {
      this.$emit('input', { text: '', url: '', target: '', onclick: '', class: '', id: '', attrs: [] });
    }
    // Add class attr
    if (this.value && typeof this.value.class === 'undefined') {
      this.value.class = '';
    }
    // Add id attr
    if (this.value && typeof this.value.id === 'undefined') {
      this.value.id = '';
    }
  }
}
</script>
