<template>
  <app-layout>
    <FormNav />
    <base-card class="space-y-4 mb-10 relative">
      <base-input label="Name" v-model="form.name" required />
      <base-input label="Heading" v-model="form.heading" />
      <base-input label="Submit Button Text" v-model="form.button_text" />
      <base-input
        label="Recipients (Comma Separated)"
        type="textarea"
        v-model="form.recipients"
      />
      <base-input
        label="Thank You Message"
        type="textarea"
        v-model="form.thank_you_message"
      />
      <base-input label="Thank You Page URL" v-model="form.thank_you_url" />
      <base-input label="Save to Database">
        <base-switch v-model="form.save_to_db" />
      </base-input>
      <base-input v-if="form.save_to_db" label="Contact List">
        <el-select v-model="form.contact_list_id" :options="contactLists" />
      </base-input>
      <base-input label="Email Template">
        <el-select v-model="form.template" :options="templates" />
      </base-input>
      <base-input label="Auto Responder">
        <base-switch v-model="form.auto_responder" />
      </base-input>
      <base-input
        v-if="form.auto_responder"
        label="Auto Responder Content"
        type="textarea"
        v-model="form.auto_responder_content"
      />
      <base-input
        v-if="form.auto_responder"
        label="Auto Responder Template"
        v-model="form.auto_responder_template"
      />

      <div>
        <base-label class="mb-2">Fields</base-label>
        <draggable
          v-model="form.fields"
          v-bind="fieldsDragOptions"
          :class="[
            'w-full rounded-md bg-gray-200 p-8 flex flex-wrap gap-4',
            form.fields && form.fields.length === 0
              ? 'pb-60 border-dashed border-4 border-gray-300'
              : '',
          ]"
        >
          <li
            v-for="(field, idxF) in form.fields"
            :key="`field-${idxF}`"
            :class="[
              'bg-white p-4 shadow-md list-none flex items-center',
              field.size === 'half'
                ? 'max-w-[50%] min-w-[40%] w-1/2 flex-1'
                : 'min-w-full flex-none',
            ]"
          >
            <div class="flex-grow-0 mr-5 cursor-move form-builder-field-handle">
              <switch-vertical-icon class="w-5 h-5" />
            </div>
            <div class="flex-auto">
              {{ field.label }} {{ field.is_required ? '*' : '' }} ({{
                fieldTypeName(field.form_field_type_id)
              }})
            </div>
            <div class="flex-grow-0 flex w-12 space-x-2 items-center">
              <base-popover>
                <template v-slot:icon>
                  <span class="sr-only">Open Properties</span>
                  <pencil-alt-icon class="w-5 h-5" />
                </template>
                <div class="space-y-3 pb-3">
                  <base-input
                    label-size="text-sm"
                    label="Label"
                    v-model="form.fields[idxF].label"
                  />
                  <base-input label-size="text-sm" label="Required">
                    <base-switch v-model="form.fields[idxF].is_required" />
                  </base-input>
                  <base-input
                    label-size="text-sm"
                    label="Column (Submissions Table)"
                  >
                    <base-switch v-model="form.fields[idxF].is_column" />
                  </base-input>
                  <base-input label-size="text-sm" label="Width">
                    <el-select v-model="form.fields[idxF].size">
                      <option value="">Full</option>
                      <option value="half">Half</option>
                    </el-select>
                  </base-input>

                  <div
                    v-for="(key, idxP) in Object.keys(field.properties)"
                    :key="`field-${idxF}-prop-${idxP}`"
                  >
                    <base-input
                      v-if="key === 'input_type'"
                      label-size="text-sm"
                      label="Input Data Type"
                    >
                      <el-select
                        v-model="form.fields[idxF].properties.input_type"
                      >
                        <option value="text">Text</option>
                        <option value="number">Number</option>
                        <option value="email">Email Address</option>
                        <option value="tel">Phone Number</option>
                        <option value="url">URL</option>
                        <option value="date">Date</option>
                        <option value="datetime-local">Date and Time</option>
                        <option value="time">Time</option>
                      </el-select>
                    </base-input>
                    <base-input
                      v-if="key === 'hidden_type'"
                      label-size="text-sm"
                      label="Hidden Data"
                    >
                      <el-select
                        v-model="form.fields[idxF].properties.hidden_type"
                      >
                        <option value="static">Static</option>
                        <option value="dynamic">Dynamic</option>
                      </el-select>
                      <base-input
                        v-if="
                          form.fields[idxF].properties.hidden_type === 'static'
                        "
                        v-model="form.fields[idxF].properties.hidden_value"
                        placeholder="Static Value"
                        class="mt-2"
                      />
                    </base-input>
                    <div v-else-if="key === 'options'">
                      <base-label>Options</base-label>
                      <draggable
                        v-model="form.fields[idxF].properties.options"
                        v-bind="fieldOptionsDragOptions"
                        class="bg-gray-200 p-2"
                      >
                        <stack-list-item
                          v-for="(opt, idxO) in form.fields[idxF].properties
                            .options"
                          :key="`field-${idxF}-prop-${idxP}-opt-${idxO}`"
                        >
                          <div class="flex w-11/12 space-x-2 overflow-hidden">
                            <div
                              class="form-builder-field-option-handle flex-grow-0 cursor-move"
                            >
                              <switch-vertical-icon class="w-4 h-4" />
                            </div>
                            <div class="flex-auto w-3/4 truncate">
                              {{
                                form.fields[idxF].properties.options[idxO]
                                  .label ||
                                form.fields[idxF].properties.options[idxO]
                                  .value ||
                                '- Empty Option -'
                              }}
                            </div>
                            <button
                              v-if="
                                form.fields[idxF].properties.options.length > 1
                              "
                              @click="removeFieldOption(idxF, idxO)"
                              class="flex-grow-0"
                            >
                              <x-icon class="w-4 h-4 text-red-500" />
                            </button>
                          </div>
                          <template v-slot:expand>
                            <div class="space-y-2">
                              <base-input
                                v-if="!form.fields[idxF].properties.hidden_type"
                                v-model="
                                  form.fields[idxF].properties.options[idxO]
                                    .label
                                "
                                label-size="text-xs"
                                label="Label*"
                              />
                              <base-input
                                v-model="
                                  form.fields[idxF].properties.options[idxO]
                                    .value
                                "
                                label-size="text-xs"
                                label="Value"
                              />
                              <base-input
                                v-model="
                                  form.fields[idxF].properties.options[idxO]
                                    .recipients
                                "
                                type="textarea"
                                label-size="text-xs"
                                label="Unique Recipients (Comma separated)"
                              />
                            </div>
                          </template>
                        </stack-list-item>
                      </draggable>
                      <base-button
                        small
                        class="mt-3"
                        @click="addFieldOption(idxF)"
                        >Add Option</base-button
                      >
                    </div>
                    <base-input
                      v-else-if="key === 'file_types'"
                      v-model="form.fields[idxF].properties.file_types"
                      label-size="text-sm"
                      label="File Types (comma separated)"
                    />
                    <base-input
                      v-else-if="key === 'file_size_limit'"
                      v-model="form.fields[idxF].properties.file_size_limit"
                      label-size="text-sm"
                      label="File Size Limit"
                    />
                  </div>
                </div>
              </base-popover>
              <base-button icon @click="removeField(idxF)">
                <x-icon class="w-5 h-5 text-red-500" />
              </base-button>
            </div>
          </li>
        </draggable>
        <base-button @click="addField" class="mt-2">Add a Field</base-button>
      </div>

      <div class="flex justify-start items-start gap-4">
        <base-button @click="saveForm"> Save </base-button>
        <template v-if="form.save_to_db">
          <base-button
            key="formUnsubscribe"
            type="danger"
            v-if="
              form.subscriptions &&
              form.subscriptions.length &&
              form.subscriptions.filter(
                (sub) => sub.user_id == this.$page.props.user.id
              ).length > 0
            "
            @click="unsubscribe()"
          >
            Unsubscribe
          </base-button>
          <base-button
            key="formSubscribe"
            type="secondary"
            v-else
            @click="subscribe()"
            >Subscribe</base-button
          >
        </template>
      </div>
    </base-card>
  </app-layout>
</template>
<script>
import FormNav from './Partials/FormNav';
import Form from '../components/Form';
import Stack from '../components/Stack';
import {
  XIcon,
  PencilAltIcon,
  SwitchVerticalIcon,
} from '@vue-hero-icons/solid';
import Table from '../components/Table';
import draggable from 'vuedraggable';
export default {
  name: 'FormEdit',
  components: {
    ...Form,
    ...Table,
    ...Stack,
    FormNav,
    XIcon,
    PencilAltIcon,
    SwitchVerticalIcon,
    draggable,
  },
  props: {
    fieldTypes: {
      type: Array,
      default: () => [],
    },
    form: {
      type: Object,
      default: () => {
        return {
          name: '',
          heading: '',
          button_text: 'Submit',
          recipients: '',
          thank_you_message: '',
          thank_you_url: '',
          contact_list_id: 1,
          auto_responder: false,
          auto_responder_content: '',
          auto_responder_template: 'default',
          save_to_db: true,
          template: '',
          fields: [],
          submissions: [],
          subscriptions: [],
        };
      },
    },
    contactLists: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      templates: [
        {
          label: 'Default Template',
          value: 'auto',
        },
      ],
      fieldsDragOptions: {
        group: {
          name: 'fields',
          pull: true,
          put: true,
        },
        handle: '.form-builder-field-handle',
        sort: true,
      },
      fieldOptionsDragOptions: {
        group: {
          name: 'fieldOptions',
          pull: false,
          put: false,
        },
        handle: '.form-builder-field-option-handle',
        sort: true,
      },
    };
  },
  mounted() {
    this.$root.$CMS_GET('templates', { dir: 'emails' }).then((data) => {
      this.templates = this.templates.concat(data);
      if (!this.form.template) {
        this.form.template = this.templates[0];
      }
    });
    document.addEventListener('keyup', this.$root.protectEvent);
    this.form.fields.forEach((field, idx) => {
      if (Array.isArray(field.properties)) {
        this.form.fields[idx].properties = {};
      }
      if (
        field.form_field_type_id === 1 &&
        typeof field.properties.input_type === 'undefined'
      ) {
        this.form.fields[idx].properties.input_type = 'text';
      }
    });
  },
  beforeDestroy() {
    document.removeEventListener('keyup', this.$root.protectEvent);
  },
  computed: {
    isSubscribed() {
      return (
        this.form.subscriptions &&
        this.form.subscriptions.length &&
        this.form.subscriptions.filter(
          (sub) => sub.user_id == this.$page.props.user.id
        ).length > 0
      );
    },
  },
  methods: {
    addField() {
      const form = this.form;
      const newField = {
        label: 'New Field',
        form_field_type_id: 0,
        is_required: false,
        is_column: false,
        size: '',
        properties: {},
      };
      const fieldTypes = new Promise((resolve) => {
        const types = {};
        this.fieldTypes.forEach((t) => {
          types[t.id] = t.name;
        });
        setTimeout(() => {
          resolve(types);
        }, 200);
      });
      this.$swal
        .fire({
          title: 'Select Field Type',
          customClass: {
            input: 'flex-wrap gap-2 space-x-0',
          },
          input: 'radio',
          inputOptions: fieldTypes,
          inputValidator: (value) => {
            if (!value) {
              return 'You must select a type';
            }
          },
        })
        .then((result) => {
          if (result.value) {
            const typeId = parseInt(result.value);
            newField.form_field_type_id = typeId;
            switch (typeId) {
              case 1:
                newField.properties.input_type = 'text';
                break;
              case 8:
                newField.properties.hidden_type = 'static';
                newField.properties.hidden_value = '';
              case 3:
              case 4:
              case 5:
                newField.properties.options = [
                  {
                    label: '',
                    value: '',
                    recipients: '',
                  },
                ];
                break;
              case 6:
              case 7:
                newField.properties.file_types = '';
                newField.properties.file_size_limit = '';
                break;
              default:
            }
            form.fields.push(newField);
          }
        });
    },
    addFieldOption(idxF) {
      const field = this.form.fields[idxF];
      const optionBasis = JSON.parse(
        JSON.stringify(field.properties.options[0])
      );
      Object.keys(optionBasis).forEach((key) => {
        optionBasis[key] = '';
      });
      field.properties.options.push(optionBasis);
    },
    removeField(idxF) {
      const field = this.form.fields[idxF];
      if (this.form.id && field.id) {
        this.$root
          .$CMS_DELETE('forms.field', { form: this.form.id, id: field.id })
          .then(() => {
            this.form.fields.splice(idxF, 1);
          });
      } else {
        // Not been saved yet
        this.form.fields.splice(idxF, 1);
      }
    },
    removeFieldOption(idxF, idxO) {
      this.form.fields[idxF].properties.options.splice(idxO, 1);
    },
    saveForm() {
      if (this.form.id) {
        this.$root.$CMS_UPDATE('forms', this.form.id, this.form).then(() => {
          this.$root.protected = false;
        });
      } else {
        this.$root.$CMS_CREATE('forms', {}, this.form).then((data) => {
          this.$root.protected = false;
          if (data && data.form && data.form.id) {
            window.location.href = route('cms.forms.show', data.form.id);
          }
        });
      }
    },
    fieldTypeName(id) {
      const type = this.fieldTypes.filter((t) => t.id === id);
      if (type.length) {
        return type[0].name || '';
      } else {
        return '';
      }
    },
    subscribe() {
      const _this = this;
      this.$swal
        .fire({
          title: 'Subscribe to form submissions',
          input: 'select',
          inputOptions: {
            daily: 'Daily',
            weekly: 'Weekly',
            monthly: 'Monthly',
          },
          inputPlaceholder: 'Select the frequency of digest emails',
          confirmButtonText: 'Subscribe',
          showCancelButton: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.$inertia.post(
              route('cms.forms.subscribe', { form: this.form.id }),
              {
                frequency: result.value,
              },
              {
                replace: true,
                preserveState: true,
                preserveScroll: true,
                only: ['form'],
                onSuccess() {
                  _this.$noty.success('Subscribed to form submissions!');
                },
                onError() {
                  _this.$noty.error(
                    'Could not subscribe to the form submissions.'
                  );
                },
              }
            );
          }
        });
    },
    unsubscribe() {
      const _this = this;
      this.$swal
        .fire({
          title: 'Are you sure you want to unsubscribe?',
          confirmButtonText: 'Unsubscribe',
          showCancelButton: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.$inertia.delete(
              route('cms.forms.unsubscribe', { form: this.form.id }),
              {
                frequency: result.value,
              },
              {
                replace: true,
                preserveState: true,
                preserveScroll: true,
                only: ['form'],
                onSuccess() {
                  _this.$noty.success('Unsubscribed from form submissions.');
                },
                onError() {
                  _this.$noty.error(
                    'Could not unsubscribe from the form submissions.'
                  );
                },
              }
            );
          }
        });
    },
  },
};
</script>
