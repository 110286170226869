<template>
  <div class="space-y-4">
    <base-heading>{{ heading }}</base-heading>
      <base-input label="To">

        <el-select :options="users" v-model="addingUser" @input="addUser" />
        <ul class="flex gap-6 items-center justify-start mt-2 text-sm ">
          <li v-for="(usr,idx) in to" :key="`to-${usr.value}-${idx}`" class="flex gap-2 items-center justify-center">
            <span>{{ usr.label }}</span>
            <button type="button" @click="removeTo(idx)"><x-icon class="w-3 h-3 text-red-500" /></button>
          </li>
        </ul>
      </base-input>
      <base-input label="Subject" v-model="subject" />
      <base-input label="Message">
        <wysiwyg v-model="message" />
      </base-input>
      <base-button @click="send">Send</base-button>
  </div>
</template>
<script>
import { XIcon } from '@vue-hero-icons/solid'
import Form from '../Form'
export default {
  components: {
    ...Form,
    XIcon,
  },
  props: {
    value: {
      type: Boolean,
      default: false, // for closing the form after sending
    },
    noteableType: {
      type: String,
      default: ''
    },
    noteableId: {
      type: Number,
      default: 0
    },
    parentId: {
      type: Number,
      default: 0
    },
    heading: {
      type: String,
      default: 'New Note'
    },
    preSubject: {
      type: String,
      default: ''
    },
    preTo: {
      type: Array,
      default: () => []
    },
  },
  methods: {
    addUser() {
      this.to.push(this.users.find(u => u.value == this.addingUser));
      this.$nextTick().then(() => {
        this.addingUser = null
      })
    },
    removeTo(idx) {
      this.to.splice(idx, 1);
    },
    send() {
      this.$CMS_POST('notes.store', {
        to: this.to,
        subject: this.subject,
        message: this.message,
        parent_id: this.parentId || null,
        noteable_id: this.noteableId || null,
        noteable_type: this.noteableType || null,
        target: this.noteableId ? this.$inertia.page.url : null,
      }).then(() => {
        this.$inertia.reload();
        this.$emit('input', false);
      })
    },
  },
  data() {
    return {
      users: [],
      to: [],
      addingUser: null,
      subject: '',
      message: '',
    }
  },
  mounted() {
    this.$CMS_GET('users.index').then(data => {
      this.users = data.items.map(i => {return {label: `${i.full_name} <${i.email}>`, value: i.id }});
    })
    if (this.preSubject.length) {
      this.subject = String(this.preSubject).toString() // copy it, don't link it
    }
    if (this.preTo.length) {
      this.to = [...this.preTo] // copy it, don't link it
    }
  }
}
</script>
