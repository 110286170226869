<template>
  <nav class="mt-5 flex-1 px-3 space-y-2">
    <div v-for="(item, idx) in nav" :key="`navItem-${idx}`">
      <app-nav-item :item="item" :idx="idx" />
    </div>
  </nav>
</template>
<script>
import AppNavItem from './AppNavItem';
export default {
  name: 'AppNav',
  components: {
    AppNavItem,
  },
  props: {
    nav: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
