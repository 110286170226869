<template>
  <app-layout>
    <FormNav />
    <base-card class="space-y-4 mb-10 relative">

      <p v-for="(field, idx) in form.fields" :key="idx">
        <strong>{{ field.label }}:</strong>
        <template v-if="submission.attachments.filter(a => a.form_field_id === field.id).length">
          <a
            v-for="(attachment,idxA) in submission.attachments.filter(a => a.form_field_id === field.id)"
            :key="`attachment-${field.id}-${idxA}`"
            :href="route('cms.forms.submissions.attachment', { form: form.id, submission: submission.id, attachment: attachment.id })"
            download
            target="_blank"
            class="inline-block mr-2"
          >Download {{ idxA > 0 ? idxA+1 : '' }}</a>
        </template>
        <span v-else>{{ submission.data[`formfield-${field.id}`] }}</span>
      </p>
      <p v-if="submission.ip_address"><strong>IP Address:</strong> {{ submission.ip_address }} &nbsp; <BanHammer :item="submission" /></p>
      <p v-if="submission.url"><strong>URL:</strong> {{ submission.url }}</p>
      <p v-if="submission.created_at"><strong>Submission Date:</strong> {{ moment(submission.created_at).local().format('YYYY-MM-DD HH:mm:ss') }}</p>

    </base-card>
  </app-layout>
</template>
<script>
import BanHammer from '../components/Misc/BanHammer'
import FormNav from './Partials/FormNav';
export default {
  name: 'FormSubmission',
  components: {
    FormNav,
    BanHammer
  },
  props: {
    form: {
      type: Object,
      default: () => {
        return {
          id: 0,
          name: '',
        };
      },
    },
    submission: {
      type: Object,
      default: () => {
        return {
          id: 0,
        };
      },
    },
  },
};
</script>
