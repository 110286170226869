<template>
  <nav v-if="mainMod && mainMod.children && mainMod.children.length > 0" class="relative z-0 rounded-lg shadow flex divide-x divide-gray-200 mb-6" aria-label="Tabs">
    <inertia-link :href="route(`cms.mod.show`, { module: mainMod.id, id: itemId })" :class="[`${route().current(`cms.mod.show`, { module: mainMod.id, id: itemId }) ? 'text-gray-900' : 'text-gray-500 hover:text-gray-700'}`, 'rounded-l-lg capitalize group relative min-w-0 flex-1 flex flex-col justify-center overflow-hidden bg-white py-4 px-4 text-xs font-medium text-center lg:text-sm hover:bg-gray-50 focus:z-10']" >
      {{ mainMod.name }}
      <span aria-hidden="true" :class="[ `${route().current(`cms.mod.show`, { module: mainMod.id, id: itemId }) ? 'bg-indigo-500' : 'bg-transparent'}`, 'absolute inset-x-0 bottom-0 h-0.5']"></span>
    </inertia-link>
    <inertia-link v-for="(child, idx) in mainMod.children" :key="`child${idx}`"
      :href="route(`cms.mod.child.index`, { module: mainMod.id, parent: itemId, child: child.id })"
      :class="[
        `${(
          route().current(`cms.mod.child.index`, { module: mainMod.id, parent: itemId, child: child.id }) ||
          route().current(`cms.mod.child.create`, { module: mainMod.id, parent: itemId, child: child.id }) ||
          route().current(`cms.mod.child.show`, { module: mainMod.id, parent: itemId, child: child.id })
        ) ? 'text-gray-900' : 'text-gray-500 hover:text-gray-700'}`,
        `${idx === mainMod.children.length-1 ? 'rounded-r-lg' : ''}`,
        'capitalize group relative min-w-0 flex-1 flex flex-col justify-center overflow-hidden bg-white py-4 px-4 text-xs font-medium text-center lg:text-sm hover:bg-gray-50 focus:z-10'
      ]"
    >
      {{ child.name }}
      <span aria-hidden="true"
        :class="[
          `${(
            route().current(`cms.mod.child.index`, { module: mainMod.id, parent: itemId, child: child.id }) ||
            route().current(`cms.mod.child.create`, { module: mainMod.id, parent: itemId, child: child.id }) ||
            route().current(`cms.mod.child.show`, { module: mainMod.id, parent: itemId, child: child.id })
          ) ? 'bg-indigo-500' : 'bg-transparent'}`,
          'absolute inset-x-0 bottom-0 h-0.5'
        ]
      "></span>
    </inertia-link>
  </nav>
</template>
<script>
export default {
  name: 'ModDetailTabs',
  props: {
    mod: {
      type: Object,
      default: () => {
        return {
          name: '',
          parent: null,
          children: []
        }
      }
    },
    itemId: {
      type: Number,
      default: 0
    }
  },
  computed: {
    mainMod() {
      return this.mod.parent || this.mod;
    }
  }
}
</script>
