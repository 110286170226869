<template>
  <app-layout>
    <mod-detail-tabs :mod="mod" :item-id="parent.item.id" />
    <base-listing  v-bind="$props" />
  </app-layout>
</template>
<script>
import ModDetailTabs from '../components/Misc/ModDetailTabs'
import BaseListing from '../components/Listing/BaseListing'

export default {
  name: 'ModChildListing',
  components: {
    ModDetailTabs,
    BaseListing
  },
  props: {
    mod: {
      type: Object,
      default: () => {
        return {
          name: '',
          model: '',
          parent: null,
          sister: null,
          template: 'New',
          publishable: false,
          elements: [],
          items: [],
        }
      }
    },
    parent: {
      type: Object,
      default: () => {
        return {
          mod: null,
          item: null
        }
      }
    },
    slug: {
      type: String,
      default: ''
    },
    items: {
      type: Array,
      default: () => {
        return []
      }
    },
    columns: {
      type: Array,
      default: () => {
        return []
      }
    },
    routeParams: {
      type: Object,
      default: () => {}
    },
    sisters: {
      type: Array,
      default: () => []
    },
    id: {
      type: Number,
      default: 0
    },

    trashable: {
      type: Boolean,
      default: false,
    },
    trashed: {
      type: Boolean,
      default: false,
    },
    sortable: {
      type: Boolean,
      default: false,
    },
    asyncItems: {
      type: Boolean,
      default: false
    }
  },
}
</script>
