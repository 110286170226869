<template>
  <div v-show="selected === tab">
    <slot />
  </div>
</template>
<script>
export default {
  name: 'TabsContent',
  props: {
    selected: {
      type: String,
      default: 'notselected'
    },
    tab: {
      type: String,
      default: 'tab'
    }
  }
}
</script>
