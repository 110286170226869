<template>
  <div class="relative" @mouseleave="highlight = null" v-click-outside="closeDropdown" :ref="`combobox-${id}-container`">
    <input ref="comboBoxQuery" @focus="openDropdown" :id="id" v-model="query" :placeholder="placeholderValue" type="text" class="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-12 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm" role="combobox" :aria-controls="`${id}-options`" aria-expanded="false">
    <button type="button" @click="toggleDropdown" class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none"  :ref="`combobox-${id}-button`">
      <selector-icon class="h-5 w-5 text-gray-400" />
    </button>
    <transition
      enter-active-class="transition-opacity ease-out duration-100"
      enter-from-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="transition-opacity ease-in duration-75"
      leave-from-class="opacity-100 "
      leave-to-class="opacity-0"
    >
      <ul v-show="open" class="fixed duration-75 will-change-transform z-50 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm" :aria-controls="`${id}-options`" role="listbox"  :ref="`combobox-${id}`">
        <!--
          Combobox option, manage highlight styles based on mouseenter/mouseleave and keyboard navigation.

          Active: "text-white bg-indigo-600", Not Active: "text-gray-900"
        -->
        <li
          :key="`${id}-option--1`"
          :class="[
            'relative cursor-default select-none py-2 pl-8 pr-4',
            highlight === -1 ? 'text-white bg-indigo-600' : 'text-gray-900'
          ]"
          role="option"
          tabindex="-1"
          @mouseenter="highlight = -1"
          @click="setValue(-1)"
        >
          <span :class="['block truncate', selectedIndex === -1 ? 'font-semibold' : '' ]">{{ placeholder || 'Please Select...' }}</span>
        </li>
        <template v-if="filteredIndexes.length">
          <li
            v-for="(opt, idxO) in options"
            v-show="filteredIndexes.includes(idxO)"
            :key="`${id}-option-${idxO}`"
            :class="[
              'relative cursor-default select-none py-2 pl-8 pr-4',
              highlight === idxO ? 'text-white bg-indigo-600' : 'text-gray-900'
            ]"
            role="option"
            tabindex="-1"
            @mouseenter="highlight = idxO"
            @click="setValue(idxO)"
          >
            <!-- Selected: "font-semibold" -->
            <span :class="['block truncate', selectedIndex === idxO ? 'font-semibold' : '' ]">
              {{ (typeof opt === 'object' && opt.label) ? opt.label : opt }}
            </span>

            <!--
              Checkmark, only display for selected option.

              Active: "text-white", Not Active: "text-indigo-600"
            -->
            <span
              v-if="selectedIndex === idxO"
              :class="[
                'absolute inset-y-0 left-0 flex items-center pl-1.5 ',
                highlight === idxO ? 'text-white' : 'text-indigo-600'
              ]"
            >
              <check-icon class="h-5 w-5" />
            </span>
          </li>
        </template>
        <li v-else class="relative cursor-default select-none py-2 pl-8 pr-4 text-gray-900">No Options Found.</li>

      </ul>
    </transition>
  </div>
</template>
<script>
import {v4 as uuidv4} from 'uuid'
import {SelectorIcon, CheckIcon} from '@vue-hero-icons/solid'
import { createPopper } from '@popperjs/core'
export default {
  name: 'ComboBox',
  components: {
    SelectorIcon, CheckIcon
  },
  props: {
    id: {
      type: String,
      default: () => uuidv4()
    },
    value: {
      type: [String, Number, Boolean, Object],
      default: ''
    },
    options: {
      type: Array,
      default: () => []
    },
    placeholder: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    value(val) {
      this.valueUpdated(val)
    },
    options() {
      this.setFilteredIndexes(this.query)
    },
    query(val) {
      this.setFilteredIndexes(val)
    }
  },
  computed: {
    selected: {
      get() {
        return (this.value && typeof this.value.id !== 'undefined') ? this.value.id : (this.value || '')
      },
      set(value) {
        this.$emit('input', value);
      }
    },
    placeholderValue() {
      return typeof this.selectedIndex === 'number' ?
        (typeof this.options[this.selectedIndex] === 'object' ?
          this.options[this.selectedIndex].label : this.options[this.selectedIndex]
        )
        : this.placeholder
    }
    // filteredOptions() {
    //   const qry = String.toString(this.query).trim().toLowerCase();
    //   if (qry.length) {
    //     return this.options.filter(opt => {
    //       const val = String.toString((typeof opt === 'object' && opt.value) ? opt.value : opt).toLowerCase();
    //       return val == qry || val.indexOf(qry) > -1;
    //     });
    //   } else {
    //     return this.options;
    //   }
    // }
  },
  methods: {
    valueUpdated(val) {
      let selected = null;
      this.options.forEach((opt, idx) => {
        if (typeof opt === 'object' && opt.value == val) {
          // console.log([1, opt.value, val]);
          selected = idx;
        } else if (opt == val) {
          // console.log([2, opt, val]);
          selected = idx;
        }
      });
      this.selectedIndex = selected;
      this.setFilteredIndexes(this.query)
    },
    setFilteredIndexes(qry) {
      qry = String(qry).trim().toLowerCase();
      if (qry.length) {
        this.filteredIndexes = this.options.map((opt, idx) => {
          const optVal = String((typeof opt === 'object' && opt.label) ? opt.label : opt).toLowerCase();
          // console.log([optVal, qry, optVal == qry, optVal.indexOf(qry) > -1]);
          return (optVal == qry || optVal.indexOf(qry) > -1) ? idx : null;
        }).filter(opt => typeof opt === 'number');
      } else {
        this.filteredIndexes = this.options.map((opt, idx) => idx);
      }
    },
    setValue(idx) {
      const opt = idx >= 0 ? this.options[idx] : '';
      if (typeof opt === 'object') {
        this.$emit('input', opt.value);
      } else {
        this.$emit('input', opt);
      }
      this.closeDropdown()
    },
    closeDropdown() {
      this.query = ''
      this.open = false
      this.dropdown.removeAttribute('data-show')
    },
    openDropdown() {
      this.query = ''
      this.open = true
      this.dropdown.setAttribute('data-show', '')
      this.instance.update();
      window.dispatchEvent(new CustomEvent("comboboxOpen", {"detail": {"uid": this.uuid}}));
    },
    toggleDropdown() {
      if (this.open) {
        this.closeDropdown()
      } else {
        this.openDropdown()
      }
    },
  },
  mounted() {
    this.query = ''
    this.container = this.$refs[`combobox-${this.id}-container`];
    this.dropdown = this.$refs[`combobox-${this.id}`];
    this.instance = createPopper(this.container, this.dropdown, {
      placement: 'bottom-start',
      strategy: 'fixed',
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 10],
          },
        },
        {
          name: "sameWidth",
          enabled: true,
          fn: ({ state }) => {
            state.styles.popper.width = `${state.rects.reference.width}px`;
          },
          phase: "beforeWrite",
          requires: ["computeStyles"],
        }
      ],
    });

    window.addEventListener('comboboxOpen', (event) => {
      if (this.open && this.uuid !== event.detail.uid) {
        this.toggleDropdown()
      }
    });
    setTimeout(() => {
      this.valueUpdated(this.value)
    }, 500)
  },
  data() {
    return {
      instance: null,
      container: null,
      dropdown: null,
      query: null,
      highlight: -1,
      selectedIndex: -1,
      filteredIndexes: [],
      open: false
    }
  },
}
</script>
