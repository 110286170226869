<template>
  <app-layout>
    <base-card class="space-y-4 mb-10 relative">
      <div
        class="
          hidden
          lg:block
          absolute
          -top-10
          right-0
          transform
          -translate-y-1/2
          z-10
        "
      >
        <base-button
          v-if="command.id"
          :disabled="$root.protected"
          :href="route(`cms.commands.run.show`, { command: command.id })"
          tag="a"
          type="link"
        >
          Run
        </base-button>
      </div>
      <div>
        <div class="mb-4">
          <base-input
            v-model="command.name"
            type="text"
            label="Name"
            placeholder=""
            required
          />
        </div>

        <div class="mb-4">
          <base-label
            tooltip="Signature of the Laravel Artisan command.<br>Example: for `php artisan inspire`, you would just enter 'inspire'"
            >Command</base-label
          >
          <base-input
            v-model="command.command"
            type="text"
            placeholder=""
            required
          />
        </div>

        <div class="mb-4">
          <base-label
            tooltip="Optional parameters for commands<br>Example: for `php artisan migrate --path=vendor/atypiccraft/cms/database`, <br>you would have a key of `--path` and a value of `vendor/atypiccraft/cms/database`"
            >Parameters</base-label
          >
          <div class="rounded-lg bg-gray-100 p-4">
            <div v-if="command.parameters.length">
              <div
                v-for="(param, idxP) in command.parameters"
                :key="`command-param-${idxP}`"
                class="flex space-x-2 mb-2 items-end"
              >
                <base-input
                  label="Key"
                  v-model="command.parameters[idxP].key"
                />
                <base-input
                  label="Value"
                  v-model="command.parameters[idxP].value"
                />
                <base-button small type="danger" @click="removeParameter(idxP)"
                  >Remove</base-button
                >
              </div>
            </div>
            <p v-else class="text-gray-300 font-bold">No parameters added</p>
          </div>
          <base-button class="mt-3" @click="addParameter"
            >Add a Parameter</base-button
          >
        </div>

        <div class="mb-4">
          <base-label
            tooltip="Optional file uploads for commands such as custom import tools"
            >Uploads</base-label
          >
          <div class="rounded-lg bg-gray-100 p-4">
            <div v-if="command.uploads.length">
              <div
                v-for="(upload, idxU) in command.uploads"
                :key="`command-upload-${idxU}`"
                class="flex space-x-2 mb-2 items-end"
              >
                <base-input
                  label="Label"
                  v-model="command.uploads[idxU].label"
                />
                <base-input
                  label="Directory"
                  v-model="command.uploads[idxU].directory"
                />
                <base-input
                  label="Filename"
                  v-model="command.uploads[idxU].filename"
                />
                <base-button small type="danger" @click="removeUpload(idxU)"
                  >Remove</base-button
                >
              </div>
            </div>
            <p v-else class="text-gray-300 font-bold">No uploads added</p>
          </div>
          <base-button class="mt-3" @click="addUpload"
            >Add an Upload</base-button
          >
        </div>
      </div>

      <base-button @click="updateCommand"> Save </base-button>
    </base-card>
  </app-layout>
</template>
<script>
import Form from '../components/Form';
import { XIcon } from '@vue-hero-icons/solid';
import Table from '../components/Table';
export default {
  name: 'CommandEdit',
  components: {
    ...Form,
    ...Table,
    XIcon,
  },
  props: {
    command: {
      type: Object,
      default: () => {
        return {
          name: '',
          command: '',
          uploads: [],
          parameters: [],
          queue: false,
          schedule: '',
        };
      },
    },
  },
  computed: {},
  mounted() {
    if (this.command) {
      if (!this.command.parameters) {
        this.command.parameters = [];
      }
      if (!this.command.uploads) {
        this.command.uploads = [];
      }
    }

    document.addEventListener('keyup', this.$root.protectEvent);
  },
  beforeDestroy() {
    document.removeEventListener('keyup', this.$root.protectEvent);
  },
  methods: {
    addParameter() {
      this.command.parameters.push({
        key: '',
        value: '',
      });
    },
    removeParameter(idx) {
      this.command.parameters.splice(idx, 1);
    },
    addUpload() {
      this.command.uploads.push({
        label: '',
        directory: '',
        filename: '',
      });
    },
    removeUpload(idx) {
      this.command.uploads.splice(idx, 1);
    },
    updateCommand() {
      if (this.command.id) {
        this.$root
          .$CMS_UPDATE('commands', this.command.id, this.command)
          .then(() => {
            this.$root.protected = false;
          });
      } else {
        this.$root.$CMS_CREATE('commands', {}, this.command).then((data) => {
          this.$root.protected = false;
          if (data && data.command && data.command.id) {
            window.location.href = route('cms.commands.show', data.command.id);
          }
        });
      }
    },
    beforeUnload(e) {
      e.returnValue = 'Are you sure you wish to leave before saving?';
    },
  },
};
</script>
