<template>
  <app-layout>
    <div class="flex flex-col items-center justify-center text-center min-h-screen gap-10">
      <h1 class="font-bold text-4xl">Server Error</h1>
      <img
        src="https://media0.giphy.com/media/EizPK3InQbrNK/giphy.gif"
        alt="Error"
      >
      <pre v-if="error" class="max-width-full overflow-scroll">{{ error }}</pre>
    </div>
  </app-layout>
</template>
<script>
export default {
  name: 'Error',
  props: ['error'],
};
</script>
