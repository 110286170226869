<template>
  <div :class="containerClass">
    <base-label v-if="label" :for="id" :size="labelSize" :color="labelColor" :tooltip="tooltip">{{ label }}</base-label>
    <slot v-if="this.$slots.default" />
    <base-text-area v-else-if="type === 'textarea'" :id="id" v-model="val" :maxlength="charlimit || null" :rows="rows" :disabled="disabled" :readonly="disabled" />
    <input
      v-else
      :type="type"
      :name="id"
      :id="id"
      :placeholder="placeholder"
      :class="[inputClass, `${disabled ? 'bg-gray-100 text-gray-700 opacity-80 cursor-not-allowed' : ''}`, 'shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md']"
      v-model="val"
      @change="$emit('change', val)"
      @click="$emit('click', $event)"
      @keypress="$emit('keypress', $event)"
      @keydown="$emit('keydown', $event)"
      @keyup="$emit('keyup', $event)"
      @focus="$emit('focus', $event)"
      @blur="$emit('blur', $event)"
      :disabled="!!disabled"
      :maxlength="charlimit || null"
    >
    <char-limit v-if="charlimit" :count="val.length" :limit="charlimit" />
    <p v-if="error" class="mt-2 text-red-500 font-medium text-sm">{{ error }}</p>
  </div>
</template>
<script>
import { v4 as uuidv4 } from 'uuid';
import BaseLabel from './BaseLabel';
import BaseTextArea from './BaseTextArea';
import CharLimit from './CharLimit.vue';
export default {
  name: 'BaseInput',
  components: {
    BaseLabel,
    BaseTextArea,
    CharLimit,
  },
  props: {
    type: {
      type: String,
      default: 'text',
    },
    id: {
      type: String,
      default: () => `input-${uuidv4()}`,
    },
    value: [String, Object, Number, Array, Boolean],
    placeholder: String,
    label: String,
    tooltip: String,
    inputClass: String,
    labelClass: String,
    labelSize: String,
    labelColor: String,
    containerClass: String,
    rows: {
      type: [Number, String],
      default: '4',
    },
    charlimit: {
      type: [Number, String],
      default: '',
    },
    disabled: {
      type: [Boolean, String, Number],
      default: false,
    },
    error: null,
  },
  computed: {
    val: {
      get() {
        return this.value || '';
      },
      set(data) {
        if (this.charlimit) {
          if (data.length <= this.charlimit) {
            this.$emit('input', data);
          } else {
            this.$emit('input', data.substring(0, this.charlimit));
          }
        } else {
          this.$emit('input', data);
        }
      },
    },
  },
};
</script>
