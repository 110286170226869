<template>
  <app-layout>
    <div class="block lg:flex">

      <!-- CONTENT -->
      <div class="w-full lg:mr-6 lg:w-3/5 lg:flex-1">
        <base-heading>
          Component
          <template v-slot:tooltip>General information about the component</template>
        </base-heading>
        <base-card class="space-y-4 mb-6">
          <base-input
            v-model="component.name"
            type="text"
            label="Name"
            placeholder=""
            required
          />

          <base-input label="Category">
            <el-select
              v-model="component.category_id"
              required
            >
              <option value="0" :selected="!component.category_id">Select...</option>
              <option
                v-for="cat in componentCategories"
                :key="`componentCategory${cat.id}`"
                :value="cat.id"
                :selected="component.category_id === cat.id"
              >{{ cat.name }}</option>
            </el-select>
          </base-input>

          <base-input label="Image">
            <file-upload
              ref="componenticon"
              v-model="component.image.files"
              :files="wrapImages(component.image.files)"
              :config="component.image.config"
              @fileupdated="updateIcon($event)"
            />
          </base-input>

          <base-input v-if="Array.isArray(component.variants)" label="Variants (one per line)">
            <base-text-area v-model="component.variants" :delimiter='"\n"' />
          </base-input>

        </base-card>
        <base-heading>
          Elements
          <template v-slot:tooltip>Drag elements from the right to build the component.<br>Edit the component to give it all the required properties.</template>
        </base-heading>
        <div class="relative mb-6">
          <draggable
            v-model="component.elements"
            :class="['w-full rounded-md bg-gray-200 p-8', component.elements.length === 0 ? 'pb-60 border-dashed border-4 border-gray-300' : '']"
            tag="ul"
            v-bind="dragOptions"
            ghost-class="list-none"
            group="elements"
            handle=".element-handle"
            @start="drag = true"
            @end="drag = false"
            @add="addElement"
          >
            <!-- <span v-for="(element,index) in component.elements" :key="`componentElement${index}`">{{element.name}}</span> -->
            <bldr-component-element
              v-for="(element,index) in component.elements"
              v-model="component.elements[index]"
              :key="`componentElement${index}`"
              :index="index"
              :upload-configs="uploadConfigs"
              :mods="mods"
              @removeElement="removeElement(index)"
            />
          </draggable>
          <p v-if="component.elements.length === 0" class="text-center text-2xl font-bold text-gray-300 pointer-events-none absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">Drag &amp; Drop Elements</p>
        </div>
        <base-heading>
          Template
          <template v-slot:tooltip>Select the template file if it already exists<br>or create it in the code area.</template>
        </base-heading>
        <!-- TODO: Template section -->
        <div class="mb-6">
          <base-input label="Template" class="mb-4">
            <el-cascade
              v-model="component.template"
              :options="templates"
            />
          </base-input>
          <base-input label="Markup">
            <base-code v-model="component.markup" />
          </base-input>
        </div>
      </div>

      <!-- SIDEBAR -->
      <div class="w-full relative mt-4 lg:w-2/5 lg:mt-0 flex-none lg:flex lg:flex-col">
        <base-heading>
          Found On
          <template v-slot:tooltip>Pages and Modules where this component is used.</template>
        </base-heading>
        <base-card class="mb-10 flex justify-center lg:flex-none relative z-30">
          <base-dropdown :items="componentPages" class="mr-4 w-full">Pages ({{ componentPages.length }})</base-dropdown>
          <base-dropdown :items="componentModules" class="w-full">Modules ({{ componentModules.length }})</base-dropdown>
        </base-card>
        <base-heading>
          Elements
          <template v-slot:tooltip>Drag and drop elements from right to left.</template>
        </base-heading>
        <div class="lg:flex-1 relative z-20">
          <base-card class="mb-4 lg:sticky top-0 z-20 overflow-auto max-h-screen">
            <tabs-container :tabs="elementCategories">
              <template v-slot:default="slotProps">
                <tabs-content v-for="(cat, idx) in elementCategories" :key="`element-tab-${cat}`" :tab="cat" v-bind="slotProps" class="bg-gray-200 p-6">
                  <draggable
                    tag="ul"
                    :clone="clone"
                    :group="{ name: 'elements', pull: 'clone', put: false }"
                    :sort="false"
                    :value="elements.filter((el) => el.category === cat)"
                    @start="drag = true"
                    @end="drag = false"
                  >
                    <li v-for="(el, idxE) in elements.filter((el) => el.category === cat)"
                      :key="`element-tab-${cat}-el-${idxE}`"
                    >
                      <base-card class="cursor-move mb-6">
                        <base-input :label="el.name">
                          <div class="pointer-events-none" v-if="Array.isArray(el.markup)">
                            <div
                              v-for="(mk,idxM) in el.markup"
                              :key="`elmk.${idxM}`"
                            >
                              <div v-if="typeof mk === 'object' && mk.is === 'draggable'"></div>
                              <component
                                :is="mk.is"
                                v-else-if="typeof mk === 'object'"
                                v-bind="mk"
                              >
                                <component
                                  :is="mk.child.is"
                                  v-if="typeof mk.child === 'object'"
                                  v-bind="mk.child"
                                ></component>
                                <div
                                  v-else-if="typeof mk.child === 'string'"
                                  v-html="mk.child"
                                ></div>
                              </component>
                              <div
                                v-else
                                v-html="mk"
                              ></div>
                            </div>
                          </div>
                          <div
                            v-else
                            v-html="el.markup"
                          ></div>
                        </base-input>
                      </base-card>
                    </li>
                  </draggable>
                </tabs-content>
              </template>
            </tabs-container>
          </base-card>
        </div>
      </div>
    </div>
    <div class="cms-detail-actions">
      <base-button
        :disabled="!tested"
        :class="!tested ? 'disabled' : ''"
        type="primary"
        class="btn-fill mr-2"
        @click="updateComponent"
      >
        Save
      </base-button>
      <base-button
        v-if="component.id"
        type="primary"
        class="btn-fill always-active"
        @click="testComponent"
      >
        Test
      </base-button>
    </div>
  </div>
  </app-layout>
</template>
<script>
import draggable from 'vuedraggable';
import Form from '../components/Form';
import Stack from '../components/Stack';
import Tabs from '../components/Tabs';
import { BldrComponentElement } from '../components/Builder';
export default {
  components: {
    ...Form,
    ...Tabs,
    BldrComponentElement,
    draggable,
  },
  name: 'ComponentEdit',
  props: {
    component: {
      type: Object,
      default: () => {
        return {
          id: 0,
          name: '',
          variants: [],
          markup: '',
          elements: [],
          category_id: null,
          template: '',
          image: {
            files: [],
            configID: 0,
            config: [
              {
                mode: 'file',
                name: 'Component icon',
                retina: false,
                suffix: '',
                location: 'public',
                directory: '/cms-components',
                dimensions: { width: '', height: '' },
              },
            ],
          },
        };
      },
    },
    componentCategories: {
      type: Array,
      default: () => [],
    },
    elements: {
      type: Array,
      default: () => [],
    },
    uploadConfigs: {
      type: Array,
      default: () => [],
    },
    mods: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost',
      };
    },
    componentPages() {
      try {
        return this.component.pages
          ? this.component.pages.map(function (page) {
              return {
                href: route('cms.pages.show', page.id),
                label: page.full_name,
                attributes: { target: '_blank' },
              };
            })
          : [];
      } catch (err) {
        console.error(err);
        return [];
      }
    },
    componentModules() {
      try {
        return this.component.modules
          ? this.component.modules.map(function (mod) {
              return {
                href: route('cms.mod.index', mod.id),
                label: mod.name,
                attributes: { target: '_blank' },
              };
            })
          : [];
      } catch (err) {
        console.error(err);
        return [];
      }
    },
    elementCategories() {
      return this.elements
        .map(function (el) {
          return el.category;
        })
        .filter(function (value, index, self) {
          return self.indexOf(value) === index;
        });
    },
  },
  data() {
    return {
      drag: false,
      editorOptions: {
        language: 'html',
      },
      templates: [
        {
          label: 'Create New Template File',
          value: 'NEW',
        },
      ],
      tested: false,
    };
  },
  mounted() {
    this.$root.$CMS_GET('templates', { dir: 'components' }).then((data) => {
      this.templates = this.templates.concat(data);
    });
    if (!this.component.variants) {
      this.component.variants = [];
    }
    document.addEventListener('keyup', this.$root.protectEvent);
    this.tested =
      !this.component.id ||
      (!this.componentPages.length && !this.componentModules.length);
  },
  beforeDestroy() {
    document.removeEventListener('keyup', this.$root.protectEvent);
  },
  // TODO: Clean up methods from old version
  methods: {
    clone: function (original) {
      var element = {};
      for (var key in original) {
        if (original.hasOwnProperty(key)) {
          element[key] = JSON.parse(JSON.stringify(original[key]));
        }
      }
      return element;
    },
    updateIcon($event) {
      if ($event.length) {
        this.component.image.files = $event.map((file) => {
          delete file.dataURL;
          return file;
        });
      }
    },
    updateComponent() {
      // TODO: Add a popup that says, "Testing pages and modules", and then after it finishes that step, it either pops up and says the following pages/modules have errors as a result of your update, please update the markup to avoid errors else it just closes and says saved
      if (this.component.id) {
        const comp = JSON.parse(JSON.stringify(this.component));
        delete comp.pages; // Less overhead
        delete comp.modules;
        this.$root
          .$CMS_UPDATE('components', this.component.id, comp)
          .then(() => {
            this.$root.protected = false;
          });
      } else {
        this.$root
          .$CMS_CREATE(`components`, {}, this.component)
          .then((data) => {
            this.$root.protected = false;
            this.$inertia.visit(route(
              `cms.components.show`,
              data.component.id
            ));
          });
      }
    },
    getProp(element, property) {
      try {
        const filter = element.properties.filter((prop) => {
          return prop.property === property;
        });
        return filter.length ? filter[0].value : '';
      } catch (err) {
        return false;
      }
    },
    isJson(str) {
      try {
        JSON.parse(str);
      } catch (e) {
        return false;
      }
      return true;
    },
    addElement(evt) {
      setTimeout(() => {
        // this.editElement(`elementpopover${evt.newIndex}`);
      });
    },
    orderElement() {
      this.component.elements.forEach((el, idx) => {
        el.sort_order = idx;
      });
    },
    // editElement(ref) {
    //   let popover = this.$refs[ref][0];
    //   popover.value = !popover.value;
    // },
    removeElement(idx) {
      if (this.id > 0) {
        // Check if the component is in use
        // If in use, display message asking if the user is sure
      }
      this.component.elements.splice(idx, 1);
    },
    // togglePopover(ref) {
    //   const popover = this.$refs[ref];
    //   if (popover.length) {
    //     popover[0].doToggle();
    //   }
    // }
    wrapImages(files) {
      return files.map((file) => {
        if (typeof file === 'string') {
          const fileArr = file.split('/');
          return {
            raw: [],
            uid:
              Math.random().toString(36).substring(2, 15) +
              Math.random().toString(36).substring(2, 15),
            url: file,
            name: fileArr[fileArr.length - 1],
            size: 100,
            status: 'ready',
            location: '',
            directory: '',
            percentage: 0,
          };
        } else {
          return file;
        }
      });
    },
    extractProps(props, elID) {
      let results = [];
      let elProps = this.elementsGroups
        .map((grp) => {
          return grp.elements;
        })
        .flat()
        .filter((el) => {
          return el.id === elID;
        });
      if (elProps.length) {
        elProps = elProps[0].properties;
      } else {
        elProps = false;
      }
      for (const prop in props) {
        let propMatch;
        if (!elProps) {
          propMatch = props[prop];
        } else {
          propMatch =
            elProps.findIndex((el) => prop.indexOf(el.property) === 0) > -1;
        }
        if (
          propMatch &&
          prop !== 'element_id' &&
          prop !== 'component_id' &&
          prop !== 'sort_order'
        ) {
          results.push({ property: prop, value: props[prop] });
        }
      }
      return results;
    },
    processElements(elements) {
      return elements
        .map((el) => {
          el.pivot_id = el.pivot.id;
          el.markup = JSON.parse(el.markup);
          el.sort_order = el.pivot.sort_order;
          el.properties = this.extractProps(el.pivot, el.id);
          if (el.children) {
            el.children = this.processElements(el.children);
          }
          delete el.pivot;
          return el;
        })
        .sort((a, b) => (a.sort_order > b.sort_order ? 1 : -1));
    },
    templateChange(value) {
      this.$root
        .$CMS_GET('templates.find', {
          path: encodeURIComponent('components/' + value.join('/')),
        })
        .then((data) => {
          this.component.markup = data;
        });
    },
    prependParentName(pages, page) {
      if (page) {
        if (typeof page.parent === 'number' && page.parent > 0) {
          let parent =
            pages[
              pages.findIndex((pg) => {
                return pg.id === page.parent;
              })
            ];
          return this.prependParentName(pages, parent) + ' -> ' + page.name;
        } else {
          return page.name;
        }
      }
    },
    testComponent() {
      this.$root
        .$CMS_POST(
          'components.test',
          { component: this.component.id },
          this.component
        )
        .then((resp) => {
          if (resp.errors && resp.errors.length) {
            console.error(resp.errors);
            this.$root
              .$CMS_ALERT({
                title: `(${resp.errors.length}) Error(s) Found`,
                text: 'Please verify any markup changes allow for graceful failure if fields are being added or removed. This may or may not be related to this component but it is related to one or more pages that use this component. More information can be found in the developer console. ',
                icon: 'error',
              })
              .then(() => {
                this.tested = false;
              });
          } else {
            this.$root
              .$CMS_ALERT({
                title: 'No Errors Found',
                text: 'You are safe to save this component.',
                icon: 'success',
              })
              .then(() => {
                this.tested = true;
              });
          }
        });
    },
  },
};
</script>
