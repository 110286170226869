<template>
  <app-layout>
    <form @submit.prevent="submit" class="relative">
      <div
        v-if="can.design"
        class="
          hidden
          lg:block
          absolute
          -top-10
          right-0
          transform
          -translate-y-1/2
          z-10
        "
      >
        <base-button
          v-if="product.id"
          :disabled="$root.protected"
          :href="route('cms.products.design', product.id)"
          tag="a"
          type="link"
        >
          Switch to Design Mode
        </base-button>
      </div>
      <base-card class="space-y-8 mb-10">

        <div class="space-y-4">
          <base-input
            v-model="product.title"
            type="text"
            label="Title"
            placeholder=""
            required
            :error="$page.props.errors.title || null"
            :charlimit="180"
          />
          <base-input
            v-model="product.meta_title"
            type="text"
            label="Meta Title"
            placeholder=""
            required
            :error="$page.props.errors.meta_title || null"
            :charlimit="180"
          />
          <base-input
            v-model="product.meta_description"
            type="textarea"
            label="Meta Description"
            placeholder=""
            required
            :error="$page.props.errors.meta_description || null"
            :charlimit="160"
          />
          <!-- <base-input
            v-model="product.summary"
            type="textarea"
            label="Summary"
            placeholder=""
            required
            :error="$page.props.errors.summary || null"
          /> -->
          <base-input label="Status" :error="$page.props.errors.status || null">
            <el-select
              v-model="product.status"
              class="select-primary"
              placeholder="Select..."
              :options="['draft', 'active', 'archived']"
            />
          </base-input>
          <base-input
            v-model="product.shopify_id"
            type="text"
            label="Shopify ID"
            placeholder=""
            required
            :error="$page.props.errors.shopify_id || null"
          />
          <base-input
            v-if="$page.props.appEnv !== 'production'"
            v-model="product.shopify_dev_id"
            type="text"
            label="Shopify ID (Development store)"
            placeholder=""
            required
            :error="$page.props.errors.shopify_dev_id || null"
          />

          <base-input
            v-if="$page.props.appEnv !== 'production'"
            label="HTML Body"
          >
            <BaseTextArea v-model="bodyHtml" :readonly="true" rows="10" textarea-class="font-mono whitespace-pre" />
            <div class="mt-2 flex gap-2 items-center">
              <base-label size="text-xs">Strip HTML</base-label>
              <base-switch v-model="bodyHtmlStripped" />
            </div>
          </base-input>
        </div>


        <div v-if="product && ready" >
          <base-heading>Components</base-heading>
          <stack-list v-if="product.components && product.components.length">
            <stack-list-item
              v-for="(c, idxC) in product.components"
              :key="`productComponent${idxC}`"
              :start-open="c.elements.length > 0"
            >
              <img
                v-if="c.image && c.image.files.length"
                class="d-inline-block mr-3"
                style="width: 50px"
                :src="
                  typeof c.image.files[0] === 'string'
                    ? c.image.files[0]
                    : c.image.files[0].url
                "
              />
              <label class="d-inline-block">{{
                c.pivot && c.pivot.name
                  ? `${c.pivot.name} (${c.name})`
                  : c.name
              }}</label>
              <div
                v-if="c.elements.length > 0"
                slot="expand"
                class="space-y-4"
              >
                <div
                  v-for="(element, idxE) in c.elements"
                  :key="`productComponent${idxC}Element${idxE}`"
                  :class="`cms-component-element-${$root.$SLUGIFY(
                    element.name
                  )}`"
                >
                  <!-- <bldr-element
                    v-if="false && element.name !== 'Repeater' && (Array.isArray(product.components[idxC].pivot.data[element.pivot.variable]) && product.components[idxC].pivot.data[element.pivot.variable].length === 1)"
                    v-model="product.components[idxC].pivot.data[element.pivot.variable]"
                    :element="element"
                  /> -->
                  <bldr-element
                    v-model="
                      product.components[idxC].pivot.data[element.pivot.variable]
                    "
                    :element="element"
                  />
                </div>
              </div>
            </stack-list-item>
          </stack-list>
          <div v-else-if="product.id">
            <base-card
              ><strong>No components added.</strong><br />Add items using the design mode at the top if
              you have design permissions.</base-card
            >
          </div>
          <div v-else>
            <base-tooltip class="inline-block" :delay="1" :offset-y="10">
              <template slot="content"
                >{{ product.id ? "Save" : "Create" }} the product page and start building
                it with components.</template
              >
              <base-button
                type="primary"
                class="mt-6"
                @click="save('published')"
                >{{ product.id ? "Save" : "Create" }}</base-button
              >
            </base-tooltip>
          </div>
        </div>

        <base-button type="button" @click="submit">Save</base-button>
        <base-button type="button" @click="pushToShopify" :disabled="$root.protected">Push To Shopify</base-button>
        <base-button tag="a" :href="route('cms.products.preview', product.id)" :disabled="$root.protected" target="_blank">Preview</base-button>

      </base-card>
    </form>
  </app-layout>
</template>
<script>
import Form from '../../components/Form';
import { BldrElement } from '../../components/Builder';
import Stack from '../../components/Stack';

export default {
  name: 'Product',
  components: {
    ...Form,
    ...Stack,
    BldrElement,
  },
  props: {
    product: {
      type: Object,
      default: () => {
        return {
          title: '',
          status: '',
          template_suffix: '',
        };
      },
    },
    can: {
      type: [Object],
      default: () => {
        return {
          design: false,
        };
      },
    },
  },
  methods: {
    submit() {
      if (this.product.id) {
        this.$inertia.put(
          route('cms.products.update', { product: this.product.id }),
          this.product,
          {
            replace: true,
            preserveScroll: true,
            onBefore: () => {
              this.$root.protected = false;
            },
            onSuccess: () => {
              this.$noty.success('Updated!');
            },
          }
        );
      } else {
        window.alert(
          'You cannot create products via the CMS. Please log into Shopify to create a project.'
        );
        // this.$inertia.post(route('cms.products.store'), this.product, {
        //   onSuccess: () => {
        //     this.$noty.success('Created!');
        //   },
        // });
      }
    },
    pushToShopify() {
      if (this.product.id) {
        this.$inertia.put(
          route('cms.products.push', { product: this.product.id }),
          {},
          {
            replace: true,
            preserveScroll: true,
            onBefore: () => {
              this.$root.protected = false;
            },
            onSuccess: () => {
              this.$noty.success('Pushed to Shopify!');
            },
          }
        );
      } else {
        window.alert(
          'You cannot push a product to shopify without it already existing in shopify!'
        );
      }
    },
  },
  computed: {
    bodyHtml() {
      if (this.bodyHtmlStripped) {
        const div = document.createElement('div');
        div.innerHTML = this.product.body_html;
        return div.textContent || div.innerText || '';
      } else {
        return this.product.body_html;
      }
    },
  },
  data() {
    return {
      bodyHtmlStripped: true,
      ready: false,
    };
  },
  mounted() {
    document.addEventListener('keyup', this.$root.protectEvent);
    this.$nextTick(() => {
      if (Array.isArray(this.product.components) && this.product.components.length) {
        this.product.components.forEach((component, idxC) => {
          if (
            typeof this.product.components[idxC].pivot.data === 'undefined' || 
            !this.product.components[idxC].pivot.data || 
            (Array.isArray(this.product.components[idxC].pivot.data))
          ) {
            this.product.components[idxC].pivot.data = {};
          }
          component.elements.forEach((el) => {
            if (
              typeof this.product.components[idxC].pivot.data[
                el.pivot.variable
              ] === 'undefined'
            ) {
              this.product.components[idxC].pivot.data[el.pivot.variable] = '';
            }
          });
        });
        this.$nextTick(() => {
          this.ready = true;
        });
      } else {
        console.log('No components!');
        this.ready = true;
      }
    });
  },
  beforeDestroy() {
    document.removeEventListener('keyup', this.$root.protectEvent);
  },
};
</script>
