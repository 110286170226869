<template>
  <div>
    <nav class="relative z-0 rounded-t-lg shadow divide-y divide-gray-200 sm:flex sm:divide-x flex-none" aria-label="Tabs">
      <tabs-button
        v-on:tabclick="handleClick"
        v-for="(tab, idx) in tabs"
        :key="`tabs-${uuid}-tab-${idx}`"
        :content="tab"
        :selected="selected"
        :class="(idx === 0 ? 'rounded-tl-lg' : ( idx === tabs.length-1 ? 'rounded-tr-lg' : ''))"
      />
    </nav>
    <slot v-bind:selected="selected" />
  </div>
</template>
<script>
import TabsButton from './TabsButton'
export default {
  name: 'TabsContainer',
  components: {
    TabsButton
  },
  props: {
    tabs: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      selected: ''
    }
  },
  methods: {
    handleClick(payload) {
      this.selected = payload;
    }
  },
  mounted() {
    this.selected = this.tabs[0];
  }
}
</script>
