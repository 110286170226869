<template>
  <DamPopover ref="dam-popover"  v-on:open="$emit('popoveropen')" v-on:close="$emit('popoverclose')">
    <template v-slot:button>
      <dam-button title="Share">
        <share-icon class="w-5 h-5" />
      </dam-button>
    </template>
    <template v-slot:title>
      Share
    </template>
    <template v-slot>
      <div class="space-y-5">
        <!-- Buttons -->
        <div class="flex items-start justify-start gap-4">
          <button v-for="section in sections" :key="`section-button-${section.name}`" :title="section.name" @click="activeSection = section.name" :class="[
            `${section.name == activeSection ? ' text-white bg-indigo-500' : 'text-black bg-white'}`,
            'w-9 h-9 rounded-md border border-solid border-gray-300 flex flex-none items-center justify-center'
          ]">
            <component :is="section.icon" class="w-5 h-5 block" />
          </button>
        </div>
        <!-- Tabs -->
        <div v-for="section in sections" :key="`section-content-${section.name}`" v-show="section.name == activeSection">
          <div v-if="section.name == 'mail'">
            <div v-if="emailSent">
              <p>Your message has been sent!</p>
              <p><a href="javascript:;" class=" text-indigo-600 hover:text-indigo-700 underline" @click="emailSent=false">Click here to send another</a></p>
            </div>
            <div v-else class="space-y-2">
              <fieldset class="space-y-1">
                <label class="font-bold text-gray-500 text-[10px] flex justify-between"><span>Recipients:</span> <span class="font-normal">(comma separate multiple emails)</span></label>
                <base-input v-model="shareRecipients" />
              </fieldset>

              <fieldset class="space-y-1">
                <label class=" font-bold text-gray-500 text-[10px]">Message:</label>
                <base-input type="textarea" v-model="shareMessage" />
              </fieldset>

              <div class="pt-4 flex items-start justify-start gap-3">
                <dam-button tag="button" class="min-w-[100px]" @click="sendEmail()">Send</dam-button>
                <dam-button tag="button" type="secondary" class="min-w-[100px]" @click="cancel()">Cancel</dam-button>
              </div>
            </div>
          </div>
          <div v-else-if="section.name == 'action'">
            <div class="space-y-2">
              <div class="space-y-1">
                <label class=" font-bold text-gray-500 text-[10px]">Format:</label>
                <el-select :options="shareOptions" v-model="activeShareOption" />
              </div>
              <div class="space-y-1">
                <label class=" font-bold text-gray-500 text-[10px]">Action:</label>
                <el-select :options="actionOptions" v-model="activeActionOption" />
              </div>
              <div class="pt-4 flex items-start justify-start gap-3">
                <dam-button tag="button" class="min-w-[100px]" @click="runAction()" :disabled="!(activeActionOption)">Run</dam-button>
                <dam-button tag="button" type="secondary" class="min-w-[100px]" @click="cancel()">Cancel</dam-button>
              </div>
            </div>
          </div>
          <div v-else class="space-y-5">
            <el-select :options="shareOptions" v-model="activeShareOption" />
            <base-text-area v-model="shareValue" class="pb-4 font-mono text-sm" rows="5"></base-text-area>
            <dam-button tag="button" type="secondary" class="min-w-[100px]" @click="cancel()">Cancel</dam-button>
          </div>
        </div>
      </div>
    </template>
  </DamPopover>
</template>
<script>
import DamButton from './Button.vue'
import DamPopover from './Popover'
import Form from '../../../components/Form'
import { ShareIcon, LinkIcon, CodeIcon, MailOpenIcon, SparklesIcon } from '@vue-hero-icons/solid'

export default {
  components: {
    DamButton,
    DamPopover,
    ShareIcon,
    LinkIcon,
    CodeIcon,
    MailOpenIcon,
    SparklesIcon,
    ...Form,
  },
  props: [
    'item'
  ],
  data() {
    return {
      activeSection: 'link',
      sections: [
        {
          name: 'link',
          icon: 'link-icon'
        },
        {
          name: 'code',
          icon: 'code-icon'
        },
        {
          name: 'action',
          icon: 'sparkles-icon'
        },
        {
          name: 'mail',
          icon: 'mail-open-icon'
        }
      ],
      actionOptions: [],
      activeActionOption: '',
      activeShareOption: '',
      allShareOptions: [],
      shareRecipients: '',
      shareMessage: '',
      emailSent: false
    }
  },
  computed: {
    shareValue() {
      const url = route('cms.dam.asset', {uuid: this.item.uuid, suffix: this.activeShareOption});
      switch(this.activeSection) {
        case 'link':
          return url;
        case 'code':
          switch (this.shareType) {
            case 'image':
              return `<img src="${url}" alt="${this.item.name} />`;
            case 'video':
              return `<video src="${url}></video>`;
            case 'audio':
              return `<audio src="${url}></audio>`;
            default:
              return `<a href="${url}">${this.item.name}</a>`
          }
        default:
          return '';
      }
    },
    shareOptions() {
      let opts = [{label: 'Original Format', value: ''}];
      switch(this.shareType) {
        case 'image':
          return opts.concat(this.allShareOptions.filter(opt => ['jpg', 'png'].some(v => opt.value.includes(v))));
        case 'video':
          return opts.concat(this.allShareOptions.filter(opt => ['mp4', 'mov'].some(v => opt.value.includes(v))));
        case 'audio':
          return opts.concat(this.allShareOptions.filter(opt => ['mp3', 'wav'].some(v => opt.value.includes(v))));
        default:
          return opts;
      }
    },
    shareType() {
      if (this.item.type.includes('image')) {
        return 'image';
      } else if (this.item.type.includes('video')) {
        return 'video';
      } else if (this.item.type.includes('audio')) {
        return 'audio';
      } else {
        return 'plain';
      }
    }
  },
  methods: {
    cancel() {
      this.$refs['dam-popover'].toggle();
    },
    sendEmail() {
      this.$CMS_POST('dam.assets.share', {asset: this.item.id}, { recipients: this.shareRecipients, message: this.shareMessage }).then(resp => {
        this.emailSent = true;
      });
    },
    runAction() {
      this.$CMS_POST('dam.actions.run', {}, {
        asset: this.item.id,
        format: this.activeShareOption,
        action: this.activeActionOption
      }).then(data => {
        if (data.status === 'success') {
          this.$noty.success(data.message || 'Success!');
        } else {
          // Error
          this.$noty.error(data.message || 'Error!');
        }
      })
    }
  },
  mounted() {
    // Use inertia loaded actions if available
    if (this.$inertia.page.props.actions) {
      this.actionOptions = this.$inertia.page.props.actions.map(item => {return {value: item.id, label: item.name}});
    } else {
      this.$CMS_GET('dam.actions.index', {}, true).then(data => {
        this.actionOptions = data.items.map(item => {return {value: item.id, label: item.name}});
      })
    }
    if (this.$inertia.page.props.suffixes) {
      this.allShareOptions = this.$inertia.page.props.suffixes;
    } else {
      console.warn('Missing suffixes');
      // this.$CMS_GET('dam.actions.index', {}, true).then(data => {
      //   this.shareOptions = data.items.map(item => {return {value: item.id, label: item.name}});
      // })
    }
  }
}
</script>
