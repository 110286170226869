<template>
  <div>
    <fieldset v-if="data && (typeof data === 'object')" class="md:flex justify-items-stretch items-end space-x-2">
      <base-input
        class="flex-grow"
        v-bind="{ placeholder, label, tooltip, inputClass, labelClass, labelSize, labelColor, containerClass, charlimit }"
        v-model="data.content"
      />
      <base-input class="flex-grow-0 md:max-w-[100px]">
        <el-select v-model="data.tag" :options="['p', 'h1', 'h2', 'h3']" required />
      </base-input>
    </fieldset>
  </div>
</template>
<script>
import { v4 as uuidv4 } from 'uuid'
import { BaseInput, ElSelect } from './index'
export default {
  name: 'TextTag',
  components: {
    BaseInput,
    ElSelect
  },
  props: {
    value: {
      type: [Object, String, Array],
      default: () => {
        return {
          content: '',
          tag: 'p'
        }
      }
    },
    id: {
      type: String,
      default: () => `text-tag-${uuidv4()}`
    },
    placeholder: String,
    label: String,
    tooltip: String,
    inputClass: String,
    labelClass: String,
    labelSize: String,
    labelColor: String,
    containerClass: String,
    charlimit: {
      type: [Number, String],
      default: ''
    },
  },
  computed: {
    data: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  mounted() {
    if (typeof this.data === 'string') {
      this.$emit('input', {
        content: this.data,
        tag: 'p'
      })
    } else if (!this.data || typeof this.data !== 'object' || Array.isArray(this.data)) {
      console.log('Text Tag is null or not obj');
      this.$emit('input', {
        content: '',
        tag: 'p'
      })
    }
  }
}
</script>
