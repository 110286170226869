<template>
  <!-- This example requires Tailwind CSS v2.0+ -->
  <div class="relative inline-block text-left">
    <div>
      <button @click="open = !open" :disabled="items.length === 0" type="button" :class="[`${items.length === 0 ? 'cursor-not-allowed' : 'hover:bg-gray-50'}`,'inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500']" :aria-expanded="open" aria-haspopup="true">
        <slot />
        <chevron-down-icon class="-mr-1 ml-2 h-5 w-5" />
      </button>
    </div>

    <transition
      enter-active-class="transition ease-out duration-100 transform"
      enter-class="opacity-0 scale-95"
      enter-to-class="opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75 transform"
      leave-class="opacity-100 scale-100"
      leave-to-class="opacity-0 scale-95"
    >
      <div v-show="open" class="origin-top-right absolute right-0 mt-2 w-56 max-h-96 overflow-auto rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
        <div class="py-1" role="none">
          <template v-for="(item, idx) in items">
            <div :key="`dropdown-${uuid}-item-${idx}`">
              <p v-if="(typeof item === 'string')" class="block px-4 py-2 text-sm text-gray-700" role="menuitem"><span>{{ item }}</span></p>
              <a v-else-if="(typeof item === 'object' && typeof item.href !== 'undefined' && typeof item.label !== 'undefined')" :href="item.href" v-bind="item.attributes || {}" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem"><span>{{ item.label }}</span></a>
              <p v-else>Invalid item</p>
            </div>
          </template>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import { ChevronDownIcon } from '@vue-hero-icons/solid'
export default {
  name: 'BaseDropdown',
  components: {
    ChevronDownIcon
  },
  props: {
    items: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      open: false
    }
  }
}
</script>
