<template>
<div class="relative z-[9999]" aria-labelledby="slide-over-title" role="dialog" aria-modal="true">
  <!-- Background backdrop, show/hide based on slide-over state. -->
  <div class="fixed inset-0 transition-opacity bg-gray-900" :class="show ? 'z-10 opacity-50' : 'pointer-events-none z-[-1] opacity-0 delay-400'"></div>

  <div class="fixed inset-0 overflow-hidden " :class="show ? 'z-20' : 'pointer-events-none z-[-1]'">
    <div class="absolute inset-0 overflow-hidden">
      <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
        <!--
          Slide-over panel, show/hide based on slide-over state.

          Entering: "transform transition ease-in-out duration-500 sm:duration-700"
            From: "translate-x-full"
            To: "translate-x-0"
          Leaving: "transform transition ease-in-out duration-500 sm:duration-700"
            From: "translate-x-0"
            To: "translate-x-full"
        -->
        <transition
          enter-active-class="transform transition ease-in-out duration-500 sm:duration-700"
          enter-from-class="translate-x-full"
          enter-to-class="translate-x-0"
          leave-active-class="transform transition ease-in-out duration-500 sm:duration-700"
          leave-from-class="translate-x-0"
          leave-to-class="translate-x-full"
        >
          <div v-show="show" class="pointer-events-auto w-screen max-w-2xl">
            <div class="flex h-full flex-col overflow-hidden bg-white shadow-xl">
              <div class="px-4 sm:px-6 mt-6">
                <div class="flex items-start justify-between">
                  <base-heading id="slide-over-title">Advanced Filter</base-heading>
                  <div class="ml-3 flex h-7 items-center">
                    <button @click="show = false" type="button" class="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                      <span class="absolute -inset-2.5"></span>
                      <span class="sr-only">Close panel</span>
                      <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
              <div class="relative flex-1 h-1 max-h-full divide-y divide-gray-200 flex flex-col">
                <div class="px-4 py-5 sm:p-6 space-y-4 flex-1 overflow-auto">
                  <base-input label="Name" v-model="filter.name" :disabled="canEditFilter ? null : true" />
                  <base-input label="Shared With All Users">
                    <base-switch v-model="filter.global" :disabled="canEditFilter ? null : true" />
                  </base-input>
                  <base-input label="Query">
                    <div class="overflow-hidden rounded-lg bg-gray-50">
                      <div class="px-4 py-5 sm:p-6 space-y-3">
                        <base-input label-size="text-xs" label="Search Query" v-model="filter.query.query" />
                        <base-input label-size="text-xs" label="Collection">
                          <el-select :options="options.collections" v-model="filter.query.collection" />
                        </base-input>
                        <base-input label-size="text-xs" label="Category">
                          <el-select :options="options.categories" v-model="filter.query.category" />
                        </base-input>
                        <base-input label-size="text-xs" label="Asset Creator">
                          <el-select :options="options.users" v-model="filter.query.creator" />
                        </base-input>
                        <base-input label-size="text-xs" label="File Types">
                          <ul class="text-sm mb-9 space-y-2">
                            <li v-for="(type, idx) in options.types" :key="`filetype-${idx}`" class="flex items-center space-x-3">
                              <input :id="`dam-advfilter-filetype-${idx}`" class="rounded border border-gray-300 w-4 h-4" type="checkbox" v-model="filter.query.types" :value="type.value" />
                              <label :for="`dam-advfilter-filetype-${idx}`">{{ type.label }}</label>
                            </li>
                          </ul>
                        </base-input>
                        <!-- Comment out these because they don't quite work yet -->
                        <!--
                        <div v-for="(group, idx) in options.meta_groups" :key="`meta-group-${idx}`">
                          <base-input label-size="text-xs" :label="group.name">
                            <ul class="text-sm mb-9 space-y-2">
                              <li v-for="(item, idxV) in options.meta_values.filter(v => v.meta_id == group.id)" :key="`meta-group-${idx}-${idxV}`" class="flex items-center space-x-3">
                                <input :id="`dam-advfilter-meta-group-${idx}-${idxV}`" class="rounded border border-gray-300 w-4 h-4" type="checkbox" v-model="filter.query.metas" :value="item">
                                <label :for="`dam-advfilter-meta-group-${idx}-${idxV}`">{{ item.value }}</label>
                              </li>
                            </ul>
                          </base-input>
                        </div>
                        <base-input label-size="text-xs" label="Relative Date">
                          <div class="flex gap-3 items-center">
                            <base-input label-size="text-xs" label="Amount" type="number" v-model="filter.query.relative_date.amount" />
                            <base-input label-size="text-xs" label="Unit">
                              <el-select :options="['hours','days','weeks','months','years']" v-model="filter.query.relative_date.unit" />
                            </base-input>
                            <base-input label-size="text-xs" label="Older / Newer">
                              <el-select :options="[{label: 'Older', value: 'gte'}, {label: 'Newer', value: 'lte'}]" v-model="filter.query.relative_date.operator" />
                            </base-input>
                          </div>
                        </base-input>
                        <base-input label-size="text-xs" label="Specific Created Date Range">
                          <div class="flex gap-3 items-center">
                            <el-date-picker v-model="filter.query.created.start" />
                            <span>to</span>
                            <el-date-picker v-model="filter.query.created.end" />
                          </div>
                        </base-input>
                        <base-input label-size="text-xs" label="Specific Updated Date Range">
                          <div class="flex gap-3 items-center">
                            <el-date-picker v-model="filter.query.updated.start" />
                            <span>to</span>
                            <el-date-picker v-model="filter.query.updated.end" />
                          </div>
                        </base-input>
                        -->
                      </div>
                    </div>
                  </base-input>
                  <div class="space-x-4">
                    <base-button @click="search">Search</base-button>
                    <base-button v-if="canEditFilter" type="secondary" @click="saveFilter">Save</base-button>
                  </div>
                </div>
                <div class="px-4 py-4 sm:px-6 bg-gray-100 flex-none">
                  <base-heading size="sm">Saved Filters</base-heading>
                  <ul class="flex flex-wrap justify-start gap-x-6 gap-y-4">
                    <li v-for="f in filters" :key="`filter-${f.id}`" :class="['inline-flex justify-center items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset ring-gray-500/10', f.id == filter.id ? 'bg-gray-600 text-gray-50' : 'bg-gray-50 text-gray-600 ']">
                      <button v-if="filter.id == f.id"  type="button" class="inline-flex items-center justify-center gap-2" @click="setFilter()">
                        <span>{{ f.name }} {{ f.global ? '*' : '' }}</span>
                        <x-icon class="w-3 h-3" />
                      </button>
                      <button v-else  type="button" class="inline-flex justify-center" @click="setFilter(f.id)">
                        <span>{{ f.name }} {{ f.global ? '*' : '' }}</span>
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</div>

</template>
<script>
const defaultFilter = {
  id: 0,
  name: '',
  global: false,
  user_id: null,
  query: {
    query: '',
    collection: null,
    category: null,
    types: [],
    meta: [],
    creator: null,
    relative_date: {
      amount: 0,
      unit: 'days',
      operator: 'lte'
    },
    created: {
      start: null,
      end: null,
    },
    updated: {
      start: null,
      end: null,
    },
  }
};
import Form from '../../../components/Form'
import { XIcon } from '@vue-hero-icons/solid'
export default {
  components: {
    ...Form,
    XIcon,
  },
  props: {
    value: false,
  },
  computed: {
    show: {
      get() { return this.value },
      set(val) { this.$emit('input', val)}
    },
    user() {
      return this.$inertia.page.props.me;
    },
    canEditFilter() {
      return !this.filter.id || (this.filter.id && this.filter.user_id == this.user.id)
    }
  },
  data() {
    return {
      filters: [],
      options: {
        users: [],
        collections: [],
        categories: [],
        types: [],
        meta_groups: [],
        meta_values: [],
      },
      filter: JSON.parse(JSON.stringify(defaultFilter))
    }
  },
  methods: {
    getFilters() {
      this.$CMS_GET('dam.filter-options').then(resp => {
        this.options.categories = resp.categories
        this.options.collections = resp.collections
        this.options.types = resp.types
        this.options.meta_groups = resp.meta_groups
        this.options.meta_values = resp.meta_values
      })
      this.$CMS_GET('users.index').then(resp => {
        this.options.users = resp.items.map((item) => {return {label: item.email, value: item.id}})
      })
      this.$CMS_GET('dam.filters.index').then(resp => {
        this.filters = resp.items
      });
    },
    search() {

      const query = {};

      // Meta filter
      if (this.filter.query.meta.length) {
        query.meta = this.filter.query.meta.map(sm => sm.meta_id + '|' + sm.value).join(',')
      }

      // File types
      if (this.filter.query.types.length) {
        query.types = this.filter.query.types.join(',')
      }

      // Search query
      if (this.filter.query.query.length) {
        query.query = this.filter.query.query
      }

      // Collection ID
      if (this.filter.query.collection) {
        query.collection = this.filter.query.collection
      }

      // Category ID
      if (this.filter.query.category) {
        query.category = this.filter.query.category
      }

      // Creator ID
      if (this.filter.query.creator) {
        query.creator = this.filter.query.creator
      }

      // Relative date
      if (this.filter.query.relative_date.amount) {
        query.relative_date = this.filter.query.relative_date.amount + '|' + this.filter.query.relative_date.unit + '|' + this.filter.query.relative_date.operator
      }

      // Created specific date
      if (this.filter.query.created.start) {
        query.created_range = moment(this.filter.query.created.start).toLocaleString();
        if (this.filter.query.created.end) {
          query.created_range = query.created_range.concat(['|', moment(this.filter.query.created.end).toLocaleString()])
        }
      }

      // Updated specific date
      if (this.filter.query.updated.start) {
        query.updated_range = moment(this.filter.query.updated.start).toLocaleString();
        if (this.filter.query.updated.end) {
          query.updated_range = query.updated_range.concat(['|', moment(this.filter.query.updated.end).toLocaleString()])
        }
      }

      this.$inertia.visit(route(`cms.dam.assets.index`, query));
    },
    saveFilter() {
      if (this.canEditFilter) {
        if (this.filter.id) {
          // Update
          this.$CMS_UPDATE('dam.filters',{filter: this.filter.id},this.filter).then(() => this.getFilters());
        } else {
          // Create
          this.$CMS_CREATE('dam.filters',{},this.filter).then(() => this.getFilters());
        }
      } else {
        console.warn('Not allowed to edit this filter!');
      }
    },
    setFilter(id) {
      if (id) {
        this.filter = this.filters.find(f => f.id == id)
      } else {
        this.filter = JSON.parse(JSON.stringify(defaultFilter))
      }
    }
  },
  mounted() {
    this.getFilters();
  }
}
</script>
