<template>
  <select
    :id="id"
    :name="id"
    :autocomplete="id"
    :disabled="disabled"
    v-model="selected"
    @change="$emit('change', $event)"
    :class="[`${disabled ? 'pointer-events-none opacity-80 bg-gray-100 text-gray-700' : ''}`, 'block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:text-sm border-gray-300 rounded-md']"
  >
    <template v-if="Array.isArray(options) && options.length">
      <option value="" :selected="!selected">{{ placeholder || 'Select...' }}</option>
      <template v-if="(typeof options[0] === 'object')">
        <option
          v-for="(opt, idx) in options"
          :key="`select-${id}-opt-${idx}`"
          :selected="selected === opt.value"
          :value="opt.value"
        >
          {{ opt.label || `Option ${idx}` }}
        </option>
      </template>
      <template v-else>
        <option
          v-for="(opt, idx) in options"
          :key="`select-${id}-opt-${idx}`"
          :selected="selected === opt"
          :value="opt"
        >
          {{ opt || `Option ${idx}` }}
        </option>
      </template>
    </template>
    <template v-else>
      <slot />
    </template>
  </select>
</template>
<script>
import {v4 as uuidv4} from 'uuid'
export default {
  name: 'ElSelectClassic',
  props: {
    id: {
      type: String,
      default: () => uuidv4()
    },
    value: {
      type: [String, Number, Boolean, Object],
      default: ''
    },
    options: {
      type: Array,
      default: () => []
    },
    placeholder: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    selected: {
      get() {
        return (this.value && typeof this.value.id !== 'undefined') ? this.value.id : (this.value || '')
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  }
}
</script>
