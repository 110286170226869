<template>
  <div v-if="value" class="fixed bg-gray-800/60 backdrop-blur-sm top-0 left-0 w-full h-full z-50 flex items-center justify-center py-16 px-24">
    <button class="absolute top-16 right-24 -translate-y-1/2 translate-x-1/2 rounded-full p-1 border-2 border-solid border-white shadow-lg bg-black" title="Cancel" type="button" @click="cancelModal">
      <XIcon class=" w-6 h-6 text-white" />
    </button>

    <div class="w-full min-h-full max-h-[90vh] bg-white shadow-xl p-5 flex flex-col xl:flex-row gap-6">
      <div class="flex-grow divide-y divide-gray-200 max-h-full overflow-auto">
        <div v-for="(asset,idx) in assets" :key="`action-modal-asset-${asset.id}`" class="flex gap-4 py-4 px-6 justify-start items-center">
          <input type="checkbox"
            v-model="selectedAssets"
            :value="asset.id"
            class="rounded border border-gray-300 w-6 h-6 flex-none"
          />
          <div class="w-2/3 overflow-hidden text-ellipsis whitespace-nowrap flex-shrink-0">{{ asset.name }}</div>
          <div class="ml-auto">
            <el-select :options="shareOptions(asset.type)" v-model="assetConfigs[idx]" />
          </div>
        </div>
      </div>
      <div class="bg-gray-50 p-4 rounded w-full xl:w-80 flex-none space-y-4">
        <base-input label="Action">
          <el-select :options="actionOptions" v-model="selectedAction" />
        </base-input>
        <dam-button tag="button" @click="runActionOnSelected">Run Action On Selected</dam-button>
      </div>
    </div>
  </div>
</template>
<script>
import DamButton from './Button.vue'
import Form from '../../../components/Form'
import { XIcon } from '@vue-hero-icons/solid'
export default {
  components: {
    DamButton,
    XIcon,
    ...Form,
  },
  props: {
    assets: {
      type: Array,
      default: () => []
    },
    value: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      actionOptions: [],
      allShareOptions: [],
      selectedAction: null,
      selectedAssets: [],
      assetConfigs: [],
    }
  },
  mounted() {
    if (this.$inertia.page.props.actions) {
      this.actionOptions = this.$inertia.page.props.actions.map(item => {return {value: item.id, label: item.name}});
    } else {
      this.$CMS_GET('dam.actions.index', {}, true).then(data => {
        this.actionOptions = data.items.map(item => {return {value: item.id, label: item.name}});
      })
    }
    if (this.$inertia.page.props.suffixes) {
      this.allShareOptions = this.$inertia.page.props.suffixes;
    } else {
      console.warn('Missing suffixes');
    }
  },
  created: function() {
    document.addEventListener('keyup', this.escape);
  },
  destroyed: function() {
    document.removeEventListener('keyup', this.escape);
  },
  watch: {
    assets(newAss, oldAss) {
      this.selectedAssets = newAss.map(ass => ass ? ass.id : null),
      this.assetConfigs = newAss.map(() => '');
    },
  },
  methods: {
    escape(event) {
      if (event.keyCode === 27) {
        this.cancelModal();
      }
    },
    cancelModal() {
      this.$emit('input', false);
    },
    shareOptions(type) {
      let opts = [{label: 'Original Format', value: ''}];
      if (type.includes('image')) {
        return opts.concat(this.allShareOptions.filter(opt => ['jpg', 'png'].some(v => opt.value.includes(v))));
      } else if (type.includes('video')) {
        return opts.concat(this.allShareOptions.filter(opt => ['mp4', 'mov'].some(v => opt.value.includes(v))));
      } else if (type.includes('audio')) {
        return opts.concat(this.allShareOptions.filter(opt => ['mp3', 'wav'].some(v => opt.value.includes(v))));
      } else {
        return opts;
      }
    },
    runActionOnSelected() {
      this.$CMS_POST('dam.actions.run_bulk', {}, {
        assets: this.assets.map((ass, idx) => {
          return {
            asset_id: ass.id,
            suffix: this.assetConfigs[idx],
            selected: this.selectedAssets.includes(ass.id),
          }
        }),
        action: this.selectedAction
      }).then(data => {
        if (data.status === 'success') {
          this.$noty.success('Job dispatched.');
        } else if (data.message) {
          this.$noty.error(data.messsage);
        } else {
          this.$noty.error('Job failed.');
        }
      });

    }
  }
}
</script>
