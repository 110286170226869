<template>
  <app-layout>
    <base-card class="space-y-4 mb-10 relative">

      <div class="hidden lg:block absolute -top-10 right-0 transform -translate-y-1/2 z-10">
        <base-button
          :href="route(`cms.commands.show`, { command: command.id })"
          tag="a"
          type="link"
        >
          Edit
        </base-button>
      </div>

      <base-heading>php artisan {{ command.command }} {{ params }}</base-heading>

      <form @submit.prevent="run" enctype="multipart/form-data" v-if="command.uploads.length && form && form.uploads">
        <fieldset v-for="(upload, idxU) in command.uploads" :key="`cmd-upload-${idxU}`">
          <base-label>{{ upload.label || 'Upload' }}</base-label>
          <input @input="setUpload(idxU, $event)" required type="file" />
        </fieldset>
      </form>

      <base-button @click="run">Run</base-button>

      <div>
        <base-heading class="mt-8">Output:</base-heading>
        <div ref="cmdout" class="mt-4 coding inverse-toggle px-5 pt-4 shadow-inner text-gray-100 text-sm font-mono subpixel-antialiased
                bg-gray-800  pb-6 rounded-lg leading-normal overflow-auto max-h-56">
          <p v-if="error" class="text-red-500">{{ error }}</p>
          <div v-else class="flex">
            <span class="text-green-400">command:~$</span>
            <pre class="flex-1 typing items-center pl-2 prewrap">{{ output }}</pre>
          </div>
        </div>
      </div>

      <div v-if="command.runs && command.runs.length">
        <base-heading class="mt-8">Runs</base-heading>
        <base-table table-css="table-fixed xl:table-auto">
          <template v-slot:head>
            <table-heading class="w-5/12 xl:w-auto">
              Date
            </table-heading>
            <table-heading class="w-3/12 max-w-[20rem] xl:w-auto">
              User
            </table-heading>
            <table-heading class="w-3/12  max-w-[8rem] xl:w-auto">
              Success
            </table-heading>
          </template>
          <template v-slot:default>
            <template v-for="(run, idxR) in command.runs">
              <tr class="bg-white" :key="`messageRow${idxR}`">
                <table-cell>
                  {{ run.updated_at }}
                </table-cell>
                <table-cell>
                  {{ run.user.first_name }}  {{ run.user.last_name }}
                </table-cell>
                <table-cell>
                  <check-circle-icon v-if="run.success" class="w-4 h-4 text-green-600" />
                  <x-circle-icon v-else class="w-4 h-4 text-red-600" />
                </table-cell>
              </tr>
            </template>
          </template>
        </base-table>
      </div>

    </base-card>
  </app-layout>
</template>
<script>
import axios from 'axios'
import Echo from 'laravel-echo'
import Form from '../components/Form'
import { XIcon, XCircleIcon, CheckCircleIcon } from '@vue-hero-icons/solid'
import Table from '../components/Table'
axios.defaults.withCredentials = true;
export default {
  name: 'CommandRun',
  components: {
    ...Form,
    ...Table,
    XIcon,
    XCircleIcon,
    CheckCircleIcon
  },
  props: {
    command: {
      type: Object,
      default: () => {
        return {
          id: 0,
          name: '',
          command: '',
          parameters: [],
          uploads: [],
          queue: false,
          schedule: ''
        }
      }
    }
  },
  data() {
    return {
      output: 'Waiting...',
      error: '',
      form: this.$inertia.form({}),
      interval: null,
      currentRuns: 0
    }
  },
  computed: {
    params() {
      if (this.command.parameters.length) {
        return this.command.parameters.map(p => {
          return `${p.key}=${p.value}`;
        }).join(' ');
      } else {
        return ''
      }
    }
  },
  methods: {
    setUpload(idx, $event) {
      console.log($event);
      this.form.uploads[idx] = $event.target.files[0];
    },
    polling() {
      this.$inertia.reload({
        only: ['command'],
        onSuccess: () => {
          if (this.command.runs.length > this.currentRuns && this.command.runs[0].success) {
            this.currentRuns = parseInt(this.command.runs.length);
            clearInterval(this.interval);
            this.output = 'Success!';
          } else {
            this.output += '.';
          }
        }
      });
    },
    run() {
      this.form.post(route('cms.commands.run', {command: this.command.id}), {
        forceFormData: true,
        preserveScroll: true,
        preserveState: true,
        onSuccess: (resp) => {
          if (!this.$echo) {
            this.output = `php artisan ${this.command.command} ${this.params}\n`;
            this.interval = setInterval(this.polling, 1000);
          }
        },
        onError: (err) => {
          console.error(err.response);
          if (err.response.data.message) {
            this.error = err.response.data.message
          }
        }
      });
    }
  },
  mounted() {
    if (this.command) {
      const form = {uploads: []};
      for (var i=0; i<this.command.uploads.length; i++) {
        form.uploads[i] = '';
      }
      this.form = this.$inertia.form(form);
      this.currentRuns = parseInt(this.command.runs.length);
    }
    if (this.$echo) {
      this.$echo.private(`cms.commands.${this.command.id}`)
        .listen('CommandStarted', (e) => {
          this.output = `php artisan ${this.command.command} ${this.params}`;
        })
        .listen('CommandEvent', (e) => {
          this.output = e.output;
          this.$nextTick().then(() => {
            const cmdout = this.$refs.cmdout;
            cmdout.scrollTop = cmdout.scrollHeight;
          })
        })
        .listen('CommandFinished', (e) => {
          this.$inertia.reload({
            only: ['command']
          })
        })
    }
  }
}
</script>
