<template>
  <a v-if="tag === 'a'" @click="$emit('click', $event)" v-bind="buttonProps">
    <slot />
  </a>
  <inertia-link v-else-if="tag === 'inertia-link'" @click="$emit('click', $event)" v-bind="buttonProps">
    <slot />
  </inertia-link>
  <button v-else type="button" @click="$emit('click', $event)" v-bind="buttonProps">
    <slot />
  </button>
</template>
<script>
export default {
  name: 'ActionsButton',
  props: {
    tag: {
      type: String,
      default: 'button',
      validator: (value) => {
        return ['button', 'inertia-link', 'a'].includes(value);
      }
    }
  },
  computed: {
    buttonProps() {
      return {
        ...this.$attrs,
        'class': 'w-full group flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900',
        'role': 'menuitem'
      }
    }
  }
}
</script>
