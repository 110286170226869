<template>
  <div :class="['col-span-1 flex text-center bg-white rounded-lg shadow', display === 'grid' ? 'flex-col divide-y divide-gray-200' : 'flex-row items-center']">
    <div :class="['p-4', display === 'row' ? 'flex gap-4 items-center w-full' : '']">
      <div :class="['relative  bg-gray-200', display === 'grid' ? 'w-full pt-[100%]' : 'w-20 h-20']">
        <img v-if="image" :src="image" :alt="description" class="w-full h-full object-cover block absolute top-0 left-0">
        <slot name="image" v-else />
      </div>
      <p v-if="description" :class="['text-gray-400 text-sm', display === 'grid' ? 'mt-2' : '', wrapDescription ? '' : 'overflow-hidden whitespace-nowrap overflow-ellipsis']">{{ description }}</p>
      <p v-if="extraDescription" :class="['hidden text-gray-400 text-sm ml-auto', display === 'row' ? 'xl:block' : '']">{{ extraDescription }}</p>
    </div>
    <div v-if="$slots.default" class="p-3">
      <slot />
    </div>
  </div>
</template>
<script>
export default {
  name: 'ImageCard',
  props: {
    image: '',
    description: '',
    extraDescription: '',
    wrapDescription: true,
    display: {
      type: String,
      default: 'grid',
      validator(value) {
        return ['grid', 'row'].includes(value);
      }
    },
  },
};
</script>
