<template>
  <component
    :is="tag"
    :type="tag === 'button' ? (submit ? 'submit' : 'button') : false"
    :href="(tag === 'a' || tag === 'inertia-link') ? href : false"
    :class="[cssClasses, disabled ? 'opacity-50 cursor-not-allowed' : hoverClasses]"
    :target="target.length ? target : false"
    @click="click($event)"
  >
    <slot />
  </component>
</template>
<script>
export default {
  name: 'BaseButton',
  props: {
    href: {
      type: String,
      default: 'javascript:;'
    },
    tag: {
      type: String,
      default: 'button'
    },
    icon: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'primary'
    },
    submit: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    target: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      cssClasses: '',
      hoverClasses: ''
    }
  },
  computed: {
    textSize() {
      return this.small ? 'text-xs' : 'text-sm'
    },
    buttonPadding() {
      return this.small ? 'px-3 py-2' : 'px-4 py-2'
    }
  },
  methods: {
    click(evt) {

      if(this.submit) {
        evt.preventDefault()
      }

      if (this.disabled) {
        return false;
      } else {
        this.$emit('click', evt);
      }
    }
  },
  mounted() {

    if (!this.icon) {
      this.cssClasses = `${this.textSize} font-bold uppercase tracking-wider transition justify-center`;
      switch(this.type) {
        case 'link':
          this.cssClasses += ' transition-spacing pb-2 border-b-4 border-current';
          this.hoverClasses = 'hover:border-b-8 hover:pb-1';
        break;
        case 'secondary':
          this.cssClasses += ` ${this.buttonPadding} inline-flex items-center bg-gray-500 border border-transparent rounded-md font-semibold text-xs text-white uppercase tracking-widest disabled:opacity-25 transition ease-in-out duration-150`;
          this.hoverClasses = 'hover:bg-gray-400 active:bg-gray-600 focus:outline-none focus:border-gray-900 focus:ring ring-gray-300';
        break;
        case 'danger':
          this.cssClasses += ` ${this.buttonPadding} inline-flex items-center bg-red-600 border border-transparent rounded-md font-semibold text-xs text-white uppercase tracking-widest disabled:opacity-25 transition ease-in-out duration-150`;
          this.hoverClasses = 'hover:bg-red-400 active:bg-red-600 focus:outline-none focus:border-red-900 focus:ring ring-red-300';
        break;
        case 'dam':
          this.cssClasses += ` ${this.buttonPadding} inline-flex items-center bg-indigo-500 border border-transparent rounded-md font-semibold text-xs text-white uppercase tracking-widest disabled:opacity-25 transition ease-in-out duration-150`;
          this.hoverClasses = 'hover:bg-indigo-400 active:bg-indigo-600 focus:outline-none focus:border-indigo-900 focus:ring ring-indigo-300';
        break;
        case 'primary':
        default:
          this.cssClasses += ` ${this.buttonPadding} inline-flex items-center bg-gray-800 border border-transparent rounded-md font-semibold text-xs text-white uppercase tracking-widest disabled:opacity-25 transition ease-in-out duration-150`;
          this.hoverClasses = 'hover:bg-gray-700 active:bg-gray-900 focus:outline-none focus:border-gray-900 focus:ring ring-gray-300';
      }
    }

  }


}
</script>
