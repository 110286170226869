<template>
  <app-layout>
    <base-card class="space-y-8 mb-10">
      <form @submit.prevent="updateReport">
        <div class="mb-4">
          <base-input
            v-model="report.name"
            type="text"
            label="Name"
            placeholder=""
            required
            aria-autocomplete="false"
          />
        </div>
        <div v-if="report.query" class="mt-8">
          <base-label>Query</base-label>
          <div class="bg-gray-50 overflow-hidden rounded-lg">
            <div class="px-4 py-5 sm:p-6">
              <div v-for="(query, idx) in report.query" :key="`searchQuery${idx}`">
                <div class="flex items-end">
                  <search-query
                    v-model="report.query[idx]"
                    :modules="modules"
                    :class="[`${idx === report.query.length - 1 ? 'mr-4' : ''}`, 'w-full']"
                    :canDelete="idx > 0"
                    :subjectDisabled="idx > 0"
                    @addQuery="addQuery(idx)"
                    @removeQuery="removeQuery(idx)"
                    @input="updatedQuery"
                  />
                </div>
                <div v-if="idx <= report.query.length - 2" class="mt-4 w-full font-bold tracking-widest uppercase text-center">- AND -</div>
              </div>
            </div>
          </div>

          <div class="mt-8" v-if="report.columns && report.columns.length">
            <base-label tooltip="Rename, reorder, and toggle columns to best suit your needs">Columns</base-label>
            <div v-if="report.columns && report.columns.length">
              <base-table sortable v-model="report.columns">
                <template v-slot:head>
                  <table-heading>ID</table-heading>
                  <table-heading>Display Name</table-heading>
                  <table-heading>Show</table-heading>
                </template>
                <template v-slot:default>
                  <tr v-for="(col, idxC) in report.columns" :key="`reportColumn${idxC}`">
                    <table-cell>{{col.column}}</table-cell>
                    <table-cell><base-input v-model="report.columns[idxC].name" /></table-cell>
                    <table-cell><base-switch v-model="report.columns[idxC].show" /></table-cell>
                  </tr>
                </template>
              </base-table>
            </div>
            <p v-else>No results found for your query...</p>
          </div>
        </div>
      </form>

      <div>
        <!-- TODO: User subscription FED and INT -->
        <base-label tooltip="Automated delivery of this report">Subscriptions</base-label>
        <base-table>
          <template v-slot:head>
            <table-heading>
              Email
            </table-heading>
            <table-heading>
              Frequency
              <base-tooltip class="inline-block ml-2 w-4 h-4 text-center align-middle normal-case" :offsetY="5">
                <template v-slot:content>
                  See <a href="https://crontab.guru" target="_blank">https://crontab.guru</a> for reference
                </template>
                <information-circle-icon class="align-top w-full h-full inline-block" />
              </base-tooltip>
            </table-heading>
            <table-heading>
              Delta
              <base-tooltip class="inline-block ml-2 w-4 h-4 text-center align-middle normal-case" :offsetY="5">
                <template v-slot:content>
                  Only get the items created since last run.
                </template>
                <information-circle-icon class="align-top w-full h-full inline-block" />
              </base-tooltip>
            </table-heading>
            <table-heading>
              Last Run
            </table-heading>
            <table-heading class="w-16">
              <span class="sr-only">Delete</span>
            </table-heading>
          </template>
          <template v-if="report.users.length" v-slot:default>
            <tr v-for="(usr, idxU) in report.users" :key="`user${usr.id}`">
              <table-cell>
                {{ usr.email }}
              </table-cell>
              <table-cell>
                <base-input class="font-mono" v-model="report.users[idxU].pivot.frequency" />
              </table-cell>
              <table-cell>
                <base-switch v-model="report.users[idxU].pivot.delta" />
              </table-cell>
              <table-cell>
                {{ usr.pivot.last_run }}
              </table-cell>
              <table-cell class="w-16">
                <base-button icon @click="removeUser(usr.id)" v-if="isAdmin || usr.id == user.id">
                  <x-icon class="w-4 h-4" />
                </base-button>
              </table-cell>
            </tr>
          </template>
          <template v-else v-slot:default>
            <tr>
              <table-cell colspan="4">No user subscriptions</table-cell>
            </tr>
          </template>
          <template v-slot:foot>
            <table-cell colspan="4">
              <el-select @change="addUser()" v-model="addUserSelect">
                <option value="" selected>Add a User...</option>
                <option
                  v-for="user in users"
                  :key="`addUser${user.id}`"
                  :value="user"
                >
                  {{ user.email }}
                </option>
              </el-select>
            </table-cell>
          </template>
        </base-table>
      </div>

      <div class="flex">
        <base-button @click="updateReport" class="mr-4">
          Save
        </base-button>
        <base-button v-if="report.id" tag="a" :href="route('cms.reports.download', report.id)" target="_blank">Download (.csv)</base-button>
      </div>
    </base-card>
  </app-layout>
</template>
<script>
import Form from '../components/Form'
import Table from '../components/Table'
import SearchQuery from '../components/Misc/SearchQuery'
import { XIcon, InformationCircleIcon } from '@vue-hero-icons/solid'
export default {
  name: 'ReportEdit',
  components: {
    ...Form,
    ...Table,
    SearchQuery,
    InformationCircleIcon,
    XIcon
  },
  props: {
    report: {
      type: Object,
      default: () => {
        return {
          name: '',
          query: [],
          columns: [],
          users: []
        }
      }
    },
    modules: {
      type: Array,
      default: () => []
    },
    users: {
      type: Array,
      default: () => []
    },
    isAdmin: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    user() {
      return this.$page.props.user
    }
  },
  data() {
    return {
      searchQueryBasis: {
        subject: '',
        column: '',
        query: '',
        operator: '',
        or: [],
        and: []
      },
      subject: '',
      queried: false,
      results: [],
      addUserSelect: ''
    }
  },
  methods: {
    addQuery(idx) {
      const basis = JSON.parse(JSON.stringify(this.searchQueryBasis));
      this.report.query.splice(idx+1, 0, {
        ...basis,
        subject: this.report.query[idx].subject
      });
    },
    removeQuery(idx) {
      this.report.query.splice(idx, 1);
    },
    updatedQuery() {
      // if the subject is changed, remove columns
      if (this.subject && this.report.query.length && this.subject !== this.report.query[0].subject) {
        this.report.columns = [];
      }
    },
    removeUser(id) {
      this.report.users = this.report.users.filter(u => {
        return u.id !== id;
      });
    },
    addUser() {
      if (this.addUserSelect && typeof this.addUserSelect === 'object') {
        const user = JSON.parse(JSON.stringify(this.addUserSelect));
        user.pivot = {
          via: 'email',
          frequency: '0 0 * * *',
          delta: false,
          last_run: ''
        }
        this.report.users.push(user);
      }
    },
    updateReport() {
      if(this.report.id) {
        this.$root.$CMS_UPDATE('reports', this.report.id, this.report);
      } else {
        this.$root.$CMS_CREATE('reports', {}, this.report).then((data)=>{
          if(data.report.id) {
            window.location.href = route('cms.reports.show', data.report.id);
          }
        });
      }
    },

  },
  mounted() {
    if (this.report.query && this.report.query.length) {
      this.subject = this.report.query[0].subject;
    }
  }
}
</script>
