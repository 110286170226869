<template>
  <fieldset>
    <div class="bg-white rounded-md -space-y-px">
      <div
        v-for="(opt, idx) in options"
        :key="`radioGroup-${uuid}-option-${idx}`"
        :class="[
          `${idx === 0 ? 'rounded-tl-md rounded-tr-md' : ''}`,
          `${idx === options.length-1 ? 'rounded-bl-md rounded-br-md' : ''}`,
          `${opt.value === value ? 'bg-indigo-50 border-indigo-200 z-10' : 'border-gray-200'}`,
          bordered ? 'border p-4' : 'py-3',
          'relative flex'
        ]"
      >
        <div class="flex items-center h-5">
          <input
            type="radio"
            class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 cursor-pointer border-gray-300"
            :id="`radioGroup-${uuid}-option-${idx}`"
            :name="`radioGroup-${uuid}`"
            :checked="opt.value === value"
            :value="opt.value"
            @input="updateValue(opt.value)"
          />
        </div>
        <label :for="`radioGroup-${uuid}-option-${idx}`" class="ml-3 flex flex-col cursor-pointer">
          <span :class="[
            `${opt.value === value ? 'text-indigo-900' : 'text-inherit'}`,
            'block text-sm font-medium'
          ]">
            {{ opt.label }}
          </span>
        </label>
      </div>
    </div>
  </fieldset>
</template>
<script>
export default {
  name: 'RadioGroup',
  props: {
    value: {
      type: String,
      default: ''
    },
    options: {
      type: Array,
      default: () => []
    },
    bordered: {
      type: Boolean,
      default: true
    },
  },
  methods: {
    updateValue(val) {
      this.$emit('input', val);
    }
  }
}
</script>
