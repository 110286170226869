<template>
  <ul class="mt-0.5 py-1 space-y-2 pl-3 border-solid border-l border-current">
    <li v-for="child in children" :key="`collection-${child.id}`">
      <div class="flex gap-4 items-center justify-between">
        <inertia-link :href="route('cms.dam.assets.index', { ...route().params, collection: child.id})" :class="selectedCollection == child.id ? 'underline text-indigo-600' : ''">{{ child.name }} ({{ child.asset_count }})</inertia-link>
        <inertia-link v-if="selectedCollection == child.id" :href="route('cms.dam.assets.index', { ...route().params, collection: null})" class="text-red-500"><XIcon class="w-4 h-4" /></inertia-link>
      </div>
      <nested-collection-filter v-if="child.children && child.children.length" :children="child.children" :selectedCollection="selectedCollection" />
     </li>
  </ul>
</template>
<script>
import { XIcon } from '@vue-hero-icons/solid'
export default {
  name: 'NestedCollectionFilter',
  props: ['selectedCollection', 'children'],
  components: { XIcon }
}
</script>
