<template>
  <div v-if="ready">
    <base-label>{{ element.pivot.label }}</base-label>
    <div
      v-for="(mk,idxM) in element.markup"
      :key="`elmk.${idxM}`"
    >
      <div v-if="(mk.is === 'draggable' && typeof element.pivot !== 'undefined')">
        <bldr-repeater
          v-model="mk.data"
          :element="element"
        />
      </div>
      <component
        :is="mk.is"
        v-else-if="mk.is === 'file-upload' || mk.is === 'base-file'"
        :config-i-d="element.pivot.upload_config_id || element.pivot.upload_config"
        :files="mk.fileList || []"
        @fileupdated="updateFiles($event, mk)"
      />
      <!-- eslint-disable-next-line -->
        <component v-else-if="typeof mk === 'object'" :is="mk.is" v-bind="mk" :isDynamic="true" :name="`c${component.id}el${element.id}_${idxM}`" :placeholder="element.pivot.placeholder || mk.placeholder || ''" v-model="mk.data" @input="inputEvent">
        <component
          :is="mk.child.is"
          v-if="typeof mk.child === 'object'"
          v-bind="mk.child"
          :placeholder="element.pivot.placeholder || mk.child.placeholder || ''"
          :name="element.pivot.variable"
        />

        <div
          v-else-if="typeof mk.child === 'string'"
          v-html="mk.child"
        />
      </component>
    </div>
    <small
      v-if="element.pivot.helptext"
      slot="helperText"
      class="form-text text-muted"
    >{{ element.pivot.helptext }}</small>
  </div>
</template>
<script>
import Form from '../Form'
import BldrReapeater from './BldrRepeater'
export default {
  name: 'BldrComponent',
  components: {
    ...Form,
    BldrReapeater
  },
  props: {
    element: Object,
    component: Object
  },
  data() {
    return {
      ready: true,
      markup: []
    }
  },
  methods: {
    updateFiles($event, field) {
      console.log(field);
      if ($event.length && typeof field !== undefined) {
        field.data = $event.map((file) => {
          delete file.dataURL;
          return file;
        });
      }
    },
    inputEvent(evt) {
      this.$emit('input', {evt, element: this.element, component: this.component});
    }
  }
}
</script>
