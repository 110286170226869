<template>
  <fieldset :class="['inline-flex items-center min-w-0 max-w-full', reversed ? 'flex-row-reverse justify-end' : 'justify-start']">
    <base-label :for="id" class="mr-3 min-w-0" :span-class="cutoff ? 'inline-block max-w-full overflow-hidden whitespace-nowrap text-ellipsis' : ''">
      <slot />
    </base-label>
    <input type="checkbox" class="mr-3 h-4 w-4 rounded flex-none" :id="id" :name="inputName" v-model="data" />
  </fieldset>
</template>
<script>
import {v4 as uuidv4} from 'uuid'
import BaseLabel from './BaseLabel'
export default {
  components: { BaseLabel },
  name: 'BaseCheckbox',
  props: {
    value: {
      type: [Boolean, Number],
      default: () => false
    },
    id: {
      type: String,
      default: () => uuidv4()
    },
    name: {
      type: String,
      default: () => ''
    },
    reversed: {
      type: Boolean,
      default: false
    },
    cutoff: {
      type: Boolean,
      default: false
    },
    checked: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    data: {
      get() {return this.value},
      set(val) {this.$emit('input', val)}
    },
    inputName() {
      return this.name.length ? this.name : this.id
    }
  }
}
</script>
