<template>
  <div class="relative block base-popover-container" :ref="`base-popover-${uuid}-container`">
    <button @click="toggle()" :id="`base-popover-${uuid}`" :ref="`base-popover-${uuid}-button`" aria-haspopup="true" type="button" class="block">
      <slot name="icon">
        <dots-vertical-icon class="w-5 h-5" />
      </slot>
    </button>
    <div
      class="base-popover w-80 max-w-screen rounded-md shadow-xl z-10 bg-white p-6 transition-opacity opacity-0"
      role="menu"
      aria-orientation="vertical"
      :aria-labelledby="`base-popover-${uuid}`"
      :ref="`base-popover-${uuid}`"
    >
      <div class="base-popover-arrow" data-popper-arrow></div>
      <div class="space-y-4 max-h-[90vh] flex flex-col">
        <div class="space-y-4 flex-auto overflow-auto scroll-shadows">
          <slot />
        </div>
        <div class="mt-6 flex-none">
          <base-button
            type="primary"
            @click="toggle"
          >
            <slot name="close">
              Done
            </slot>
          </base-button>
          <slot name="btns" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { DotsVerticalIcon } from '@vue-hero-icons/solid';
import { createPopper } from '@popperjs/core';
export default {
  name: 'BasePopover',
  components: {
    DotsVerticalIcon,
  },
  data() {
    return {
      open: false,
      container: null,
      button: null,
      popover: null,
      instance: null,
    };
  },
  methods: {
    toggle() {
      if (this.open) {
        this.open = false;
        this.popover.removeAttribute('data-show');
        this.$emit('close');
      } else {
        window.dispatchEvent(
          new CustomEvent('basePopoverOpen', { detail: { uid: this.uuid } })
        );
        this.instance.update();
        this.popover.setAttribute('data-show', '');
        this.open = true;
      }
    },
  },
  mounted() {
    window.addEventListener('basePopoverOpen', (evt) => {
      if (evt.detail.uid !== this.uuid && this.open) {
        this.toggle();
      }
    });
    this.container = this.$refs[`base-popover-${this.uuid}-container`];
    this.button = this.$refs[`base-popover-${this.uuid}-button`];
    this.popover = this.$refs[`base-popover-${this.uuid}`];
    this.instance = createPopper(this.button, this.popover, {
      placement: 'right',
      strategy: 'fixed',
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 10],
          },
        },
      ],
    });
  },
};
</script>

<style>
.base-popover {
  z-index: 999999999;
}
.base-popover:not([data-show]) {
  pointer-events: none;
}
.base-popover[data-show] {
  opacity: 1 !important;
}
.base-popover-arrow,
.base-popover-arrow::before {
  position: absolute;
  width: 8px;
  height: 8px;
  background: inherit;
}

.base-popover-arrow {
  visibility: hidden;
}

.base-popover-arrow::before {
  visibility: visible;
  content: '';
  transform: rotate(45deg);
}
.base-popover[data-popper-placement^='top'] > .base-popover-arrow {
  bottom: -4px;
}

.base-popover[data-popper-placement^='bottom'] > .base-popover-arrow {
  top: -4px;
}

.base-popover[data-popper-placement^='left'] > .base-popover-arrow {
  right: -4px;
}

.base-popover[data-popper-placement^='right'] > .base-popover-arrow {
  left: -4px;
}
</style>
