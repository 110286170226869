var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.closeDropdown),expression:"closeDropdown"}],ref:`combobox-${_vm.id}-container`,staticClass:"relative",on:{"mouseleave":function($event){_vm.highlight = null}}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.query),expression:"query"}],ref:"comboBoxQuery",staticClass:"w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-12 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm",attrs:{"id":_vm.id,"placeholder":_vm.placeholderValue,"type":"text","role":"combobox","aria-controls":`${_vm.id}-options`,"aria-expanded":"false"},domProps:{"value":(_vm.query)},on:{"focus":_vm.openDropdown,"input":[function($event){if($event.target.composing)return;_vm.query=$event.target.value},_vm.queryOptions]}}),_vm._v(" "),_c('button',{ref:`combobox-${_vm.id}-button`,staticClass:"absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none",attrs:{"type":"button"},on:{"click":_vm.toggleDropdown}},[_c('selector-icon',{staticClass:"h-5 w-5 text-gray-400"})],1),_vm._v(" "),_c('transition',{attrs:{"enter-active-class":"transition-opacity ease-out duration-100","enter-from-class":"opacity-0","enter-to-class":"opacity-100","leave-active-class":"transition-opacity ease-in duration-75","leave-from-class":"opacity-100 ","leave-to-class":"opacity-0"}},[_c('ul',{directives:[{name:"show",rawName:"v-show",value:(_vm.open),expression:"open"}],ref:`combobox-${_vm.id}`,staticClass:"fixed duration-75 will-change-transform z-50 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm",attrs:{"id":`combobox-${_vm.id}-options`,"aria-controls":`${_vm.id}-options`,"role":"listbox"}},[_c('li',{key:`${_vm.id}-option--1`,class:[
          'relative cursor-default select-none py-2 pl-8 pr-4',
          _vm.highlight === -1 ? 'text-white bg-indigo-600' : 'text-gray-900',
        ],attrs:{"role":"option","tabindex":"-1"},on:{"mouseenter":function($event){_vm.highlight = -1},"click":function($event){return _vm.setValue(-1)}}},[_c('span',{class:[
            'block truncate',
            _vm.selectedIndex === -1 ? 'font-semibold' : '',
          ]},[_vm._v(_vm._s(_vm.placeholder || 'Please Select...'))])]),_vm._v(" "),(_vm.options.length)?_vm._l((_vm.options),function(opt,idxO){return _c('li',{key:`${_vm.id}-option-${idxO}`,class:[
            'relative cursor-default select-none py-2 pl-8 pr-4',
            _vm.highlight === idxO ? 'text-white bg-indigo-600' : 'text-gray-900',
          ],attrs:{"role":"option","tabindex":"-1"},on:{"mouseenter":function($event){_vm.highlight = idxO},"click":function($event){return _vm.setValue(idxO)}}},[_c('span',{class:[
              'block truncate',
              _vm.selectedIndex === idxO ? 'font-semibold' : '',
            ]},[_vm._v("\n            "+_vm._s(typeof opt === 'object' && opt.label ? opt.label : opt)+"\n          ")]),_vm._v(" "),(_vm.selectedIndex === idxO)?_c('span',{class:[
              'absolute inset-y-0 left-0 flex items-center pl-1.5 ',
              _vm.highlight === idxO ? 'text-white' : 'text-indigo-600',
            ]},[_c('check-icon',{staticClass:"h-5 w-5"})],1):_vm._e()])}):_c('li',{staticClass:"relative cursor-default select-none py-2 pl-8 pr-4 text-gray-900"},[_vm._v("\n        No Options Found.\n      ")])],2)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }