var render = function render(){var _vm=this,_c=_vm._self._c;return ((_vm.item && typeof _vm.idx === 'number'))?_c('div',[((typeof _vm.item.children !== 'undefined' && Array.isArray(_vm.item.children)))?_c('div',{staticClass:"space-y-2"},[_c('button',{class:_vm.cssClass,on:{"click":function($event){_vm.isOpen = !_vm.isOpen}}},[_c(`${_vm.item.icon}-icon`,{tag:"component",class:_vm.iconClass}),_vm._v(" "),_c('span',{staticClass:"inline-block ml-2 whitespace-nowrap overflow-hidden overflow-ellipsis"},[_vm._v(_vm._s(_vm.item.name))]),_vm._v(" "),_c('svg',{staticClass:"ml-auto h-5 w-5 transform group-hover:text-gray-200 transition-colors ease-in-out duration-150",class:_vm.isOpen ? 'text-gray-200 rotate-90' : 'text-gray-100',attrs:{"viewBox":"0 0 20 20","aria-hidden":"true"}},[_c('path',{attrs:{"d":"M6 6L14 10L6 14V6Z","fill":"currentColor"}})])],1),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isOpen),expression:"isOpen"}],staticClass:"space-y-1 bg-gray-900 bg-opacity-30 p-3 rounded-md"},[_c('inertia-link',{class:[
      'nav-child-item',
      (_vm.route().current(_vm.item.route) ? 'bg-gray-900 text-white' : 'text-gray-100 hover:bg-gray-700 hover:text-white'),
      'group flex items-center px-2 py-2 text-sm font-medium rounded-md bg-opacity-50 hover:bg-opacity-50'
    ],attrs:{"href":_vm.route(_vm.item.route)}},[_c('span',{staticClass:"2xl:hidden"},[_vm._v("MM")]),_c('span',[_vm._v("Manage "+_vm._s(_vm.item.name))])]),_vm._v(" "),_vm._l((_vm.item.children),function(child,idxC){return _c('inertia-link',{key:`navItem-${_vm.idx}-child-${idxC}`,class:[
          'nav-child-item',
          (_vm.route().current(child.route, child.id) ? 'bg-gray-900 text-white' : 'text-gray-100 hover:bg-gray-700 hover:text-white'),
          'group flex items-center px-2 py-2 text-sm font-medium rounded-md bg-opacity-50 hover:bg-opacity-50'
        ],attrs:{"href":_vm.route(child.route, child.id)}},[_c('span',{staticClass:"2xl:hidden"},[_vm._v(_vm._s(child.name.includes(' ') ? child.name.split(' ').map(n => n.substring(0,1)).join('').substring(0,2) : child.name.substring(0,2)))]),_vm._v(" "),_c('span',[_vm._v(_vm._s(child.name))])])})],2)]):_c('inertia-link',{class:_vm.cssClass,attrs:{"href":_vm.route(_vm.item.route)}},[_c(`${_vm.item.icon}-icon`,{tag:"component",class:_vm.iconClass}),_vm._v(" "),_c('span',{staticClass:"inline-block ml-2 whitespace-nowrap overflow-hidden overflow-ellipsis"},[_vm._v(_vm._s(_vm.item.name))])],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }