<template>
  <div class="flex -space-x-1 overflow-hidden relative z-1">
    <p v-for="(usr, idx) in activeUsers" :key="`${uuid}-avatar-${idx}`" :class="[
      'inline-block rounded-full ring-2 ring-white overflow-hidden relative hover:z-10',
      size == 'sm' ? 'h-6 w-6 text-xs' : '',
      size == 'md' ? 'h-8 w-8 text-sm' : '',
      size == 'lg' ? 'h-10 w-10 text-base' : '',
      size == 'xl' ? 'h-12 w-12 text-lg' : '',
    ]" :style="`left: ${20*idx}px;`">
      <img v-if="usr.user.avatar" :src="usr.user.avatar" class="block w-full h-full object-cover" :title="`${usr.user.first_name} ${usr.user.last_name}`" />
      <span class="flex w-full h-full items-center justify-center rounded-full bg-gray-500" :title="`${usr.user.first_name} ${usr.user.last_name}`">
        <span class="font-medium leading-none text-white">{{ usr.user.first_name.substring(0,1) }}{{ usr.user.last_name.substring(0,1) }}</span>
      </span>
    </p>
  </div>
</template>
<script>
export default {
  name: 'ActiveUsers',
  props: {
    size: {
      type: String,
      default: 'md',
    },
    url: {
      type: String,
      default: ''
    }
  },
  computed: {
    activeUsers() {
      if (this.url.length) {
        return this.$root.usersLocations.filter((ul) => {
          const url = String(this.url);
          if (url.includes('://')) {
            return ul.location == new URL(url).pathname;
          } else if (url.charAt(0) == '/') {
            return ul.location == url;
          } else {
            return false;
          }
        });
      } else {
        return [ ...new Map(this.$root.usersLocations.map(ul => [ul.user_id, ul])).values()];
      }
    }
  },

}
</script>
