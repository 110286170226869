<template>
  <actions-popover :ref="popoverRef">
    <actions-group v-if="trashed">
      <actions-button @click.prevent="restore()">
        <reply-icon class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" />
        Restore
      </actions-button>
    </actions-group>
    <actions-group v-else>
      <actions-button v-if="slug === 'pages'" tag="a" :href="route(`cms.preview.page`, { ...routeParams, id})" target="_blank">
        <desktop-computer-icon class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" />
        Preview
      </actions-button>
      <actions-button v-else-if="slug === 'mod'" tag="a" :href="route(`cms.preview.module`, { ...routeParams, item_id: id})" target="_blank">
        <desktop-computer-icon class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" />
        Preview
      </actions-button>
      <actions-button tag="inertia-link" :href="route(`cms.${slug}.show`, { ...routeParams, id})">
        <pencil-alt-icon class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" />
        Edit
      </actions-button>
      <actions-button v-if="route().has(`cms.${slug}.clone`)" @click.prevent="clo()">
        <duplicate-icon class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" />
        Duplicate
      </actions-button>
      <actions-button v-if="route().has(`cms.${slug}.download`)" tag="a" target="_blank" :href="route(`cms.${slug}.download`, { ...routeParams, id})">
        <download-icon class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" />
        Download
      </actions-button>
      <actions-button v-if="route().has(`cms.${slug}.export`)" tag="a" target="_blank" :href="route(`cms.${slug}.export`, { ...routeParams, id})">
        <download-icon class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" />
        Export
      </actions-button>
      <actions-button v-if="slug === 'commands'" tag="inertia-link" :href="route(`cms.commands.run.show`, { ...routeParams, id})">
        <play-icon class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" />
        Run
      </actions-button>
    </actions-group>
    <actions-group>
      <actions-button @click.prevent="del()">
        <trash-icon class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" />
        Delete
      </actions-button>
    </actions-group>
  </actions-popover>
</template>
<script>
import Actions from '../Actions';
import {
  PencilAltIcon,
  DuplicateIcon,
  TrashIcon,
  DownloadIcon,
  PlayIcon,
  DesktopComputerIcon,
  ReplyIcon,
} from '@vue-hero-icons/solid';
export default {
  name: 'ListingItemActions',
  components: {
    ...Actions,
    PencilAltIcon,
    DuplicateIcon,
    TrashIcon,
    DownloadIcon,
    PlayIcon,
    DesktopComputerIcon,
    ReplyIcon,
  },
  props: {
    id: {
      type: Number,
    },
    slug: {
      type: String,
    },
    routeParams: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      popoverRef: `listingItemActionsPopover-${this.uuid}`,
      trashed: false,
    };
  },
  methods: {
    toggle() {
      this.$refs[this.popoverRef].$emit('toggle');
    },
    del() {
      this.toggle();

      setTimeout(() => {
        this.$swal
          .fire({
            title: 'Are you sure you want to delete this?',
            showDenyButton: false,
            showCancelButton: true,
          })
          .then((result) => {
            if (result.isConfirmed) {
              this.$inertia.delete(
                route(`cms.${this.slug}.destroy`, {
                  ...this.routeParams,
                  id: this.id,
                }),
                {
                  preserveState: true,
                  preserveScroll: true,
                  only: ['items'],
                  onFinish: () =>
                    window.dispatchEvent(new CustomEvent('listingReload')),
                }
              );
            }
          });
      }, 500);
    },
    clo() {
      this.toggle();
      setTimeout(() => {
        let alertObj = {
          title: 'Are you sure you wish to duplicate?',
          showCancelButton: true,
          confirmButtonText: `Duplicate`,
        };
        if (this.slug === 'pages') {
          alertObj = {
            title: 'Do you wish to duplicate the page with the content?',
            showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: `With Content`,
            denyButtonText: `Without Content`,
          };
        }
        this.$swal.fire(alertObj).then((result) => {
          if (result.isConfirmed) {
            this.$inertia.visit(
              route(`cms.${this.slug}.clone`, {
                ...this.routeParams,
                id: this.id,
              }) + '?content=with',
              {
                preserveState: true,
                preserveScroll: true,
                only: ['items'],
                onFinish: () => {
                  window.dispatchEvent(new CustomEvent('listingReload'));
                },
              }
            );
          } else if (result.isDenied) {
            this.$inertia.visit(
              route(`cms.${this.slug}.clone`, {
                ...this.routeParams,
                id: this.id,
              }) + '?content=without',
              {
                preserveState: true,
                preserveScroll: true,
                only: ['items'],
                onFinish: () =>
                  window.dispatchEvent(new CustomEvent('listingReload')),
              }
            );
          }
        });
      }, 500);
    },
    restore() {

    }
  },
  mounted() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    if (urlParams.has('trashed')) {
      this.trashed = true;
    }
  }
};
</script>
