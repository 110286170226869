import BaseCheckbox from './BaseCheckbox'
import BaseCheckboxes from './BaseCheckboxes'
import BaseCode from './BaseCode'
import BaseFile from './BaseFile'
import BaseInput from './BaseInput'
import BaseLabel from './BaseLabel'
import BaseLink from './BaseLink'
import BaseSwitch from './BaseSwitch'
import BaseTextArea from './BaseTextArea'
import BaseVideo from './BaseVideo'
import ComboBox from './ComboBox'
import Dropzone from './Dropzone'
import ElCascade from './ElCascade'
import ElDatePicker from './ElDatePicker'
import ElSelect from './ElSelect'
import FileUpload from './FileUpload'
import FormSelect from './FormSelect'
import RadioGroup from './RadioGroup'
import TextTag from './TextTag'
import Wysiwyg from './Wysiwyg'

export {
  BaseCheckbox,
  BaseCheckboxes,
  BaseCode,
  BaseFile,
  BaseInput,
  BaseLabel,
  BaseLink,
  BaseSwitch,
  BaseTextArea,
  BaseVideo,
  ComboBox,
  Dropzone,
  ElCascade,
  ElDatePicker,
  ElSelect,
  FileUpload,
  FormSelect,
  RadioGroup,
  TextTag,
  Wysiwyg
}

export default {
  BaseCheckbox,
  BaseCheckboxes,
  BaseCode,
  BaseInput,
  BaseFile,
  BaseLabel,
  BaseLink,
  BaseSwitch,
  BaseTextArea,
  BaseVideo,
  ComboBox,
  Dropzone,
  ElCascade,
  ElDatePicker,
  ElSelect,
  FileUpload,
  FormSelect,
  RadioGroup,
  TextTag,
  Wysiwyg
}
