<template>
  <div v-if="value" class="fixed bg-gray-800/60 backdrop-blur-sm top-0 left-0 w-full h-full z-50 flex items-center justify-center py-16 px-24">
    <button class="absolute top-16 right-24 -translate-y-1/2 translate-x-1/2 rounded-full p-1 border-2 border-solid border-white shadow-lg bg-black" title="Cancel" type="button" @click="cancelModal">
      <XIcon class=" w-6 h-6 text-white" />
    </button>

    <div class="w-full min-h-full max-h-[90vh] bg-white shadow-xl p-5 flex flex-col xl:flex-row gap-6">
      <div class="flex-grow divide-y divide-gray-200 max-h-full overflow-auto">
        <div class="flex gap-4 py-4 px-6 justify-start items-start">
          <input type="checkbox"
            v-model="selectedProperties"
            value="collections"
            class="rounded border border-gray-300 w-6 h-6 flex-none"
          />
          <div class="w-2/3 flex-shrink-0 space-y-3">
            <base-label>Collections</base-label>
            <template v-if="collections.length">
              <div v-for="(col,idx) in collections" :key="`selected-collection-${idx}`" class="flex items-center gap-4">
                <p>{{ col.label }}</p>
                <button type="button" @click="removeCollection(idx)"><x-icon class="w-4 h-4 text-red-500" /></button>
              </div>
            </template>
            <p v-else>No Collections Assigned</p>
            <div v-if="addingCollection" class="space-y-2">
              <base-input label="Collection">
                <el-select :options="collectionOptions" v-model="addedCollection" />
              </base-input>
              <base-input v-if="addedCollection == -1" label="New Collection Name" v-model="newCollection" />
              <div class="space-x-2">
                <dam-button @click="addCollection">Add</dam-button>
                <dam-button @click="addingCollection = false" type="secondary">Cancel</dam-button>
              </div>
            </div>
            <button v-else type="button" class="font-bold text-blue-500 underline" @click="addingCollection = true">Add Collection</button>
          </div>
        </div>
        <div class="flex gap-4 py-4 px-6 justify-start items-start">
          <input type="checkbox"
            v-model="selectedProperties"
            value="categories"
            class="rounded border border-gray-300 w-6 h-6 flex-none"
          />
          <div class="w-2/3 flex-shrink-0 space-y-3">
            <base-label>Categories</base-label>
            <template v-if="categories.length">
              <div v-for="(col,idx) in categories" :key="`selected-category-${idx}`" class="flex items-center gap-4">
                <p>{{ col.label }}</p>
                <button type="button" @click="removeCategory(idx)"><x-icon class="w-4 h-4 text-red-500" /></button>
              </div>
            </template>
            <p v-else>No Categories Assigned</p>
            <div v-if="addingCategory" class="space-y-2">
              <base-input label="Category">
                <el-select :options="categoryOptions" v-model="addedCategory" />
              </base-input>
              <base-input v-if="addedCategory == -1" label="New Category Name" v-model="newCategory" />
              <div class="space-x-2">
                <dam-button @click="addCategory">Add</dam-button>
                <dam-button @click="addingCategory = false" type="secondary">Cancel</dam-button>
              </div>
            </div>
            <button v-else type="button" class="font-bold text-blue-500 underline" @click="addingCategory = true">Add Category</button>
          </div>
        </div>
        <div v-for="(meta,idx) in metas" :key="`meta-${idx}`" class="flex gap-4 py-4 px-6 justify-start items-start">
          <input type="checkbox"
            v-model="selectedProperties"
            :value="`meta_${meta.id > -1 ? meta.id : meta.name}`"
            class="rounded border border-gray-300 w-6 h-6 flex-none"
          />
          <div class="w-2/3 flex-shrink-0 space-y-3">
            <base-input :label="meta.name" v-model="meta.value" />
          </div>
        </div>
        <div class="flex gap-4 py-4 px-6 justify-start items-start">
          <div class="w-6 h-6 flex-none"></div>
          <div class="w-2/3 flex-shrink-0">
            <div v-if="addingMeta" class="space-y-2">
              <base-input label="Meta">
                <el-select :options="metaOptions" v-model="addedMetaGroup" />
              </base-input>
              <div v-if="addedMetaGroup == -1" class="flex gap-2">
                <base-input label="New Meta Name" v-model="newMeta.name" />
                <base-input label="New Meta Value" v-model="newMeta.value" />
              </div>
              <base-input v-else-if="addedMetaGroup >= 0" label="Meta Value" v-model="addedMetaValue" />
              <div class="space-x-2">
                <dam-button @click="addMeta">Add</dam-button>
                <dam-button @click="addingMeta = false" type="secondary">Cancel</dam-button>
              </div>
            </div>
            <button v-else type="button" class="font-bold text-blue-500 underline" @click="addingMeta = true">Add Meta</button>
          </div>
        </div>
      </div>
      <div class="bg-gray-50 p-4 rounded w-full xl:w-80 flex-none space-y-4">
        <div v-for="(asset,idx) in assets" :key="`edit-modal-asset-${idx}`" class="flex gap-4 py-4 px-6 justify-start items-center">
          <input type="checkbox"
            v-model="selectedAssets"
            :value="asset.id"
            class="rounded border border-gray-300 w-6 h-6 flex-none"
          />
          <div class="w-2/3 overflow-hidden text-ellipsis whitespace-nowrap flex-shrink-0">{{ asset.name }}</div>
        </div>
        <div class="py-4 px-6 border-t border-solid border-gray-200">
          <dam-button tag="button" @click="save">Update Selected</dam-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DamButton from './Button.vue'
import Form from '../../../components/Form'
import { XIcon } from '@vue-hero-icons/solid'
export default {
  components: {
    DamButton,
    XIcon,
    ...Form,
  },
  props: {
    assets: {
      type: Array,
      default: () => []
    },
    value: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    collectionOptions() {
      return [
        {
          label: 'Create New Collection',
          value: -1
        },
        ...this.$page.props.collections.map(c => {
          return {
            label: c.name,
            value: c.id
          }
        })
      ];
    },

    categoryOptions() {
      return [
        {
          label: 'Create New Category',
          value: -1
        },
        ...this.$page.props.categories.map(c => {
          return {
            label: c.name,
            value: c.id
          }
        })
      ];
    },

    metaOptions() {
      return [
        {
          label: 'Create New Meta',
          value: -1
        },
        ...this.$page.props.meta_groups.map(m => {
          return {
            label: m.name,
            value: m.id
          }
        }).filter(m => {
          return this.metas.findIndex(mm => mm.id == m.value) < 0;
        })
      ];
    },

  },
  data() {
    return {
      selectedAssets: [],
      selectedProperties: [], // Left side checkboxes
      categories: [],
      collections: [],
      metas: [],
      addingCollection: false,
      addingCategory: false,
      addingMeta: false,
      addedCollection: null,
      addedCategory: null,
      addedMetaGroup: null,
      addedMetaValue: '',
      newCollection: '',
      newCategory: '',
      newMeta: {name: '', value: ''},
    }
  },
  mounted() {
    //
  },
  created: function() {
    document.addEventListener('keyup', this.escape);
  },
  destroyed: function() {
    document.removeEventListener('keyup', this.escape);
  },
  watch: {
    assets(newAss, oldAss) {
      this.selectedAssets = newAss.map(ass => ass ? ass.id : null)
      this.setDefaultValues()
    },
  },
  methods: {
    escape(event) {
      if (event.keyCode === 27) {
        this.cancelModal();
      }
    },
    cancelModal() {
      this.$emit('input', false);
    },
    setDefaultValues() {
      this.collections = this.collectionOptions.filter(c => {
        return this.assets.filter(a => a.collections.filter(ac => {
          return ac.id == c.value;
        }).length > 0).length == this.assets.length && this.assets.length > 0;
      });
      this.categories = this.categoryOptions.filter(c => {
        return this.assets.filter(a => a.categories.filter(ac => {
          return ac.id == c.value;
        }).length > 0).length == this.assets.length && this.assets.length > 0;
      });
      this.assets.forEach(ass => {
        if (Array.isArray(ass.meta) && ass.meta.length) {
          ass.meta.forEach(am => {
            // Metas structure [...{id: int, name: 'Name', value: 'Value'}]
            // Lookup in metas array
            const lookup = this.metas.findIndex(m => m.id == am.id);
            console.log(lookup);
            // If exists, check if value the same as what is stored, else set the value as blank because multiple
            if (lookup > -1) {
              console.log(`exists asset ${ass.id} meta ${am.id}`);
              if (this.metas[lookup].value == am.pivot.value) {
                // Nothing to do
              } else {
                this.metas[lookup].value = '(multiple values)';
                // this.metas[lookup].value = [... new Set(this.metas[lookup].value.concat(`, ${am.pivot.value}`).split(', '))].join(', '); // They differ from previously set so add as a comma sep value
              }
            } else {
              console.log(`no exist asset ${ass.id} meta ${am.id}`);
              // If no exist, add
              this.metas.push({id: am.id, name: am.name, value: am.pivot.value});
            }

          });
        }
      });
    },
    addCollection() {
      if (!this.selectedProperties.includes('collections')) {
        this.selectedProperties.push('collections');
      }
      if (this.addedCollection === -1 && this.newCollection.length) {
        this.collections.push({label: this.newCollection, value: -1});
      } else {
        this.collections.push({label: this.collectionOptions.find(c => c.value == this.addedCollection).label || '???', value: this.addedCollection })
      }
      this.$nextTick(() => {
        this.addedCollection = null;
        this.addingCollection = false;
      });
    },
    removeCollection(idx) {
      if (!this.selectedProperties.includes('collections')) {
        this.selectedProperties.push('collections');
      }
      this.collections.splice(idx, 1);
    },
    addCategory() {
      if (!this.selectedProperties.includes('categories')) {
        this.selectedProperties.push('categories');
      }
      if (this.addedCategory === -1 && this.newCategory.length) {
        this.categories.push({label: this.newCategory, value: -1});
      } else {
        this.categories.push({label: this.categoryOptions.find(c => c.value == this.addedCategory).label || '???', value: this.addedCategory })
      }
      this.$nextTick(() => {
        this.addedCategory = null;
        this.addingCategory = false;
      });
    },
    removeCategory(idx) {
      if (!this.selectedProperties.includes('categories')) {
        this.selectedProperties.push('categories');
      }
      this.categories.splice(idx, 1);
    },
    addMeta() {
      let add = true;
      if (this.addedMetaGroup === -1 && this.newMeta.name.length && this.newMeta.value.length) {
        this.metas.push({id: -1, ...this.newMeta});
        if (!this.selectedProperties.includes(`meta_${this.newMeta.name}`)) {
          this.selectedProperties.push(`meta_${this.newMeta.name}`);
        }
      } else if (this.addedMetaGroup > -1 && this.addedMetaValue.length) {
        const addedMeta = this.metaOptions.find(mo => mo.value == this.addedMetaGroup);
        this.metas.push({id: this.addedMetaGroup, name: addedMeta.name, value: this.addedMetaValue});
        if (!this.selectedProperties.includes(`meta_${this.addedMetaGroup}`)) {
          this.selectedProperties.push(`meta_${this.addedMetaGroup}`);
        }
      } else {
        add = false;
      }
      this.$nextTick(() => {
        if (add) {
          this.addingMeta = false;
          this.addedMetaGroup = null;
          this.addedMetaValue = '';
          this.newMeta.name = '';
          this.newMeta.value = '';
        }
      });
    },
    save() {
      const data = {};
      this.selectedProperties.forEach(prop => {
        switch (prop) {
          case 'categories':
            data.categories = this.categories;
            break;
          case 'collections':
            data.collections = this.collections;
            break;
          default:
            // meta
            const propStr = String(prop);
            if (propStr.indexOf('meta_') === 0) {
              // Initialize meta in data if not initialized
              if (!Array.isArray(data.meta)) {
                data.meta = [];
              }
              const metaId = propStr.slice(5);
              if (isNaN(metaId)) {
                // is a new meta item and is using name instead of ID
                data.meta.push(this.metas.find(m => m.name == metaId));
              } else {
                // existing meta using ID
                data.meta.push(this.metas.find(m => m.id == metaId));
              }
            }
            break;
        }
      });
      this.$CMS_POST('dam.assets.bulk-edit', {}, {
        assets: this.selectedAssets,
        updates: data,
        _method: 'PUT'
      }).then((resp) => {
        if (resp.status == 'success') {
          window.location.reload();
        }
      })
    },
  }
}
</script>
