var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex -space-x-1 overflow-hidden relative z-1"},_vm._l((_vm.activeUsers),function(usr,idx){return _c('p',{key:`${_vm.uuid}-avatar-${idx}`,class:[
    'inline-block rounded-full ring-2 ring-white overflow-hidden relative hover:z-10',
    _vm.size == 'sm' ? 'h-6 w-6 text-xs' : '',
    _vm.size == 'md' ? 'h-8 w-8 text-sm' : '',
    _vm.size == 'lg' ? 'h-10 w-10 text-base' : '',
    _vm.size == 'xl' ? 'h-12 w-12 text-lg' : '',
  ],style:(`left: ${20*idx}px;`)},[(usr.user.avatar)?_c('img',{staticClass:"block w-full h-full object-cover",attrs:{"src":usr.user.avatar,"title":`${usr.user.first_name} ${usr.user.last_name}`}}):_vm._e(),_vm._v(" "),_c('span',{staticClass:"flex w-full h-full items-center justify-center rounded-full bg-gray-500",attrs:{"title":`${usr.user.first_name} ${usr.user.last_name}`}},[_c('span',{staticClass:"font-medium leading-none text-white"},[_vm._v(_vm._s(usr.user.first_name.substring(0,1))+_vm._s(usr.user.last_name.substring(0,1)))])])])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }