<template>
  <img v-if="appLogo" class="bg-black" :src="appLogo" :alt="appName" />
  <div v-else class="flex items-center justify-center bg-black">
    <svg class="block h-1/2 w-auto" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48.78 49.56" :title="appName"><title>{{ appName }}</title><g id="Layer_2" data-name="Layer 2"><g id="LOGOS"><path class="cls-1" fill="#FFFFFF" d="M14.05,26.73h7.63l2-8.47-3.79-14c-1-3.3-2.06-4-3.81-4-2.48,0-3.61,4-3.61,4L.44,44.09c-.82,2.79-.72,5.47,2.27,5.47,3.2,0,5-5,5-5ZM48,45,34.71,4s-1-4-4.73-4h-9.6c.21.1,1,.93,1.75,3.5L34.65,48.22a1.87,1.87,0,0,0,2.06,1.34h9.7c1.45,0,2.37-.62,2.37-2A27,27,0,0,0,48,45ZM16,29.24,10.58,44.61c-1.34,3.81-3.1,4.84-3.41,5h9.6a7.09,7.09,0,0,0,6.7-5l4.08-11.14-1.08-4.23Z"/></g></g></svg>
  </div>
</template>
<script>
export default {
  name: 'AppLogo',
  computed: {
    appName() {
      return this.$page.props.appName;
    },
    appLogo() {
      return this.$page.props.appLogo;
    }
  }
}
</script>
