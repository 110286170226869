<template>
  <div class="py-10 px-4 sm:px-6 space-y-4">
    <div v-for="(day, idxD) in days.filter(d => d.events.length > 0)" :key="`cal-list-${idxD}`" class="space-y-2">
      <base-time :datetime="day.date" format="L" class="text-sm font-bold" />
      <ol class="divide-y divide-gray-100 overflow-hidden rounded-lg bg-white text-sm shadow ring-1 ring-black ring-opacity-5">
        <li v-for="(event, idxE) in day.events" :key="`cal-list-${idxD}-${idxE}`" class="group flex p-4 pr-6 focus-within:bg-gray-50 hover:bg-gray-50">
          <div class="flex-auto">
            <p class="font-normal text-gray-900"><span v-if="event.scheduleable?.series" class="font-semibold">{{ event.scheduleable?.series?.title }}: </span>
            {{ event.scheduleable?.title }} {{ event.media ? `(${mediaOptions.find(e => e.value == event.media)?.label})` : '' }}</p>
            <base-time :datetime="event.datetime" format="LT" class="mt-2 flex items-center text-gray-700" />
          </div>
          <button v-if="!hideEdit" type="button" @click="$emit('edit', {date: day.date, idxE })" class="ml-6 flex-none self-center rounded-md bg-white py-2 px-3 font-semibold text-gray-900 opacity-0 shadow-sm ring-1 ring-inset ring-gray-300 hover:ring-gray-400 focus:opacity-100 group-hover:opacity-100">
            Edit
          </button>
        </li>
      </ol>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    days: {
      type: Array,
      default: () => []
    },
    hideEdit: {
      type: Boolean,
      default: false
    },
    mediaOptions: {
      type: Array,
      default: () => []
    }
  }
}
</script>
