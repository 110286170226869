<template>
  <div :class="(Array.isArray(value.and) && value.and.length) || (Array.isArray(value.or) && value.or.length) ? 'p-8 border border-gray-400 rounded' : ''">
    <div class="lg:flex items-end">
      <base-input label="Field" class="flex-none w-full lg:w-1/5 xl:w-56 mb-4 lg:mb-0 lg:mr-4">
        <combo-box
          :options="fields"
          :value="value.field"
          @input="updateValue('field', $event)"
          placeholder="Please Select..."
        />
      </base-input>
      <base-input label="Operator" class="flex-none w-full lg:w-1/5 xl:w-56 mb-4 lg:mb-0 lg:mr-4">
        <combo-box
          :disabled="!value.field"
          :options="operators"
          :value="value.operator"
          @input="updateValue('operator', $event)"
          placeholder="Please Select..."
        />
      </base-input>
      <base-input
        :disabled="!value.field || !value.operator"
        :value="value.query"
        @input="updateValue('query', $event)"
        class="w-full mb-4 lg:mb-0 lg:mr-4"
        label="Query"
      />
      <div class="flex-none flex items-center">
        <base-button
          :disabled="!value.field || !value.operator || !value.query"
          @click="type !== 'or' ? $emit('addQuery') : updateAndOr('and')"
          class="mr-4"
        >
          And
        </base-button>
        <base-button
          :disabled="!value.field || !value.operator || !value.query"
          @click="type !== 'and' ? $emit('addQuery') : updateAndOr('or')"
          class="mr-4"
        >
          Or
        </base-button>
        <base-button
          icon
          v-show="canDelete && !(Array.isArray(value.and) && value.and.length) && !(Array.isArray(value.or) && value.or.length)"
          class="text-red-600 w-5"
          @click="$emit('removeQuery')"
        >
            <x-icon class="w-full h-auto" />
        </base-button>
      </div>
    </div>
    <template v-if="value.or && Array.isArray(value.or) && value.or.length">
      <div v-for="(qry, idxO) in value.or" :key="`formQueryOr${idxO}`">
        <div class="my-4 w-full font-bold tracking-widest uppercase text-center">- OR -</div>
        <form-query
          type="or"
          :value="qry"
          :fields="fields"
          @input="updateAndOr('or', idxO, $event)"
          @addQuery="updateAndOr('or', idxO)"
          @removeQuery="removeAndOr('or', idxO)"
        />
      </div>
    </template>
    <template v-if="value.and && Array.isArray(value.and) && value.and.length">
      <div v-for="(qry, idxA) in value.and" :key="`formQueryAnd${idxA}`">
        <div class="my-4 w-full font-bold tracking-widest uppercase text-center">- AND -</div>
        <form-query
          type="and"
          :value="qry"
          :fields="fields"
          @input="updateAndOr('and', idxA, $event)"
          @addQuery="updateAndOr('and', idxA)"
          @removeQuery="removeAndOr('and', idxA)"
        />
      </div>
    </template>
  </div>
</template>
<script>
import {XIcon} from '@vue-hero-icons/solid'
import Form from '../Form'
export default {
  name: 'FormQuery',
  components: {
    ...Form,
    XIcon
  },
  props: {
    fields: {
      type: Array,
      default: () => []
    },
    value: {
      type: Object,
      default: () => {
        return JSON.parse(JSON.stringify(this.formQueryBasis))
      }
    },
    type: {
      type: String,
      default: 'and'
    },
    canDelete: {
      type: Boolean,
      default: true
    },
  },
  data() {
    return {
      operators: ['Is', 'Contains', 'Is Not', 'Does Not Contain'],
      formQueryBasis: {
        field: '',
        query: '',
        operator: '',
        or: [],
        and: []
      },
    }
  },
  computed: {
  },
  methods: {
    updateValue(key, value) {

      this.$emit('input', { ...this.value, [key]: value });

      console.log(key,value);
    },
    updateAndOr(type, index, value) {
      const q = this.value[type];
      if (value) {
        // update existing
        q[index] = value;
      } else {
        // add new
        const basis = JSON.parse(JSON.stringify(this.formQueryBasis));
          q.splice(index+1, 0, {
          ...basis,
          field: this.value.field
        });
      }
      this.$emit('input', { ...this.value, [type]: q });
    },
    removeAndOr(type, index) {
      const q = this.value[type];
      q.splice(index, 1);
      this.$emit('input', { ...this.value, [type]: q });
    },
  },
  mounted() {

  }
}
</script>
