<template>
  <p :class="[`text-${size}`, noMargin ? '' : 'mb-4', 'flex items-center font-semibold lg:flex-none']">
    <span><slot /></span>
    <base-tooltip v-if="$slots.tooltip" class="inline-block ml-2 w-4 h-4 text-center" :offsetY="5">
      <template v-slot:content>
        <slot name="tooltip" />
      </template>
      <information-circle-icon class="align-top w-full h-full inline-block" />
    </base-tooltip>
  </p>
</template>
<script>
import BaseTooltip from './BaseTooltip'
import { InformationCircleIcon } from '@vue-hero-icons/solid'
export default {
  name: 'BaseHeading',
  props: {
    noMargin: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 'xl',
      validator: function (value) {
        // The value must match one of these strings
        return value.length === 0 || ['xs', 'sm', 'base', 'lg', 'xl', '2xl', '3xl', '4xl', '5xl', '6xl', '7xl', '8xl', '9xl'].indexOf(value) !== -1
      }
    }
  },
  components: {
    BaseTooltip,
    InformationCircleIcon
  }
}
</script>
