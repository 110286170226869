<template>
  <DamPopover ref="dam-popover" v-on:open="$emit('popoveropen')" v-on:close="$emit('popoverclose')">
    <template v-slot:button>
      <dam-button>
        {{ action }}
      </dam-button>
    </template>
    <template v-slot:title>
      {{ action }}
    </template>
    <template v-slot>
      <div class="space-y-10">
        <RadioGroup :options="downloadOptions" v-model="selectedOption" :bordered="false" class="text-gray-500 " />
        <div class="space-x-4">
          <dam-button v-if="(typeof selectedOption !== 'string')" tag="button" type="primary" class="min-w-[100px]" :disabled="true">{{ action }}</dam-button>
          <dam-button v-else-if="action == 'Download'" tag="a" type="primary" :href="fileUrl" download class="min-w-[100px]">Download</dam-button>
          <dam-button v-else tag="button" type="primary" class="min-w-[100px]" @click="doIt">{{ action }}</dam-button>
          <dam-button tag="button" type="secondary" class="min-w-[100px]" @click.native="cancel()">Cancel</dam-button>
        </div>
      </div>
    </template>
  </DamPopover>
</template>
<script>
const imageDownloadOptions = [
  {label: 'Original Format', value: ''},
  {label: 'JPG - Full Size', value: 'jpg-full'},
  {label: 'JPG - Medium Size', value: 'jpg-medium'},
  {label: 'PNG - Full Size', value: 'png-full'},
  {label: 'PNG - Medium Size', value: 'png-medium'},
];
const videoDownloadOptions = [
  {label: 'Original Format', value: ''},
  {label: 'MP4 - Full Size', value: 'mp4-full'},
  {label: 'MP4 - Medium Size', value: 'mp4-medium'},
];
const audioDownloadOptions = [
  {label: 'Original Format', value: ''},
  {label: 'MP3 - High Quality', value: 'mp3-high'},
  {label: 'MP3 - Medium Quality', value: 'mp3-medium'},
];

import DamPopover from './Popover'
import DamButton from './Button'
import { RadioGroup } from '../../../components/Form';
export default {
  components: {
    DamButton,
    DamPopover,
    RadioGroup,
  },
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    action: {
      type: String,
      default: 'Download'
    },
  },
  data() {
    return {
      selectedOption: null,
      allDownloadOptions: [],
    }
  },
  mounted() {
    if (this.$inertia.page.props.suffixes) {
      this.allDownloadOptions = this.$inertia.page.props.suffixes;
    } if (Array.isArray(window.cmsDamAllSuffixes)) {
      this.allDownloadOptions = window.cmsDamAllSuffixes;
    } else {
      console.warn('Missing suffixes');
      this.$CMS_GET('dam.assets.suffixes', {}, true).then(data => {
        this.allDownloadOptions = window.cmsDamAllSuffixes = data.suffixes;
      });
    }
  },
  computed: {
    // downloadOptions() {
    //   switch(this.downloadType) {
    //     case 'image':
    //       return imageDownloadOptions;
    //     case 'video':
    //       return videoDownloadOptions;
    //     case 'audio':
    //       return audioDownloadOptions;
    //     default:
    //       return [{label: 'Original Format', value: 'original'}];
    //   }
    // },

    downloadOptions() {
      let opts = [{label: 'Original Format', value: ''}];
      switch(this.downloadType) {
        case 'image':
          return opts.concat(this.allDownloadOptions.filter(opt => ['jpg', 'png'].some(v => opt.value.includes(v))));
        case 'video':
          return opts.concat(this.allDownloadOptions.filter(opt => ['mp4', 'mov'].some(v => opt.value.includes(v))));
        case 'audio':
          return opts.concat(this.allDownloadOptions.filter(opt => ['mp3', 'wav'].some(v => opt.value.includes(v))));
        default:
          return opts;
      }
    },
    downloadType() {
      if (this.item.type.includes('image')) {
        return 'image';
      } else if (this.item.type.includes('video')) {
        return 'video';
      } else if (this.item.type.includes('audio')) {
        return 'audio';
      } else {
        return 'plain';
      }
    },
    fileUrl() {
      return route('cms.dam.asset', {uuid: this.item.uuid, suffix: this.selectedOption});
    }
  },
  methods: {
    cancel() {
      this.$refs['dam-popover'].toggle();
    },
    // Papa Palpatine says: DEW IT
    doIt() {
      const url = this.fileUrl;
      if (this.action === 'Download') {
        // TODO: Fetch download
        window.open(url + '?download=1', '_blank');
      } else {
        this.$emit('selected', {name: this.item.name, url});
      }
    }
  }
}
</script>
