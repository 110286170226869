<template>
  <app-layout>
    <base-card class="space-y-4 mb-10">
      <form @submit.prevent="updateUser" autocomplete="new-password">
        <div class="mb-4">
          <base-input
            v-model="user.email"
            type="text"
            label="Email"
            placeholder=""
            required
            aria-autocomplete="false"
          />
        </div>
        <div class="mb-4">
          <base-input
            v-model="user.first_name"
            type="text"
            label="First Name"
            placeholder=""
            required
          />
        </div>
        <div class="mb-4">
          <base-input
            v-model="user.last_name"
            type="text"
            label="Last Name"
            placeholder=""
            required
          />
        </div>
        <div class="mb-4">
          <base-input
            v-model="password"
            type="password"
            label="Password"
            placeholder=""
            :disabled="user.type !== 'local'"
            @keypress="passwordEvent"
          />
          <p v-if="user.type !== 'local'"><small>You cannot change the password of this account because it uses an external service.</small></p>
          <div v-else v-show="passwordForm">
            <base-input
              v-model="password_confirmation"
              type="password"
              label="Password Confirm"
              placeholder=""
              class="my-4"
            />
            <base-button v-if="user.id" @click="updatePassword" :disabled="password.length < 8 || password !== password_confirmation">Update</base-button>
          </div>
        </div>
        <div v-if="user.id && user.groups">

          <base-label class="mb-3">Groups</base-label>
          <div class="mb-6 flex flex-col">
            <div class="py-2 align-middle inline-block min-w-full ">
              <base-table>
                <template v-slot:head>
                  <table-heading>
                    Group
                  </table-heading>
                  <table-heading v-if="mayUpdateGroups" class="w-16">
                    <span class="sr-only">Delete</span>
                  </table-heading>
                </template>
                <template v-slot:default>
                    <tr v-for="group in user.groups" :key="`gorup${group.id}`">
                      <table-cell>
                        {{ group.name }}
                      </table-cell>
                      <table-cell v-if="mayUpdateGroups" class="w-16">
                        <base-button icon @click="removeGroup(group.id)">
                          <x-icon class="w-4 h-4" />
                        </base-button>
                      </table-cell>
                    </tr>
                </template>
                <template v-if="mayUpdateGroups" v-slot:foot>
                  <table-cell colspan="2">
                    <el-select @input="addGroup()" v-model="addGroupSelect">
                      <option value="" selected>Add a Group...</option>
                      <option
                        v-for="group in groupOptions"
                        :key="`addGroup${group.id}`"
                        :value="group"
                      >
                        {{ group.name }}
                      </option>
                    </el-select>
                  </table-cell>
                </template>
              </base-table>
            </div>
          </div>
        </div>
        <div v-else>

          <p>Please save to begin adding groups.</p>

        </div>
      </form>

      <base-button @click="updateUser">
        Save
      </base-button>
    </base-card>
  </app-layout>
</template>
<script>
import Form from '../components/Form'
import Table from '../components/Table'
import { XIcon } from '@vue-hero-icons/solid'
export default {
  name: 'UserEdit',
  components: {
    ...Form,
    ...Table,
    XIcon
  },
  props: {
    user: {
      type: Object,
      default: () => {
        return {
          email: '',
          first_name: '',
          last_name: '',
          type: 'local'
        }
      }
    },
    groups: {
      type: Array,
      default: () => []
    },
    mayUpdateGroups: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      passwordForm: false,
      addGroupSelect: '',
      password: '',
      password_confirmation: ''
    }
  },
  computed: {
    groupOptions() {
      if (this.user.groups) {
        return this.groups.filter((usr) => {
          return !this.user.groups.some(u => {
            return u.id === usr.id;
          });
        });
      } else {
        return this.groups;
      }
    }
  },
  methods: {
    updateUser() {
      if(this.user.id) {
        this.$root.$CMS_UPDATE('users', this.user.id, this.user);
      } else {
        this.$root.$CMS_CREATE('users', {}, {...this.user, password: this.password, password_confirmation: this.password_confirmation}).then((data)=>{
          if(data.user.id) {
            window.location.href = route('cms.users.show', data.user.id);
          }
        });
      }
    },
    updatePassword() {
      this.$root.$CMS_UPDATE('users.password', {}, {password: this.password, password_confirmation: this.password_confirmation});
    },
    removeGroup(id) {
      this.user.groups = this.user.groups.filter(u => {
        return u.id !== id;
      });
    },
    addGroup() {
      if (this.addGroupSelect) {
        this.user.groups.push(this.addGroupSelect);
      }
    },
    passwordEvent() {
      console.log('key');
      this.passwordForm = this.password.length > 0
    }
  }
}
</script>
