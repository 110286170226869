<template>
  <fieldset v-if="video && typeof video.value === 'string'" class="mb-3">
    <div class="md:flex">
      <div class="md:w-1/2 lg:w-40 flex-grow-0 mr-2">
        <base-label size="text-xs" color="text-gray-600">Type:</base-label>
        <el-select
          v-model="video.type"
          :options="videoTypes"
          required
          @input="typeUpdate"
        />
      </div>
      <base-input
        class="flex-auto md:w-1/2 lg:w-full"
        label-color="text-gray-600"
        label-size="text-xs"
        :label="video.type === 'iframe' ? 'Embed Code' : 'URL'"
        :type="video.type === 'iframe' ? 'textarea' : 'text'"
        v-model="video.value"
        @input="videoUpdate"
      />
    </div>
    <div class="mt-2" v-if="video && video.type && video.value">
      <base-label size="text-xs" color="text-gray-600">Preview:</base-label>
      <div v-if="video.type === 'iframe'" v-html="video.value"></div>
      <template v-else-if="video.type === 'mp4'">
        <video width="640" height="360" :src="video.value" controls></video>
      </template>
      <template
        v-else-if="(video.type === 'youtube' && typeof video.id !== 'undefined')"
      >
        <iframe
          width="640"
          height="360"
          :src="`https://www.youtube.com/embed/${video.id}`"
          title="YouTube video player"
          frameborder="0"
        ></iframe>
      </template>
      <template
        v-else-if="(video.type === 'vimeo' && typeof video.id !== 'undefined')"
      >
        <iframe
          :src="`https://player.vimeo.com/video/${video.id}`"
          width="640"
          height="360"
          frameborder="0"
        ></iframe>
      </template>
      <p v-else>Preview not available</p>
    </div>
  </fieldset>
</template>
<script>
import BaseInput from './BaseInput';
import BaseLabel from './BaseLabel';
import ElSelect from './ElSelect';
const defaultValue = {
  value: '',
  type: '',
};
export default {
  name: 'BaseVideo',
  components: {
    BaseInput,
    BaseLabel,
    ElSelect,
  },
  props: {
    value: {
      type: [Object, String],
      default: () => JSON.parse(JSON.stringify(defaultValue)),
    },
  },
  computed: {
    video: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
  methods: {
    typeUpdate() {
      if (this.updateTimeout) {
        clearInterval(this.updateTimeout);
      }
    },
    videoUpdate() {
      if (this.updateTimeout) {
        clearInterval(this.updateTimeout);
      }
      this.updateTimeout = setTimeout(() => {
        const vid = this.video;
        if (vid.value) {
          if (vid.value.indexOf('<iframe') > -1) {
            vid.type = 'iframe';
          } else if (vid.value.indexOf('.mp4') > -1) {
            vid.type = 'mp4';
          } else if (
            vid.value.indexOf('youtube') > -1 ||
            vid.value.indexOf('youtu.be') > -1
          ) {
            vid.type = 'youtube';
            if (vid.value.indexOf('watch?v=') > -1) {
              vid.id = vid.value.split('watch?v=')[1];
            } else {
              const youtubeArray = vid.value.split('/');
              vid.id = youtubeArray[youtubeArray.length - 1];
            }
          } else if (vid.value.indexOf('vimeo') > -1) {
            vid.type = 'vimeo';
            const vimeoArray = vid.value.split('/');
            console.log(vimeoArray);
            vid.id = vimeoArray[vimeoArray.length - 1];
          } else {
            return false;
          }
          this.$emit('input', vid);
        }
      }, 1000);
    },
  },
  data() {
    return {
      videoTypes: ['mp4', 'youtube', 'vimeo', 'iframe'],
      updateTimeout: null,
    };
  },
  mounted() {
    if (!this.value || Array.isArray(this.value)) {
      this.$emit('input', JSON.parse(JSON.stringify(defaultValue)));
    } else if (typeof this.value === 'string') {
      this.$emit('input', { type: '', value: this.value });
    } else if (
      typeof this.value === 'object' &&
      (!this.value.value || !this.value.type)
    ) {
      this.$emit('input', {
        type: this.value.type || '',
        value: this.value.value || '',
      });
    }
    this.$nextTick(() => {
      this.videoUpdate();
    });
  },
};
</script>
