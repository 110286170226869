var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"relative inline-block text-left z-10",on:{"mouseleave":_vm.mouseLeave,"mouseenter":_vm.mouseEnter}},[_c('div',[_c('button',{class:_vm.unstyledButton ? '' : 'inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',attrs:{"type":"button","aria-expanded":"true","aria-haspopup":"true"},on:{"click":_vm.toggleDropdown}},[_vm._t("button")],2)]),_vm._v(" "),_c('transition',{attrs:{"enter-active-class":"ease-out duration-100","enter-class":"opacity-0 scale-95","enter-to-class":"opacity-100 scale-100","leave-active-class":"ease-in duration-75","leave-class":"opacity-100 scale-100","leave-to-class":"opacity-0 scale-95"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.open),expression:"open"}],class:[
      'transition absolute p-2',
      _vm.position === 'right' ? '-right-2' : '',
      _vm.position === 'left' ? '-left-2' : '',
      _vm.position === 'center' ? 'left-1/2 -translate-x-1/2' : '',
    ]},[_c('div',{class:[
        'transition transform origin-top-right rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-20',
        _vm.width === 'normal' ? 'w-56' : '',
        _vm.width === 'wide' ? 'w-64' : '',
        _vm.width === 'narrow' ? 'w-32' : '',
      ],attrs:{"role":"menu","aria-orientation":"vertical","aria-labelledby":"menu-button","tabindex":"-1"}},[_c('div',{staticClass:"py-1",attrs:{"role":"none"}},[_vm._t("default")],2)])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }