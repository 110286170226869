<template>
  <app-layout>
    <base-card v-if="queries.length">
      <form @submit.prevent="search">
        <div class="mb-4" v-for="(query, idx) in queries" :key="`searchQuery${idx}`">
          <div class="flex items-end">
            <search-query
              v-model="queries[idx]"
              :modules="modules"
              :class="[`${idx === queries.length - 1 ? 'mr-4' : ''}`, 'w-full']"
              :canDelete="idx > 0"
              @addQuery="addQuery(idx)"
              @removeQuery="removeQuery(idx)"
            />
          </div>
          <div v-if="idx <= queries.length - 2" class="mt-4 w-full font-bold tracking-widest uppercase text-center">- AND -</div>
        </div>
        <div class="mt-4 text-center">
          <base-button submit @click="search" :disabled="!(queries.length > 0 && queries[queries.length - 1].query.length > 0)">Search</base-button>
        </div>
      </form>
    </base-card>
    <base-card v-if="queried" class="mt-8">
      <base-heading>Results</base-heading>
      <div v-if="results.length">
        <div class="mb-4 flex justify-end">
          <base-button @click="createReport">Create Report</base-button>
        </div>
        <div class="overflow-y-visible overflow-x-auto">
          <base-table>
            <template v-slot:head>
              <table-heading v-for="(col, idxH) in Object.keys(results[0])" :key="`resultsHeading${idxH}`">
                {{ col }}
              </table-heading>
            </template>
            <template v-slot:default>
              <tr v-for="(result, idxR) in results" :key="`resultsRow${idxR}`">
                <table-cell v-for="(col, idxC) in Object.keys(result)" :key="`resultsRow${idxR}Col${idxC}`">
                  <inertia-link v-if="resultsRoute && idxC === 0" :href="route(resultsRoute.route, {...resultsRoute.params, id: result.id})" class="underline">
                    {{ result[col] }}
                  </inertia-link>
                  <span v-else>
                    {{ result[col] }}
                  </span>
                </table-cell>
              </tr>
            </template>
          </base-table>
        </div>
      </div>
      <p v-else>No results found for your query...</p>
    </base-card>
  </app-layout>
</template>
<script>
import SearchQuery from '../components/Misc/SearchQuery';
import Table from '../components/Table';
export default {
  name: 'Search',
  components: {
    ...Table,
    SearchQuery,
  },
  props: {
    modules: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      searchQueryBasis: {
        subject: '',
        column: '',
        query: '',
        operator: '',
        or: [],
        and: [],
      },
      queries: [],
      queried: false,
      results: [],
    };
  },
  methods: {
    search() {
      this.queried = true;
      this.$root
        .$CMS_POST('search.query', {}, { queries: this.queries })
        .then((resp) => {
          if (resp.results) {
            this.results = resp.results;
          } else {
            this.results = [];
          }
        });
    },
    addQuery(idx) {
      const basis = JSON.parse(JSON.stringify(this.searchQueryBasis));
      this.queries.splice(idx + 1, 0, {
        ...basis,
        subject: this.queries[idx].subject,
      });
    },
    removeQuery(idx) {
      this.queries.splice(idx, 1);
    },
    createReport() {
      this.$swal
        .fire({
          title: `New Report`,
          html: `<form id="swal-create-report-form"><input type="text" id="swal-create-report-name" class="swal2-input" placeholder="Report Name" /></form>`,
          confirmButtonText: 'Create',
          focusConfirm: false,
          preConfirm: () => {
            const reportName = this.$swal
              .getPopup()
              .querySelector('#swal-create-report-name');
            if (!reportName.value) {
              this.$swal.showValidationMessage(`Please include a name`);
            }
            return { reportName: reportName.value };
          },
        })
        .then((result) => {
          const formData = new FormData();
          formData.append('name', result.value.reportName);
          formData.append('query', JSON.stringify(this.queries));
          this.$root.$CMS_POST('reports.store', {}, formData).then((resp) => {
            if (resp.status === 'success') {
              window.location.href = route('cms.reports.show', resp.report.id);
            }
          });
        });
    },
  },
  computed: {
    resultsRoute() {
      const model = this.queries.length ? this.queries[0].subject : null;
      if (model) {
        if (model.indexOf('Cms\\Page') > -1) {
          return { route: 'cms.pages.show', params: {} };
        } else {
          const mod = this.modules.filter((mod) => model === mod.model);
          if (mod.length) {
            return { route: 'cms.mod.show', params: { module: mod[0].id } };
          } else {
            return null;
          }
        }
      } else {
        return null;
      }
    },
  },
  mounted() {
    this.queries.push(JSON.parse(JSON.stringify(this.searchQueryBasis)));
  },
};
</script>
