<template>
  <base-popover>
    <template v-slot:icon>
      <span class="sr-only">Open Properties</span>
      <pencil-alt-icon class="w-5 h-5" />
    </template>

    <div v-if="item.pivot && item.properties" class="space-y-4 max-h-[80vh] overflow-auto px-4 -mx-4">
      <div
        v-for="(prop,pdx) in item.properties"
        :key="`property${pdx}`"
      >
        <div v-if="prop.property === 'required'">
          <base-input label="Required">
            <base-switch
              v-model="item.pivot.required"
              type="primary"
            />
          </base-input>
        </div>
        <div v-else-if="prop.property === 'is_column'">
          <base-input label="Show on Listing?">
            <base-switch
              v-model="item.pivot.is_column"
              type="primary"
            />
          </base-input>
        </div>
        <div v-else-if="prop.property === 'upload_config' || prop.property === 'upload_config_id'">
          <base-input
            label="Upload Config"
            required
          >
            <el-select v-model="item.pivot.upload_config_id">
              <option
                v-for="(upload,uploadIndex) in uploadConfigs"
                :key="`upload${uploadIndex}`"
                :value="upload.id"
              >
                {{ upload.name }}
              </option>
            </el-select>
          </base-input>
        </div>
        <div v-else-if="(prop.property === 'module' || prop.property === 'recordset_id') && mods.length">
          <base-input
            label="Module"
            required
          >
            <el-select v-model="item.pivot.recordset_id">
              <option
                v-for="m in mods"
                :key="`recordset-mod-${m.id}`"
                :value="m.id"
              >
                {{ m.name }}
              </option>
            </el-select>
          </base-input>
        </div>
        <div v-else-if="prop.property === 'options' && item.name.toUpperCase() !== 'COMPONENTS' && item.name.toUpperCase() !== 'REPEATER'">
          <base-input
            :label="prop.property"
            required
          >
            <base-text-area v-model="item.pivot[prop.property]" :delimiter='"\n"' />
          </base-input>
        </div>
        <div v-else-if="(prop.property === 'column' || prop.property === 'variable') && item.pivot.column">
          <base-input
            v-model="item.pivot.column"
            type="text"
            label="Column"
            :required="true"
          />
        </div>
        <div v-else-if="prop.property === 'variable'">
          <base-input
            v-model="item.pivot.variable"
            type="text"
            label="Variable"
            :required="true"
          />
        </div>
        <div v-else-if="prop.property === 'label'">
          <base-input
            v-model="item.pivot.label"
            type="text"
            label="Label"
            @change="labelPropertyUpdate(true)"
            :required="true"
          />
        </div>
        <div v-else-if="prop.property === 'show_if_column'">
          <base-input
            v-model="item.pivot.show_if_column"
            type="text"
            label="Show If: Column"
          />
        </div>
        <div v-else-if="prop.property === 'show_if_operator'">
          <base-input label="Show If: Operator">
            <el-select v-model="item.pivot.show_if_operator" :options="['equals', 'not-equals']" />
          </base-input>
        </div>
        <div v-else-if="prop.property === 'show_if_value'">
          <base-input
            v-model="item.pivot.show_if_value"
            type="text"
            label="Show If: Value"
          />
        </div>
        <div v-else-if="(prop.property !== 'id' && typeof prop.value !== 'object' && prop.property !== 'options')">
          <base-input
            v-model="item.pivot[prop.property]"
            type="text"
            :label="prop.property"
            :required="prop.property === 'variable' || prop.property === 'label'"
          />
        </div>
      </div>
    </div>
    <div v-else-if="item.properties" class="space-y-4">
      <div
        v-for="(prop,propIndex) in item.properties"
        :key="`prop${propIndex}`"
      >
        <div v-if="prop.property === 'required'">
          <base-input label="Required">
            <base-switch
              v-model="prop.value"
              type="primary"
            />
          </base-input>
        </div>
        <div v-else-if="prop.property === 'is_column'">
          <base-input label="Show on Listing?">
            <base-switch
              v-model="prop.value"
              type="primary"
            />
          </base-input>
        </div>
        <div v-else-if="prop.property === 'upload_config' || prop.property === 'upload_config_id'">
          <base-input
            label="Upload Config"
            required
          >
            <el-select v-model="prop.value">
              <option
                v-for="(upload,uploadIndex) in uploadConfigs"
                :key="`upload${uploadIndex}`"
                :value="upload.id"
              >
                {{ upload.name }}
              </option>
            </el-select>
          </base-input>
        </div>
        <div v-else-if="prop.property === 'module'">
          <base-input
            label="Module"
            required
          >
            <el-select v-model="prop.value">
              <option v-for="(mod, modIdx) in mods" :key="`mod${modIdx}`" :value="mod.id" :selected="mod.id === prop.value">
                {{ mod.name }}
              </option>
            </el-select>
          </base-input>
        </div>
        <div v-else-if="item.name !== 'Repeater' && prop.property === 'options'">
          <base-input
            :label="prop.property"
            required
          >
            <base-text-area v-model="prop.value" :delimiter='"\n"' />
          </base-input>
        </div>
        <div v-else-if="prop.property === 'label'">
          <base-input
            v-model="prop.value"
            type="text"
            label="Label"
            @change="labelPropertyUpdate(false)"
            :required="true"
          />
        </div>
        <div v-else-if="prop.property === 'show_if_column'">
          <base-input
            v-model="prop.value"
            type="text"
            label="Show If: Column"
          />
        </div>
        <div v-else-if="prop.property === 'show_if_operator'">
          <base-input label="Show If: Operator">
            <el-select v-model="prop.value" :options="['equals', 'not-equals']" />
          </base-input>
        </div>
        <div v-else-if="prop.property === 'show_if_value'">
          <base-input
            v-model="prop.value"
            type="text"
            label="Show If: Value"
          />
        </div>
        <div v-else-if="(prop.property !== 'id' && prop.property !== 'is_column' && prop.property !== 'options' && typeof prop.value !== 'object')">
          <base-input
            v-model="prop.value"
            type="text"
            :label="prop.property"
            :required="prop.property === 'variable' || prop.property === 'label'"
          />
        </div>
      </div>
    </div>
    <div v-else class="space-y-4">
      <base-input
        v-model="item.pivot.label"
        type="text"
        label="Label"
        @change="labelPropertyUpdate"
        required
      />
      <base-input
        v-model="item.pivot.column"
        type="text"
        label="Column"
        required
      />
      <base-input label="Required">
        <base-switch
          v-model="item.pivot.required"
          type="primary"
        />
      </base-input>
      <base-input
        v-model="item.pivot.helptext"
        type="text"
        label="Helper Text"
      />
    </div>

  </base-popover>
</template>
<script>
// import { Select, Option, Popover } from "element-ui";
import Form from '../Form'
import { PencilAltIcon } from '@vue-hero-icons/solid'
export default {
  name: 'EditPopover',
  components: {
    ...Form,
    PencilAltIcon
  },
  props: {
    value: {
      type: Object,
      default() {
        return {};
      }
    },
    mods: {
      type: Array,
      default() {
        return [];
      }
    },
    uploadConfigs: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  computed: {
    item: {
        get() { return this.value },
        set(data) {
          this.$emit('input', data)
        }
    }
  },
  methods: {
    labelPropertyUpdate(isPivot) {
      if (isPivot) {
        if (typeof this.item.pivot.variable !== 'undefined' && this.item.pivot.variable === '') {
          this.item.pivot.variable = this.$root.$SLUGIFY(this.item.pivot.label, '_');
        } else if (typeof this.item.pivot.column !== 'undefined' && this.item.pivot.column === ''){
          this.item.pivot.column = this.$root.$SLUGIFY(this.item.pivot.label, '_');
        }
        this.$emit('input', this.item)
      } else {
        const val = this.item.properties.filter(prop => prop.property === 'label')[0].value;
        this.item.properties = this.item.properties.map((prop) => {
          if ((prop.property === 'variable' || prop.property === 'column') && prop.value === '') {
            prop.value = this.$root.$SLUGIFY(val, '_')
          }
          return prop;
        })
        this.$emit('input', this.item);
      }
    }
  },
}
</script>
