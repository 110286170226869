<template>
  <div class="relative block dam-popover-container" :ref="`dam-popover-${uuid}-container`">
    <button @click.stop="toggle()" :id="`dam-popover-${uuid}`" :ref="`dam-popover-${uuid}-button`" aria-haspopup="true" type="button" class="flex h-full items-stretch">
      <slot name="button">
        <dots-vertical-icon class="w-5 h-5" />
      </slot>
    </button>
    <div
      class="dam-popover w-80 max-w-screen z-10 transition-opacity opacity-0 "
      role="menu"
      aria-orientation="vertical"
      :aria-labelledby="`dam-popover-${uuid}`"
      :ref="`dam-popover-${uuid}`"
    >
      <div class="dam-popover-arrow" data-popper-arrow></div>
      <div class="max-h-[90vh] rounded-md shadow-xl bg-white overflow-hidden border-gray-300">
        <div class="bg-gray-800 text-white flex justify-between items-center pt-4 px-5 pb-3">
          <span class="font-bold text-sm leading-5">
            <slot name="title" />
          </span>
          <button @click="toggle()">
            <x-circle-icon class="w-5 h-5" />
          </button>
        </div>
        <div class=" py-5 px-8 text-left">
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { DotsVerticalIcon, XCircleIcon } from '@vue-hero-icons/solid';
import { createPopper } from '@popperjs/core';
export default {
  name: 'DamPopover',
  components: {
    DotsVerticalIcon,
    XCircleIcon
  },
  data() {
    return {
      open: false,
      container: null,
      button: null,
      popover: null,
      instance: null,
    };
  },
  methods: {
    toggle() {
      if (this.open) {
        this.open = false;
        this.popover.removeAttribute('data-show');
        this.$emit('close');
      } else {
        window.dispatchEvent(
          new CustomEvent('damPopoverOpen', { detail: { uid: this.uuid } })
        );
        this.instance.update();
        this.popover.setAttribute('data-show', '');
        this.$emit('open');
        this.open = true;
      }
    },
  },
  mounted() {
    window.addEventListener('damPopoverOpen', (evt) => {
      if (evt.detail.uid !== this.uuid && this.open) {
        this.toggle();
      }
    });
    this.container = this.$refs[`dam-popover-${this.uuid}-container`];
    this.button = this.$refs[`dam-popover-${this.uuid}-button`];
    this.popover = this.$refs[`dam-popover-${this.uuid}`];
    this.instance = createPopper(this.button, this.popover, {
      placement: 'top',
      strategy: 'absolute',
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 20],
          },
        },
        {
          name: 'flip',
          enabled: false,
        },
      ],
    });
  },
};
</script>

<style>
.dam-popover {
  z-index: 999999999;
}
.dam-popover:not([data-show]) {
  pointer-events: none;
}
.dam-popover[data-show] {
  opacity: 1 !important;
}
.dam-popover-arrow,
.dam-popover-arrow::before {
  position: absolute;
  width: 8px;
  height: 8px;
  background: white;
}

.dam-popover-arrow {
  visibility: hidden;
}

.dam-popover-arrow::before {
  visibility: visible;
  content: '';
  transform: rotate(45deg);
}
.dam-popover[data-popper-placement^='top'] > .dam-popover-arrow {
  bottom: -4px;
}

.dam-popover[data-popper-placement^='bottom'] > .dam-popover-arrow {
  top: -4px;
}

.dam-popover[data-popper-placement^='left'] > .dam-popover-arrow {
  right: -4px;
}

.dam-popover[data-popper-placement^='right'] > .dam-popover-arrow {
  left: -4px;
}
</style>
