<template>
  <stack-list-item
    class="relative rounded-md shadow-md overflow-hidden flex-grow-1 w-full ring-1 ring-gray-300" tag="div"
  >
    <base-label>Shopify Fields</base-label>
    <div slot="expand" class="space-y-4">
      <base-input label="Shopify Page Type">
        <el-select
          v-model="$page.props.page.shopify_page_type"
          :options="['none', 'page', 'collection', 'smart_collection']"
          placeholder="Select..."
        />
      </base-input>

      <base-input label="Shopify Output">
        <el-select
          v-model="$page.props.page.shopify_output"
          :options="['body', 'liquid']"
          placeholder="Select..."
        />
      </base-input>
      <base-input v-if="$page.props.page.shopify_output === 'liquid'" label="Shopify Liquid Template">
        <el-select
          v-model="$page.props.page.shopify_liquid_template"
          :options="shopifyLiquidTemplates"
          placeholder="Select..."
        />
      </base-input>
      <base-input
        v-model="$page.props.page.shopify_id"
        type="text"
        label="Shopify ID"
        placeholder=""
      />
      <base-input
        v-if="env !== 'production'"
        v-model="$page.props.page.shopify_dev_id"
        type="text"
        label="Shopify ID (Development store)"
        placeholder=""
      />
      <base-input
        v-model="$page.props.page.shopify_meta_title_id"
        type="text"
        label="Shopify Meta Title ID"
        placeholder=""
      />
      <base-input
        v-if="env !== 'production'"
        v-model="$page.props.page.shopify_dev_meta_title_id"
        type="text"
        label="Shopify Meta Title ID (Development store)"
        placeholder=""
      />
      <base-input
        v-model="$page.props.page.shopify_meta_description_id"
        type="text"
        label="Shopify Meta Description ID"
        placeholder=""
      />
      <base-input
        v-if="env !== 'production'"
        v-model="$page.props.page.shopify_dev_meta_description_id"
        type="text"
        label="Shopify Meta Description ID (Development store)"
        placeholder=""
      />
    </div>
  </stack-list-item>
</template>
<script>
import Form from '../../../components/Form';
import Stack from '../../../components/Stack';
export default {
  name: 'ShopifyPageFields',
  components: {
    ...Form,
    ...Stack,
  },
  props: ['env'],
  data() {
    return {
      shopifyLiquidTemplates: [],
    };
  },
  computed: {},
  methods: {},
  mounted() {
    this.$CMS_GET('liquid.index').then((data) => {
      this.shopifyLiquidTemplates = data.map((liquid) => {
        return liquid.key;
      });
    });
  },
};
</script>
