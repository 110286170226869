<template>
  <app-layout>
    <base-card class="space-y-4 mb-10">
      <div>
        <div class="mb-4">
          <base-input
            v-model="group.name"
            type="text"
            label="Name"
            placeholder=""
            required
          />
        </div>
        <div v-if="group.id && permissions.length">
          <base-label class="mb-3">Permissions</base-label>
          <div class="mb-6 flex flex-col">
            <div class="py-2 align-middle inline-block min-w-full">
              <base-table>
                <template v-slot:head>
                  <table-heading>
                    Section
                  </table-heading>
                  <table-heading v-for="opt in permOptions" :key="`perm-heading-${opt}`">
                    {{ opt }}
                  </table-heading>
                </template>
                <template v-slot:default>
                  <tr v-for="(perm, idxP) in permissions" :key="`perm-${idxP}`">
                    <table-cell>
                      <button @click="selectRow(idxP)">{{ perm.name }}</button>
                    </table-cell>
                    <table-cell v-for="opt in permOptions" :key="`perm-${idxP}-${opt}`">
                      <base-checkbox
                        v-model="permissions[idxP][opt]"
                        class="inline-block"
                        :title="opt"
                        :checked="perm[opt] === 1"
                      ></base-checkbox>
                    </table-cell>
                  </tr>
                </template>
                <template v-slot:foot>
                  <table-heading>
                    <span class="align-middle mr-2">Select All</span><base-checkbox class="inline-block align-middle" @input="selectAll()" :value="allSelected.all" />
                  </table-heading>
                  <table-heading v-for="opt in permOptions" :key="`perm-footer-${opt}`">
                    <base-checkbox class="inline-block" @input="selectAll(opt)" :value="allSelected[opt]" />
                  </table-heading>
                </template>
              </base-table>
            </div>
          </div>
          <base-label class="mb-3">Users</base-label>
          <div class="mb-6 flex flex-col">
            <div class="py-2 align-middle inline-block min-w-full">
              <base-table>
                <template v-slot:head>
                  <table-heading class="w-20">
                    <span class="sr-only">Image</span>
                  </table-heading>
                  <table-heading class="w-1/3">
                    Name
                  </table-heading>
                  <table-heading>
                    Email
                  </table-heading>
                  <table-heading class="w-16">
                    <span class="sr-only">Delete</span>
                  </table-heading>
                </template>
                <template v-slot:default>
                  <tr v-for="user in group.users" :key="`user${user.id}`">
                    <table-cell class="w-20">
                      <img v-if="user.avatar" class="h-auto w-full rounded-full" :src="user.avatar">
                    </table-cell>
                    <table-cell class="w-1/3">
                      {{ user.first_name }} {{ user.last_name }}
                    </table-cell>
                    <table-cell>
                      {{ user.email }}
                    </table-cell>
                    <table-cell class="w-16">
                      <base-button icon @click="removeUser(user.id)">
                        <x-icon class="w-4 h-4" />
                      </base-button>
                    </table-cell>
                  </tr>
                </template>
                <template v-slot:foot>
                  <table-cell colspan="4">
                    <el-select @input="addUser()" v-model="addUserSelect">
                      <option value="" selected>Add a User...</option>
                      <option
                        v-for="user in userOptions"
                        :key="`addUser${user.id}`"
                        :value="user"
                      >
                        {{ user.email }}
                      </option>
                    </el-select>
                  </table-cell>
                </template>
              </base-table>
            </div>
          </div>
        </div>
        <div v-else>

          <p>Please save to begin adding users and updating permissions.</p>

        </div>
      </div>

      <base-button @click="updateGroup">
        Save
      </base-button>
    </base-card>
  </app-layout>
</template>
<script>
import Form from '../components/Form'
import { XIcon } from '@vue-hero-icons/solid'
import Table from '../components/Table'
export default {
  name: 'GroupEdit',
  components: {
    ...Form,
    ...Table,
    XIcon
  },
  props: {
    group: {
      type: Object,
      default: () => {
        return {
          name: ''
        }
      }
    },
    users: {
      type: Array,
      default: () => []
    },
    permOptions: {
      type: Array,
      default: () => []
    },
    permissions: {
      type: Array,
      default: () => []
    },
  },
  data() {
    return {
      addUserSelect: ''
    }
  },
  computed: {
    userOptions() {
      if (this.group.users) {
        return this.users.filter((usr) => {
          return !this.group.users.some(u => {
            return u.id === usr.id;
          });
        });
      } else {
        return this.users;
      }
    },
    allSelected() {
      const selected = {};
      this.permOptions.forEach(opt => {
        selected[opt] = this.permissions.filter(p => p[opt] === 1).length === this.permissions.length;
      });
      selected.all = this.permissions.filter(p => this.permOptions.every(opt => p[opt])).length === this.permissions.length;
      return selected;
    }
  },
  methods: {
    updateGroup() {
      if(this.group.id) {
        this.$root.$CMS_UPDATE('groups', this.group.id, {...this.group, permissions: this.permissions});
      } else {
        this.$root.$CMS_CREATE('groups', {}, this.group).then((data)=>{
          if(data.group.id) {
            window.location.href = route('cms.groups.show', data.group.id);
          }
        });
      }
    },
    removeUser(id) {
      this.group.users = this.group.users.filter(u => {
        return u.id !== id;
      });
    },
    addUser() {
      if (this.addUserSelect) {
        this.group.users.push(this.addUserSelect);
      }
    },
    selectAll(opt) {
      if (opt) {
        // Select all in a column
        if (this.allSelected[opt]) {
          // Deselect
          this.permissions = this.permissions.map(p => {
            p[opt] = 0;
            return p;
          });
        } else {
          // Select
          this.permissions = this.permissions.map(p => {
            p[opt] = 1;
            return p;
          });
        }
      } else {
        // Select everything
        if (this.allSelected.all) {
          // Deselect
          this.permissions = this.permissions.map(p => {
            this.permOptions.forEach(v => p[v] = 0)
            return p;
          });
        } else {
          // Select
          this.permissions = this.permissions.map(p => {
            this.permOptions.forEach(v => p[v] = 1)
            return p;
          });
        }
      }
    },
    selectRow(idx) {
      this.permOptions.forEach(opt => {
        this.permissions[idx][opt] = !this.permissions[idx][opt] ? 1 : 0;
      })
    }
  }
}
</script>
