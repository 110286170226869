<template>
  <div class="w-full">
    <textarea :ref="`codemirror-${uuid}`" v-model="code"></textarea>
  </div>
</template>
<script>


export default {
  name: 'BaseCode',
  props: ['value'],
  data() {
    return {
      editor: null
    }
  },
  computed: {
    code: {
      get() { return this.value },
      set(val) { this.$emit('input', val) }
    }
  },
  methods: {
    betterTab(cm) {
      if (cm.somethingSelected()) {
        cm.indentSelection("add");
      } else {
        cm.replaceSelection(cm.getOption("indentWithTabs")? "\t":
          Array(cm.getOption("indentUnit") + 1).join(" "), "end", "+input");
      }
    }
  },
  mounted() {
    this.editor = CodeMirror.fromTextArea(this.$refs[`codemirror-${this.uuid}`], {
      lineNumbers: true,
      theme: 'neat',
      mode: 'simple',
      extraKeys: { Tab: this.betterTab }
    });
    this.editor.on('changes', () => {
      this.$emit('input', this.editor.getValue());
    });
  }

}
</script>
<style>
  .CodeMirror {
    max-width: 100%;
    max-height: 100%;
  }
</style>
