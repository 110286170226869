<template>
  <app-layout>
    <div class="space-y-10">
      <div class="flex flex-col gap-10 xl:flex-row">
        <section :class="`w-full xl:w-2/3 flex flex-col ${$inertia.page.props.cms.notes_enabled ? 'flex-grow-0' : 'flex-grow'}`">
          <div class="flex justify-between items-center flex-none">
            <base-heading>Bulletin Board</base-heading>
            <base-button class="mb-4" @click="createMessage" :disabled="messageComposing" small>Create</base-button>
          </div>
          <base-card class="mb-4 flex-grow" v-if="messageComposing">
            <base-heading size="base">
              <template v-slot:default>New Message</template>
              <template v-slot:tooltip>This creates a new message and<br>is sent to all CMS users</template>
            </base-heading>
            <form @submit.prevent="sendNewMessage">
              <base-input class="mb-3" label="Headline" v-model="newMessage.headline" />
              <base-input class="mb-3" label="Content">
                <wysiwyg v-model="newMessage.content" />
              </base-input>
              <base-button @click="sendNewMessage" class="mr-4">Send</base-button>
              <base-button @click="cancelNewMessage" type="secondary">Cancel</base-button>
            </form>
          </base-card>
          <base-card v-if="messages.length" nospace class="flex-grow">
            <base-table shadow="noshadow" table-css="table-fixed xl:table-auto flex-grow">
              <template v-slot:head>
                <table-heading class="w-5/12 xl:w-auto">
                  Subject
                </table-heading>
                <table-heading class="w-3/12 max-w-[20rem] xl:w-auto">
                  From
                </table-heading>
                <table-heading class="w-3/12  max-w-[8rem] xl:w-auto">
                  Date
                </table-heading>
                <table-heading class="w-1/6 max-w-[4rem] xl:w-auto">
                  <span class="sr-only">Actions</span>
                </table-heading>
              </template>
              <template v-slot:default>
                <template v-for="(message, idxM) in messages">
                  <tr class="bg-white" :key="`messageRow${idxM}`">
                    <table-cell>
                      <button @click="expandMessage(message)" class="inline-block text-left w-full hover:text-gray-900">
                        {{ message.headline }}
                      </button>
                    </table-cell>
                    <table-cell>
                      <div>
                        <img v-if="message.user.avatar" class="inline-block h-6 w-6 rounded-full align-middle" :src="message.user.avatar" alt="">
                        <span v-else class="inline-block h-6 w-6 rounded-full text-xs bg-gray-800 text-white leading-6 text-center align-middle"><small>{{ message.user.first_name[0] }}{{ message.user.last_name[0] }}</small></span>
                        <span class="inline-block pl-2 pr-6 align-middle">{{ message.user.first_name }} {{ message.user.last_name }}</span>
                      </div>
                    </table-cell>
                    <table-cell>
                      {{ message.updated_at }}
                    </table-cell>
                    <td class="px-6">
                      <actions-popover :ref="`messageActions-${message.id}`">
                        <actions-button @click.prevent="messageEdit(message)">
                          <pencil-alt-icon class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" />
                          Edit
                        </actions-button>
                        <actions-button @click.prevent="messageDelete(message)">
                          <trash-icon class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" />
                          Delete
                        </actions-button>
                      </actions-popover>
                    </td>
                  </tr>
                  <tr v-if="messageExpanded === message.id" class="border-0" :key="`messageRow${idxM}-content`">
                    <table-cell colspan="4" class="bg-gray-200">
                      <div class="p-2">
                        <base-card>
                          <div v-if="messageEditing === message.id">
                            <base-input label="Message">
                              <wysiwyg v-model="messages[idxM].content" />
                            </base-input>
                            <div class="mt-4">
                              <base-button @click="messageUpdate(message)" class="mr-4">Save</base-button>
                              <base-button type="secondary" @click="messageEditCancel()">Cancel</base-button>
                            </div>
                          </div>
                          <p class="whitespace-pre-line" v-else v-html="message.content"></p>
                        </base-card>
                      </div>
                    </table-cell>
                  </tr>
                </template>
              </template>
            </base-table>
          </base-card>
          <base-card v-else class="flex-grow">
            <p>No messages found.</p>
          </base-card>
          <template v-if="pendingPages && pendingPages.columns && Array.isArray(pendingPages.items) && pendingPages.items.length">
            <div class="flex justify-between items-center mt-10 flex-none">
              <base-heading>Pages Pending Approval</base-heading>
            </div>
            <base-table v-if="pendingPages.items.length" table-css="table-fixed xl:table-auto flex-grow">
              <template v-slot:head>
                <table-heading class="w-5/12 xl:w-auto">
                  Title
                </table-heading>
                <table-heading class="w-3/12 max-w-[20rem] xl:w-auto">
                  User
                </table-heading>
                <table-heading class="w-3/12  max-w-[8rem] xl:w-auto">
                  Date
                </table-heading>
                <table-heading  class="w-1/6 max-w-[4rem] xl:w-auto">
                  <span class="sr-only">Actions</span>
                </table-heading>
              </template>
              <template v-slot:default>
                <template v-for="(page, idxM) in pendingPages.items">
                  <tr class="bg-white" :key="`messageRow${idxM}`">
                    <table-cell>
                      <inertia-link :href="route('cms.pages.draft', { page: page.id, draft: page.draft_id })">{{ page.name }}</inertia-link>
                    </table-cell>
                    <table-cell>
                      <div>
                        <img v-if="page.draft_user.avatar" class="inline-block h-6 w-6 rounded-full align-middle" :src="page.draft_user.avatar" alt="">
                        <span v-else class="inline-block h-6 w-6 rounded-full text-xs bg-gray-800 text-white leading-6 text-center align-middle"><small>{{ page.draft_user.first_name[0] }}{{ page.draft_user.last_name[0] }}</small></span>
                        <span class="inline-block pl-2 pr-6 align-middle">{{ page.draft_user.first_name }} {{ page.draft_user.last_name }}</span>
                      </div>
                    </table-cell>
                    <table-cell>
                      {{ page.draft_date }}
                    </table-cell>
                    <td class="px-6">
                      <actions-popover :ref="`Actions-${page.draft_id}`">
                        <actions-button tag="inertia-link" :href="route('cms.pages.draft', { page: page.id, draft: page.draft_id })">
                          <pencil-alt-icon class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" />
                          Edit
                        </actions-button>
                        <actions-button tag="inertia-link" :href="route('cms.pages.draft.delete', { page: page.id, draft: page.draft_id })">
                          <trash-icon class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" />
                          Delete
                        </actions-button>
                      </actions-popover>
                    </td>
                  </tr>
                </template>
              </template>
            </base-table>
          </template>
        </section>
        <section v-if="$inertia.page.props.cms.notes_enabled" class="w-full xl:w-1/3 flex-grow-0 flex flex-col">
          <div class="flex justify-between items-center flex-none">
            <base-heading>Notes</base-heading>
            <base-button class="mb-4" small @click="createNote = true">Create</base-button>
          </div>
          <base-card class="flex-grow h-96 flex flex-col">
            <div class="border-b border-gray-200 flex-none">
              <nav class="-mb-px flex space-x-8" aria-label="Tabs">
                <button type="button" @click="notesTab = 'inbox'" :class="[notesTab == 'inbox' ? 'border-indigo-500 text-indigo-600' : 'border-transparent text-gray-500 hover:border-gray-200 hover:text-gray-700', 'flex whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium']">
                  Inbox
                  <span v-if="unreadInboxCount" :class="[notesTab == 'inbox' ? 'bg-indigo-100 text-indigo-600' : 'bg-gray-100 text-gray-900', 'ml-3 hidden rounded-full py-0.5 px-2.5 text-xs font-medium md:inline-block']">{{ unreadInboxCount }}</span>
                </button>
                <button type="button" @click="notesTab = 'sent'" :class="[notesTab == 'sent' ? 'border-indigo-500 text-indigo-600' : 'border-transparent text-gray-500 hover:border-gray-200 hover:text-gray-700', 'flex whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium']">
                  Sent
                </button>
              </nav>
            </div>
            <div v-if="notesTab == 'inbox'" class="py-3 px-1 flex-1 h-full overflow-auto">
              <notes-listing :notes="notes.inbox" />
            </div>
            <div v-if="notesTab == 'sent'" class="py-3 px-1 flex-1 h-full overflow-auto">
              <notes-listing :notes="notes.sent" />
            </div>
          </base-card>
        </section>
      </div>
      <section>
        <base-heading>Activity</base-heading>
        <base-card class="flex gap-12 flex-wrap items-start justify-start">
          <div v-for="(log,idx) in logs.filter(l => l.logs.length)" :key="`dashlog-${idx}`" class="w-1/3 xl:w-1/4 2xl:w-1/5 overflow-hidden">
            <base-heading size="lg"><inertia-link :href="route(`cms.${log.slug}.index`, log.routeParams || {})">{{ log.name }}</inertia-link></base-heading>
            <base-logs :logs="log.logs" :slug="log.slug" :routeParams="log.routeParams || {}" :namecol="log.namecol || 'name'" />
          </div>
        </base-card>
      </section>
    </div>
    <notes-create v-model="createNote" />
  </app-layout>

</template>
<script>
import Form from '../components/Form'
import Table from '../components/Table'
import Actions from '../components/Actions'
import ListingInner from '../components/Listing/ListingInner'
import { PencilAltIcon, TrashIcon } from '@vue-hero-icons/solid'
import { BaseLogs } from '../components/Base'
import Wysiwyg from '../components/Form/Wysiwyg.vue'
import NotesListing from '../components/Notes/NotesListing.vue'
import NotesCreate from '../components/Notes/NotesCreate.vue'
export default {
  name: 'Dashboard',
  components: {
    ...Form,
    ...Table,
    ...Actions,
    BaseLogs,
    PencilAltIcon,
    TrashIcon,
    ListingInner,
    Wysiwyg,
    NotesListing,
    NotesCreate,
  },
  computed: {
    unreadInboxCount() {
      return this.notes.inbox.filter(n => !!n.read).length;
    }
  },
  props: {
    messages: {
      type: Array,
      default: () => []
    },
    pendingPages: {
      type: Object,
      default: () => {}
    },
    logs: {
      type: Array,
      default: () => []
    },
    notes: {
      type: Object,
      default: () => { return {
        inbox: [],
        sent: [],
      }}
    }
  },
  data() {
    return {
      messageExpanded: 0,
      messageEditing: 0,
      messageComposing: false,
      newMessage: {
        headline: '',
        content: ''
      },
      notesTab: 'inbox', // inbox or sent,
      createNote: false,
    }
  },
  methods: {
    expandMessage(message) {
      if (this.messageExpanded === message.id) {
        this.messageExpanded = 0;
      } else {
        this.messageExpanded = message.id;
      }
    },
    createMessage() {
      this.messageComposing = true;
    },
    cancelNewMessage() {
      this.messageComposing = false;
      this.newMessage = {
        headline: '',
        content: ''
      };
    },
    messageDelete(message) {
      this.$inertia.delete(route(`cms.dashboard-messages.destroy`, message.id), {
        preserveState: true,
        preserveScroll: true,
        only: ['messages'],
        onBefore: () => confirm('Are you sure you want to delete this message?'),
        onStart: () => {
          document.body.style.cursor = 'wait'
          const ref = `messageActions-${message.id}`;
          this.$refs[ref][0].$emit('toggle');
        },
        onFinish: () => document.body.style.cursor = 'default',
      })
    },
    messageEdit(message) {
      this.messageEditing = message.id;
      this.messageExpanded = message.id;
      const ref = `messageActions-${message.id}`;
      this.$refs[ref][0].$emit('toggle');
    },
    messageEditCancel() {
      this.messageEditing = 0;
      this.messageExpanded = 0;
    },
    messageUpdate(message) {
      this.$inertia.put(route(`cms.dashboard-messages.destroy`, message.id), message, {
        preserveState: true,
        preserveScroll: true,
        only: ['messages'],
        onFinish: () => this.messageEditing = 0
      })
    },
    sendNewMessage() {
      const msg = {...this.newMessage};
      this.$inertia.post(route('cms.dashboard-messages.store', msg))
      this.$nextTick(() => {
        this.messageComposing = false;
        this.newMessage = {
          headline: '',
          content: ''
        };
      })
    }
  }
}
</script>
