<template>
  <div class="font-mono whitespace-pre bg-gray-900 text-gray-100 overflow-auto p-4">
    {{ contents }}
  </div>
</template>
<script>
export default {
  name: 'SourceViewer',
  props: ['src'],
  methods: {
    loadContents(src) {
      fetch(src).then(resp => resp.text()).then(data => {
        this.contents = data;
      })
    }
  },
  watch: {
    src(val) {
      this.loadContents(val);
    }
  },
  data() {
    return {
      contents: ''
    }
  },
  mounted() {
    if (this.src) {
      this.loadContents(this.src);
    }
  },
}
</script>
