<template>
  <app-layout>
    <form v-if="ready" @submit.prevent="save('draft')" class="relative">
      <div
        v-if="can.design_page"
        class="
          hidden
          lg:block
          absolute
          -top-10
          right-0
          transform
          -translate-y-1/2
          z-10
        "
      >
        <base-button
          v-if="page.id || pageId"
          :disabled="$root.protected"
          :href="
            draftId && pageId
              ? route('cms.pages.draft.design', {
                  page: pageId,
                  draft: draftId,
                })
              : route('cms.pages.design', page.id)
          "
          tag="a"
          type="link"
        >
          Switch to Design Mode
        </base-button>
      </div>
      <div class="block lg:flex">
        <div class="flex-1 w-full">
          <base-card class="space-y-4 mb-10">
            <base-input
              v-model="page.name"
              type="text"
              label="Name"
              placeholder=""
              required
              @change="setSlug()"
            />

            <div>
              <div id="cms-page-slug" class="flex items-end space-x-2">
                <base-input
                  v-model="page.slug"
                  :disabled="url_locked"
                  type="text"
                  label="URL"
                  placeholder=""
                  required
                  class="flex-grow"
                />
                <base-button v-if="url_locked" small @click="toggleUrlLock()" class="flex-grow-0">Edit URL</base-button>
              </div>
              <div v-if="page.id && !url_locked" class="mt-2">
                <base-checkbox reversed v-model="url_generate_redirect">Generate permanent redirect (301) to new URL?</base-checkbox>
                <br>
                <base-button small @click="updateUrl()" :disabled="!page.slug || page.slug == old_url" >Save URL</base-button>
                <base-button small @click="toggleUrlLock()">Cancel</base-button>
              </div>
            </div>



            <div id="cms-page-live-link" v-if="page.id && old_url">
              <base-label>Live URL</base-label>
              <a
                :href="old_url"
                target="_blank"
                class="underline text-indigo-500 font-semibold"
                >{{ old_url }}</a
              >
            </div>

            <base-input label="Parent">
              <el-select
                v-model="page.parent"
                class="select-primary"
                placeholder="Select..."
                @change="setSlug()"
              >
                <option value="0">None</option>
                <option
                  v-for="(pg, idx) in pages"
                  :key="`parentPage${idx}`"
                  :value="pg.id"
                  :selected="pg.id === page.parent"
                >
                  {{ pg.full_name }} {{ pg.slug ? `(${pg.slug})` : '' }}
                </option>
              </el-select>
            </base-input>

            <base-input label="Type" :disabled="!can.design_page">
              <el-select
                v-model="page.type"
                :options="cms.page_types"
                placeholder="Select..."
              />
            </base-input>

            <base-input label="Layout" :disabled="!can.design_page">
              <el-select
                v-model="page.layout"
                :options="layouts"
                placeholder="Select..."
              />
            </base-input>

            <base-input
              label="Template"
              v-if="page.type === 'template'"
              :disabled="!can.design_page"
            >
              <el-select v-model="page.template" :options="templates" />
            </base-input>

            <base-input
              label="Page Class"
              v-else
              v-model="page.page_class"
              type="text"
              :disabled="!can.design_page"
            />

            <base-input
              label="Preview Controller Method"
              v-model="page.preview_controller_method"
              type="text"
              :disabled="!can.design_page"
            />

            <base-input label="Published">
              <base-switch v-model="page.published" />
            </base-input>

            <base-input
              label="Publish Date"
              tooltip="Only set this if you need to schedule it to go live on a specific<br> date. Be sure to leave it marked unpublished above."
            >
              <el-date-picker v-model="page.published_at" />
            </base-input>

            <base-input label="Include in Nav">
              <base-switch v-model="page.in_nav" />
            </base-input>

            <base-input label="Indexable">
              <base-switch v-model="page.indexable" />
            </base-input>

            <!-- <div v-if="page && page.page_id && page.id !== page.page_id" class="mb-3"> -->
            <!-- <badge class="mb-3" :type="page.status === 'pending' ? 'success' : 'default'">{{page.status}}</badge> -->
            <!--
            <base-input label="Revisions">
              <el-select
                v-model="selectedRevision"
                class="select-primary"
                placeholder="Select..."
                @change="switchRevision"
              >
                <option
                  v-for="(rev, idx) in revisions"
                  :key="`revPage${idx}`"
                  :value="idx"
                >{{ idx > 0 ? `${rev.updated_at} by ${rev.user.email}` : 'Latest' }} </option>
              </el-select>
            </base-input>
            -->
            <!-- </div> -->

            <base-input
              v-model="page.meta_title"
              type="text"
              label="Meta Title"
              placeholder=""
              required
            />

            <base-input
              v-model="page.meta_description"
              type="textarea"
              label="Meta Description"
              placeholder=""
              :charlimit="160"
              required
            />

            <base-input
              v-model="page.meta_keywords"
              type="text"
              label="Meta Keywords"
              placeholder=""
            />

            <base-input label="Meta Additions">
              <base-code
                ref="meta_additions_editor"
                v-model="page.meta_additions"
                class="editor h-40"
              />
            </base-input>

            <base-input label="Open Graph Image">
              <file-upload
                v-model="page.og_image"
                :files="page.og_image || []"
                :config="ogImageConfig"
                :filename="`page-${page.id || uuid}`"
              />
            </base-input>

            <ShopifyPageFields
              v-if="page.shopify_page_type"
              :env="$page.props.appEnv"
            />

          </base-card>
          <div v-if="page.type === 'component'">
            <base-heading>Components</base-heading>
            <stack-list v-if="page.components">
              <stack-list-item
                v-for="(c, idxC) in page.components"
                :key="`pageComponent${idxC}`"
                :start-open="c.elements.length > 0"
              >
                <img
                  v-if="c.image && c.image.files.length"
                  class="d-inline-block mr-3"
                  style="width: 50px"
                  :src="
                    typeof c.image.files[0] === 'string'
                      ? c.image.files[0]
                      : c.image.files[0].url
                  "
                />
                <label class="d-inline-block">{{
                  c.pivot && c.pivot.name
                    ? `${c.pivot.name} (${c.name})`
                    : c.name
                }}</label>
                <div
                  v-if="c.elements.length > 0"
                  slot="expand"
                  class="space-y-4"
                >
                  <div
                    v-for="(element, idxE) in c.elements"
                    :key="`pageComponent${idxC}Element${idxE}`"
                    :class="`cms-component-element-${$root.$SLUGIFY(
                      element.name
                    )}`"
                  >
                    <!-- <bldr-element
                      v-if="false && element.name !== 'Repeater' && (Array.isArray(page.components[idxC].pivot.data[element.pivot.variable]) && page.components[idxC].pivot.data[element.pivot.variable].length === 1)"
                      v-model="page.components[idxC].pivot.data[element.pivot.variable]"
                      :element="element"
                    /> -->
                    <bldr-element
                      v-model="
                        page.components[idxC].pivot.data[element.pivot.variable]
                      "
                      :element="element"
                      :class="[element.name.toUpperCase() === 'SECTION' && idxE > 0 ? 'pt-8' : '']"
                      v-show="(
                        (element.pivot.show_if_column &&
                        element.pivot.show_if_operator &&
                        element.pivot.show_if_value) ? (
                          (
                            element.pivot.show_if_column.includes('component_variant') && (
                              (element.pivot.show_if_operator === 'equals') ? (
                                page.components[idxC].pivot.variant == element.pivot.show_if_value
                              ) : (
                                page.components[idxC].pivot.variant != element.pivot.show_if_value
                              )
                            )
                          ) ||
                          (
                            page.components[idxC].pivot.data[element.pivot.show_if_column] &&
                            (
                              (element.pivot.show_if_operator === 'equals') ? (
                                page.components[idxC].pivot.data[element.pivot.show_if_column] == element.pivot.show_if_value
                              ) : (
                                page.components[idxC].pivot.data[element.pivot.show_if_column] != element.pivot.show_if_value
                              )
                            )
                          )
                        ) : true
                      )"
                    />
                  </div>
                </div>
              </stack-list-item>
            </stack-list>
            <div v-else-if="page.id">
              <base-card
                >Save the page and add items using the design mode at the top if
                you have design permissions.</base-card
              >
            </div>
            <div v-else>
              <base-tooltip class="inline-block" :delay="1" :offset-y="10">
                <template slot="content"
                  >{{ page.id ? "Save" : "Create" }} the page and start building
                  it with components.</template
                >
                <base-button
                  type="primary"
                  class="mt-6"
                  @click="save('published')"
                  >{{ page.id ? "Save" : "Create" }}</base-button
                >
              </base-tooltip>
            </div>
          </div>
          <div v-else>
            <base-heading>Elements</base-heading>

            <base-card v-if="page.id && page.elements" class="space-y-4">
              <div v-for="(el, idx) in page.elements" :key="`el${idx}`">
                <template
                  v-if="
                    el.pivot.column !== 'published' &&
                    el.pivot.column !== 'published_at'
                  "
                >
                  <stack-list v-if="el.elements">
                    <stack-list-item :start-open="el.elements.length > 0">
                      <template v-slot:default>
                        <img
                          v-if="el.image && el.image.files.length"
                          class="inline-block mr-3 w-14 align-middle"
                          :src="
                            typeof el.image.files[0] === 'string'
                              ? el.image.files[0]
                              : el.image.files[0].url
                          "
                        />
                        <label class="inline-block align-middle">{{
                          i.pivot.label
                        }}</label>
                      </template>
                      <template v-slot:expand>
                        <div
                          v-for="(elel, idxE) in el.elements"
                          :key="`el${idx}Element${idxE}`"
                        >
                          <i-element
                            v-model="
                              page.data[el.pivot.variable][elel.pivot.variable]
                            "
                            :element="elel"
                          />
                        </div>
                      </template>
                    </stack-list-item>
                  </stack-list>
                  <div v-else>
                    <bldr-element
                      v-model="page.data[el.pivot.variable]"
                      :element="el"
                      :class="[el.name.toUpperCase() === 'SECTION' && idx > 0 ? 'pt-8' : '']"
                    />
                  </div>
                </template>
              </div>
            </base-card>

            <div v-else-if="page.id">
              <base-card
                >Save the page and add items using the design mode at the top if
                you have design permissions.</base-card
              >
            </div>
            <div v-else>
              <base-tooltip class="inline-block" :delay="1" :offset-y="10">
                <template slot="content"
                  >{{ page.id ? "Save" : "Create" }} the page and start building
                  it with elements.</template
                >
                <base-button
                  type="primary"
                  class="mt-6"
                  @click="save('published')"
                  >{{ page.id ? "Save" : "Create" }}</base-button
                >
              </base-tooltip>
            </div>
          </div>
        </div>
        <div
          v-if="pageId"
          class="
            flex-none
            w-full
            relative
            flex-column
            mt-4
            lg:max-w-xs lg:mt-0 lg:ml-6
          "
        >
          <base-card class="flex-none mb-4 lg:sticky top-0 z-20 py-2" nospace>
            <div class="flex items-center justify-center flex-wrap">
              <base-button
                v-if="page.id && page.shopify_page_type && !draftId"
                small
                type="primary"
                class="m-2"
                :disabled="$root.protected"
                @click="pushToShopify()"
              >Push To Shopify</base-button>

              <base-tooltip v-if="can.publish_page" :delay="1" :offset-y="10">
                <template slot="content"
                  >Save the page and push it live</template
                >
                <base-button
                  id="cms-detail-action-publish"
                  small
                  type="primary"
                  class="m-2"
                  @click="save('published')"
                  >{{ draftId ? "Save as Live" : "Save" }}</base-button
                >
              </base-tooltip>
              <base-tooltip v-else :delay="1" :offset-y="10">
                <template slot="content"
                  >Request reivew from someone with publish privileges</template
                >
                <base-button
                  small
                  type="primary"
                  class="m-2"
                  @click="save('pending')"
                  >Submit For Review</base-button
                >
              </base-tooltip>
              <base-tooltip :delay="1" :offset-y="10">
                <template slot="content"
                  >Save the page as a draft which does not affect the live
                  site</template
                >
                <base-button
                  small
                  type="primary"
                  class="m-2"
                  @click="save('draft')"
                  >{{ draftId ? "Save Draft" : "Save as Draft" }}</base-button
                >
              </base-tooltip>
              <base-tooltip v-if="draftId && pageId" :offset-y="10">
                <template slot="content"
                  >Delete the current draft you are on. This cannot be
                  undone!</template
                >
                <base-button
                  small
                  type="danger"
                  class="m-2"
                  @click="deleteDraft()"
                  >Delete Draft</base-button
                >
              </base-tooltip>
              <base-button
                small
                tag="a"
                :href="
                  draftId && pageId
                    ? `${route('cms.preview.page', pageId)}?draft=${draftId}`
                    : route('cms.preview.page', page.id)
                "
                target="_blank"
                type="secondary"
                :disabled="$root.protected"
                class="m-2"
                >Preview</base-button
              >
            </div>
          </base-card>
          <base-card class="mb-4">
            <base-label>Active Users</base-label>
            <active-users :url="$inertia.page.url" />
          </base-card>
          <base-card v-if="$inertia.page.props.cms.notes_enabled && page.id" class="mb-4">
            <base-label>Notes</base-label>
            <div class="w-full max-h-60 overflow-auto mb-4">
              <notes-listing :detail="true" :notes="page.notes.filter(n => !n.parent_id)" />
            </div>
            <base-button small @click="creatingNote = true">Create</base-button>
          </base-card>
          <base-card class="flex-grow-1 min-h-full">
            <base-label class="block">Drafts</base-label>
            <el-select v-model="selectedDraft" @input="selectDraft($event)">
              <option :selected="!selectedDraft" value="">Live</option>
              <option
                v-for="(draft, idx) in drafts"
                :value="draft.id"
                :selected="selectedDraft === draft.id"
                :key="`draft-${idx}`"
              >
                Draft: {{ draft.name }} ({{
                  moment(draft.updated_at).format("MMMM Do YYYY, h:mm:ss a")
                }})
              </option>
            </el-select>
            <!-- TODO: Revisions-->
            <!--
            <base-label class="block mt-6">Revisions</base-label>
            <p>TODO</p>
            -->
            <base-label class="block mt-6">Logs</base-label>
            <base-logs slug="pages" type="detail" :logs="logs" />
          </base-card>
        </div>
      </div>
    </form>

    <notes-create v-if="$inertia.page.props.cms.notes_enabled" :noteable-id="page.id" noteable-type="Atypiccraft\Cms\Page" v-model="creatingNote" />
  </app-layout>
</template>
<script>
import Form from '../components/Form';
import { BldrElement } from '../components/Builder';
import Stack from '../components/Stack';
import ShopifyPageFields from './Shopify/Partials/PageFields';
import ActiveUsers from '../components/Misc/ActiveUsers';
import NotesListing from '../components/Notes/NotesListing';
import NotesCreate from '../components/Notes/NotesCreate';
export default {
  components: {
    ...Stack,
    ...Form,
    ActiveUsers,
    BldrElement,
    NotesListing,
    NotesCreate,
    ShopifyPageFields,
  },
  props: {
    page: {
      type: Object,
      default: () => {
        return {
          name: '',
          slug: '',
          type: 'component',
          layout: 'default',
          template: '',
          parent: null,
          in_nav: false,
          indexable: false,
          meta_title: '',
          meta_description: '',
          meta_keywords: '',
          meta_additions: '',
          og_image: [],
          published: false,
        };
      },
    },
    can: {
      type: [Object],
      default: () => {
        return {
          design_page: false,
        };
      },
    },
    pages: {
      type: [Array],
      default: () => [],
    },
    logs: {
      type: [Array],
      default: () => [],
    },
    pageId: {
      type: Number,
      default: 0,
    },
    draftId: {
      type: [Number],
      default: 0,
    },
    drafts: {
      type: [Array],
      default: () => [],
    },
    revisions: {
      type: [Array],
      default: () => [],
    },
  },
  data() {
    return {
      creatingNote: false,
      selectedDraft: 0,
      selectedRevision: 0,
      ready: false,
      old_url: '',
      url_locked: false,
      url_generate_redirect: false,
      templates: [
        {
          label: 'Create New Template File',
          value: 'NEW',
        },
      ],
      layouts: ['default'],
      ogImageConfig: [
        {
          mode: 'image-crop',
          name: 'Open Graph Image',
          retina: false,
          suffix: '',
          location: 'public',
          directory: 'open-graph-images',
          dimensions: {
            width: '1200',
            height: '630',
          },
        },
      ],
    };
  },
  computed: {
    cms() {
      return this.$page.props.cms;
    },
  },
  methods: {
    save(status) {
      console.log(this.page);
      if (this.pageId || this.page.id) {
        if (status === 'draft' && !this.draftId) {
          this.$swal
            .fire({
              title: 'Create Draft',
              html: `<form id="swal-create-draft-form"><input type="text" id="swal-create-draft-name" class="swal2-input" placeholder="Draft name..." /></form>`,
              confirmButtonText: 'Create',
              focusConfirm: false,
              preConfirm: () => {
                const draftName = this.$swal
                  .getPopup()
                  .querySelector('#swal-create-draft-name');
                if (!draftName.value) {
                  this.$swal.showValidationMessage(`Please include a name`);
                }
                return { draftName: draftName.value };
              },
            })
            .then((result) => {
              if (result.isConfirmed) {
                this.submitSave('draft', { draftName: result.value.draftName });
              }
            });
        } else {
          this.submitSave(status);
        }
      } else {
        if (this.pageId && this.draftId) {
          this.page.id = this.pageId;
        }

        this.$root
          .$CMS_CREATE('pages', {}, { page: this.page, status: 'published' })
          .then((data) => {
            this.$root.protected = false;
            if (data.pageId) {
              this.$nextTick(() => {
                window.location.href = route('cms.pages.design', data.pageId);
              });
            }
          });
      }
    },
    submitSave(status, additionalData) {
      let data = { page: this.page, status: status, draftId: this.draftId };
      if (additionalData) {
        data = { ...data, ...additionalData };
      }
      this.$root
        .$CMS_UPDATE('pages', this.pageId || this.page.id, data)
        .then((resp) => {
          // afterwards remove protected
          this.$root.protected = false;

          this.$nextTick(() => {
            if (this.draftId && status === 'published') {
              window.location.href = route('cms.pages.show', this.pageId);
            } else {
              if (resp.draftId) {
                window.location.href = route('cms.pages.draft', {
                  page: this.pageId,
                  draft: resp.draftId,
                });
              }
            }
          });
        });
    },
    setSlug() {
      if (!this.page.id) {
        this.page.slug = '';
        if (this.page.parent) {
          const parent = this.pages.filter((pg) => pg.id == this.page.parent);
          if (parent.length) {
            this.page.slug = parent[0].slug + '/';
          }
        }
        this.page.slug += this.$root.$SLUGIFY(this.page.name);
      }
    },
    selectDraft(val) {
      if (!val) {
        this.$inertia.visit(route('cms.pages.show', this.pageId));
      } else {
        this.$inertia.visit(
          route('cms.pages.draft', { page: this.pageId, draft: val })
        );
      }
    },
    deleteDraft() {
      this.$swal
        .fire({
          title: 'Are you sure you want to delete this draft?',
          confirmButtonText: 'Delete',
          focusConfirm: false,
          showCancelButton: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.$inertia.delete(
              route('cms.pages.draft.delete', {
                page: this.pageId,
                draft: this.draftId,
              })
            );
          }
        });
    },
    pushToShopify() {
      this.$CMS_GET('pages.shopify.push', { page: this.page.id }).then(
        (data) => {
          if (data.status === 'success') {
            this.$noty.success('Pushed to Shopify!');
          } else {
            this.$noty.error('Could not push to Shopify.');
          }
        }
      );
      // this.$inertia.get(
      //   route('cms.pages.shopify.push', { page: this.page.id }),
      //   {
      //     replace: true,
      //     preserveState: true,
      //     preserveScroll: true,
      //     only: ['page'],
      //     onSuccess: () => {
      //       this.$noty.success('Pushed to Shopify!');
      //     },
      //     onError: () => {
      //       this.$noty.error('Could not push to Shopify.');
      //     },
      //   }
      // );
    },
    selectRevision() {},
    toggleUrlLock() {
      if (this.old_url != this.page.slug) {
        this.page.slug = this.old_url;
      }
      this.url_locked = !this.url_locked;
    },
    updateUrl() {
      this.$swal
        .fire({
          title: 'Are you sure you want to change the URL?',
          html: `The URL will be changing from <strong>${
            this.old_url
          }</strong> to <strong>${
            this.page.slug
          }</strong><br>and a 301 redirect <strong>${
            this.url_generate_redirect ? 'will' : 'will not'
          }</strong> be created.`,
          confirmButtonText: 'Continue',
          focusConfirm: false,
          showCancelButton: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            // Update via API
            axios({
              url: route('cms.pages.url', { page: this.page.id }),
              method: 'PUT',
              data: {
                url: this.page.slug,
                redirect: this.url_generate_redirect,
              },
            })
              .then((resp) => {
                console.log(resp);
                if (resp.status === 200) {
                  this.old_url = this.page.slug;
                  this.url_locked = true;
                } else {
                  // Error?
                  console.log(JSON.stringify(resp));
                }
              })
              .catch((err) => {
                if (
                  err.response &&
                  err.response.data &&
                  err.response.data.message
                ) {
                  console.log(err.response.data);
                  this.$swal.fire({
                    title: err.response.data.message,
                  });
                } else {
                  window.alert(
                    'An error occurred! Check the console for details.'
                  );
                  console.error(err);
                }
              });
          } else {
            // Do nothing
          }
        });
    },
  },
  mounted() {
    this.$root.$CMS_GET('templates', { dir: 'pages' }).then((data) => {
      this.templates = this.templates.concat(data);
      if (!this.page.template) {
        // this.page.template = this.templates[0];
      }
    });
    this.$root.$CMS_GET('layouts').then((data) => {
      this.layouts = data;
      if (!this.page.layout) {
        this.page.layout = this.layouts[0];
      }
    });
    if (!this.page.og_image) {
      this.page.og_image = [];
    }
    document.addEventListener('keyup', this.$root.protectEvent);
    this.selectedDraft = this.draftId;

    this.$nextTick(() => {
      if (Array.isArray(this.page.components) && this.page.components.length) {
        this.page.components.forEach((component, idxC) => {
          if (typeof this.page.components[idxC].pivot.data === 'undefined') {
            this.page.components[idxC].pivot.data = {};
          }
          component.elements.forEach((el) => {
            if (
              typeof this.page.components[idxC].pivot.data[
                el.pivot.variable
              ] === 'undefined'
            ) {
              this.page.components[idxC].pivot.data[el.pivot.variable] = '';
            }
          });
        });
        this.$nextTick(() => {
          this.ready = true;
        });
      } else {
        this.ready = true;
      }
    });
    if (this.page.id) {
      this.url_locked = true;
      this.old_url = this.page.slug;
    }
  },
  beforeDestroy() {
    document.removeEventListener('keyup', this.$root.protectEvent);
  },
};
</script>
