<template>
  <app-layout>
    <base-card class="space-y-6 mb-10">
      <div class="space-y-4">
        <base-input
          v-model="contactList.name"
          type="text"
          label="Name"
          placeholder=""
          required
        />
      </div>
      <div class="flex gap-4">
        <base-button @click="save">
          Save
        </base-button>
        <base-button tag="a" target="_blank" :href="route('cms.contact-lists.export', contactList.id)">Export to CSV</base-button>
      </div>
    </base-card>
    <base-card class="w-full">
      <base-heading size="sm">Contacts</base-heading>
      <base-table table-css="table-fixed xl:table-auto">
        <template v-slot:head>
          <table-heading class="w-1/12 max-w-[100px]">
            ID
          </table-heading>
          <table-heading class="w-4/12 xl:w-auto">
            Email
          </table-heading>
          <table-heading class="w-3/12 xl:w-auto">
            First Name
          </table-heading>
          <table-heading class="w-3/12 xl:w-auto">
            Last Name
          </table-heading>
          <table-heading class="w-1/12 max-w-[100px]">
            Action
          </table-heading>
        </template>
        <template v-slot:default>
          <template v-for="(contact, idxC) in contactList.contacts">
            <tr class="bg-white" :key="`contact${idxC}`">
              <table-cell>
                <inertia-link :href="route('cms.contacts.show', { id: contact.id})">
                  {{ contact.id }}
                </inertia-link>
              </table-cell>
              <table-cell>
                <inertia-link :href="route('cms.contacts.show', { id: contact.id})">
                  {{ contact.email }}
                </inertia-link>
              </table-cell>
              <table-cell>
                <inertia-link :href="route('cms.contacts.show', { id: contact.id})">
                  {{ contact.first_name }}
                </inertia-link>
              </table-cell>
              <table-cell>
                <inertia-link :href="route('cms.contacts.show', { id: contact.id})">
                  {{ contact.last_name }}
                </inertia-link>
              </table-cell>
              <table-cell>
                <inertia-link method="delete" as="button" :href="route('cms.contact-lists.remove-contact', { contactList: contactList.id, contact: contact.id})" title="Remove">
                  <x-icon class="w-5 h-5 text-red-500" />
                </inertia-link>
              </table-cell>
            </tr>
          </template>
          <tr v-if="!contactList.contacts.length">
            <table-cell colspan="5">
              No Contacts
            </table-cell>
          </tr>
          <tr v-if="addingContact">
            <table-cell>Contact</table-cell>
            <table-cell colspan="3">
              <el-select :options="contactOptions" v-model="addedContact" />
            </table-cell>
            <table-cell>
              <inertia-link v-if="addedContact" method="put" as="button" :href="route('cms.contact-lists.add-contact', { contactList: contactList.id, contact: addedContact})" v-on:finish="addingContact = false" title="Save">
                <check-icon class="w-5 h-5 text-green-500" />
              </inertia-link>
              <check-icon v-else class="w-5 h-5 text-gray-400 cursor-not-allowed" />
            </table-cell>
          </tr>
        </template>
        <template v-slot:foot>
          <table-cell colspan="5">
            <base-button v-if="!addingContact" small @click="addingContact = true">Add Contact</base-button>
            <base-button v-else small @click="addingContact = false">Cancel</base-button>
          </table-cell>
        </template>
      </base-table>
    </base-card>
  </app-layout>
</template>
<script>
import Form from '../components/Form';
import { XIcon, CheckIcon } from '@vue-hero-icons/solid';
import Table from '../components/Table';
export default {
  name: 'ContactEdit',
  components: {
    ...Form,
    ...Table,
    XIcon,
    CheckIcon,
  },
  props: {
    contactList: {
      type: Object,
      default: () => {
        return {
          name: '',
          contacts: [],
        };
      },
    },
    contactOptions: {
      type: Array,
      default: () => []
    },
  },
  methods: {
    save() {
      if (this.contactList.id) {
        this.$root.$CMS_UPDATE(
          'contact-lists',
          this.contactList.id,
          this.contactList
        );
      } else {
        this.$root
          .$CMS_CREATE('contact-lists', {}, this.contactList)
          .then((data) => {
            if (data.contactList.id) {
              window.location.href = route(
                'cms.contact-lists.show',
                data.contactList.id
              );
            }
          });
      }
    },
  },

  data() {
    return {
      addingContact: false,
      addedContact: null,
    }
  }
};
</script>
