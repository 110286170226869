<template>
  <app-layout>
    <base-card class="space-y-4 mb-10">
      <base-input
        v-model="config.name"
        type="text"
        label="Name"
        placeholder=""
        required
      />
      <base-input label="Type">
        <el-select :options="componentConfigTypes" v-model="config.type" />
      </base-input>
      <base-input
        v-model="config.css_classes"
        type="text"
        label="CSS Classes"
        required
      />

      <base-button @click="updateConfig">
        Save
      </base-button>
    </base-card>
  </app-layout>
</template>
<script>
import Form from '../components/Form';
import { XIcon } from '@vue-hero-icons/solid';
import Table from '../components/Table';
export default {
  name: 'ComponentConfig',
  components: {
    ...Form,
    ...Table,
    XIcon,
  },
  props: {
    config: {
      type: Object,
      default: () => {
        return {
          name: '',
          type: '',
          css_classes: '',
        };
      },
    },
    componentConfigTypes: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    updateConfig() {
      if (this.config.id) {
        this.$root.$CMS_UPDATE(
          'component-configs',
          this.config.id,
          this.config
        );
      } else {
        this.$root
          .$CMS_CREATE('component-configs', {}, this.config)
          .then((data) => {
            if (data.config.id) {
              window.location.href = route(
                'cms.component-configs.show',
                data.config.id
              );
            }
          });
      }
    },
  },
};
</script>
