<template>
  <component :is="tag" :class="[
    'inline-flex items-center justify-center px-4 py-2 border text-sm font-medium rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 text-center transition-all cursor-pointer',
    type === 'secondary' ? 'bg-white text-indigo-600 border-solid border-indigo-600 hover:bg-gray-50 hover:border-indigo-700' : 'text-white bg-indigo-600 hover:bg-indigo-700 border-transparent',
    disabled ? 'opacity-50 pointer-events-none' : '',
  ]" @click="$emit('click')">
    <slot />
  </component>
</template>
<script>
export default {
  name: 'DamButton',
  props: {
    tag: {
      type: String,
      default: 'span'
    },
    type: {
      type: String,
      default: 'primary'
    },
    disabled: {
      type: [Boolean, Number],
      default: false
    }
  }
}
</script>
