<template>
  <dam-panel heading="Choose Details">
    <div class="flex flex-col gap-5 lg:flex-row">
      <steps-indicator
        v-bind="{ currentStep, steps }"
        class="w-full lg:w-1/3"
      />
      <div class="w-full lg:w-2/3">
        <div v-show="currentStep == 0">
          <base-heading size="lg">Collection</base-heading>
          <el-select
            :options="collections"
            v-model="collection"
            placeholder="Please Select..."
          />
          <base-input
            v-if="collection == -1"
            label="New Collection Name"
            v-model="newCollection"
            class="mt-4"
            :inputClass="
              newCollectionError
                ? 'outline outline-2 outline-offset-1 outline-red-500'
                : ''
            "
            :error="newCollectionError"
            @input="newCollectionTouched = true"
          />
        </div>
        <div v-show="currentStep == 1">
          <base-heading size="lg">Select File{{ single ? '' : 's' }}</base-heading>
          <dropzone v-model="files" :single="single" ref="dropzone" />
        </div>
        <div v-show="currentStep == 2">
          <base-heading size="lg">Review and Edit</base-heading>
          <ul class="space-y-6">
            <li
              v-for="(item, idx) in files"
              :key="`file-${idx}`"
              class="flex gap-4 justify-start items-center"
            >
              <img
                v-if="item.file.type.includes('image')"
                :src="item.file.previewElement.querySelector('img').src"
                class="w-36 h-36 rounded-lg overflow-hidden flex-none"
              />
              <div
                v-else
                class="w-36 h-36 flex items-center justify-center rounded-lg bg-gray-200 text-gray-500 flex-none"
              >
                <DocumentIcon class="w-10 h-10" />
              </div>
              <div class="space-y-3 w-full">
                <base-input
                  inline
                  label="Name"
                  v-model="files[idx].name"
                />
                <base-input label="Category">
                  <el-select :options="categories" v-model="files[idx].category_id" />
                </base-input>
              </div>
            </li>
          </ul>
        </div>
        <div class="flex justify-end items-center gap-4 mt-20">
          <dam-button
            tag="button"
            class="min-w-[120px]"
            type="secondary"
            @click="--currentStep"
            v-if="currentStep > 0"
            >Previous</dam-button
          >
          <dam-button
            tag="button"
            class="min-w-[120px]"
            @click="++currentStep"
            v-if="currentStep < steps.length - 1"
            :disabled="!mayProceed"
            >Next</dam-button
          >
          <dam-button
            tag="button"
            class="min-w-[120px]"
            :disabled="saving"
            @click="save()"
            v-if="currentStep == steps.length - 1"
            >{{ saving ? 'Uploading...' : 'Finish' }}</dam-button
          >
        </div>
      </div>
    </div>
  </dam-panel>
</template>
<script>
import DamPanel from './Panel';
import DamButton from './Button';
// import DamDropzone from './Dropzone';
import StepsIndicator from '../../../components/Misc/StepsIndicator.vue';
import Form from '../../../components/Form';
import {
  DocumentIcon
 } from '@vue-hero-icons/outline'
export default {
  name: 'AssetsCreate',
  components: {
    DamPanel,
    DamButton,
    DocumentIcon,
    StepsIndicator,
    ...Form
  },
  props: {
    collections: {
      type: Array,
      default: () => []
    },
    categories: {
      type: Array,
      default: () => []
    },
    single: {
      type: Boolean,
      default: false
    },
    modal: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    steps() {
      return ['Select Collection', 'Select File'+(this.single ? '' : 's'), 'Review and Edit'];
    },
    newCollectionError() {
      if (this.newCollectionTouched) {
        if (this.newCollection.length < 4) {
          return 'Collection name is too short';
        } else if (this.collections.findIndex((col) => col.label.toLowerCase() === this.newCollection.toLowerCase()) > -1) {
          return 'Collection already exists.';
        } else {
          return null;
        }
      } else {
        return null;
      }
    },
    mayProceed() {
      switch(this.currentStep) {
        case 0:
          return this.collection > 0 || this.collection === -2 || (this.collection === -1 && !this.newCollectionError);
        case 1:
          return this.files.length;
        default:
          return false;
      }
    }
  },
  data() {
    return {
      saving: false,
      currentStep: 0,
      collection: null,
      newCollection: '',
      files: [],
      newCollectionTouched: false,
    }
  },
  methods: {
    save() {
      this.saving = true;
      if (this.single && this.files.length > 1) {
        window.alert('Please only upload one file!');
        return false;
      }
      const formData = new FormData;
      formData.append('collection_id', this.collection);
      if (this.collection == -1 && this.newCollection) {
        formData.append('new_collection', this.newCollection);
      }
      this.files.forEach(file => {
        formData.append('files[]', file.file, file.name);
        formData.append('file_categories[]', file.category_id || -2);
      });
      this.$CMS_POST('dam.assets.store', {}, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then((data) => {
        if (data.status === 'success') {
          window.dispatchEvent(new CustomEvent('dam-batches', {detail: {
            batches: data.assetBatches.map(ab => {return {asset: ab.asset.name, batch: ab.batch}})
          }}))
          if (this.modal) {
            this.$emit('uploaded', { files: data.assetBatches })
          } else {
            this.$inertia.visit(route('cms.dam.assets.index'));
          }
        } else {
          console.warn(data);
        }
        this.$nextTick().then(() => {
          this.saving = false;
        })
      });
    },
  },
  mounted() {

  },
};
</script>
