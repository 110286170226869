<template>
  <div :class="[
    (nested ? `p-6 bg-gray-${level * 100 + 100} border-gray-200` : `bg-white border-b border-gray-200 text-left max-w-full rounded-lg ${shadow}`),
    (scroll ? 'overflow-visible overflow-x-auto overflow-y-visible lg:overflow-visible' : 'overflow-visible')
  ]">
    <!-- Projects table (small breakpoint and up) -->
    <div :class="[
      (nested ? `border overflow-visible ${shadow}` : 'border-b'),
      (scroll ? 'overflow-visible overflow-x-auto overflow-y-visible lg:overflow-visible' : 'overflow-visible'),
      'rounded-lg align-middle inline-block w-full border-gray-200'
    ]">
      <table :class="[tableCss, 'w-full', (sortable ? 'base-table--sortable' : '')]">
        <thead v-if="$slots.head">
          <tr :class="['bg-gray-50', (nested ? 'border-b' : 'border-t border-b border-gray-200')]">
            <slot name="head" />
          </tr>
        </thead>
        <tbody class="bg-white divide-y divide-gray-100">
          <slot />
        </tbody>
        <tfoot v-if="$slots.foot" class="bg-gray-50 border-t border-gray-200">
          <tr>
            <slot name="foot" />
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
</template>
<script>
import TableCell from './TableCell';
export default {
  name: 'BaseTable',
  components: {
    TableCell,
  },
  props: {
    nested: {
      type: Boolean,
      default: false,
    },
    level: {
      type: Number,
      default: 1,
    },
    tableCss: {
      type: String,
      default: '',
    },
    scroll: {
      type: Boolean,
      default: false,
    },
    // Enable draggable
    sortable: {
      type: Boolean,
      default: false,
    },
    // v-model for draggable
    value: {
      type: Array,
      default: () => [],
    },
    shadow: {
      type: String,
      default: 'shadow'
    },
  },
  computed: {
    items: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
  data() {
    return {};
  },
};
</script>
<style>
/* .base-table--sortable tr::before {
  content: ' ';
  display: table-cell;
  opacity: 0.9;
  vertical-align: middle;
  width: 50px;
}
.base-table--sortable tbody tr {
  cursor: move;
}
.base-table--sortable tbody tr::before {
  background-image: url("https://cdn.jsdelivr.net/npm/heroicons@1.0.0/solid/switch-vertical.svg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 33% auto;
} */
</style>
