<template>
  <div class="py-1" role="none">
    <slot />
  </div>
</template>
<script>
export default {
  name: 'ActionsGroup'
}
</script>
