<template>
  <app-layout no-page-name>
    <div class="bg-white shadow mb-8 border border-solid border-gray-300 border-opacity-80">
      <!-- This example requires Tailwind CSS v2.0+ -->
      <nav class="bg-gray-800">
        <div class="mx-auto px-4 sm:px-6 lg:px-8">
          <div class="flex items-center justify-between h-16 relative">
            <div class="lg:hidden">
              <button @click="mobileNavOpen = !mobileNavOpen" class="text-white">
                <MenuIcon v-show="!mobileNavOpen" class="w-7 h-7" />
                <XIcon v-show="mobileNavOpen" class="w-7 h-7" />
              </button>
            </div>
            <div :class="[
              'absolute top-full -left-6 bg-gray-800 z-30 overflow-hidden transition-height min-w-[300px]',
              mobileNavOpen ? 'max-h-96 lg:max-h-none' : 'max-h-0 lg:max-h-none',
              'lg:static lg:flex'
            ]">
              <div class="flex flex-col gap-4 p-4 lg:p-0 lg:flex-row lg:items-center">
                <inertia-link v-for="(item, idx) in nav"
                  :key="`nav-${idx}`"
                  :href="route(item.route)"
                  :class="['block px-3 py-2 rounded-md text-sm font-medium', `${route().current(item.route) || route().current(item.fam) ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white'}`]"
                  aria-current="page"
                >
                    {{ item.label }}
                </inertia-link>
              </div>
            </div>
            <div class="flex items-center space-x-4">
              <div class="md:ml-4 md:flex-shrink-0 md:flex md:items-center">
                <inertia-link :href="route('cms.dam.settings.index')" class="bg-gray-800 p-1 rounded-full text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                  <span class="sr-only">Settings</span>
                  <cog-icon class="h-6 w-6" />
                </inertia-link>
              </div>
              <div class="flex-shrink-0">
                <inertia-link :href="route('cms.dam.assets.create')" class="relative inline-flex items-center px-4 py-2 space-x-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-500 hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-indigo-500">
                  <span>Upload Assets</span>
                  <upload-icon class="h-6 w-6" />
                </inertia-link>
              </div>
            </div>
          </div>
        </div>
      </nav>
      <div class="md:flex md:items-center md:justify-between mx-auto p-4 sm:p-6 lg:px-10 lg:py-6">
        <div class="flex-1 min-w-0">
          <h2 class="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
            {{ pageName }}
          </h2>
        </div>
        <form @submit.prevent="searchDam" class="flex rounded-md shadow-sm">
          <div class="relative flex items-stretch flex-grow focus-within:z-10">
            <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <search-icon class="h-5 w-5 text-gray-400" />
            </div>
            <input type="text" name="search" id="dam-layout-search" v-model="searchQuery" class="focus:ring-indigo-500 focus:border-indigo-500 block w-full rounded-none rounded-l-md pl-10 sm:text-sm border-gray-300" placeholder="Search by name">
          </div>
          <button type="submit" class="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500">
            Submit
          </button>
        </form>
      </div>
    </div>
    <div :class="contentContainer ? 'bg-white shadow p-6 border border-solid border-gray-300 border-opacity-80' : ''">
      <slot />
    </div>
  </app-layout>
</template>
<script>
import { UploadIcon } from '@vue-hero-icons/outline';
import { CogIcon, SearchIcon, SortAscendingIcon, MenuIcon, XIcon } from '@vue-hero-icons/solid';
export default {
  name: 'DamLayout',
  components: {
    UploadIcon,
    CogIcon,
    SearchIcon,
    SortAscendingIcon,
    MenuIcon,
    XIcon,
  },
  props: {
    contentContainer: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      mobileNavOpen: false,
      searchQuery: ''
    }
  },
  computed: {
    pageName() {
      return this.$page.props.pageName;
    },
    nav() {
      return [
        {
          label: 'Dashboard',
          route: 'cms.dam.dashboard',
          fam: 'cms.dam.dashboard.*',
        },
        {
          label: 'Assets',
          route: 'cms.dam.assets.index',
          fam: 'cms.dam.assets.*',
        },
        {
          label: 'Categories',
          route: 'cms.dam.categories.index',
          fam: 'cms.dam.categories.*',
        },
        {
          label: 'Collections',
          route: 'cms.dam.collections.index',
          fam: 'cms.dam.collections.*',
        },
        // TODO: For later revisions
        // {
        //   label: 'Portals',
        //   route: 'cms.dam.portals.index',
        //   fam: 'cms.dam.portals.*',
        // },
        // {
        //   label: 'Workflows',
        //   route: 'cms.dam.workflows.index',
        //   fam: 'cms.dam.workflows.*',
        // },
        {
          label: 'Actions',
          route: 'cms.dam.actions.index',
          fam: 'cms.dam.actions.*',
        },
      ];
    },
  },
  methods: {
    searchDam() {
      this.$inertia.visit(route('cms.dam.assets.index', {query: this.searchQuery}));
    }
  },
  mounted() {
    const params = route().params;
    if (params.query) {
      this.searchQuery = params.query;
    }
  }
};
</script>
