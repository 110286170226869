<template>
  <!-- New in 1.5: Uses upload config int_name as selector instead of array -->
  <!--
    Example File:
    {
      mobile: {
        url: 'https://example.com/storage/dir/test_sm.jpg',
        retina: 'https://example.com/storage/dir/test_sm@2x.jpg',
        webp: 'https://example.com/storage/dir/test_sm.webp',
        webp_retina: 'https://example.com/storage/dir/test_sm@2x.webp',
      },
      desktop: {
        url: 'https://example.com/storage/dir/test_lg.jpg',
        retina: 'https://example.com/storage/dir/test_lg@2x.jpg',
        webp: 'https://example.com/storage/dir/test_lg.webp',
        webp_retina: 'https://example.com/storage/dir/test_lg@2x.webp',
      }
    }
   -->
  <div
    class="file-upload-container cms-file-upload"
    @externalfile="externalFile"
    v-if="ready"
  >
    <div v-if="!disabled" class="block">
      <div class="inline-block relative">
        <base-button class="mr-2 relative z-0"> select file </base-button>
        <input
          type="file"
          :name="id"
          :id="id"
          @change="changeFile"
          class="block opacity-0 absolute top-0 left-0 w-full h-full z-10 cursor-pointer"
        />
      </div>

      <base-button
        v-if="damInstalled"
        type="dam"
        @click="selectFromAssetManager"
      >
        Select From Asset Manager
      </base-button>
      <base-button
        v-if="data"
        size="small"
        type="danger"
        class="ml-2"
        @click="deleteFiles"
      >
        delete files
      </base-button>
    </div>
    <div v-if="!disabled" class="block">
      <base-checkbox class="mt-3" v-model="overwrite">
        Overwrite existing?
      </base-checkbox>
    </div>
    <div v-if="!disabled" class="block files mt-3 text-sm">
      <small v-if="idealDimensions.width > 0 && idealDimensions.height > 0"
        >For best results use an image with a miniumum width of
        {{ idealDimensions.width }}px and miniumum height of
        {{ idealDimensions.height }}px.
      </small>
      <small>Max file size {{ $page.props.cms.max_upload }}MB</small>
    </div>
    <div class="block files mt-4 mb-4">
      <div
        v-for="(conf, idx) in upload.config"
        :key="`config${idx}`"
        class="files__items m-4"
      >
        <div v-if="conf.int_name && data[conf.int_name]">
          <div
            v-if="data[conf.int_name].url && isImage(data[conf.int_name].url)"
          >
            <img :src="data[conf.int_name].url" class="w-[200px]" />
            <p class="mt-2">{{ conf.name || 'File' }}</p>
          </div>
          <div v-else>
            <a
              :href="data[conf.int_name]"
              target="_blank"
              class="block rounded p-4 shadow bg-white text-center hover:shadow-lg min-w-[100px]"
            >
              <document-icon class="w-7 h-7 block mx-auto mb-4" />
              <span>{{ conf.name || 'File' }}</span>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="upload.config.length && upload.config[0].mode !== 'file'"
      class="mb-4"
    >
      <base-input
        label="Alt Text"
        v-model="data.alt_text"
        :disabled="disabled"
      />
    </div>
  </div>
</template>
<script>
import Cropper from './Cropper';
import Vue from 'vue';
// import axios from 'axios';
import { BaseCheckbox } from './index';
import { v4 as uuidv4 } from 'uuid';
import { DocumentIcon } from '@vue-hero-icons/outline';

let CropperClass = Vue.extend(Cropper);
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}
// function dataURLtoFile(dataurl, filename) {
//     var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
//         bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
//     while(n--){
//         u8arr[n] = bstr.charCodeAt(n);
//     }
//     return new File([u8arr], filename, {type:mime});
// }

export default {
  name: 'BaseFile',
  components: {
    BaseCheckbox,
    DocumentIcon,
  },
  props: {
    id: {
      type: String,
      default: () => uuidv4(),
    },
    files: {
      type: Array,
      default: () => {
        return [];
      },
    },
    configID: {
      type: Number,
      default: 0,
    },
    config: {
      type: Array,
      default: () => {
        return [
          {
            mode: 'file',
            name: 'Upload',
            int_name: 'upload',
            retina: false,
            webp: false,
            suffix: '',
            location: 'public',
            directory: '/temp',
            dimensions: { width: '', height: '' },
          },
        ];
      },
    },
    value: {
      type: [Array, Object],
      default: () => [],
    },
    filename: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      justMounted: true,
      overwrite: false,
      fileList: [],
      upload_files: [],
      upload: {
        config: [],
        configID: 0,
      },
      ready: false,
    };
  },
  computed: {
    data: {
      get() {
        return this.value;
      },
      set(data) {
        this.$emit('input', data);
      },
    },
    idealDimensions() {
      const dimensions = { width: 0, height: 0 };
      this.upload.config
        .filter((c) => c.mode !== 'file')
        .forEach((c) => {
          const width = parseInt(c.dimensions.width) * (c.retina ? 2 : 1);
          const height = parseInt(c.dimensions.height) * (c.retina ? 2 : 1);
          if (width > dimensions.width) {
            dimensions.width = width;
          }
          if (height > dimensions.height) {
            dimensions.height = height;
          }
        });
      return dimensions;
    },
    damInstalled() {
      return this.$page.props.cms.dam_installed;
    },
  },
  watch: {
    fileList() {
      if (this.justMounted) {
        this.justMounted = false;
      } else {
        if (this.fileList.length) {
          this.$emit('input', this.fileList);
          this.$emit('fileupdated', this.fileList);
        }
      }
    },
  },
  // Import it a weird way because input includes file includes input include file includes chicken includes egg
  beforeCreate: function () {
    this.$options.components.BaseInput = require('./BaseInput').default;
  },
  mounted() {
    this.upload.config = this.config;
    this.fileList = this.files.length
      ? this.files
      : this.value.length
      ? this.value
      : [];
    this.upload.configID = this.configID;

    if (typeof this.data.alt_text !== 'string') {
      this.data.alt_text = '';
    }

    if (this.configID > 0) {
      this.$root.$CMS_GET('uploads.show', this.configID).then((data) => {
        this.upload.config =
          typeof data.upload.config === 'string'
            ? JSON.parse(data.upload.config)
            : data.upload.config;
        this.$nextTick(() => {
          this.ready = true;
        });
      });
    } else {
      this.ready = true;
    }
  },
  methods: {
    isImage(filename) {
      const extensions = ['jpg', 'jpeg', 'png', 'gif', 'svg', 'webp'];
      const arr = filename.split('.');
      return filename && extensions.includes(arr[arr.length - 1].toLowerCase());
    },
    changeFile(ev) {
      if (ev.target && ev.target.files && ev.target.files[0]) {
        if (this.filename) {
          const extArray = ev.target.files[0].name.split('.');
          const ext = extArray[extArray.length - 1];
          this.updateFiles(
            new File([ev.target.files[0]], this.filename + '.' + ext)
          );
        } else {
          this.updateFiles(ev.target.files[0]);
        }
      } else {
        console.log('Nothing to do.');
      }
    },
    updateFiles(file, fileList, cb) {
      if (this.fileList.length) {
        this.fileList.splice(0, this.fileList.length);
      }
      this.upload_files = [];

      this.processFile(file, 0, cb);
    },
    processFile(file, confIndex, cb) {
      let conf = this.upload.config[confIndex];
      if (conf.mode === 'image-crop') {
        // Crop
        let image = file;
        getBase64(file).then((data) => {
          image.data = data;
          let instance = new CropperClass({
            propsData: {
              image: image,
              config: conf,
            },
          });
          instance.$mount();
          instance.$on('cancel', () => {
            instance.$el.remove();
            instance.$destroy();
            instance = null;
            if (this.upload_files.length) {
              this.doDeleteFiles();
            }
          });
          instance.$on('crop', ($event) => {
            // base64toBlob($event).then(data => {
            //   this.fileList.push(data);
            // });

            // Instead of pushing the cropped image to the array,
            // upload it to the server and then push the url to the array
            // Orrrr wait to upload until done looping through everything?
            // In which case set up a temp variable for the files array
            //_this.fileList.push($event);

            this.upload_files.push($event);

            setTimeout(() => {
              instance.$el.remove();
              instance.$destroy();
              instance = null;

              this.processFileEnd(file, confIndex, cb);
            }, 250);
          });
          this.$el.appendChild(instance.$el);
        });
      } else if (conf.mode === 'file') {
        let loader = this.$loading.show({
          color: '#009ae3',
          backgroundColor: '#000',
        });
        const formData = new FormData();
        // Add formdata
        formData.append('version', '2');
        formData.append('configID', this.upload.configID);
        formData.append('config', JSON.stringify(this.upload.config));
        formData.append('file', file);
        this.$root
          .$CMS_POST('storage.store', {}, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then((data) => {
            this.upload_files.push({
              type: file.type,
              data: data.url,
              name: file.name,
            });
            this.data = { ...data.files, alt_text: this.data.alt_text || '' };
            loader.hide();
            this.$noty.success('File uploaded!');
          });
      } else {
        getBase64(file).then((data) => {
          // Instead of pushing the cropped image to the array,
          // upload it to the server and then push the url to the array
          // _this.fileList.push(file.raw);
          this.upload_files.push({
            type: file.type,
            data: data.split(',').pop(),
            name: file.name,
          });
          setTimeout(() => {
            this.processFileEnd(file, confIndex);
          }, 250);
        });
      }
    },
    processFileEnd(file, confIndex, cb) {
      if (typeof this.upload.config[confIndex + 1] !== 'undefined') {
        this.processFile(file, confIndex + 1, cb);
      } else {
        if (this.upload_files.length) {
          this.$root
            .$CMS_FILE_UPLOAD({
              version: 2,
              files: JSON.stringify(this.upload_files),
              configID: this.upload.configID,
              config: JSON.stringify(this.upload.config),
              overwrite: this.overwrite,
            })
            .then((data) => {
              this.data = { ...data.files, alt_text: this.data.alt_text || '' };
              if (typeof cb === 'function') {
                cb();
              }
            });
        }
        return;
      }
    },
    deleteFiles() {
      const _this = this;
      this.$root
        .$CMS_ALERT({
          title: 'Are you sure?',
          text: `You won't be able to revert this!`,
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, delete them!',
        })
        .then((result) => {
          if (result.isConfirmed) {
            _this.doDeleteFiles();
          }
        });
    },
    doDeleteFiles() {
      const _this = this;
      _this.$root
        .$CMS_FILE_DELETE({
          files: JSON.stringify(_this.fileList),
        })
        .then((data) => {
          if (data.status === 'success') {
            console.log('deleted!');
          } else {
            console.error('FILE DELETE: Something went wrong....');
            console.error(data);
          }
          _this.fileList = [];
          _this.$emit('input', []);
          _this.$emit('fileupdated', []);
        });
    },
    async externalFile(evt) {
      if (typeof evt.detail !== 'undefined') {
        await fetch(evt.detail)
          .then((response) => response.blob())
          .then((blob) => {
            var filename = evt.detail.split('/');
            filename = filename[filename.length - 1];
            // var file = dataURLtoFile(data, filename);
            var file = new File([blob], filename, { mime: blob.type });

            this.updateFiles(file, this.fileList);
          });
      }
    },
    selectFromAssetManager() {
      window.addEventListener(
        'asset-manager-file-selected',
        this.selectedFromAssetManager
      );
      window.dispatchEvent(
        new CustomEvent('asset-manager-modal', {
          detail: {
            defaultType:
              this.upload.config[0].mode === 'file' ? null : 'image/%',
          },
        })
      );
      // TODO: if upload is image, limit dam modal to only show image assets
    },
    selectedFromAssetManager(evt) {
      const files = evt.detail?.files || [];
      console.log(evt);

      if (files.length) {
        const file = files[0];
        if (String(this.upload?.config[0].mode || '').includes('image')) {
          fetch(file.url, {
            mode: 'no-cors',
          })
            .then((response) => response.blob())
            .then((blob) => {
              // var filename = file.split('/');
              // filename = filename[filename.length-1];
              // var file = dataURLtoFile(data, filename);
              var fileObj = new File([blob], file.name, { mime: blob.type });

              // console.log(fileObj, file.name, blob.type);

              this.updateFiles(fileObj);
            });
        } else if (this.upload?.config.length === 1) {
          const data = {};
          data[this.upload.config[0].int_name] = file.url;
          this.data = { ...data, alt_text: '' };
          console.log(data);
        } else {
          console.warn('Unknown config');
        }
      } else {
        console.warn('No files');
        console.log(evt.detail);
      }
      window.removeEventListener(
        'asset-manager-file-selected',
        this.selectedFromAssetManager
      );
    },
  },
};
</script>
<style scoped>
.files {
  display: flex;
  flex-wrap: wrap;
}
</style>
