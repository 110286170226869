<template>
  <app-layout>
    <base-card class="space-y-4 mb-10">
      <div>
        <div class="mb-4">
          <base-input
            v-model="category.name"
            type="text"
            label="Name"
            placeholder=""
            required
          />
        </div>
      </div>

      <base-button @click="updateCategory">
        Save
      </base-button>
    </base-card>
  </app-layout>
</template>
<script>
import Form from '../components/Form';
import { XIcon } from '@vue-hero-icons/solid';
import Table from '../components/Table';
export default {
  name: 'ComponentCategory',
  components: {
    ...Form,
    ...Table,
    XIcon,
  },
  props: {
    category: {
      type: Object,
      default: () => {
        return {
          name: '',
        };
      },
    },
  },
  methods: {
    updateCategory() {
      if (this.category.id) {
        this.$root.$CMS_UPDATE(
          'component-categories',
          this.category.id,
          this.category
        );
      } else {
        this.$root
          .$CMS_CREATE('component-categories', {}, this.category)
          .then((data) => {
            if (data.category.id) {
              window.location.href = route(
                'cms.component-categories.show',
                data.category.id
              );
            }
          });
      }
    },
  },
};
</script>
