<template>
  <dam-layout>
    <div class="space-y-4">
      <base-input label="Name" v-model="item.name" />
      <base-input label="Parent">
        <el-select :options="collectionOptions" v-model="item.parent_id" />
      </base-input>
      <base-input label="Show On Dashboard">
        <base-switch v-model="item.show_on_dashboard" />
      </base-input>

      <div class="inline-flex gap-4 items-start justify-start">
        <base-button @click="save()">Save</base-button>
        <base-button @click="save(true)">Save &amp; Exit</base-button>
      </div>
    </div>
  </dam-layout>
</template>
<script>
import DamLayout from '../../layouts/DamLayout';
import Form from '../../components/Form';
export default {
  name: 'CollectionsEdit',
  components: {
    DamLayout,
    ...Form
  },
  props: {
    collections: {
      type: Array,
      default: () => []
    },
    item: {
      type: Object,
      default: () => {
        return {
          id: 0,
          name: '',
          show_on_dashboard: false,
          parent_id: null
        }
      }
    }
  },
  computed: {
    collectionOptions() {
      return [
        { label: 'None', value: 0 },
        ...this.collections
          // .filter(col => {return (col.full_name.match(/>/g)||[]).length < 2}) // prevent more than 3 levels deep
          .map(col => {return {label: col.full_name, value: col.id}}) // map for select
      ]
    }
  },
  methods: {
    save(exit) {
      if (this.item.id) {
        this.$root
          .$CMS_UPDATE('dam.collections', this.item.id, this.item)
          .then(() => {
            this.$root.protected = false;
            if (exit) {
              this.$inertia.visit(route('cms.dam.collections.index'));
            } else {
              this.$inertia.reload();
            }
          });
      } else {
        this.$root.$CMS_CREATE('dam.collections', {}, this.item).then((data) => {
          this.$root.protected = false;
          if (data && data.item && data.item.id) {
            if (exit) {
              this.$inertia.visit(route('cms.dam.collections.index'));
            } else {
              window.location.href = route('cms.dam.collections.show', data.item.id);
            }
          }
        });
      }
    }
  }
};
</script>
