var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
  (_vm.nested ? `p-6 bg-gray-${_vm.level * 100 + 100} border-gray-200` : `bg-white border-b border-gray-200 text-left max-w-full rounded-lg ${_vm.shadow}`),
  (_vm.scroll ? 'overflow-visible overflow-x-auto overflow-y-visible lg:overflow-visible' : 'overflow-visible')
]},[_c('div',{class:[
    (_vm.nested ? `border overflow-visible ${_vm.shadow}` : 'border-b'),
    (_vm.scroll ? 'overflow-visible overflow-x-auto overflow-y-visible lg:overflow-visible' : 'overflow-visible'),
    'rounded-lg align-middle inline-block w-full border-gray-200'
  ]},[_c('table',{class:[_vm.tableCss, 'w-full', (_vm.sortable ? 'base-table--sortable' : '')]},[(_vm.$slots.head)?_c('thead',[_c('tr',{class:['bg-gray-50', (_vm.nested ? 'border-b' : 'border-t border-b border-gray-200')]},[_vm._t("head")],2)]):_vm._e(),_vm._v(" "),_c('tbody',{staticClass:"bg-white divide-y divide-gray-100"},[_vm._t("default")],2),_vm._v(" "),(_vm.$slots.foot)?_c('tfoot',{staticClass:"bg-gray-50 border-t border-gray-200"},[_c('tr',[_vm._t("foot")],2)]):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }