<template>
  <ul v-if="notes.length" role="list" class="divide-y divide-gray-200 border-b border-gray-200">
    <li v-for="note in notes" :key="`note-${note.id}`" class="relative bg-white px-6 py-5 focus-within:ring-2 focus-within:ring-inset focus-within:ring-blue-600 hover:bg-gray-50">
      <div class="flex justify-between space-x-3">
        <div class="min-w-0 flex-1">
          <button type="button" @click="reading = note.id" class="block focus:outline-none text-left">
            <span class="absolute inset-0" aria-hidden="true"></span>
            <p class="truncate text-sm font-medium text-gray-900">{{ note.sender.full_name }}</p>
            <p class="truncate text-sm text-gray-500"><span v-if="note.noteable_type && !detail">[<span v-text="note.noteable_type.split('\\').slice(-1)[0]"></span>: {{ note.noteable_id }}]</span> {{ note.subject }}</p>
          </button>
        </div>
        <time :datetime="note.created_at" class="flex-shrink-0 whitespace-nowrap text-sm text-gray-500">{{ ago(note.updated_at) }}</time>
      </div>
      <div class="mt-1">
        <p class="line-clamp-2 text-sm text-gray-600">{{ note.summary }}</p>
      </div>
      <div v-if="reading === note.id" class="fixed bg-gray-800/60 backdrop-blur-sm top-0 left-0 w-full h-full z-50 flex items-center justify-center py-16 px-24">
        <button class="absolute top-16 right-24 -translate-y-1/2 translate-x-1/2 rounded-full p-1 border-2 border-solid border-white shadow-lg bg-black" title="Cancel" type="button" @click="cancelModal">
          <XIcon class=" w-6 h-6 text-white" />
        </button>

        <div class="w-full min-h-full max-h-[90vh] bg-white shadow-xl rounded-sm overflow-hidden flex flex-col">
          <div class="w-full min-h-full grow overflow-auto p-6">
            <notes-message :note="note" />
          </div>
        </div>
      </div>
    </li>
  </ul>
  <p v-else class="font-medium text-gray-500 text-sm">
    No notes.
  </p>
</template>
<script>
import NotesMessage from './NotesMessage.vue'
import { XIcon } from '@vue-hero-icons/solid'
export default {
  name: 'NotesListing',
  components: {
    XIcon,
    NotesMessage,
  },
  props: {
    notes: {
      type: Array,
      default: () => []
    },
    detail: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    ago (date) {
      return this.moment(date).fromNow()
    },
    escape(event) {
      if (event.keyCode === 27) {
        this.cancelModal();
      }
    },
    cancelModal() {
      this.reading = 0;
    },
  },
  created: function() {
    document.addEventListener('keyup', this.escape);
  },
  destroyed: function() {
    document.removeEventListener('keyup', this.escape);
  },
  data() {
    return {
      reading: 0
    }
  }
}
</script>
