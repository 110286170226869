<template>
  <div>
    <base-input container-class="mb-3" label="Filter Options" label-size="text-xs" v-if="options.length > 10 && options[0] && typeof options[0] === 'object'" v-model="search" @input="searchOptions" placeholder="Search options..." />
    <div v-if="options[0] && typeof options[0] === 'object'" class="grid gap-2 grid-flow-row grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 border border-solid border-gray-300 rounded p-4 overflow-auto max-h-80" v-show="!search || matchingOptions.length">
      <div v-for="(opt,idx) in checkedOptions" :key="`checkboxes-${checkedOptions.length}-${id}-${idx}-checked`" v-show="!search.length || matchingOptions.includes(opt.id)" class="overflow-hidden text-ellipsis whitespace-nowrap">
        <!-- Dynamic Checkboxes -->
        <base-checkbox reversed cutoff :name="inputName" :value="data && data.includes(opt.id)" @input="check(opt.id)">
          {{ opt.name || opt.title || opt[Object.keys(opt).filter(o => o !== 'id')[0]] || opt.id }}
        </base-checkbox>
      </div>
      <div v-for="(opt,idx) in uncheckedOptions" :key="`checkboxes-${uncheckedOptions.length}-${id}-${idx}`" v-show="!search.length || matchingOptions.includes(opt.id)" class="overflow-hidden text-ellipsis whitespace-nowrap">
        <!-- Dynamic Checkboxes -->
        <base-checkbox reversed cutoff :name="inputName" :value="data && data.includes(opt.id)" @input="check(opt.id)">
          {{ opt.name || opt.title || opt[Object.keys(opt).filter(o => o !== 'id')[0]] || opt.id }}
        </base-checkbox>
      </div>
    </div>
    <div v-else class="grid gap-2 grid-flow-row grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 border border-solid border-gray-300 rounded p-4 overflow-auto max-h-80" v-show="!search || matchingOptions.length">
      <div v-for="(opt,idx) in checkedOptions" :key="`checkboxes-${checkedOptions.length}-${id}-${idx}-checked`" class="overflow-hidden text-ellipsis whitespace-nowrap">
        <!-- Basic Checkboxes -->
        <base-checkbox reversed cutoff:key="`checkboxes-${id}-${idx}`" :name="inputName" :value="data && data.includes(opt)" @input="check(opt)">
          {{ opt }}
        </base-checkbox>
      </div>
      <div v-for="(opt,idx) in uncheckedOptions" :key="`checkboxes-${uncheckedOptions.length}-${id}-${idx}`" class="overflow-hidden text-ellipsis whitespace-nowrap">
        <!-- Basic Checkboxes -->
        <base-checkbox reversed cutoff:key="`checkboxes-${id}-${idx}`" :name="inputName" :value="data && data.includes(opt)" @input="check(opt)">
          {{ opt }}
        </base-checkbox>
      </div>
    </div>
    <p v-if="search.length && !matchingOptions.length" class="text-gray-500 font-semibold text-sm">No matching options</p>
  </div>
</template>
<script>
import {v4 as uuidv4} from 'uuid'
import BaseInput from './BaseInput'
import BaseCheckbox from './BaseCheckbox'
export default {
  components: { BaseCheckbox, BaseInput },
  name: 'BaseCheckboxes',
  props: {
    value: {
      type: Array,
      default: () => []
    },
    options: {
      type: Array,
      default: () => []
    },
    name: {
      type: String,
      default: () => ''
    },
    reversed: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      default: () => uuidv4()
    },
  },
  computed: {
    data: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', [...(new Set(val))])
      }
    },
    inputName() {
      return `${this.name || this.id}[]`;
    },
    checkedOptions() {
      return this.value && this.value.length ?
        (
          this.options[0] && typeof this.options[0] === 'object' ?
            this.options.filter(opt => this.value && this.value.includes(opt.id))
            : this.options.filter(opt => this.value && this.value.includes(opt))
        )
        : [];
    },
    uncheckedOptions() {
      return this.value && this.value.length ?
        (
          this.options[0] && typeof this.options[0] === 'object' ?
            this.options.filter(opt => !(this.value && this.value.includes(opt.id)))
            : this.options.filter(opt => !(this.value && this.value.includes(opt)))
        )
        : this.options;
    }
  },
  methods: {
    check(opt) {
      const optIndex = this.data ? this.data.indexOf(opt) : -1;
      if (optIndex >= 0) {
        this.data.splice(optIndex, 1);
        // bugfix: make sure it's not in there multiple times
        if (this.data.indexOf(opt) > -1) {
          this.check(opt);
        }
      } else {
        if (this.data) {
          this.data.push(opt);
        } else {
          this.data = [opt];
        }
      }
    },
    searchOptions() {
      this.matchingOptions = this.options.filter((opt) => {
        const str = String(opt.name || opt.title || opt[Object.keys(opt).filter(o => o !== 'id')[0]] || opt.id).toLowerCase();
        return str.indexOf(this.search.toLowerCase()) > -1;
      }).map(opt => opt.id);
    }
  },
  data() {
    return {
      search: '',
      matchingOptions: []
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (typeof this.data === 'undefined') {
        this.$emit('input', []);
      }
    })
  }
}
</script>
