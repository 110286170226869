<template>
  <p :class="[cssClass, 'font-bold', 'text-sm', 'mt-1']">{{ count }} / {{ limit }}</p>
</template>
<script>
export default {
  name: 'CharLimit',
  data() {
    return {
      cssClass: 'text-gray-500'
    }
  },
  mounted() {
    this.setCss()
  },
  props: {
    count: {
      type: Number,
      default: 0
    },
    limit: {
      type: Number,
      default: 1
    }
  },
  methods: {
    setCss() {
      const ratio = this.count / this.limit;
      if (ratio >= 1) {
        this.cssClass = 'text-red-500'
      } else if (ratio >= 0.5) {
        this.cssClass = 'text-yellow-500'
      } else {
        this.cssClass = 'text-gray-500'
      }
    }
  },
  watch: {
    count() {
      this.setCss()
    }
  }
}
</script>
