<template>
  <div>
    <ul class="flex gap-6 flex-wrap justify-start items-center mb-6">
      <li v-for="(item, idx) in items" :key="`item${idx}`" class="flex gap-2 items-center justify-center">
        <span>{{ item[labelKey] }}</span>
        <dam-button-simple @click="removeItem(idx)" text-color="text-red-600"><XIcon class=" w-3 h-3 " /></dam-button-simple>
      </li>
    </ul>
    <div v-if="addingItem" class="space-y-4">
      <el-select :options="options" v-model="newItemId" placeholder="Please select..." />
      <div v-if="newItemId === 0" class="flex gap-4">
        <base-input label="Name" v-model="newItemName" />
      </div>
      <div class="flex gap-4 justify-start items-start">
        <dam-button tag="button" @click="save">Save</dam-button>
        <dam-button tag="button" type="secondary" @click="addingItem = false">Cancel</dam-button>
      </div>
    </div>
    <dam-button v-else tag="button" @click="addingItem = true">Add New</dam-button>
  </div>
</template>
<script>
import DamButton from './Button'
import DamButtonSimple from './ButtonSimple'
import Form from '../../../components/Form'
import {XIcon} from '@vue-hero-icons/solid'
export default {
  components: {
    DamButton,
    DamButtonSimple,
    XIcon,
    ...Form
  },
  props: {
    slug: {
      type: String,
      default: ''
    },
    labelKey: {
      type: String,
      default: 'name'
    },
    assetId: {
      type: Number,
      default: 0
    },
    value: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    items: {
      get() { return this.value },
      set(val) { this.$emit('input', val) }
    }
  },
  methods: {
    removeItem(idx) {
      this.$CMS_UPDATE(`dam.${this.slug}`, this.items[idx].id, {asset_id: this.assetId, detach: true }).then(data => {
          this.items.splice(idx, 1);
          this.newItemId = -1;
          this.addingItem = false;
        });
    },
    save() {
      if (this.newItemId == 0) {
        this.$CMS_CREATE(`dam.${this.slug}`, {}, {[this.labelKey]: this.newItemName, asset_id: this.assetId}).then(data => {
          this.items.push(data.item);
          this.newItemName = '';
          this.newItemId = -1;
          this.addingItem = false;
        });
      } else if (this.newItemId > 0) {
        this.$CMS_UPDATE(`dam.${this.slug}`, this.newItemId, {asset_id: this.assetId}).then(data => {
          this.items.push(data.item);
          this.newItemId = -1;
          this.addingItem = false;
        });
      }
    }
  },
  data() {
    return {
      addingItem: false,
      newItemName: '',
      newItemId: -1,
      options: []
    }
  },
  mounted() {
    this.$CMS_GET(`dam.${this.slug}.index`).then(data => {
      this.options = [{
        value: 0,
        label: 'Create New'
      }, ...data.items.map(i => {
        return {
          label: i[this.labelKey],
          value: i.id
        }
      })]
    });
  }
}
</script>
