<template>
  <app-layout>
    <template v-if="pinnable && Array.isArray(pinnedItems)">
      <stack-list class="mb-5">
        <stack-list-item>
          Pinned Items ({{ pinnedItems.filter(i => i.is_pinned).length }})
          <template v-slot:expand>
            <listing-inner v-bind="$props" :items="pinnedItems.filter(i => i.is_pinned)" :sortable="false" :sisters="[]" :trashable="false" :trashed="false" :showChildren="false" />
          </template>
        </stack-list-item>
      </stack-list>
      <base-listing v-bind="$props">
        <template v-if="buttons.length" v-slot:buttons>
          <base-button v-for="button in buttons" :key="button.href" :tag="button.tag" :href="button.href">{{ button.text }}</base-button>
        </template>
      </base-listing>
    </template>
    <base-listing v-else v-bind="$props">
        <template v-if="buttons.length" v-slot:buttons>
          <base-button v-for="button in buttons" :key="button.href" :tag="button.tag" :href="button.href">{{ button.text }}</base-button>
        </template>
    </base-listing>
  </app-layout>
</template>
<script>
import BaseListing from '../components/Listing/BaseListing';
import ListingInner from '../components/Listing/ListingInner';
import Stack from '../components/Stack';

const flattenPinnedItems = (data, parent) => {
  return data.reduce((prev, el) => {
    if (parent && el.name) {
      el.name = `${parent} > ${el.name}`;
    }
    prev.push(el);
    if (el.children && el.children.length) {
      prev.push(...flattenPinnedItems(el.children, el.name));
    }
    return prev;
  }, []);
};

export default {
  name: 'Listing',
  components: {
    BaseListing,
    ListingInner,
    ...Stack,
  },
  props: {
    slug: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      default: () => {
        return [];
      },
    },
    columns: {
      type: Array,
      default: () => {
        return [];
      },
    },
    routeParams: {
      type: Object,
      default: () => {},
    },
    sisters: {
      type: Array,
      default: () => [],
    },
    id: {
      type: Number,
      default: 0,
    },
    mod: {
      type: Object,
      default: () => {
        return {};
      },
    },
    parent: {
      type: Object,
      default: () => {
        return {
          mod: null,
          item: null,
        };
      },
    },
    trashable: {
      type: Boolean,
      default: false,
    },
    trashed: {
      type: Boolean,
      default: false,
    },
    sortable: {
      type: Boolean,
      default: false,
    },
    buttonsReverse: {
      type: Boolean,
      default: false,
    },
    buttons: {
      type: Array,
      default: () => [],
    },
    asyncItems: {
      type: Boolean,
      default: false
    },
    itemsPinned: {
      type: Array,
      default: () => []
    },
    columnSortable: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    pinnable() {
      return (
        Array.isArray(this.items) &&
        this.items.length &&
        typeof this.items[0].is_pinned !== 'undefined'
      ) || (
        Array.isArray(this.itemsPinned) &&
        this.itemsPinned.length &&
        typeof this.itemsPinned[0].is_pinned !== 'undefined'
      );
    },
    pinnedItems() {
      if (this.asyncItems && this.itemsPinned.length) {
        return this.itemsPinned;
      } else {
        const itemsClone = JSON.parse(JSON.stringify(this.items));
        return flattenPinnedItems(itemsClone);
      }
    },
  },
  methods: {},
};
</script>
