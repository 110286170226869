<template>
  <app-layout>
    <stack-list>
      <stack-list-item v-for="(setting, idx) in settings" :key="`setting${idx}-${setting.id || ''}`" ref="setting">
        <template v-slot:default>
          {{ setting.name || 'New Setting' }}
        </template>
        <template v-slot:expand>
          <base-input label="Name" :disabled="!setting.deletable" v-model="setting.name" class="mb-4" />
          <base-input label="Type" v-if="!setting.id" class="mb-4">
            <el-select v-model="setting.type" @change="newSettingType(setting.type, idx)">
              <option value="string" selected>String</option>
              <option value="textarea">Large String / Code Block</option>
              <option value="upload">File Upload</option>
            </el-select>
          </base-input>
          <base-input label="Value" class="mb-4">
            <base-input v-if="setting.type === 'string'" v-model="setting.value" />
            <div v-else-if="setting.type === 'upload' && setting.value.files">
              <file-upload
                v-model="setting.value.files"
                :files="wrapImages(setting.value.files)"
                :config="setting.value.config"
                :ref="`setting${idx}-upload`"
                @fileupdated="save(setting)"
              />
            </div>
            <base-text-area v-else v-model="setting.value" rows="10"  />
          </base-input>
          <base-button @click="save(setting)" class="mr-4">{{ `${ setting.id ? 'Save' : 'Create' }` }}</base-button>
          <base-button v-if="setting.deletable && setting.id" @click="del(setting)" type="danger">Delete</base-button>
        </template>
      </stack-list-item>
    </stack-list>
    <div class="mt-4">
      <base-button @click="add" :disabled="settings.filter(s => !s.id).length > 0">Add</base-button>
    </div>
    <div class="mt-6 flex justify-between items-between w-full">
      <base-heading size="xl">System Health</base-heading>
      <!-- <inertia-link :href="route('cms.settings.refresh-status')" title="Refresh Status">
        <RefreshIcon class="w-5 h-5" />
      </inertia-link> -->
    </div>
    <base-table class="table-fixed">
      <template v-slot:head>
        <table-heading  class="w-2/3">
          Service
        </table-heading>
        <table-heading class="w-1/3 text-center">
          Status
        </table-heading>
      </template>
      <template v-slot:default>
        <tr>
          <table-cell  class="w-2/3">
            Scheduler
          </table-cell>
          <table-cell class="w-1/3 text-center">
            <CheckCircleIcon v-if="system_health.scheduler_running" class="inline-block w-5 h-5 text-green-500" />
            <XCircleIcon v-else class="inline-block  w-5 h-5 text-red-500" />
          </table-cell>
        </tr>
        <tr>
          <table-cell class="w-2/3">
            Queue Worker
          </table-cell>
          <table-cell  class="w-1/3 text-center">
            <CheckCircleIcon v-if="system_health.queue_running" class="inline-block  w-5 h-5 text-green-500" />
            <XCircleIcon v-else class="inline-block  w-5 h-5 text-red-500" />
          </table-cell>
        </tr>
      </template>
    </base-table>

    <base-heading size="xl" class="mt-6">IP Access</base-heading>
    <base-table class="table-fixed">
      <template v-slot:head>
        <table-heading>
          Name
        </table-heading>
        <table-heading>
          IP Address
        </table-heading>
        <table-heading>
          Access
        </table-heading>
        <table-heading class="w-28">
          Actions
        </table-heading>
      </template>
      <template v-if="ip_addresses.length || ip_whitelist.length" v-slot:default>
        <tr v-for="(ip, idxIP) in ip_whitelist" :key="`ipWhitelist-${idxIP}`">
          <table-cell>
            Manually added
          </table-cell>
          <table-cell>
            {{ ip }}
          </table-cell>
          <table-cell colspan="2">
            allowed
          </table-cell>
        </tr>
        <tr v-for="(ip, idxIP) in ip_addresses" :key="`ipAddress-${idxIP}`">
          <table-cell>
            <base-input v-if="ipAddressEditing === idxIP" v-model="ip_addresses[idxIP].name" />
            <span v-else>
              {{ ip.name }}
            </span>
          </table-cell>
          <table-cell>
            <base-input v-if="ipAddressEditing === idxIP" v-model="ip_addresses[idxIP].ip_address" />
            <span v-else>
              {{ ip.ip_address }}
            </span>
          </table-cell>
          <table-cell>
            <el-select v-if="ipAddressEditing === idxIP" :options="['allowed', 'denied']" v-model="ip_addresses[idxIP].access" />
            <span v-else>
              {{ ip.access }}
            </span>
          </table-cell>
          <table-cell class="w-28" overflow>
            <base-button v-if="ipAddressEditing === idxIP" @click="ipAddressEditing = -1">Done</base-button>
            <div v-else class="w-10">
              <actions-popover :ref="`ipAddressActions-${idxIP}`">
                <actions-button @click.prevent="ipAddressEdit(idxIP)">
                  <pencil-alt-icon class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" />
                  Edit
                </actions-button>
                <actions-button @click.prevent="ipAddressDelete(idxIP)">
                  <trash-icon class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" />
                  Delete
                </actions-button>
              </actions-popover>
            </div>
          </table-cell>
        </tr>

      </template>
      <template v-else v-slot:default>
        <tr>
          <table-cell colspan="4">No IP Addresses added.</table-cell>
        </tr>
      </template>
      <template v-slot:foot>
        <table-cell colspan="4" class="space-x-4">
          <base-button @click="saveIpAddresses">Save</base-button>
          <base-button @click="addIpAddress">Add IP Address</base-button>
        </table-cell>
      </template>
    </base-table>
    <!-- <base-card v-else>
      <p class="text-gray-500 text-sm">IP restriction is disabled in the system configuration; a developer will need to enable this in the env file.</p>
    </base-card> -->

    <base-heading size="xl" class="mt-6">Banned Words</base-heading>
    <base-table class="table-fixed">
      <template v-slot:head>
        <table-heading>
          Content
        </table-heading>
        <table-heading>
          Reject/Ban
        </table-heading>
        <table-heading class="w-28">
          Actions
        </table-heading>
      </template>
      <template v-if="banned_words.length" v-slot:default>
        <tr v-for="(word, idxBW) in banned_words" :key="`bannedWord-${idxBW}`">
          <table-cell>
            <base-input v-if="bannedWordEditing === idxBW" v-model="banned_words[idxBW].content" />
            <span v-else>
              {{ word.content }}
            </span>
          </table-cell>
          <table-cell>
            <base-input v-if="bannedWordEditing === idxBW" v-model="banned_words[idxBW].action" />
            <span v-else>
              {{ word.action }}
            </span>
          </table-cell>
          <table-cell class="w-28" overflow>
            <base-button v-if="bannedWordEditing === idxBW" @click="bannedWordEditing = -1">Done</base-button>
            <div v-else class="w-10">
              <actions-popover :ref="`bannedWordActions-${idxBW}`">
                <actions-button @click.prevent="bannedWordEdit(idxBW)">
                  <pencil-alt-icon class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" />
                  Edit
                </actions-button>
                <actions-button @click.prevent="bannedWordDelete(idxBW)">
                  <trash-icon class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" />
                  Delete
                </actions-button>
              </actions-popover>
            </div>
          </table-cell>
        </tr>

      </template>
      <template v-else v-slot:default>
        <tr>
          <table-cell colspan="4">No banned words added.</table-cell>
        </tr>
      </template>
      <template v-slot:foot>
        <table-cell colspan="4" class="space-x-4">
          <base-button @click="saveBannedWords">Save</base-button>
          <base-button @click="addBannedWord">Add Banned Word</base-button>
        </table-cell>
      </template>
    </base-table>

    <div id="cms-settings-commands" class="mt-5">
      <base-button type="danger" small @click="clearServerCache($event)">Clear Server Cache</base-button>
    </div>
  </app-layout>
</template>
<script>
import Form from '../components/Form'
import Stack from '../components/Stack'
import Table from '../components/Table'
import Actions from '../components/Actions'
import { PencilAltIcon, TrashIcon, CheckCircleIcon, XCircleIcon, RefreshIcon } from '@vue-hero-icons/solid'
export default {
  name: 'Settings',
  components: {
    ...Stack,
    ...Form,
    ...Table,
    ...Actions,
    PencilAltIcon,
    TrashIcon,
    CheckCircleIcon,
    XCircleIcon,
    RefreshIcon
  },
  data() {
    return {
      ipAddressEditing: -1,
      bannedWordEditing: -1,
    }
  },
  props: {
    settings: {
      type: Array,
      default: () => []
    },
    ip_addresses: {
      type: Array,
      default: () => []
    },
    ip_restricted: {
      type: Boolean,
      default: false
    },
    ip_whitelist: {
      type: Array,
      default: () => []
    },
    banned_words: {
      type: Array,
      default: () => [],
    },
    system_health : {
      type: Object,
      default: () => {
        return {
          scheduler_running: false,
          queue_running: false
        }
      }
    }
  },
  methods: {
    add() {
      if (!this.settings.filter(s => !s.id).length) {
        this.settings.push({
          name: '',
          type: 'string',
          deletable: 1,
          value: ''
        });
        this.$nextTick(() => {
          this.$refs.setting[this.$refs.setting.length-1].$el.firstChild.dispatchEvent(new MouseEvent('click'));
        })
      }
    },
    save(setting) {
      if (setting.value.files) {
        setting.value.files = setting.value.files.map((f) => {
          if (f.dataURL) {
            delete f.dataURL;
          }
          return f;
        })
      }
      this.$nextTick(() => {
        if(setting.id) {
          this.$root.$CMS_UPDATE('settings', setting.id, setting);
        } else {
          this.$root.$CMS_CREATE('settings', {}, setting).then((data)=>{
            if(data.setting.id) {
              const idx = this.settings.findIndex((s) => {
                return s.name === data.setting.name;
              });
              this.settings[idx].id = data.setting.id;
              this.$refs.setting[this.$refs.setting.length-1].$el.firstChild.dispatchEvent(new MouseEvent('click'));
            }
          });
        }
      })
    },
    del(setting) {
      this.$inertia.delete(route(`cms.settings.destroy`, { id: setting.id }), {
        preserveState: true,
        preserveScroll: true,
        only: ['settings'],
        onBefore: () => confirm('Are you sure you want to delete this item?'),
        onStart: () => document.body.style.cursor = 'wait',
        onFinish: () => document.body.style.cursor = 'default'
      })
    },
    wrapImages(files) {
      return files.map((file) => {
        if (typeof file === 'string') {
          const fileArr = file.split('/');
          return {
            "raw": [],
            "uid": Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15),
            "url": file,
            "name": fileArr[fileArr.length-1],
            "size": 100,
            "status": "ready",
            "location": "",
            "directory": "",
            "percentage": 0
          };
        } else {
          return file;
        }
      });
    },
    newSettingType(value, idx) {
      if (value === 'upload') {
        console.log(this.settings[idx]);
        this.settings[idx].value = JSON.parse('{"files":[],"configID":0,"config":[{"mode":"file","name":"Setting File","retina":false,"suffix":"","location":"public","directory":"/cms-settings","dimensions":{"width":"","height":""}}]}')
      } else {
        this.settings[idx].value = '';
      }
    },
    clearServerCache($event) {
      $event.target.classList.add('disabled');
      $event.target.style.minWidth = `${$event.target.offsetWidth}px`;
      $event.target.innerHTML = '<span class="block mx-auto spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>';
      this.$CMS_GET('settings.clearcache').then((resp)=>{
        if (resp.status === 'success') {
          $event.target.innerHTML = "Cache Cleared";
          setTimeout(() => {
            $event.target.innerHTML = "Clear Server Cache";
            $event.target.classList.remove('disabled');
          }, 5000)
        }
      }).catch(() => {
        $event.target.innerHTML = "Error!";
          setTimeout(() => {
            $event.target.innerHTML = "Clear Server Cache";
            $event.target.classList.remove('disabled');
          }, 5000)
      });
    },
    addIpAddress() {
      this.ip_addresses.push({
        name: '',
        ip_address: '',
        access: ''
      });
      this.ipAddressEditing = this.ip_addresses.length - 1
    },
    ipAddressEdit(idx) {
      this.ipAddressEditing = idx;
    },
    ipAddressDelete(idx) {
      this.ip_addresses.splice(idx,1);
    },
    saveIpAddresses() {
      this.$inertia.post(route('cms.settings.ip-addresses'), {
        ip_addresses: this.ip_addresses
      }, {
        onFinish: () => {
          this.$noty.success('IP Addresses updated!')
        }
      })
    },

    addBannedWord() {
      this.banned_words.push({
        content: '',
        action: 'reject',
      });
      this.bannedWordEditing = this.banned_words.length - 1
    },
    bannedWordEdit(idx) {
      this.bannedWordEditing = idx;
    },
    bannedWordDelete(idx) {
      this.banned_words.splice(idx,1);
    },
    saveBannedWords() {
      this.$inertia.post(route('cms.settings.banned-words'), {
        banned_words: this.banned_words
      }, {
        onFinish: () => {
          this.$noty.success('Banned words updated!')
        }
      })
    }
  }
}
</script>
