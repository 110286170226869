<template>
  <div
    v-if="ready"
    class="flex mb-4"
  >
    <div :class="`${componentsCollapsed ? 'w-[98%]' : 'w-2/3'} pr-6`">
      <draggable
        v-if="data"
        v-model="data"
        class="list-group"
        tag="ul"
        handle=".component-handle"
        :empty-insert-threshold="100"
        :class="['w-full rounded-md bg-gray-200 p-8', data.length === 0 ? 'pb-60 border-dashed border-4 border-gray-300' : '']"
        :sort="true"
        :group="{
          name: `components-${uuid}`,
          put: `components-options-${uuid}`,
          pull: true
        }"
        ghost-class="list-none"
        @start="drag = true"
        @end="drag = false"
        @add="addItem"
        :disabled="disabled"
      >
        <li v-for="(item, idx) in data" :key="`componentsComponent${idx}-${item._component.uuid || item._component.id}`" class="flex items-center mb-6 w-full">

          <div class="component-handle flex-grow-0 w-5 mr-4">
            <switch-vertical-icon class="h-5 w-5 text-gray-900 cursor-move" />
          </div>
          <stack-list class="w-full" v-if="item._component && item._component.elements">
            <stack-list-item :start-open="item._component.elements.length > 0">
              <template v-slot:default>
                <img
                  v-if="item._component.image.files.length"
                  class="component-inner hidden flex-grow-0 flex-shrink-0 w-10 mr-4 md:inline-block"
                  :src="typeof item._component.image.files[0] === 'string' ? item._component.image.files[0] : item._component.image.files[0].url"
                >
                <label class="inline-flex items-center justify-start">
                  <span class="block flex-none">{{ item._component.pivot && item._component.pivot.name ? `${item._component.pivot.name} (${item._component.name})` : item._component.name }}</span>
                  <span class="block whitespace-nowrap overflow-hidden overflow-ellipsis" v-if="item._component.elements.length > 0 && item._component.elements[0].pivot.variable && (typeof data[idx][item._component.elements[0].pivot.variable] !== 'undefined')">
                    &nbsp;-- {{ componentPeek(idx) }}
                  </span>
                </label>

              </template>
              <template v-slot:expand>
                <div class="ml-auto mr-0 -mt-4 w-5">
                  <bldr-component-configs v-if="(typeof item.component_configs !== 'undefined')" class="mt-1 mr-3" v-bind="{componentConfigs, componentConfigTypes}" v-model="item.component_configs" />
                </div>
                <div v-if="item._component.elements.length > 0" class="space-y-4">
                  <div
                    v-for="(element,idxE) in item._component.elements"
                    :key="`pageComponent${idx}Element${idxE}`"
                    :class="`cms-component-element-${ $root.$SLUGIFY(element.name) }`"
                  >
                    <bldr-element
                      v-if="(typeof data[idx][element.pivot.variable] !== 'undefined')"
                      v-model="data[idx][element.pivot.variable]"
                      :element="element"
                      :class="[element.name.toUpperCase() === 'SECTION' && idxE > 0 ? 'pt-8' : '']"
                    />
                  </div>
                </div>
              </template>
            </stack-list-item>
          </stack-list>
          <div class="element-actions flex-grow-0 w-5 ml-4">
            <base-button
              icon
              class="align-middle"
              @click="removeItem(idx)"
            >
              <x-icon class="w-5 h-5 text-red-500" />
            </base-button>
          </div>
        </li>
      </draggable>
    </div>
    <div :class="`${componentsCollapsed ? 'w-5' : 'w-1/3'}  -mt-8`">
      <div class="sticky top-0">
        <div class="flex mb-4 justify-between items-center">
          <base-label v-if="!componentsCollapsed" class="mb-4" tooltip="Drag &amp; drop these components to the area on the left.">
            Components
          </base-label>
          <button type="button" @click="componentsCollapsed = !componentsCollapsed" :title="componentsCollapsed ? 'Show Components' : 'Hide Components'">
            <chevron-left-icon v-if="componentsCollapsed" class="w-5 h-5" />
            <chevron-right-icon v-else class="w-5 h-5" />
          </button>
        </div>
        <base-label v-if="componentsCollapsed" class="rotate-90">Components</base-label>
        <draggable
          v-else-if="Array.isArray(element.pivot.options)"
          :value="element.pivot.options"
          class="w-full rounded-md bg-gray-200 p-4 max-h-[90vh] overflow-auto"
          tag="ul"
          :group="{
            name: `components-options-${uuid}`,
            pull: 'clone',
            put: false
          }"
          :sort="false"
          :clone="clone"
        >
          <li
            v-for="(opt, idxO) in element.pivot.options"
            :key="`element-option${idxO}`"
            class="w-full mb-3"
          >
            <base-card size="sm" class="w-full">
              <label>{{ opt.name }}</label>
            </base-card>
          </li>
        </draggable>
      </div>
    </div>
  </div>
</template>
<script>
import Form from '../Form'
import Stack from '../Stack'
import draggable from "vuedraggable";
import BldrRepeater from './BldrRepeater'
import { SwitchVerticalIcon, XIcon, ChevronLeftIcon, ChevronRightIcon } from '@vue-hero-icons/solid'
import BldrComponentConfigs from './BldrComponentConfigs';
export default {
  name: 'BldrComponents',
  components: {
    ...Form,
    ...Stack,
    draggable,
    SwitchVerticalIcon,
    XIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
    BldrRepeater,
    BldrComponentConfigs,
    BldrElement: () => import("./BldrElement.vue")
  },
  props: {
    element: Object,
    value: {
      type: [Array, String],
      default: ""
    },
    options: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // uuid: this.uuid,
      drag: false,
      ready: true,
      componentsCollapsed: false
    };
  },
  computed: {
    data: {
      get() {
        return this.value;
      },
      set(data) {
        this.$emit("input", data);
      }
    },

    componentConfigs() {
      return this.$page.props.componentConfigs || [];
    },
    componentConfigTypes() {
      return this.$page.props.componentConfigTypes || [];
    },
  },
  mounted() {

  },
  methods: {
    parseElObj(val) {
      if (val.text) {
        return val.text;
      } else if (val.content) {
        return val.content;
      } else if (val.alt) {
        return val.alt;
      } else {
        return val;
      }
    },
    componentPeek(idx) {
      const val = this.data[idx][this.data[idx]?._component?.elements[0]?.pivot?.variable];
      let output = null;
      if (val) {
        if (typeof val === 'string') {
          output = val;
        } else if (Array.isArray(val)) {
          // repeater?
          output = val[0];
          if (typeof output === 'object') {
            output = this.parseElObj(output);
          }
        } else if (typeof val === 'object') {
          output = this.parseElObj(val);
        }
      }
      return typeof output === 'string' ? output.replace(/<\/?[^>]+(>|$)/g, "") : idx;
    },
    clone: function(original) {

      const comp = {component_id: original.id, component_configs: []};
      const copy = {};
      for (var key in original) {
        if (original.hasOwnProperty(key)) {
          if (key === "elements" && Array.isArray(original[key])) {
            copy[key] = JSON.parse(JSON.stringify(original[key]));
            copy[key].map(el => {
              for (var k in el) {
                if (k === "markup" && typeof el[k] === "string") {
                  el[k] = JSON.parse(el[k]);
                }
              }
              return el;
            });
          } else {
            copy[key] = JSON.parse(JSON.stringify(original[key]));
          }
        }
      }

      original.elements.forEach(el => {
        if (el.children.length) {
          if (el.name === "Repeater") {
            comp[el.pivot.variable] = [this.buildElements(el.children)];
          } else {
            comp[el.pivot.variable] = [this.buildElements(el.children)];
          }
        } else {
          comp[el.pivot.variable] = "";
        }
      });

      comp._component = copy;
      comp._component.uuid = Math.random().toString(20).substring(2, 16)
      console.log('clone', comp);
      return comp;
    },
    addItem(evt) {
      // console.log(evt);
      // debugger;
      // let comp = {
      //   component_id: this.components[evt.newIndex].id
      // };
      // if (this.components[evt.newIndex].elements) {
      //   this.components[evt.newIndex].elements.forEach(el => {
      //     if (el.children.length) {
      //       if (el.name === "Repeater") {
      //         comp[el.pivot.variable] = this.buildElements(el.children);
      //       } else {
      //         comp[el.pivot.variable] = this.buildElements(el.children);
      //       }
      //       console.log(this.buildElements(el.children));
      //     } else {
      //       comp[el.pivot.variable] = "";
      //     }
      //   });
      // }
      // this.$nextTick(() => {
      //   this.data.splice(evt.newIndex, 0, comp);
      // })
    },
    removeItem(idx) {
      const data = JSON.parse(JSON.stringify(this.data));
      data.splice(idx, 1)
      this.$emit('input', data);
    },
    buildElements(elements) {
      let elData = {};
      elements.forEach(el => {
        console.log('bE', el);
        if (el.children.length) {
          elData[el.pivot.variable] = [this.buildElements(el.children)];
        } else {
          elData[el.pivot.variable] = "";
        }
      });
      console.log('elData', elData);
      return elData;
    }
  },

}
</script>
