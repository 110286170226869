<template>
  <tr v-if="(item)">

    <!-- Child expand Column -->
    <td class="pl-6 w-11">
      <button title="Toggle Children" v-if="showChildren && Array.isArray(item.children) && item.children.length" @click="childrenToggle(item.id)">
        <chevron-right-icon class="transform h-5 w-5 text-gray-300 hover:text-gray-400" :class="(childrenVisible || searched) ? 'rotate-90' : ''" />
      </button>
    </td>

    <!-- Drag Handle Column -->
    <td v-if="sortable" class="hidden lg:table-cell px-6 w-24">
      <div class="flex space-x-3">
        <button @click="shift('up')" :class="item.sort_order <= 0 ? 'opacity-25 pointer-events-none' : ''">
          <arrow-up-icon class="h-3 w-3 'text-gray-300 hover:text-gray-400'" />
        </button>
        <button @click="shift('down')" :class="item.sort_order >= listingSize - 1 ? 'opacity-25 pointer-events-none' : '' ">
          <arrow-down-icon class="h-3 w-3 'text-gray-300 hover:text-gray-400'" />
        </button>
      </div>
    </td>

    <!-- Bookmark Column (if pinnable) -->
    <td v-if="(typeof item.is_pinned !== 'undefined')" class="hidden lg:table-cell px-6 w-16">
      <base-tooltip class="inline-block">
        <button type="button" class="inline-block" @click.prevent="pin(item.id)">
          <bookmark-icon :class="`inline-block h-5 w-5 ${item.is_pinned ? 'text-red-600' : 'text-gray-300 hover:text-gray-400' }`" />
        </button>
        <template slot="content">
          {{ item.is_pinned ? 'Unbookmark' : 'Bookmark' }}
        </template>
      </base-tooltip>
    </td>

    <!-- ID Column -->
    <table-cell class="hidden lg:table-cell w-24">
      <inertia-link :href="route(`cms.${slug}.show`, { ...routeParams, id: item.id})">
        {{ item.id }}
      </inertia-link>
    </table-cell>

    <!-- Dynamic Columns -->
    <table-cell
      v-for="(col, idxC) in columns"
      :key="`listingRowCol${idxC}`"
      :class="[
        (col.size == 'lg' ? 'w-auto max-w-md ' : (col.size == 'sm' ? 'max-w-xs w-32' : '')),
        (idxC > 0 ? 'hidden lg:table-cell' : ''),
        (col.attribute === 'updated_at' || col.attribute === 'created_at' || col.attribute === 'published_at' ? 'w-52' : '')
      ]"
    >
      <base-switch v-if="(col.attribute === 'is_published')" :value="resolveData(item, col.attribute)" @input="setPublished(item.id)" />
      <template v-else-if="(quickEditing && quickEditBlocked.indexOf(col.attribute) === -1 && typeof item[col.attribute] !== 'undefined')">
        <input
          type="text"
          class="p-1 -m-1 text-sm text-gray-700 border-0 ring-1 ring-gray-500"
          v-model="item[col.attribute]"
          v-on:keydown.enter.prevent="quickUpdate"
          v-on:keydown.esc.prevent="quickEditing = false"
          @input="quickEdited(col.attribute)"
          :ref="`quickEdit-${uuid}`"
        />
      </template>
      <span v-else>
        <inertia-link v-if="idxC === 0" :href="route(`cms.${slug}.show`, { ...routeParams, id: item.id})">
          {{ resolveData(item, col.attribute) }}
        </inertia-link>
        <template v-else>
          {{ resolveData(item, col.attribute) }}
        </template>
        <BanHammer v-if="col.attribute === 'ip_address' && slug !== 'bannedips'" :item="item" />
      </span>
    </table-cell>
    <td v-if="route().has(`cms.${slug}.update`) && (slug === 'mod')" class="pr-6 w-14">
      <div class="flex justify-end items-center">
        <div class="w-8 h-8 bg-white inline-flex items-center justify-center">
          <base-tooltip class="inline-block" :delay="1" :offset-y="10"  v-if="quickEditing" >
            <template slot="content">
              Quick Save
            </template>
            <base-button small icon class="text-gray-400 hover:text-gray-500" @click="quickUpdate"><SaveIcon class="w-5 h-5" /></base-button>
          </base-tooltip>
          <base-tooltip class="inline-block" :delay="1" :offset-y="10" v-else >
            <template slot="content">
              Quick Edit
            </template>
            <base-button small icon class="text-gray-400 hover:text-gray-500" @click="quickEdit"><LightningBoltIcon class="w-5 h-5" /></base-button>
          </base-tooltip>
        </div>
      </div>
    </td>
    <td class="px-6">
      <active-users :url="route(`cms.${slug}.show`, { ...routeParams, id: item.id})" />
    </td>
    <td class="pr-6 w-14">
      <listing-item-actions :id="item.id" :slug="slug" :routeParams="routeParams" />
    </td>
  </tr>
</template>

<script>
import {
  ArrowUpIcon,
  ArrowDownIcon,
  BookmarkIcon,
  ChevronRightIcon,
  LightningBoltIcon,
  SaveIcon,
} from '@vue-hero-icons/solid';
import { BaseSwitch } from '../Form';
import ListingItemActions from './ListingItemActions';
import Table from '../Table';
import BanHammer from '../Misc/BanHammer';
import ActiveUsers from '../Misc/ActiveUsers';
export default {
  name: 'ListingInnerRow',
  components: {
    ...Table,
    ActiveUsers,
    BaseSwitch,
    ListingItemActions,
    ArrowUpIcon,
    ArrowDownIcon,
    BookmarkIcon,
    ChevronRightIcon,
    LightningBoltIcon,
    SaveIcon,
    BanHammer
  },
  props: {
    slug: {
      type: String,
      default: '',
    },
    item: {
      type: [Object, Boolean],
      default: () => false,
    },
    columns: {
      type: Array,
      default: () => [],
    },
    sortable: {
      type: Boolean,
      default: false,
    },
    searched: {
      type: Boolean,
      default: false,
    },
    routeParams: {
      type: Object,
      default: () => {},
    },
    idx: {
      type: Number,
      default: 0,
    },
    listingSize: {
      type: Number,
      default: 0,
    },
    showChildren: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      childrenVisible: false,
      quickEditing: false,
      quickEditedColumns: [],
      quickEditBlocked: ['created_at', 'updated_at', 'published_at'],
    };
  },
  methods: {
    pin(id) {
      this.item.is_pinned = !this.item.is_pinned;
      setTimeout(() => {
        this.$inertia.visit(
          route(`cms.${this.slug}.pin`, { ...this.routeParams, id }),
          {
            preserveState: true,
            preserveScroll: true,
            only: ['items'],
            onBefore: () => document.body.classList.add('is-waiting'),
            onFinish: () =>
              window.dispatchEvent(new CustomEvent('listingReload')),
          }
        );
      }, 500);
    },
    childrenToggle(id) {
      this.childrenVisible = !this.childrenVisible;
      this.$emit('childrenToggle', { id: id, visible: this.childrenVisible });
    },
    resolveData(object, propertyName) {
      var parts = propertyName.split('.'),
        length = parts.length,
        i,
        property = object || this;

      for (i = 0; i < length; i++) {
        if (property && typeof property[parts[i]] !== undefined) {
          property = property[parts[i]];
          // Time formatting
          if (['updated_at', 'created_at', 'deleted_at'].includes(parts[i])) {
            property = this.moment(property)
              .local()
              .format('YYYY-MM-DD HH:mm:ss');
          }
          if (
            typeof property === 'undefined' ||
            (typeof property === 'string' && property.length < 1)
          ) {
            property = '- EMPTY -';
          }
        }
      }

      // Fallback to ID
      if (
        typeof property === 'object' &&
        property &&
        typeof property.id !== 'undefined'
      ) {
        property = property.id;
      } else {
        console.log(property);
      }

      return property;
    },
    setPublished(id) {
      this.item.is_published = !this.item.is_published;
      setTimeout(() => {
        this.$inertia.visit(
          route(`cms.${this.slug}.publish`, { ...this.routeParams, id }),
          {
            preserveState: true,
            preserveScroll: true,
            only: ['items'],
            onBefore: () => document.body.classList.add('is-waiting'),
            onFinish: () =>
              window.dispatchEvent(new CustomEvent('listingReload')),
          }
        );
      }, 500);
    },
    shift(direction) {
      this.$inertia.put(
        route(`cms.${this.slug}.shift`, {
          ...this.routeParams,
          id: this.item.id,
        }),
        {
          direction,
        },
        {
          preserveState: true,
          preserveScroll: true,
          only: ['items'],
          onBefore: () => document.body.classList.add('is-waiting'),
          onFinish: () =>
            window.dispatchEvent(new CustomEvent('listingReload')),
        }
      );
    },
    quickUpdate() {
      const payload = {};
      const key = this.slug === 'pages' ? 'page' : 'item';
      payload[key] = {};
      this.quickEditedColumns.forEach((col) => {
        payload[key][col] = this.item[col];
      });
      this.$root
        .$CMS_UPDATE(
          this.slug,
          { ...this.routeParams, id: this.item.id },
          payload
        )
        .then(() => {
          this.quickEditedColumns = [];
          this.quickEditing = false;
        });
    },
    quickEdit() {
      this.quickEditing = true;
      this.$nextTick().then(() => {
        this.$refs[`quickEdit-${this.uuid}`][0].focus();
      });
    },
    quickEdited(col) {
      if (this.quickEditedColumns.indexOf(col) === -1) {
        this.quickEditedColumns.push(col);
      }
    }
  },
};
</script>
