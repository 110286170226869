<template>
  <div class="relative">
    <button type="button" @click="open = !open" aria-haspopup="listbox" aria-expanded="true" aria-labelledby="listbox-label" class="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
      <span class="block truncate" v-if="data">
        {{ path && path.length ? `${path.join(delimiter)}${delimiter}` : '' }}{{ data.label }}
      </span>
      <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
        <selector-icon class="h-5 w-5 text-gray-400" />
      </span>
    </button>
    <transition
      enter-active-class=""
      enter-class=""
      enter-to-class=""
      leave-active-class="transition ease-in duration-100"
      leave-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <div v-show="open" class="rounded-md bg-white shadow-lg absolute top-full left-0 min-w-full w-max mt-1 ring-1 ring-black ring-opacity-5 z-50 overflow-hidden">
        <el-cascade-panel v-model="data" :options="options" class="w-1/2 relative z-50" />
      </div>
    </transition>

  </div>
</template>
<script>
/**
 * options: [
 *  {
 *    label: String,
 *    value: String,
 *    children: Array
 *  }
 * ]
 */
// TODO: Revisit this component
import ElCascadePanel from './ElCascadePanel'
import {SelectorIcon} from '@vue-hero-icons/solid'
export default {
  name: 'ElCascade',
  props: {
    value: {
      type: [Object, String],
      default: () => {
        return {
          label: '',
          value: '',
          children: []
        }
      }
    },
    options: {
      type: Array,
      default: () => []
    },
    delimiter: {
      type: String,
      default: '/'
    }
  },
  data() {
    return {
      open: false,
      path: []
    }
  },
  computed: {
    data: {
      get() {
        if (typeof this.value === 'string') {
          this.path = this.value.split(this.delimiter);
          const val = this.path.pop();
          return { label: val, value: val, path: []};
        } else {
          if (this.value) {
            this.path = this.value.path;
          }
          return this.value;
        }
      },
      set(val) {
        this.path = val.path;
        this.$emit('input', (typeof this.value === 'string' ? `${ this.path && this.path.length > 1 ? this.path.join(this.delimiter) + this.delimiter : ''}${val.value}` : val))
      }
    }
  },
  components: {
    ElCascadePanel,
    SelectorIcon
  },
  methods: {
    select(val) {
      this.$emit('input', val)
    }
  }
}
</script>
