<template>
  <div class="block">
    <ul tabindex="-1" role="listbox" aria-labelledby="listbox-label" aria-activedescendant="listbox-item-3" class="max-h-60 py-1 text-base ring-1 ring-black ring-opacity-5 overflow-y-auto overflow-x-visible focus:outline-none sm:text-sm bg-white">
      <!--
        Select option, manage highlight styles based on mouseenter/mouseleave and keyboard navigation.

        Highlighted: "text-white bg-indigo-600", Not Highlighted: "text-gray-900"
      -->
      <li
        v-for="(opt,idx) in options"
        id="listbox-option-0"
        role="option"
        :key="`cascade-panel-${uuid}-opt-${idx}`"
        :class="[
          `${ isHighlighted(opt) ? 'text-white bg-indigo-600' : 'text-gray-900' }`,
          'cursor-default select-none'
        ]"
      >
        <!-- Selected: "font-semibold", Not Selected: "font-normal" -->
        <button
          type="button"
          class="flex w-full relative py-2 pl-3 pr-9"
          @mouseover="highlight(opt.value)"
          @mouseout="unhighlight(opt.value)"
          @focus="highlight(opt.value)"
          @focusout="unhighlight(opt.value)"
          @click="handleClick(opt)"
        >
          <span
            :class="[
              `${ isHighlighted(opt) ? 'font-semibold' : 'font-normal' }`,
              'block truncate'
            ]"
          >
            {{ opt.label }}
          </span>

          <!--
            Checkmark, only display for selected option.

            Highlighted: "text-white", Not Highlighted: "text-indigo-600"
          -->
          <span
            :class="[
              `${ isHighlighted(opt) ? 'text-white' : 'text-indigo-600' }`,
              'absolute inset-y-0 right-0 flex items-center pr-4'
            ]"
          >
            <chevron-right-icon v-if="opt.children && opt.children.length" class="h-5 w-5" />
            <check-icon v-else v-show="value && value.value === opt.value" class="h-5 w-5" />
          </span>
        </button>
      </li>
    </ul>
    <el-cascade-panel v-if="opened && opened.children && opened.children.length" v-model="data" :options="opened.children" :parent="opened" class="absolute left-full top-0 w-full" />
  </div>
</template>
<script>
/**
 * options: [
 *  {
 *    label: String,
 *    value: String,
 *    children: Array
 *  }
 * ]
 */
import { ChevronRightIcon, CheckIcon } from '@vue-hero-icons/solid'
export default {
  name: 'ElCascadePanel',
  components: {
    ChevronRightIcon,
    CheckIcon
  },
  props: {
    value: {
      type: [Object, String],
      default: () => {
        return {
          label: '',
          value: '',
          path: []
        }
      }
    },
    options: {
      type: Array,
      default: () => []
    },
    parent: {
      type: [Object, Boolean],
      default: false
    }
  },
  data() {
    return {
      opened: null,
      highlighted: []
    }
  },
  computed: {
    data: {
      get() {
        return this.value.val || this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    highlight(val) {
      this.highlighted.push(val);
    },
    unhighlight(val) {
      const idx = this.highlighted.indexOf(val);
      if (idx >= 0) {
        this.highlighted.splice(idx, 1);
      }
    },
    isHighlighted(opt) {
      return (this.opened && this.opened.value === opt.value) || this.highlighted.indexOf(opt.value) >= 0
    },
    handleClick(opt) {
      if (opt.children && opt.children.length) {
        if (this.opened && this.opened.value === opt.value) {
          this.opened = null
        } else {
          this.opened = opt
        }
      } else {
        this.$emit('input', { ...opt, path: [...this.value.path, this.parent.label]});
      }
    }
  },
  mounted() {
    if (this.value && this.value.path) {
      this.opened = this.options.find((opt) => {
        return this.value.path.includes(opt.label) || this.value.path.includes(opt.value)
      })
    }
  }
}
</script>
