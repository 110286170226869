var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.mainMod && _vm.mainMod.children && _vm.mainMod.children.length > 0)?_c('nav',{staticClass:"relative z-0 rounded-lg shadow flex divide-x divide-gray-200 mb-6",attrs:{"aria-label":"Tabs"}},[_c('inertia-link',{class:[`${_vm.route().current(`cms.mod.show`, { module: _vm.mainMod.id, id: _vm.itemId }) ? 'text-gray-900' : 'text-gray-500 hover:text-gray-700'}`, 'rounded-l-lg capitalize group relative min-w-0 flex-1 flex flex-col justify-center overflow-hidden bg-white py-4 px-4 text-xs font-medium text-center lg:text-sm hover:bg-gray-50 focus:z-10'],attrs:{"href":_vm.route(`cms.mod.show`, { module: _vm.mainMod.id, id: _vm.itemId })}},[_vm._v("\n    "+_vm._s(_vm.mainMod.name)+"\n    "),_c('span',{class:[ `${_vm.route().current(`cms.mod.show`, { module: _vm.mainMod.id, id: _vm.itemId }) ? 'bg-indigo-500' : 'bg-transparent'}`, 'absolute inset-x-0 bottom-0 h-0.5'],attrs:{"aria-hidden":"true"}})]),_vm._v(" "),_vm._l((_vm.mainMod.children),function(child,idx){return _c('inertia-link',{key:`child${idx}`,class:[
      `${(
        _vm.route().current(`cms.mod.child.index`, { module: _vm.mainMod.id, parent: _vm.itemId, child: child.id }) ||
        _vm.route().current(`cms.mod.child.create`, { module: _vm.mainMod.id, parent: _vm.itemId, child: child.id }) ||
        _vm.route().current(`cms.mod.child.show`, { module: _vm.mainMod.id, parent: _vm.itemId, child: child.id })
      ) ? 'text-gray-900' : 'text-gray-500 hover:text-gray-700'}`,
      `${idx === _vm.mainMod.children.length-1 ? 'rounded-r-lg' : ''}`,
      'capitalize group relative min-w-0 flex-1 flex flex-col justify-center overflow-hidden bg-white py-4 px-4 text-xs font-medium text-center lg:text-sm hover:bg-gray-50 focus:z-10'
    ],attrs:{"href":_vm.route(`cms.mod.child.index`, { module: _vm.mainMod.id, parent: _vm.itemId, child: child.id })}},[_vm._v("\n    "+_vm._s(child.name)+"\n    "),_c('span',{class:[
        `${(
          _vm.route().current(`cms.mod.child.index`, { module: _vm.mainMod.id, parent: _vm.itemId, child: child.id }) ||
          _vm.route().current(`cms.mod.child.create`, { module: _vm.mainMod.id, parent: _vm.itemId, child: child.id }) ||
          _vm.route().current(`cms.mod.child.show`, { module: _vm.mainMod.id, parent: _vm.itemId, child: child.id })
        ) ? 'bg-indigo-500' : 'bg-transparent'}`,
        'absolute inset-x-0 bottom-0 h-0.5'
      ],attrs:{"aria-hidden":"true"}})])})],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }