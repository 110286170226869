<template>
  <div class="space-y-3">
    <base-input label="Name" v-model="dropbox.name" />
    <base-input label="API Key" v-model="dropbox.key" />
    <base-input label="API Secret" v-model="dropbox.secret" />
    <base-input label="Directory" v-model="dropbox.directory" />
  </div>
</template>
<script>

import Form from '../../../components/Form'
export default {
  components: {
    ...Form,
  },
  props: {
    dropbox: {
      type: Object,
      default: () => {
        return {
          name: '',
          key: '',
          secret: '',
          directory: '/',
        }
      }
    }
  }
}
</script>
