<template>
  <prism-editor
    :id="id"
    class="cms-editor"
    v-model="data"
    :highlight="highlighter"
    :line-numbers="true"
    :readonly="false"
  ></prism-editor>
</template>
<script>
import { v4 as uuidv4 } from 'uuid';
import { PrismEditor } from 'vue-prism-editor'
import 'vue-prism-editor/dist/prismeditor.min.css'; // import the styles somewhere
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-markup';
import 'prismjs/components/prism-javascript';
import 'prismjs/components/prism-css';
export default {
  name: 'BaseCode',
  components: {
    PrismEditor
  },
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  methods: {
    highlighter(code) {
      return highlight(
        code,
        {
          ...languages['markup'],
          ...languages['js'],
          ...languages['css'],
        },
        'markup'
      );
    },
  },
  data() {
    return {
      id: `code-editor-${uuidv4()}`
    }
  },
  computed: {
    data: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    }
  }
}
</script>
<style>
  .cms-editor {
    align-items: stretch;
    background-color: #fafafa;
    display: flex;
    font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
    font-size: 14px;
    line-height: 1.5;
    min-height: 400px;
    padding: 5px 10px;
  }
</style>
