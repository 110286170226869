<template>
  <el-select v-model="selected">
    <option>Select...</option>
    <option v-for="(form, idx) in forms" :key="`form-${idx}`" :value="form.id" :selected="selected == form.id">{{ form.name }}</option>
  </el-select>
</template>
<script>
import ElSelect from './ElSelect'
export default {
  name: 'FormSelect',
  components: {
    ElSelect
  },
  computed: {
    selected: {
      get() { return this.value },
      set(val) { this.$emit('input', val)}
    }
  },
  props: ['value'],
  data() {
    return {
      forms: []
    }
  },
  mounted() {
    this.$root.$CMS_GET('forms.index').then((data) => {
      this.forms = data.forms
    })
  }
}
</script>
