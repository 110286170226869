<template>
  <div class="repeater bg-gray-200 p-6" v-if="ready">
    <draggable
      v-if="Array.isArray(data) && data.length && basis"
      v-model="data"
      tag="ul"
      :group="`repeater-${uuid}`"
      handle=".element-handle"
      ghost-class="list-none"
      @start="drag=true"
      @end="drag=false"
      :disabled="disabled"
    >
      <transition-group
        type="transition"
        :name="!drag ? 'flip-list' : null"
      >
        <li
          v-for="(item,idxI) in data"
          :key="`repeater-${uuid}_${idxI}`"
          class="flex items-center mb-6"
        >
          <div v-if="!disabled" class="element-handle flex-none w-5 mr-4">
            <switch-vertical-icon class="h-5 w-5 text-gray-900 cursor-move" />
          </div>
          <stack-list-item
            ref="stack-list-item"
            :startOpen="element.children.length < 3"
            class="bg-white relative rounded-md shadow-md overflow-hidden flex-grow-1 w-full"
          >
            <base-label class="mt-2">{{ element.pivot.label || 'Repeater' }} Item {{ item._repeaterItemId }}</base-label>
            <div slot="expand" class="element-inner space-y-3">
              <div
                v-for="(el, idxE) in element.children"
                :key="`repeater-${uuid}_${idxI}_${idxE}`"
              >
                <div v-if="data[idxI] && el.pivot">
                  <bldr-element
                    :ref="el.pivot[varProp]"
                    v-if="false && el.name !== 'Repeater' && (Array.isArray(data[idxI][el.pivot[varProp]]) && data[idxI][el.pivot[varProp]].length === 1)"
                    v-model="data[idxI][el.pivot[varProp]][0]"
                    :element="el"
                    :disabled="disabled"
                  />
                  <bldr-element
                    :ref="el.pivot[varProp]"
                    v-if="(typeof data[idxI][el.pivot[varProp]] !== 'undefined')"
                    v-model="data[idxI][el.pivot[varProp]]"
                    :element="el"
                    :disabled="disabled"
                  />
                </div>
                <div
                  v-for="(elel, idxEE) in el"
                  v-else-if="Array.isArray(el)"
                  :key="`repeater-${uuid}_${idxI}_${idxE}_${idxEE}`"
                >
                  <bldr-element
                    :ref="elel.pivot.variable"
                    v-if="false && elel.name !== 'Repeater' && (Array.isArray(data[idxI][elel.pivot.variable]) && data[idxI][elel.pivot.variable].length === 1)"
                    v-model="data[idxI][elel.pivot.variable][0]"
                    :element="elel"
                    :disabled="disabled"
                  />
                  <bldr-element
                    v-else
                    :ref="elel.pivot.variable"
                    v-model="data[idxI][elel.pivot.variable]"
                    :element="elel"
                    :disabled="disabled"
                  />
                </div>
                <div v-else>
                  {{ JSON.stringify(el) }}
                </div>
              </div>
            </div>
          </stack-list-item>
          <div class="element-actions flex-none w-5 ml-4">
            <base-button
              v-if="!disabled"
              icon
              @click="removeItem(idxI)"
            >
              <x-icon class="w-5 h-5 text-red-500" />
            </base-button>
          </div>
        </li>
      </transition-group>
    </draggable>
    <p
      v-if="!data || (data && data.length <= element.pivot.max)"
      class="mt-3 mb-5"
    >
      <base-button
        v-if="!disabled"
        type="primary"
        @click="addItem()"
      >
        Add Item
      </base-button>
    </p>
    <dropzone v-if="dropzoneVariable && !disabled" v-model="dropzoneFiles" :compact="true" :border="true" :previewless="true" @fileadded="dropzoneInput" />
  </div>
</template>
<script>
import Form from '../Form'
import draggable from 'vuedraggable'
import Stack from '../Stack'
import { SwitchVerticalIcon, XIcon } from '@vue-hero-icons/solid'
export default {
  name: 'BldrReapeater',
  components: {
    ...Form,
    ...Stack,
    SwitchVerticalIcon,
    XIcon,
    draggable
  },
  props: {
    element: Object,
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [Array, String],
      default: () => {
        return [];
      }
    }
  },
  data() {
    return {
      // data: [],
      drag: false,
      // elements: [],
      // uuid: this.uuid,
      basis: null,
      ready: false,
      dropzoneFiles: [],
      dropzoneTimeout: null,
    }
  },
  computed: {
    data: {
        get() {return this.value },
        set(data) {
          this.$emit('input', data)
        }
    },
    dragOptions() {
      return {
        disabled: false,
        ghostClass: "ghost"
      };
    },
    varProp() {
      if (
        Array.isArray(this.element.children) &&
        typeof this.element.children[0] === 'object' &&
        typeof this.element.children[0].pivot === 'object' &&
        typeof this.element.children[0].pivot.column !== 'undefined'
      ) {
        return 'column';
      } else {
        return 'variable';
      }
    },
    dropzoneVariable() {
      if (Array.isArray(this.element.children)) {
        const els = this.element.children.filter((el) => String(el.name).includes('Upload'));
        if (els.length) {
          const el = els[0];
          return el.pivot.column || el.pivot.variable;
        } else {
          return null
        }
      } else {
        return null
      }
    }
  },
  beforeCreate: function () {
    this.$options.components.BldrElement = require('./BldrElement').default
  },
  mounted() {
    this.basis = this.buildElements(this.element.children);

    if (!Array.isArray(this.value)) {
      this.data = [];
    }

    if (this.data && !this.data.length && !this.value.length) {
      this.data = [JSON.parse(JSON.stringify(this.basis))];
    }

    if (this.data && this.data.length) {
      this.data = this.data.map((item, idx) => {
        item._repeaterItemId = idx + 1;
        return item;
      });
    }

    // Validate that data has all the fields in the element
    if (Array.isArray(this.data) && Array.isArray(this.element.children)) {
      let isValid = true;
      let tempData;
      try {
        tempData = JSON.parse(JSON.stringify(this.data));
      } catch (err) {
        console.error(['Repeater Error 1', err]);
      }
      this.element.children.forEach((child) => {
        tempData.forEach((item, idx) => {
          if (typeof item[child.pivot.variable] === 'undefined' && typeof this.basis[child.pivot.variable] === 'object') {
            try {
              tempData[idx][child.pivot.variable] = JSON.parse(JSON.stringify(this.basis[child.pivot.variable]));
            } catch (err) {
              console.error(['Repeater Error 2', idx, err]);
            }
            isValid = false;
            console.log(['validate set value', child.pivot.variable]);
          } else if (typeof item[child.pivot.variable] === 'undefined') {
            tempData[idx][child.pivot.variable] = this.basis[child.pivot.variable];
            isValid = false;
            console.log(['validate repeater', tempData[child.pivot.variable]]);
          }
        });
      });
      if (!isValid) {
        this.$emit('input', tempData)
      }
    } else {
      console.log('not validating');
    }

    this.$nextTick(() => {
      if (!Array.isArray(this.value)) {
        this.$emit('input', []);
      }
      this.ready = true;
      console.log('repeater ready?');
    })
  },
  methods: {
    addItem(cb) {
      const newRow = JSON.parse(JSON.stringify(this.basis));
      newRow._repeaterItemId = this.data.length + 1;
      this.data.push(newRow);

      this.$emit('input', this.data);
      const _this = this;
      setTimeout(function(){

        _this.$refs['stack-list-item'][_this.$refs['stack-list-item'].length-1].toggleExpand();
        const pageLoaded = new Event('cms_page_loaded');
        document.body.dispatchEvent(pageLoaded);

        if (typeof cb === 'function') {
          cb();
        }
      }, 500);
    },
    removeItem(index) {
      this.data.splice(index,1);
    },
    buildElements(elements) {
      let elData = {};
      const varprop = this.varProp;
      elements.forEach(el => {
        console.log(el);
        try {
          if (Array.isArray(el)) {
            console.log(['buildElement 1', el]);
            elData = this.buildElements(el);
          } else if (typeof el === 'object') {
            if (!el.pivot && Array.isArray(el.properties)) {
              // Make pivot using properties
              el.pivot = {};
              el.properties.forEach(p => {
                el.pivot[p.property] = p.value;
              });
            }
            console.log(['buildElement 2', el]);
            if (el.children && el.children.length) {
              elData[el.pivot[varprop]] = this.buildElements(el.children);
            } else {
              if (typeof el.pivot[varprop] !== 'undefined') {
                if (el.markup.length > 1 || el.name === 'File Upload' || el.name === 'Link' || el.name === 'Repeater' || el.name === 'Components') {
                  elData[el.pivot[varprop]] = [];
                } else {
                  elData[el.pivot[varprop]] = '';
                }
              } else {
                console.warn([varprop, el, el.pivot[varprop]]);
              }
            }
            console.log(['elData', JSON.stringify(elData)]);
          } else {
            console.warn(['wtf', el]);
          }
        } catch (err) {
          console.log([err, el]);
          debugger;
        }
      });
      return elData;
    },
    dropzoneInput() {
      const _this = this;
      clearTimeout(this.dropzoneTimeout);
      this.dropzoneTimeout = setTimeout(function() {
        _this.dropzoneProcess();
      }, 1000);
    },
    dropzoneProcess() {
      const _this = this;
      if (_this.dropzoneFiles.length) {
        const file = _this.dropzoneFiles.splice(0,1)[0].file;
        _this.addItem(function() {
          const last = _this.$refs[_this.dropzoneVariable].length-1;
          const fileupload = _this.$refs[_this.dropzoneVariable][last].$refs['field'][0].$refs['fileupload'];
          fileupload.updateFiles(file, [], _this.dropzoneProcess)
        })
      }
    }

  }
}
</script>
