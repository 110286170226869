<template>
  <button type="button" @click="$emit('tabclick', content)" :class="[`${isSelected ? 'text-gray-900' : 'text-gray-500 hover:text-gray-700'}`, 'capitalize group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10']">
    {{ content }}
    <span aria-hidden="true" :class="[ `${isSelected ? 'bg-indigo-500' : 'bg-transparent'}`, 'absolute inset-x-0 bottom-0 h-0.5']"></span>
  </button>
</template>
<script>
export default {
  name: 'TabsButton',
  props: {
    selected: {
      type: String,
      default: 'notselected'
    },
    content: {
      type: String,
      default: 'content'
    }
  },
  computed: {
    isSelected() {
      return this.selected === this.content;
    }
  }
}
</script>
