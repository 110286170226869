<template>
  <dam-layout :content-container="false">
    <div class="relative flex gap-8 flex-col lg:flex-row justify-start items-start">
      <nav class="w-full bg-gray-100  sticky top-0 flex-1 lg:flex-none  lg:w-64 px-4 py-3" aria-label="Sidebar">
        <ul role="list" class="-mx-2 flex gap-1 lg:flex-col">
          <li v-for="section in sections" :key="`nav-${section}`">
            <!-- Current: "bg-gray-50 text-indigo-600", Default: "text-gray-700 hover:text-indigo-600 hover:bg-gray-50" -->
            <button type="button" @click="goToSection(section)" :class="[
              currentSection == section ? 'bg-gray-200 text-indigo-600' : 'text-gray-700 hover:text-indigo-600 hover:bg-gray-200',
              'group flex w-full gap-x-3 rounded-md p-2 pl-3 text-sm leading-6 font-semibold capitalize'
            ]">{{ section }}</button>
          </li>
        </ul>
      </nav>
      <div class="w-full flex-1 flex flex-col gap-8">
        <base-card id="general" class="space-y-8">
          <base-heading>General</base-heading>
          <stack-list v-if="general.length">
            <stack-list-item v-for="(setting,idx) in general" :key="`settings-general-${idx}`">
              <template v-slot:default>
                {{ setting.name || 'Setting' }}
              </template>
              <template v-slot:expand>
                <settings-general :general="setting.data" />
                <base-button @click="saveSetting('general', setting.data, setting.id)" class="mt-5">Save</base-button>
              </template>
            </stack-list-item>
          </stack-list>
          <p v-else class="text-sm text-gray-700 font-bold">
            No General settings added
          </p>
          <div v-if="newGeneral" class="p-5 border border-gray-200 rounded space-y-5">
            <base-heading size="base">New General Setting</base-heading>
            <settings-general :general="newGeneral" />
            <div class="space-x-4">
              <base-button @click="saveSetting('general', newGeneral)">Save</base-button>
              <base-button @click="newGeneral = null" type="secondary">Cancel</base-button>
            </div>
          </div>
          <base-button v-else @click="addGeneral">New General Setting</base-button>
        </base-card>
        <base-card id="file" class="space-y-8">
          <base-heading>Files</base-heading>
          <stack-list v-if="fileConfigs.length">
            <stack-list-item v-for="(setting,idx) in fileConfigs" :key="`settings-fileConfigs-${idx}`">
              <template v-slot:default>
                {{ setting.name || 'File Config' }}
              </template>
              <template v-slot:expand>
                <settings-file-config :file="setting.data" />
                <base-button @click="saveSetting('file', setting.data, setting.id)" class="mt-5">Save</base-button>
              </template>
            </stack-list-item>
          </stack-list>
          <p v-else class="text-sm text-gray-700 font-bold">
            No File Config added
          </p>
          <div v-if="newFileConfig" class="p-5 border border-gray-200 rounded space-y-5">
            <base-heading size="base">New File Config</base-heading>
            <settings-file-config :file="newFileConfig" />
            <div class="space-x-4">
              <base-button @click="saveSetting('file', newFileConfig)">Save</base-button>
              <base-button @click="newFileConfig = null" type="secondary">Cancel</base-button>
            </div>
          </div>
          <base-button v-else @click="addFileConfig">New File Config</base-button>
        </base-card>
        <base-card id="server" class="space-y-8">
          <base-heading>Servers</base-heading>
          <stack-list v-if="servers.length">
            <stack-list-item v-for="(setting,idx) in servers" :key="`settings-servers-${idx}`">
              <template v-slot:default>
                {{ setting.name || 'Server' }}
              </template>
              <template v-slot:expand>
                <settings-server :server="setting.data" />
                <base-button @click="saveSetting('server', setting.data, setting.id)" class="mt-5">Save</base-button>
              </template>
            </stack-list-item>
          </stack-list>
          <p v-else class="text-sm text-gray-700 font-bold">
            No servers added
          </p>
          <div v-if="newServer" class="p-5 border border-gray-200 rounded space-y-5">
            <base-heading size="base">New Server</base-heading>
            <settings-server :server="newServer" />
            <div class="space-x-4">
              <base-button @click="saveSetting('server', newServer)">Save</base-button>
              <base-button @click="newServer = null" type="secondary">Cancel</base-button>
            </div>
          </div>
          <base-button v-else @click="addServer">New Server</base-button>
        </base-card>
        <base-card v-show="false" id="dropbox" class="space-y-8">
          <base-heading>Dropbox</base-heading>
          <stack-list v-if="dropboxes.length">
            <stack-list-item v-for="(setting,idx) in dropboxes" :key="`settings-dropboxes-${idx}`">
              <template v-slot:default>
                {{ setting.name || 'Dropbox' }}
              </template>
              <template v-slot:expand>
                <settings-dropbox :server="setting.data" />
                <base-button @click="saveSetting('dropbox', setting.data, setting.id)" class="mt-5">Save</base-button>
              </template>
            </stack-list-item>
          </stack-list>
          <p v-else class="text-sm text-gray-700 font-bold">
            No Dropbox added
          </p>
          <div v-if="newDropbox" class="p-5 border border-gray-200 rounded space-y-5">
            <base-heading size="base">New Dropbox</base-heading>
            <settings-dropbox :dropbox="newDropbox" />
            <div class="space-x-4">
              <base-button @click="saveSetting('dropbox', newDropbox)">Save</base-button>
              <base-button @click="newDropbox = null" type="secondary">Cancel</base-button>
            </div>
          </div>
          <base-button v-else @click="addDropbox">New Dropbox</base-button>
        </base-card>
      </div>
    </div>
  </dam-layout>
</template>
<script>
import BaseHeading from '../../components/Base/BaseHeading.vue';
import Form from '../../components/Form'
import Stack from '../../components/Stack';
import DamLayout from '../../layouts/DamLayout';
import SettingsDropbox from './components/SettingsDropbox.vue';
import SettingsServer from './components/SettingsServer.vue';
import SettingsFileConfig from './components/SettingsFileConfig.vue';
import SettingsGeneral from './components/SettingsGeneral.vue';
export default {
  components: {
    DamLayout,
    BaseHeading,
    SettingsServer,
    SettingsDropbox,
    SettingsFileConfig,
    SettingsGeneral,
    ...Stack,
    ...Form,
  },
  props: {
    settings: {
      type: Array,
      default: () => []
    },
  },
  computed: {
    servers() {
      return this.settings.filter(s => s.type == 'server');
    },
    dropboxes() {
      return this.settings.filter(s => s.type == 'dropbox');
    },
    fileConfigs() {
      return this.settings.filter(s => s.type == 'file');
    },
    general() {
      return this.settings.filter(s => s.type == 'general');
    }
  },
  methods: {
    goToSection(section) {
      this.currentSection = section
      document.getElementById(section).scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })
    },
    addServer() {
      this.newServer = {
        name: 'New Server',
        protocol: 'FTP',
        host: '',
        port: 21,
        username: '',
        password: '',
        directory: '/',
      }
    },
    addDropbox() {
      this.newDropbox = {
        name: 'New Dropbox',
        key: '',
        secret: '',
        directory: '/',
      };
    },
    addFileConfig() {
      this.newFileConfig = {
        name: 'File Config',
        type: 'image', // image/video/audio
        config: ''
      }
    },
    addGeneral() {
      this.newGeneral = {
        name: 'General Setting',
        key: '',
        value: ''
      }
    },
    resetNew(type) {
      switch (type) {
        case 'server':
          this.newServer = null;
          break;
        case 'file':
          this.newFileConfig = null;
          break;
        case 'dropbox':
          this.newDropbox = null;
          break;
        case 'general':
          this.newGeneral = null;
          break;
        default:
      }
    },
    saveSetting(type, setting, id) {
      if (id) {
        this.$CMS_UPDATE('dam.settings', {id: id}, {
          name: setting.name,
          type,
          data: setting
        }).then(() => {
          this.$inertia.reload();
        });
      } else {
        this.$CMS_CREATE('dam.settings', {}, {
          name: setting.name,
          type,
          data: setting
        }).then(() => {
          this.resetNew(type);
          this.$inertia.reload();
        })
      }
    },
  },
  data() {
    return {
      sections: [
        'general',
        'file',
        'server',
        // 'dropbox',
      ],
      currentSection: 'general',
      newGeneral: null,
      newServer: null,
      newDropbox: null,
      newFileConfig: null,
    }
  },
}
</script>
