<template>
  <!-- Global notification live region, render this permanently at the end of the document -->
  <div aria-live="assertive" class="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6 z-[999]">
    <div class="flex w-full flex-col items-center space-y-4 sm:items-end">
      <!-- Notification panel, dynamically insert this into the live region when it needs to be displayed -->
      <transition enter-active-class="transform ease-out duration-300 transition" enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2" enter-to-class="translate-y-0 opacity-100 sm:translate-x-0" leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
        <div v-if="batches.length" class="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
          <div class="px-4 divide-y divide-gray-300">
            <div v-for="batch in batches" :key="batch.id" class="flex items-start py-4">
              <div class="ml-3 w-0 flex-1 pt-0.5">
                <p class="text-sm font-medium text-gray-900">{{ batch.asset }}</p>
                <p :class="['mt-1 text-sm text-white w-full h-6 relative z-20',  batch.finished && batch.failed ? 'bg-red-600' : 'bg-gray-300']">
                  <span :class="[
                      (batch.finished && batch.failed ? 'from-red-500 to-red-600' : (batch.finished ? 'from-green-500 to-green-600' : ' from-indigo-500 to-indigo-600')),
                      'w-0 h-full absolute top-0 left-0 z-10 bg-gradient-to-b'
                    ]"
                    :style="`width: ${batch.progress}%`"
                  ></span>
                  <span class="absolute inset-0 px-2 tracking-wide font-medium drop-shadow-sm z-20 leading-6" v-text="(batch.finished && batch.failed ? 'FAILED' : (batch.finished ? 'FINISHED' : `${batch.progress}%`))"></span>
                </p>
              </div>
              <div class="ml-4 flex flex-shrink-0">
                <button type="button" @click="deleteBatch(batch.id)" class="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                  <span class="sr-only">Close</span>
                  <XIcon class="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
            </div>
            <div v-if="(batches.length > 1)" class="py-4">
              <button type="button" @click="clearAll" class="block w-full text-center text-blue-500 font-bold">Clear All</button>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
import {XIcon} from '@vue-hero-icons/solid'
export default {
  components: {
    XIcon,
  },
  data() {
    return {
      batches: []
    }
  },
  methods: {
    deleteBatch(id) {
      const btchs = this.batches.filter(btch => {
        return btch.id !== id;
      });
      this.batches = [...btchs];
      this.$nextTick(() => {
        window.sessionStorage.setItem('dam-batches', JSON.stringify(btchs));
      });
    },
    addBatches(event) {
      const addedBatches = event.detail.batches;
      if (Array.isArray(addedBatches) && addedBatches.length) {
        addedBatches.forEach(batch => {
          this.batches.push({
            asset: batch.asset,
            id: batch.batch,
            progress: 0,
            finished: false,
            failed: 0
          });
        })
        this.$nextTick(() => {
          window.sessionStorage.setItem('dam-batches', JSON.stringify(this.batches));
        });
      }
    },
    checkBatches() {
      if (this.batches.length) {
        this.batches.forEach((batch, idx) => {
          if (batch.id && !batch.finished && batch.failed < 1) {
            fetch(route('cms.dam.batch', {id: batch.id}))
            .then(resp => resp.json())
            .then(data => {
              if (data.batch) {
                // update progress and if it is finished
                this.batches[idx].progress = data.batch.progress;
                this.batches[idx].finished = !!data.batch.finishedAt;
                this.batches[idx].failed = data.batch.failedJobs;
                if (this.batches.filter(i => i.finished).length == this.batches.length) {
                  this.$inertia.reload();
                }
                // if progress < 100 and finished is true
              } else {
                // delete from batches
                this.batches.splice(idx, 1);
              }
            })
          }
        })
      }
    },
    clearAll() {
      this.batches = []
      this.$nextTick(() => {
        window.sessionStorage.setItem('dam-batches', JSON.stringify([]));
      });
    },
  },
  mounted() {
    const cachedBatches = window.sessionStorage.getItem('dam-batches');
    if (cachedBatches && cachedBatches.length) {
      this.batches = JSON.parse(cachedBatches);
    }
    setInterval(this.checkBatches, 2000);
  },
  created: function() {
    window.addEventListener('dam-batches', this.addBatches);
  },
  destroyed: function() {
    window.removeEventListener('dam-batches', this.addBatches);
  },
}
</script>
