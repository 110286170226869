<template>
  <dam-layout>
    <asset-listing v-bind="{items, categories, collections, meta_groups, meta_values, types}" />
  </dam-layout>
</template>
<script>
import DamLayout from '../../layouts/DamLayout';
import AssetListing from './components/AssetListing';
export default {
  name: 'AssetsIndex',
  components: {
    DamLayout,
    AssetListing,
  },
  props: {
    slug: {
      type: String,
      default: '',
    },
    items: {
      type: Object,
      default: () => {
        return {
          current_page: 1,
          data: [],
          from: 1,
          to: 1,
          last_page: 1,
          total: 1
        }
      }
    },
    categories: {
      type: Array,
      default: () => {
        return [];
      },
    },
    collections: {
      type: Array,
      default: () => {
        return [];
      },
    },
    meta_groups: {
      type: Array,
      default: () => {
        return []
      }
    },
    meta_values: {
      type: Array,
      default: () => {
        return []
      }
    },
    columns: {
      type: Array,
      default: () => {
        return [];
      },
    },
    types: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
};
</script>
