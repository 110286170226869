<template>

    <image-card :class="[
        'relative transition-card hover:z-20 focus:z-30',
        display === 'grid' ? 'w-60 2xl:w-64 hover:shadow-2xl hover:scale-105' : 'w-full pl-12',
        isFocused ? 'z-30' : 'z-10'
      ]"
      tabindex="0"
      :description="item.name"
      :extra-description="`Last Modified ${lastModified}`"
      :wrap-description="false"
      :display="display"
    >
      <template v-slot:image>
        <component :is="linkToAsset ? 'inertia-link' : 'div'"  :href="linkToAsset ? route('cms.dam.assets.show', item.id) : null" class="w-full h-full block absolute top-0 left-0">
          <img v-if="item.thumbnail" :src="item.thumbnail" :alt="item.name" class="w-full h-full object-cover block absolute top-0 left-0">
          <svg v-else class="absolute inset-0 m-auto w-1/2 h-1/2 opacity-60"><use :href="`#filetype-${fileIcon}`" /></svg>
        </component>
      </template>
      <div :class="['flex items-stretch  p-1', display === 'grid' ? 'justify-between' : 'gap-4 flex-row-reverse']">
        <slot name="actions" :isFocused="isFocused">
          <input type="checkbox"
            v-model="selectedAssets"
            :value="item.id"
            :class="['rounded border border-gray-300 w-6 h-6', display === 'grid' ? 'self-center' : 'absolute left-5 top-1/2 -translate-y-1/2']"
          />
          <popover-download :item="item" v-on:popoveropen="isFocused = true" v-on:popoverclose="isFocused = false" />
          <popover-share :item="item" v-on:popoveropen="isFocused = true" v-on:popoverclose="isFocused = false" />
        </slot>
      </div>
    </image-card>
</template>
<script>
import mime from 'mime'
import ImageCard from './ImageCard'
import PopoverDownload from './PopoverDownload'
import PopoverShare from './PopoverShare'
import moment from 'moment'
export default {
  components: {
    ImageCard,
    PopoverDownload,
    PopoverShare
  },
  props: {
    item: {
      type: Object,
      default: () => {
        return {
          id: 0,
          name: ''
        }
      }
    },
    value: '',
    linkToAsset: true,
    display: {
      type: String,
      default: 'grid',
      validator(value) {
        return ['grid', 'row'].includes(value);
      }
    },
  },
  computed: {
    selectedAssets: {
      get() { return this.value },
      set(val) { this.$emit('input', val)}
    },
    fileIcon() {
      const ext = mime.getExtension(this.item.type);
      if (ext) {
        return ext;
      } else {
        return 'default';
      }
    },
    lastModified() {
      return moment(this.item.updated_at).format('LLL');
    }
  },
  data() {
    return {
      isFocused: false
    }
  }
}
</script>
