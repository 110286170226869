<template>
  <div class="space-y-2">
    <div class="flex justify-between">
      <div class="space-y-2">
        <p class="truncate text-sm font-medium text-gray-900 flex-none">From: {{ note.sender.full_name }}</p>
        <p class="truncate text-sm font-medium text-gray-800 flex-none">To: {{ note.users.map(u => `${u.full_name} <${u.email}>`).join(', ') }}</p>
        <p class="truncate text-sm text-gray-700 flex-none"><a v-if="note.noteable_type && noteableRouteName" :href="route(noteableRouteName, noteableRouteParams)" class="underline">[<span v-text="noteableName"></span>: {{ note.noteable_id }}]</a> {{ note.subject }}</p>
      </div>
      <time :datetime="note.created_at" class="flex-shrink-0 whitespace-nowrap text-sm text-gray-600">{{ ago(note.updated_at) }}</time>
    </div>
    <div class="w-full h-full overflow-auto text-sm text-gray-600 flex-grow py-3" v-html="note.message"></div>
    <div class="rounded-lg p-6 relative bg-gray-500/5" v-if="replying">
      <notes-form v-model="replying" :parent-id="replyId" heading="Reply" :pre-subject="`${note.message.indexOf('RE:') === 0 ? '' : 'RE: '}${note.subject}`" :pre-to="note.users.map(u => {return {label: u.full_name, value: u.id}})" />
      <base-button class="absolute left-28 bottom-6" small @click="replying = false,replyId = 0">Cancel</base-button>
    </div>
    <base-button v-else small @click="reply(note.id)">Reply</base-button>
    <div v-if="note.replies && note.replies.length" class="p-4 border-l border-gray-500">
      <div class="rounded-lg px-4 py-5 sm:p-6 bg-gray-500/5 space-y-4">
        <notes-message v-for="reply in note.replies" :key="`reply-${reply.id}`" :note="reply" />
      </div>
    </div>

  </div>
</template>
<script>
import NotesForm from './NotesForm.vue'
export default {
  name: 'NotesMessage',
  components: {
    NotesForm,
  },
  props: {
    note: {
      type: Object,
      default: () => {
        return {
          subject: '',
          message: '',
          sender: {
            full_name: ''
          },
          replies: []
        }
      }
    }
  },
  methods: {
    ago (date) {
      return this.moment(date).fromNow()
    },
    reply(id) {
      this.replyId = id;
      this.replying = true;
    }
  },
  computed: {
    noteableName() {
      return this.note.noteable_type.split('\\').slice(-1)[0]
    }
  },
  mounted() {
    if (this.note.noteable_type.indexOf('Cms\\Page') > -1) {
      this.noteableRouteName = 'cms.pages.show';
      this.noteableRouteParams.id = this.note.noteable_id;
    } else {
      let mods = window.sessionStorage.getItem(`cms_modules`);
      if (mods) {
        mods = JSON.parse(mods);
        mods.forEach(mod => {
          if (mod.model.indexOf(this.note.noteable_type) > -1) {
            this.noteableRouteName = 'cms.mod.show';
            this.noteableRouteParams.module = mod.id;
            this.noteableRouteParams.id = this.note.noteable_id;
          }
        });
      } else {
        this.$CMS_GET('modules.index').then(data => {
          window.sessionStorage.setItem(`cms_modules`, JSON.stringify(data.items));
          data.items.forEach(mod => {
            if (mod.model.indexOf(this.note.noteable_type) > -1) {
              this.noteableRouteName = 'cms.mod.show';
              this.noteableRouteParams.module = mod.id;
              this.noteableRouteParams.id = this.note.noteable_id;
            }
          });
        })
      }
    }
  },
  data() {
    return {
      replying: false,
      replyId: 0,
      noteableRouteName: '',
      noteableRouteParams: {}
    }
  },
}
</script>
