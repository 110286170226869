<template>
  <app-layout>

    <div class="block lg:flex">

      <!-- CONTENT -->
      <div class="w-full">

        <base-card class="space-y-4 mb-10">

          <base-input
            v-model="upload.name"
            type="text"
            label="Name"
            placeholder=""
            required
          />

          <base-input label="Configs">
            <div class="my-4 bg-gray-50 overflow-hidden rounded-lg">
              <div class="px-4 py-5 sm:p-6">
                <div v-if="upload.config.length" class="grid grid-flow-row gap-8 grid-cols-1 md:grid-cols-2">
                  <div
                    v-for="(config,index) in upload.config"
                    :key="`config${index}`"
                    class="row col-lg-6"
                  >
                    <base-card class="relative">
                      <button
                        class="absolute right-3 top-3"
                        @click="removeConfig(index)"
                      >
                        <x-icon class="text-red-600 w-6 h-6" />
                      </button>
                      <div class="space-y-3">
                        <base-input
                          label="Name *"
                          v-model="upload.config[index].name"
                          required
                          @change="autoIntName(index)"
                        />
                        <base-input
                          label="Integration Name *"
                          v-model="upload.config[index].int_name"
                          required
                        />
                        <div class="xl:grid xl:grid-cols-2 gap-6 space-y-3">
                          <base-input label="Mode">
                            <radio-group
                              v-model="upload.config[index].mode"
                              :options="[
                                {label: 'File', value: 'file'},
                                {label: 'Image: As-Is', value: 'image'},
                                {label: 'Image: Resize', value: 'image-resize'},
                                {label: 'Image: Crop', value: 'image-crop'}
                              ]"
                            />
                          </base-input>
                          <div class="space-y-3">
                            <div class="flex items-end gap-2" v-if="upload.config[index].mode !== 'file'">
                              <base-input
                                label="Screen Min-Width"
                                v-model="upload.config[index].min_width"
                              />
                              <p>px</p>
                            </div>
                            <template v-if="upload.config[index].mode !== 'file'">
                              <base-label>Dimensions</base-label>
                              <div class="space-y-3 md:flex md:space-x-2 md:space-y-0">
                                <base-input
                                  v-model="upload.config[index].dimensions.width"
                                  placeholder="Width"
                                  :required="config.mode === 'image-crop'"
                                  :disabled="config.mode !== 'image-crop' && config.mode !== 'image-resize'"

                                />
                                <base-input
                                  v-model="upload.config[index].dimensions.height"
                                  placeholder="Height"
                                  :required="config.mode === 'image-crop'"
                                  :disabled="config.mode !== 'image-crop' && config.mode !== 'image-resize'"
                                />
                              </div>
                            </template>
                            <base-input
                              label="Suffix"
                              v-model="upload.config[index].suffix"
                              type="text"
                            />
                            <div class="flex gap-10">
                              <base-input label="Retina" v-if="upload.config[index].mode !== 'file'">
                                <base-switch
                                  v-model="upload.config[index].retina"
                                  class="mb-0"
                                  type="primary"
                                  :disabled="config.mode.indexOf('image') < 0"
                                />
                              </base-input>
                              <base-input label="WEBP" v-if="upload.config[index].mode !== 'file'">
                                <base-switch
                                  v-model="upload.config[index].webp"
                                  class="mb-0"
                                  type="primary"
                                  :disabled="config.mode.indexOf('image') < 0"
                                />
                              </base-input>
                            </div>
                          </div>
                        </div>
                        <base-label>Location</base-label>
                        <div class="space-y-3 md:flex md:space-x-2 md:space-y-0">
                          <el-select v-model="upload.config[index].location" class="md:w-1/3">
                            <option disabled value="" :selected="!upload.config[index].location">Select...</option>
                            <option v-for="(disk, idx) in disks"  :key="`disk-${idx}`" :value="disk" :selected="upload.config[index].location === disk">{{disk}}</option>
                          </el-select>
                          <base-input
                            class="md:w-2/3"
                            v-model="upload.config[index].directory"
                            placeholder="Directory"
                          />
                        </div>
                      </div>
                    </base-card>
                  </div>
                </div>
                <p v-else class="text-lg text-center text-gray-400 font-bold uppercase">No configs added.</p>
              </div>
            </div>
            <base-button
              type="primary"
              cl
              @click="addConfig()"
            >
              Add
            </base-button>
          </base-input>
        </base-card>
      </div>
    </div>

    <div class="mt-6">
      <base-button class="mr-4" @click="updateUpload()">Save</base-button>
      <base-button v-if="route().has('cms.uploads.export')" tag="a" :href="route(`cms.uploads.export`, {id: mod.id})" target="_blank">Export</base-button>
    </div>

  </app-layout>
</template>
<script>
import Form from '../components/Form'
import Stack from '../components/Stack'
import Tabs from '../components/Tabs'
import draggable from 'vuedraggable'
import { EditPopover, BldrComponentElement } from '../components/Builder'
import { InformationCircleIcon, SwitchVerticalIcon, XIcon } from '@vue-hero-icons/solid'
export default {
  components: {
    ...Stack,
    ...Form,
    ...Tabs,
    draggable,
    BldrComponentElement,
    EditPopover,
    InformationCircleIcon,
    SwitchVerticalIcon,
    XIcon
  },
  props: {
    upload: {
      type: Object,
      default: () => {
        return {
          name: '',
          config: []
        }
      }
    },
    disks: {
      type: [Array],
      default: () => []
    },
  },
  methods: {
    addConfig() {
      this.upload.config.push({
        name: '',
        int_name: '',
        min_width: 0,
        mode: 'image',
        dimensions: {width: '', height: ''},
        suffix: '',
        retina: false,
        webp: false,
        location: '',
        directory: ''
      });
    },
    removeConfig(idx) {
      this.upload.config.splice(idx, 1);
    },
    updateUpload() {
      // Sort configs by min-width largest to smallest
      this.upload.config.sort((a, b) => {
        return parseInt(b.min_width || 0) - parseInt(a.min_width || 0);
      })
      if(this.upload.id) {
        this.$root.$CMS_UPDATE('uploads', this.upload.id, this.upload);
      } else {
        this.$root.$CMS_CREATE('uploads', {}, this.upload).then((data)=>{
          if(data.upload.id) {
            window.location.href = route('cms.uploads.show', data.upload.id)
          }
        });
      }
    },
    autoIntName(index) {
      const conf = this.upload.config[index];
      if (!conf.int_name) {
        this.upload.config[index].int_name = this.$root.$SLUGIFY(conf.name, '_');
      }
    }
  },
  mounted() {
    if (Array.isArray(this.upload.config) && this.upload.config.length) {
      this.upload.config = this.upload.config.map((conf, idx) => {
        if (conf.name && !conf.int_name) {
          conf.int_name = this.$root.$SLUGIFY(conf.name, '_');
        }
        if (typeof conf.min_width === 'undefined') {
          conf.min_width = 0;
        }
        return conf;
      })
    }
  }
}
</script>
