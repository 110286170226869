<template>
  <nav aria-label="Progress">
    <ol role="list" :class="['flex gap-8', smallDirection == 'vertical' ? 'flex-col' : 'flex-row', largeDirection == 'vertical' ? 'lg:flex-col' : 'lg:flex-row']">
      <li v-for="(step, stepIdx) in steps" :key="`step-${stepIdx}`" class="relative">
        <div v-if="stepIdx < currentStep" class="group flex w-full items-center">
          <span class="flex items-center px-6 text-sm font-medium">
            <span class="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-indigo-600">
              <CheckIcon class="h-6 w-6 text-white" aria-hidden="true" />
            </span>
            <span class="ml-4 text-sm font-medium text-gray-900">{{ step }}</span>
          </span>
        </div>
        <div v-else-if="stepIdx === currentStep" class="flex items-center px-6 text-sm font-medium" aria-current="step">
          <span class="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-indigo-600">
            <span class="text-indigo-600">{{ stepIdx+1 }}</span>
          </span>
          <span class="ml-4 text-sm font-medium text-indigo-600">{{ step }}</span>
        </div>
        <div v-else :href="step.href" class="group flex items-center">
          <span class="flex items-center px-6 text-sm font-medium">
            <span class="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-gray-300 ">
              <span class="text-gray-500 ">{{ stepIdx+1 }}</span>
            </span>
            <span class="ml-4 text-sm font-medium text-gray-500 ">{{ step }}</span>
          </span>
        </div>
      </li>
    </ol>
  </nav>
</template>

<script>
import { CheckIcon } from '@vue-hero-icons/solid'

export default {
  name: 'StepsIndicator',
  components: {
    CheckIcon
  },
  props: {
    smallDirection: {
      type: String,
      default: 'horizontal'
    },
    largeDirection: {
      type: String,
      default: 'vertical'
    },
    currentStep: {
      type: Number,
      default: 0
    },
    steps: {
      type: Array,
      default: () => []
    }
  }
}

</script>
