<template>
  <div class="relative w-full min-h-[600px] flex items-stretch gap-8">
    <!-- SIDEBAR -->
    <div id="cms-dam-sidebar" class="relative w-52 flex-none bg-gray-50 px-8 py-7 select-none">
      <div class="absolute top-0 -right-1 w-1 h-full bg-gradient-to-r from-black to-transparent opacity-10 cursor-ew-resize" @mousedown="startResizeSidebar" @mouseup="stopResizeSidebar"></div>
      <!-- <inertia-link v-if="selectedCategory || selectedCollection || selectedMeta.length || selectedTypes.length" :href="route('cms.dam.assets.index')" class="mb-8 pb-1 pr-1 inline-flex items-center text-red-800 border-solid border-b-2 border-current text-sm">
        <XIcon class="w-4 h-4 mr-2" />
        <span>Clear All Filters</span>
      </inertia-link> -->
      <!-- Collections -->
      <p v-if="collections.length" class="font-bold text-base mb-4">Collections</p>
      <div v-if="collections.length" class="relative mb-9">
        <ul :class="`text-sm space-y-2 pl-8 -ml-8 ${expandedFilter == 'collections' ? '' : 'overflow-auto pb-5 max-h-40'}`">
          <!-- <li><a href="#" class=" underline text-indigo-600">Category 1 (1,000)</a></li> -->
          <li v-for="item in collections.filter(item => !item.parent_id && (selectedCollection == item.id || expandedCollection == item.id))" :key="`collection-${item.id}`" class="relative">
            <button v-if="(item.children && item.children.length) && expandedCollection > -1" class="absolute -left-2 top-1 -translate-x-full -translate-y-[1px]" type="button" @click="expandedCollection = (expandedCollection == item.id) ? 0 : item.id">
              <MinusCircleIcon v-if="expandedCollection == item.id" class="w-4 h-4" />
              <PlusCircleIcon v-else class="w-4 h-4" />
            </button>
            <div class="flex gap-4 items-center justify-between">
              <inertia-link :href="route('cms.dam.assets.index', { ...route().params, collection: item.id})" :class="selectedCollection == item.id ? 'underline text-indigo-600' : ''">{{ item.name }} ({{ item.asset_count }})</inertia-link>
              <inertia-link v-if="selectedCollection == item.id" :href="route('cms.dam.assets.index', { ...route().params, collection: null})" class="text-red-500"><XIcon class="w-4 h-4" /></inertia-link>
            </div>
            <nested-collection-filter v-if="item.children && item.children.length" v-show="item.id == expandedCollection || expandedCollection == -1" :children="item.children" :selectedCollection="selectedCollection" />
          </li>
          <li v-for="item in collections.filter(item => !item.parent_id &&  selectedCollection != item.id && expandedCollection != item.id)" :key="`collection-${item.id}`" class="relative">
            <button v-if="(item.children && item.children.length) && expandedCollection > -1" class="absolute -left-2 top-1 -translate-x-full -translate-y-[1px]" type="button" @click="expandedCollection = (expandedCollection == item.id) ? 0 : item.id">
              <MinusCircleIcon v-if="expandedCollection == item.id" class="w-4 h-4" />
              <PlusCircleIcon v-else class="w-4 h-4" />
            </button>
            <div class="flex gap-4 items-center justify-between">
              <inertia-link :href="route('cms.dam.assets.index', { ...route().params, collection: item.id})" :class="selectedCollection == item.id ? 'underline text-indigo-600' : ''">{{ item.name }} ({{ item.asset_count }})</inertia-link>
              <inertia-link v-if="selectedCollection == item.id" :href="route('cms.dam.assets.index', { ...route().params, collection: null})" class="text-red-500"><XIcon class="w-4 h-4" /></inertia-link>
            </div>
            <nested-collection-filter v-if="item.children && item.children.length" v-show="item.id == expandedCollection || expandedCollection == -1" :children="item.children" :selectedCollection="selectedCollection" />
          </li>
        </ul>
        <div v-if="expandedFilter != 'collections'" class=" absolute left-0 bottom-9 bg-gradient-to-t from-gray-50 to-transparent w-full h-8 -ml-8 pointer-events-none "></div>
        <button type="button" @click="toggleFilterExpand('collections')" class="p-2 w-full flex items-center justify-center">
          <chevron-down-icon :class="`${expandedFilter == 'collections' ? 'rotate-180' : ''} w-5 h-5`" />
        </button>
      </div>
      <!-- /END collections -->
      <!-- CATEGORIES -->
      <p v-if="categories.length" class="font-bold text-base mb-4">Categories</p>

      <div v-if="categories.length" class="relative mb-9">
        <ul :class="`text-sm space-y-2 pl-8 -ml-8 ${expandedFilter == 'categories' ? '' : 'overflow-auto pb-5 max-h-40'}`">
          <!-- <li><a href="#" class=" underline text-indigo-600">Category 1 (1,000)</a></li> -->
          <li v-for="item in categories.filter(item => !item.parent_id && (selectedCategory == item.id || expandedCategory == item.id))" :key="`category-${item.id}`" class="relative">
            <button v-if="(item.children && item.children.length) && expandedCategory > -1" class="absolute -left-2 top-1 -translate-x-full -translate-y-[1px]" type="button" @click="expandedCategory = (expandedCategory == item.id) ? 0 : item.id">
              <MinusCircleIcon v-if="expandedCategory == item.id" class="w-4 h-4" />
              <PlusCircleIcon v-else class="w-4 h-4" />
            </button>
            <div class="flex gap-4 items-center justify-between">
              <inertia-link :href="route('cms.dam.assets.index', { ...route().params, category: item.id})" :class="selectedCategory == item.id ? 'underline text-indigo-600' : ''">{{ item.name }} ({{ item.asset_count }})</inertia-link>
              <inertia-link v-if="selectedCategory == item.id" :href="route('cms.dam.assets.index', { ...route().params, category: null})" class="text-red-500"><XIcon class="w-4 h-4" /></inertia-link>
            </div>
            <nested-category-filter v-if="item.children && item.children.length" v-show="item.id == expandedCategory || expandedCategory == -1" :children="item.children" :selectedCategory="selectedCategory" />
          </li>
          <li v-for="item in categories.filter(item => !item.parent_id && selectedCategory != item.id && expandedCategory != item.id)" :key="`category-${item.id}`" class="relative">
            <button v-if="(item.children && item.children.length) && expandedCategory > -1" class="absolute -left-2 top-1 -translate-x-full -translate-y-[1px]" type="button" @click="expandedCategory = (expandedCategory == item.id) ? 0 : item.id">
              <MinusCircleIcon v-if="expandedCategory == item.id" class="w-4 h-4" />
              <PlusCircleIcon v-else class="w-4 h-4" />
            </button>
            <div class="flex gap-4 items-center justify-between">
              <inertia-link :href="route('cms.dam.assets.index', { ...route().params, category: item.id})" :class="selectedCategory == item.id ? 'underline text-indigo-600' : ''">{{ item.name }} ({{ item.asset_count }})</inertia-link>
              <inertia-link v-if="selectedCategory == item.id" :href="route('cms.dam.assets.index', { ...route().params, category: null})" class="text-red-500"><XIcon class="w-4 h-4" /></inertia-link>
            </div>
            <nested-category-filter v-if="item.children && item.children.length" v-show="item.id == expandedCategory || expandedCategory == -1" :children="item.children" :selectedCategory="selectedCategory" />
          </li>
        </ul>
        <div v-if="expandedFilter != 'categories'" class=" absolute left-0 bottom-9 bg-gradient-to-t from-gray-50 to-transparent w-full h-8 -ml-8 pointer-events-none "></div>
        <button type="button" @click="toggleFilterExpand('categories')" class="p-2 w-full flex items-center justify-center">
          <chevron-down-icon :class="`${expandedFilter == 'categories' ? 'rotate-180' : ''} w-5 h-5`" />
        </button>
      </div>
      <!-- <ul class="text-sm mb-9 space-y-2">
        <li v-for="item in categories" :key="`category-${item.id}`">
          <inertia-link :href="route('cms.dam.assets.index', { ...route().params, category: item.id})" :class="selectedCategory == item.id ? 'underline text-indigo-600' : ''">{{ item.name }} ({{ item.asset_count }})</inertia-link>
        </li>
      </ul> -->
      <!-- /END CATEGORIES -->
      <!-- TYPES -->
      <p class="font-bold text-base mb-4">File Types</p>
      <ul class="text-sm mb-9 space-y-2">
        <li v-for="(type, idx) in types" :key="`filetype-${idx}`" class="flex items-center space-x-3">
          <input :id="`dam-filter-filetype-${idx}`" class="rounded border border-gray-300 w-4 h-4" type="checkbox" v-model="selectedTypes" :value="type.value" @change="filterByFileType">
          <label :for="`dam-filter-filetype-${idx}`">{{ type.label }}</label>
        </li>
      </ul>
      <!-- /END TYPES -->
      <!-- METAS -->
      <div v-for="(group, idx) in meta_groups" :key="`meta-group-${idx}`">
        <p class="font-bold text-base mb-4">{{ group.name }}</p>
        <ul class="text-sm mb-9 space-y-2">
          <li v-for="(item, idxV) in meta_values.filter(v => v.meta_id == group.id)" :key="`meta-group-${idx}-${idxV}`" class="flex items-center space-x-3">
            <input :id="`dam-filter-meta-group-${idx}-${idxV}`" class="rounded border border-gray-300 w-4 h-4" type="checkbox" v-model="selectedMeta" :value="item" @change="filterByMeta">
            <label :for="`dam-filter-meta-group-${idx}-${idxV}`">{{ item.value }}</label>
          </li>
        </ul>
      </div>
      <!-- /END METAS -->
    </div>
    <!-- /SIDEBAR -->
    <!-- PAGINATION + ASSETS + DROPZONE -->
    <div class="relative flex-grow">
      <!-- PAGINATION -->
      <div class="bg-gray-100 mb-7 w-full flex items-center text-xs gap-6 relative z-20">
        <button type="button" @click="selectAllAssets" class="flex-none px-6 py-3 border border-transparent text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
          Select {{ selectedAssets.length === items.data.length ? 'None' : 'All' }}
        </button>
        <button-dropdown v-if="selectedAssets.length" unstyled-button position="left">
          <template v-slot:button>
            <div class="flex items-center gap-1">
              <span>({{ selectedAssets.length }}) Selected</span>
              <chevron-down-icon class="w-4 h-4" />
            </div>
          </template>
          <template v-slot>
            <ul class="px-6 py-3 divide-y divide-gray-200 text-sm text-gray-600">
              <li class="py-3 hover:text-gray-800">
                <a target="_blank" :href="route('cms.dam.assets.zip', {assets: selectedAssetsIds})">Download as Zip</a>
              </li>
              <li class="py-3 hover:text-gray-800">
                <button type="button" @click="showActionModal()">Run Action</button>
              </li>
              <li class="py-3 hover:text-gray-800">
                <inertia-link method="delete" as="button" type="button" :href="route('cms.dam.assets.delete_selected')" :data="{ assets: selectedAssets }" v-on:finish="selectedAssets = []" >Delete Selected</inertia-link>
              </li>
              <!-- Hiding bulk edit for now -->
              <li class="py-3 hover:text-gray-800">
                <button type="button" @click="showEditSelectedModal()">Edit</button>
              </li>
            </ul>
          </template>
        </button-dropdown>
        <p class="px-1 py-1 flex-grow">{{ items.from }} - {{ items.to }} of {{ items.total }}</p>
        <div class="flex-none flex items-center py-1 px-4 space-x-5">
          <div class="ml-6 bg-gray-200 p-0.5 rounded-lg flex items-center">
            <button type="button"
              @click="setView('row')"
              :class="['p-1.5 rounded-md text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500', viewAs === 'row' ? 'shadow-sm bg-white' : 'hover:bg-white hover:shadow-sm']"
            >
              <view-list-icon class="h-5 w-5" />
              <span class="sr-only">Use list view</span>
            </button>
            <button type="button"
              @click="setView('grid')"
              :class="['ml-0.5 p-1.5 rounded-md text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500', viewAs === 'grid' ? 'shadow-sm bg-white' : 'hover:bg-white hover:shadow-sm']"
            >
              <view-grid-icon class="h-5 w-5" />
              <span class="sr-only">Use grid view</span>
            </button>
          </div>
          <button-dropdown unstyled-button width="wide">
            <template v-slot:button>
              <div class="flex items-center gap-1">
                <span>Sort By</span>
                <chevron-down-icon class="w-4 h-4" />
              </div>
            </template>
            <template v-slot>
              <ul class="px-6 py-3 divide-y divide-gray-200 text-sm text-gray-600">
                <li v-for="opt in sortOptions"
                  :key="`sortby-${opt.value}`"
                  :class="['py-3', sortingBy === opt.value ? 'text-indigo-600 font-bold' : 'hover:text-gray-800']"
                >
                  <span v-if="sortingBy === opt.value">
                    {{ opt.label }}
                  </span>
                  <inertia-link v-else :href="route('cms.dam.assets.index', { ...route().params, order_by: opt.value, page: 1})">
                    {{ opt.label }}
                  </inertia-link>
                </li>
              </ul>
            </template>
          </button-dropdown>

          <button type="button" title="Advanced Filter" @click="showAdvFilter = true">
            <adjustments-icon class="w-5 h-5 text-gray-500" />
          </button>

          <div class="flex gap-1 items-center justify-end">
            <inertia-link v-if="items.current_page > 2" :href="route('cms.dam.assets.index', {...route().params, page: 1 })">
              <ChevronDoubleLeftIcon class="w-3 h-3" />
            </inertia-link>
            <inertia-link v-if="items.current_page > 1" :href="route('cms.dam.assets.index', {...route().params, page: items.current_page-1 })">
              <ChevronLeftIcon class="w-3 h-3" />
            </inertia-link>
            <span>Page {{ items.current_page }} of {{ items.last_page }}</span>
            <inertia-link v-if="items.next_page_url" :href="route('cms.dam.assets.index', {...route().params, page: items.current_page+1})">
              <ChevronRightIcon class="w-3 h-3" />
            </inertia-link>
            <inertia-link v-if="items.last_page > items.current_page+1" :href="route('cms.dam.assets.index', {...route().params, page: items.last_page})">
              <ChevronDoubleRightIcon class="w-3 h-3" />
            </inertia-link>
          </div>
        </div>
      </div>
      <!-- /PAGINATION -->
      <!-- ASSETS -->
      <div v-if="items.data && items.data.length" class="flex flex-wrap items-start gap-6">
        <asset-card v-for="item,idx in items.data" :key="`asset-card-${idx}`" :item="item" v-model="selectedAssets" :linkToAsset="true" :display="viewAs" />
      </div>
      <div v-else class="py-20 px-10 text-center">
        <p class="mb-4 text-gray-500 font-bold">No results found.</p>
        <dam-button tag="a" :href="route('cms.dam.assets.create')">Upload Assets</dam-button>
      </div>
      <!-- /ASSETS -->
    </div>
    <!-- /PAGINATION + ASSETS + DROPZONE-->
    <ActionModal :assets="selectedAssets.map(id => items.data.find(item => item.id == id))" v-model="actionModal" />
    <EditSelectedModal :assets="selectedAssets.map(id => items.data.find(item => item.id == id))" v-model="editSelectedModal" />
    <AdvFilter v-model="showAdvFilter" />
  </div>
</template>
<script>
import AssetCard from './AssetCard';
import AdvFilter from './AdvFilter';
import ActionModal from './ActionModal';
import EditSelectedModal from './EditSelectedModal';
import ButtonDropdown from '../../../components/Misc/ButtonDropdown';
import NestedCollectionFilter from './NestedCollectionFilter';
import NestedCategoryFilter from './NestedCategoryFilter';
import DamButton from './Button'
import {
  ChevronDownIcon,
  ChevronRightIcon,
  ChevronLeftIcon,
  ChevronDoubleRightIcon,
  ChevronDoubleLeftIcon,
  AdjustmentsIcon,
  MinusCircleIcon,
  PlusCircleIcon,
  ViewListIcon,
  ViewGridIcon,
  XIcon,
} from '@vue-hero-icons/solid';

export default {
  name: 'AssetListing',
  components: {
    AssetCard,
    ActionModal,
    AdvFilter,
    ButtonDropdown,
    ChevronDownIcon,
    ChevronRightIcon,
    ChevronDoubleRightIcon,
    ChevronLeftIcon,
    ChevronDoubleLeftIcon,
    AdjustmentsIcon,
    EditSelectedModal,
    DamButton,
    NestedCategoryFilter,
    NestedCollectionFilter,
    MinusCircleIcon,
    PlusCircleIcon,
    ViewListIcon,
    ViewGridIcon,
    XIcon,
  },
  methods: {
    resizeSidebar($evt) {
      const sidebar = document.getElementById('cms-dam-sidebar');
      const resizeWidth = $evt.clientX - sidebar.getBoundingClientRect().left;
      if (resizeWidth > 200) {
        sidebar.style.width = resizeWidth + 'px';
      } else {
        sidebar.style.width = null;
      }
    },
    startResizeSidebar() {
      document.addEventListener('mousemove', this.resizeSidebar, false);
      document.addEventListener('mouseup', this.stopResizeSidebar, false);
    },
    stopResizeSidebar() {
      document.removeEventListener('mouseup', this.stopResizeSidebar, false);
      document.removeEventListener('mousemove', this.resizeSidebar, false);
      const sidebar = document.getElementById('cms-dam-sidebar');
      window.sessionStorage.setItem('cmsDamSidebarWidth', sidebar.style.width);
    },
    selectAllAssets() {
      if (this.selectedAssets.length === this.items.data.length) {
        this.selectedAssets = []; // Select none
      } else {
        this.selectedAssets = this.items.data.map(item => item.id);
      }
    },
    setView(view) {
      this.viewAs = view;
      window.sessionStorage.setItem('dam-view-as', view);
    },
    filterByMeta() {

      this.$inertia.visit(route(`cms.dam.assets.index`, {
        ...route().params,
        'meta': this.selectedMeta.map(sm => sm.meta_id + '|' + sm.value).join(',')
      }));
    },
    filterByFileType() {

      this.$inertia.visit(route(`cms.dam.assets.index`, {
        ...route().params,
        'types': this.selectedTypes.join(',')
      }));
    },
    showActionModal() {
      this.actionModal = true;
    },
    showEditSelectedModal() {
      this.editSelectedModal = true;
    },
    findAncestor(items, searchId) {
      let foundId = 0;
      items.forEach(item => {
        if (item.id == searchId) {
          foundId = item.id;
        } else if(Array.isArray(item.children) && item.children.length) {
          const childrenFoundId = this.findAncestor(item.children, searchId);
          if (childrenFoundId) {
            foundId = item.id;
          }
        }
      })
      return foundId;
    },
    toggleFilterExpand(type) {
      if (type == this.expandedFilter) {
        this.expandedFilter = 'none';
      } else {
        this.expandedFilter = type;
      }
    }
  },
  props: {
    items: {
      type: Object,
      default: () => {
        return {
          current_page: 1,
          data: [],
          from: 1,
          to: 1,
          last_page: 1,
          total: 1
        }
      }
    },
    categories: {
      type: Array,
      default: () => {
        return [];
      },
    },
    collections: {
      type: Array,
      default: () => {
        return [];
      },
    },
    meta_groups: {
      type: Array,
      default: () => {
        return [];
      }
    },
    meta_values: {
      type: Array,
      default: () => {
        return [];
      }
    },
    types: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  computed: {
    sortingBy() {
      return route().params.order_by || 'created_at,desc'
    },
    selectedAssetsIds() {
      return this.selectedAssets.join(',');
    }
  },
  data() {
    return {
      selectedAssets: [],
      selectedMeta: [],
      selectedTypes: [],
      selectedCollection: 0,
      selectedCategory: 0,
      expandedCollection: 0,
      expandedCategory: 0,
      expandedFilter: 'none',
      viewAs: 'grid',
      actionModal: false,
      editSelectedModal: false,
      showAdvFilter: false,
      sortOptions: [
        { label: 'Name (Ascending)', value: 'name,asc' },
        { label: 'Name (Descending)', value: 'name,desc' },
        { label: 'Created Date (Ascending)', value: 'created_at,asc' },
        { label: 'Created Date (Descending)', value: 'created_at,desc' },
        { label: 'Modified Date (Ascending)', value: 'updated_at,asc' },
        { label: 'Modified Date (Descending)', value: 'updated_at,desc' },
      ]
    };
  },
  mounted() {
    console.log('asset listing');
    const params = new URLSearchParams(window.location.search);
    const meta = params.get('meta');
    const collection = params.get('collection');
    const category = params.get('category');
    const types = params.get('types');
    if (meta) {
      meta.split(',').forEach(m => {
        const val = m.split('|');
        this.selectedMeta.push({meta_id: val[0], value: val[1]});
      });
    }
    if (parseInt(collection)) {
      this.selectedCollection = parseInt(collection);
      this.expandedCollection = this.findAncestor(this.collections, collection);
    }
    if (parseInt(category)) {
      this.selectedCategory = parseInt(category);
      this.expandedCategory = this.findAncestor(this.categories, category);
    }
    if (types) {
      this.selectedTypes = types.split(',');
    }
    this.viewAs = window.sessionStorage.getItem('dam-view-as') || 'grid';
    const sidebar = document.getElementById('cms-dam-sidebar');
    sidebar.style.width = window.sessionStorage.getItem('cmsDamSidebarWidth');
  },
};
</script>
<style>
.dropzone {
  background-color: rgba(255, 255, 255, 0.95) !important;
}
</style>
