<template>
  <!-- DEPRECATED: Replaced by BaseFile -->
  <!-- TODO: Delete in 2.0 -->
  <div
    class="file-upload-container cms-file-upload"
    @externalfile="externalFile"
    v-if="ready"
  >
    <div class="block">
      <div class="inline-block relative">
        <base-button
          class="mr-2 relative z-0"
        >
          select file
        </base-button>
        <input type="file" :name="id" :id="id" @change="changeFile" class="block opacity-0 absolute top-0 left-0 w-full h-full z-10 cursor-pointer" />
      </div>
      <base-button
        v-if="data.length"
        size="small"
        type="danger"
        class="ml-2"
        @click="deleteFiles"
      >
        delete files
      </base-button>
    </div>
    <div class="block">
      <base-checkbox class="mt-3" v-model="overwrite">
        Overwrite existing?
      </base-checkbox>
    </div>
    <div class="block files mt-4 mb-4">
      <div
        v-for="(file,idx) in data"
        :key="`file${idx}`"
        class="files__items m-4"
      >
        <div v-if="typeof file === 'string'">
          <img :src="file">
          <p class="mt-2">
            {{ file.split('/')[file.split('/').length - 1] }}
          </p>
        </div>
        <div v-else>
          <img
            v-if="isImage(file)"
            :src="file.url"
          >
          <p class="mt-2">
            {{ file.name }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Cropper from './Cropper';
import Vue from 'vue';
import axios from 'axios';
import { BaseCheckbox } from './index'
import { v4 as uuidv4 } from 'uuid'

let CropperClass = Vue.extend(Cropper);
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}
function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, {type:mime});
}

export default {
  name: 'FileUpload',
  components: {
    BaseCheckbox
  },
  props: {
    id: {
      type: String,
      default: () => uuidv4()
    },
    files: {
      type: Array,
      default: () => {
        return [];
      }
    },
    configID: {
      type: Number,
      default: 0
    },
    config: {
      type: Array,
      default: () => {
        return [{
          mode: "file",
          name: "Upload",
          retina: false,
          suffix: "",
          location: "public",
          directory: "/temp",
          dimensions: { width: "", height: "" }
        }];
      }
    },
    value: {
      type: [Array, Object],
      default: () => []
    },
    filename: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      justMounted: true,
      overwrite: false,
      fileList: [],
      upload_files: [],
      upload: {
        config: [],
        configID: 0
      },
      ready: false
    }
  },
  computed: {
    data: {
      get() { return this.value },
      set(data) {this.$emit('input', data)}
    }
  },
  watch: {
    fileList() {
      if (this.justMounted) {
        this.justMounted = false;
      } else {
        if (this.fileList.length) {
          this.$emit('input', this.fileList);
          this.$emit('fileupdated', this.fileList);
        }
      }
    }
  },
  mounted() {

    this.upload.config = this.config;
    // Make value array if for some reason not array
    if (!Array.isArray(this.value) && typeof this.value === 'object') {
      this.$emit('input', [this.value]);
    }
    this.fileList = this.files.length ? this.files : (this.value.length ? this.value : []);
    this.upload.configID = this.configID;

    if (this.configID > 0) {
      this.$root.$CMS_GET('uploads.show', this.configID).then((data)=>{
        this.upload.config = typeof data.upload.config === 'string' ? JSON.parse(data.upload.config) : data.upload.config;
        this.$nextTick(() => {
          this.ready = true;
        })
      });
    } else {
      this.ready = true;
    }
  },
  methods: {
    isImage(file) {
      const extensions = ['jpg', 'jpeg', 'png', 'gif', 'svg'];
      const arr = file.name.split('.');
      return file && extensions.includes(arr[arr.length - 1].toLowerCase());
    },
    changeFile(ev) {
      if (ev.target&& ev.target.files && ev.target.files[0]) {
        if (this.filename) {
          const extArray = ev.target.files[0].name.split('.');
          const ext = extArray[extArray.length - 1];
          this.updateFiles(new File([ev.target.files[0]], this.filename + '.' + ext));
        } else {
          this.updateFiles(ev.target.files[0]);
        }
      } else {
        console.log('Nothing to do.');
      }
    },
    updateFiles(file, fileList) {
      if (this.fileList.length) {
        this.fileList.splice(0, this.fileList.length);
      }
      this.upload_files = [];

      this.processFile(file, 0);

    },
    processFile(file, confIndex) {
      let conf = this.upload.config[confIndex];
      if (conf.mode === 'image-crop') {
        // Crop
        let image = file;
        getBase64(file).then((data) => {
          image.data = data;
          let instance = new CropperClass({
            propsData : {
              image: image,
              config: conf
            },
          });
          instance.$mount();
          instance.$on('cancel', () => {

              instance.$el.remove();
              instance.$destroy();
              instance = null;
              if (this.upload_files.length) {
                this.doDeleteFiles();
              }

          });
          instance.$on('crop', ($event) => {
            // base64toBlob($event).then(data => {
            //   this.fileList.push(data);
            // });

            // Instead of pushing the cropped image to the array,
            // upload it to the server and then push the url to the array
            // Orrrr wait to upload until done looping through everything?
            // In which case set up a temp variable for the files array
            //_this.fileList.push($event);

            this.upload_files.push($event);

            setTimeout(() => {
              instance.$el.remove();
              instance.$destroy();
              instance = null;

              this.processFileEnd(file, confIndex);

            }, 250);

          });
          this.$el.appendChild(instance.$el);
        });
      } else {
        getBase64(file).then((data) => {
          // Instead of pushing the cropped image to the array,
          // upload it to the server and then push the url to the array
          // _this.fileList.push(file.raw);
          this.upload_files.push({
            type: file.type,
            data: data.split(',').pop(),
            name: file.name
          });
          setTimeout(()=>{
            this.processFileEnd(file, confIndex);
          }, 250);
        });
      }
    },
    processFileEnd(file, confIndex) {
      if (typeof this.upload.config[confIndex+1] !== 'undefined') {
        this.processFile(file, confIndex+1);
      } else {
        if (this.upload_files.length) {
          this.$root.$CMS_FILE_UPLOAD({
            files: JSON.stringify(this.upload_files),
            configID: this.upload.configID,
            config: JSON.stringify(this.upload.config),
            overwrite: this.overwrite
          }).then((data) => {
            data.files.forEach(f => {
              // f.raw = dataURLtoFile(f.dataURL, f.name);
              this.fileList.push(f);
              // _this.data.push(f);
            });
          });
        }
        return;
      }
    },
    deleteFiles() {
      const _this = this;
      this.$root.$CMS_ALERT({
        title: "Are you sure?",
        text: `You won't be able to revert this!`,
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete them!",
      }).then(result => {
        if (result.isConfirmed) {
          _this.doDeleteFiles();
        }
      });
    },
    doDeleteFiles() {
      const _this = this;
      _this.$root.$CMS_FILE_DELETE({
        files: JSON.stringify(_this.fileList),
      }).then((data) => {
        if (data.status === 'success') {
          console.log('deleted!');
        } else {
          console.error('FILE DELETE: Something went wrong....');
          console.error(data);
        }
        _this.fileList = [];
        _this.$emit('input', []);
        _this.$emit('fileupdated', []);
      });
    },
    async externalFile(evt) {
      if (typeof evt.detail !== 'undefined') {
        await fetch(evt.detail)
          .then( response => response.blob() )
          .then(blob => {
            var filename = evt.detail.split('/');
            filename = filename[filename.length-1];
            // var file = dataURLtoFile(data, filename);
            var file = new File([blob], filename, {mime: blob.type})

            this.updateFiles(file, this.fileList);
          })
      }
    }
  }
}
</script>
<style scoped>
  .files {
    display: flex;
    flex-wrap: wrap;
  }
  .files__items {
    width: 200px;
  }
</style>
