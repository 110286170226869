<template>
<!-- This example requires Tailwind CSS v2.0+ -->
<div class="fixed z-10 inset-0 overflow-y-auto">
  <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
    <transition
      name="modal-bg"
      enter-active-class="ease-out duration-300"
      enter-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="ease-in duration-200"
      leave-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <div class="fixed inset-0 transition-opacity" aria-hidden="true">
        <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
      </div>
    </transition>

    <!-- This element is to trick the browser into centering the modal contents. -->
    <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

    <slot v-on:close="close()" />

  </div>
</div>
</template>
<script>
export default {
  name: 'BaseModal',
  data() {
    return {
      visible: false
    }
  },
  methods: {
    close() {
      this.visible = false;
      setTimeout(() => {
        this.$destroy();
      }, 500)
    }
  },
  mounted() {
    setTimeout(() => {
      this.visible = true
    }, 500)
  }
}
</script>
